import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import BUModal from '../BusinessUnit/BUModal';
import RegionModal from '../Region/RegionModal';
import {
  indexBusinessUnit,
  updateBusinessUnit,
  deleteBusinessUnit,
} from '../../../redux/modules/businessUnits';
import {
  indexRegion,
  updateRegion,
  createRegion,
  deleteRegion,
} from '../../../redux/modules/regions';
import { BusinessUnit } from '../../../common/models/businessUnit';
import { Region, RegionData } from '../../../common/models/region';
import loadData from '../../../utils/loadData';

type RegionNewOrEditData = RegionData & { _id?: string };

const BUTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [nameSort, setNameSort] = useState('asc');
  const [isOpenBUModal, setIsOpenBUModal] = useState(false);
  const [businessUnit, setBusinessUnit] = useState({} as BusinessUnit);
  const businessUnits = useSelector(state => state.businessUnits.data);
  const busSorted = useMemo(() => {
    return _.orderBy(businessUnits, 'name', nameSort);
  }, [nameSort, businessUnits]);
  const [isOpenRegionModal, setIsOpenRegionModal] = useState(false);
  const [region, setRegion] = useState({} as RegionNewOrEditData);
  const regions = useSelector(state => state.regions.data);

  const changeNameSort = () => {
    const newSort = nameSort === 'asc' ? 'desc' : 'asc';
    setNameSort(newSort);
  };

  const editBU = (bu: BusinessUnit) => {
    return () => {
      setIsOpenBUModal(true);
      setBusinessUnit(bu);
    };
  };

  const closeBUModal = () => {
    setIsOpenBUModal(false);
  };

  const handleBUEdit = data => {
    dispatch(updateBusinessUnit(businessUnit._id, data)).then(() => {
      closeBUModal();
    });
  };

  const newRegion = (bu: BusinessUnit) => {
    return () => {
      setRegion({ businessUnitId: bu._id });
      setIsOpenRegionModal(true);
    };
  };

  const editRegion = region => {
    return () => {
      setRegion(region);
      setIsOpenRegionModal(true);
    };
  };

  const closeRegionModal = () => {
    setIsOpenRegionModal(false);
  };

  const handleRegion = data => {
    if (region._id) {
      dispatch(updateRegion(region._id, data)).then(closeRegionModal);
    } else {
      dispatch(createRegion(data)).then(closeRegionModal);
    }
  };

  const buildBUItem = (bu: BusinessUnit) => {
    return (
      <tr key={bu._id}>
        <td className="has-regular-align">
          <div className="td-container">{bu.name}</div>
        </td>
        <td>
          <div className="td-container">
            <Button className="btn-primary text-small" onClick={newRegion(bu)}>
              <i className="fa fa-plus m-r" />
              {t('businessUnit.addARegion')}
            </Button>
            <ButtonGroup className="pull-right show-hover">
              <Button className="list-action btn-link text-muted" onClick={editBU(bu)}>
                <i className="fa fa-edit" />
              </Button>
              {(!bu.regions || bu.regions.length === 0) && (
                <ConfirmButton
                  className="list-action btn-link text-danger text-danger-hover"
                  onClick={() => dispatch(deleteBusinessUnit(bu._id))}
                  message={t('businessUnit.removeThisBU')}
                  confirmButtonStyle="danger"
                >
                  <i className="fa fa-trash-o" />
                </ConfirmButton>
              )}
            </ButtonGroup>
          </div>
        </td>
      </tr>
    );
  };

  const buildRegionItem = (region: Region) => {
    return (
      <tr key={`region_${region._id}`}>
        <td />
        <td>
          <div className="td-container">
            {region.name}
            <ButtonGroup className="pull-right show-hover">
              <Button className="list-action btn-link text-muted" onClick={editRegion(region)}>
                <i className="fa fa-edit" />
              </Button>
              <ConfirmButton
                className="list-action btn-link text-danger text-danger-hover"
                onClick={() => dispatch(deleteRegion(region._id))}
                message={t('businessUnit.removeThisRegion')}
                confirmButtonStyle="danger"
              >
                <i className="fa fa-trash-o" />
              </ConfirmButton>
            </ButtonGroup>
          </div>
        </td>
      </tr>
    );
  };

  const buildBURegionList = () => {
    const busRegionsList: Array<JSX.Element> = [];
    busSorted.forEach((bu: BusinessUnit) => {
      busRegionsList.push(buildBUItem(bu));
      _.filter(regions, { businessUnitId: bu._id }).forEach((region: Region) => {
        busRegionsList.push(buildRegionItem(region));
      });
    });
    return busRegionsList;
  };

  return (
    <div>
      <table
        className="table table-striped table-bordered table-hover dataTable no-footer"
        role="grid"
      >
        <thead>
          <tr role="row">
            <th className={`sorting_${nameSort}`} onClick={changeNameSort}>
              {t('businessUnit.name')}
            </th>
            <th>{t('region.regions')}</th>
          </tr>
        </thead>
        <tbody>{buildBURegionList()}</tbody>
      </table>
      <BUModal
        businessUnit={businessUnit}
        open={isOpenBUModal}
        close={closeBUModal}
        onSubmit={handleBUEdit}
      />
      <RegionModal
        region={region}
        businessUnits={businessUnits}
        users={[]}
        open={isOpenRegionModal}
        close={closeRegionModal}
        onSubmit={handleRegion}
      />
    </div>
  );
};

export default loadData(async dispatch => {
  await dispatch(indexBusinessUnit());
  await dispatch(indexRegion());
})(BUTable);
