import React from 'react';
import { Form, Input } from 'formsy-react-components';
import { withTranslation } from 'react-i18next';
import BaseForm from '../Form/BaseForm';

export class PasswordForm extends BaseForm {
  handleSubmit(data) {
    const formData = Object.assign({}, data);
    if (!formData.password) {
      delete formData.password;
    }
    this.props.onSubmit(formData);
  }

  render() {
    const { t, onCancel } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} onValid={this.enableButton} onInvalid={this.disableButton}>
        <Input
          className="form-control form-control-lg"
          layout="vertical"
          name="oldPassword"
          value=""
          label={t('password.oldPassword')}
          type="password"
          validations="minLength:8"
          validationError={t('password.passwordMin8Char')}
          placeholder={t('password.yourPassword')}
          required
        />
        <Input
          className="form-control form-control-lg"
          layout="vertical"
          name="newPassword"
          value=""
          label={t('password.newPassword')}
          type="password"
          validations="minLength:8"
          validationError={t('password.passwordMin8Char')}
          placeholder={t('password.yourPassword')}
          required
        />
        <Input
          className="form-control form-control-lg"
          layout="vertical"
          name="newPasswordConfirmation"
          value=""
          label={t('password.confirmPassword')}
          type="password"
          validations="equalsField:newPassword"
          validationErrors={{
            equalsField: t('password.passwordsMustMatch'),
          }}
          placeholder={t('password.retypePassword')}
          required
        />
        <p className="indication-text">{t('password.passwordRequirements')}</p>

        <div className="text-center m-t-lg m-b-md">
          <button
            type="submit"
            className="btn btn-primary btn-lg m-r-md"
            formNoValidate
            disabled={!this.state.canSubmit}
          >
            {t('common.save')}
          </button>
          <button type="button" className="btn btn-lg btn-secondary" onClick={onCancel}>
            {t('common.cancel')}
          </button>
        </div>
      </Form>
    );
  }
}

export default withTranslation()(PasswordForm);
