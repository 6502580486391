import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

export class TreeNodes extends Component {
  constructor(props) {
    super(props);
    this.handleNodeChange = this.handleNodeChange.bind(this);
  }

  handleNodeChange(node, key, value) {
    const nodes = _.cloneDeep(this.props.nodes);
    nodes.forEach(n => {
      const uniq = n.id ? 'id' : 'key';
      if (n[uniq] === node[uniq]) {
        Object.assign(n, node);
      }
    });
    this.props.onChange(nodes, node, key, value);
  }

  render() {
    const { nodes } = this.props;
    if (nodes.length === 0) {
      return null;
    }
    return (
      <div className="treenode">
        {nodes.map(
          node =>
            node && (
              <Node
                key={node.id || node.key}
                node={node}
                onChange={this.handleNodeChange}
                actions={this.props.actions}
                lastLevelCollapsed={this.props.lastLevelCollapsed}
              />
            ),
        )}
      </div>
    );
  }
}

TreeNodes.propTypes = {
  nodes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  actions: PropTypes.func,
  lastLevelCollapsed: PropTypes.bool,
};

export class Node extends Component {
  constructor(props) {
    super(props);
    let collapsed = props.node.collapsed;
    if (
      props.node &&
      props.node.nodes &&
      props.node.nodes.length > 0 &&
      props.node.nodes[0] &&
      !props.node.nodes[0].nodes &&
      props.lastLevelCollapsed
    ) {
      collapsed = true;
    }
    this.state = { collapsed };
    this.handleClick = this.handleClick.bind(this);
    this.handleNodesChange = this.handleNodesChange.bind(this);
  }

  handleClick() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  buildNode(node) {
    const arrowClassName = classNames('treenode__arrow', {
      'treenode__arrow--collapsed': this.state.collapsed,
    });
    const nodeClassName = classNames('node__name', {
      clickable: node.nodes && node.nodes.length > 0,
    });
    return (
      <div>
        {this.props.actions &&
          !this.props.node.hideActions &&
          React.createElement(this.props.actions, { node, onChange: this.props.onChange })}
        {node.nodes && node.nodes.length > 0 && (
          <div onClick={this.handleClick} className={arrowClassName} />
        )}
        <div onClick={this.handleClick} className={nodeClassName}>
          {node.name}
        </div>
      </div>
    );
  }

  handleNodesChange(nodes, child, key) {
    const node = _.cloneDeep(this.props.node);
    if (node.selectType === 'radio') {
      nodes.forEach(n => {
        n[key] = false;
        const uniq = n.id ? 'id' : 'key';
        if (n[uniq] === child[uniq]) {
          n[key] = true;
        }
      });
    }

    if (nodes.every(n => !n[key])) {
      node[key] = false;
    }
    this.props.onChange(Object.assign({}, node, { nodes }));
  }

  render() {
    const { node } = this.props;
    return (
      <div className="treenode__node">
        {node && this.buildNode(node)}
        {node.nodes && !this.state.collapsed && (
          <TreeNodes
            nodes={node.nodes}
            onChange={this.handleNodesChange}
            actions={this.props.actions}
            lastLevelCollapsed={this.props.lastLevelCollapsed}
          />
        )}
      </div>
    );
  }
}

Node.propTypes = {
  node: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  actions: PropTypes.func,
  lastLevelCollapsed: PropTypes.bool,
};
