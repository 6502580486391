import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { OEEFilterState, updateOEEFilter } from '../../../redux/modules/oeeFilter';

const OEEInputChangeMonthButtons: React.FC<{ onChange: Function }> = ({ onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const oeeFilter: OEEFilterState = useSelector(state => state.oeeFilter);
  const now = new Date();
  const { date } = oeeFilter;

  const handleFilterMonth = (n: number) => {
    return e => {
      e.preventDefault();
      const newFilterMonth = moment(date).add(n, 'month');
      const newFilter = Object.assign({}, oeeFilter, {
        date: newFilterMonth.toDate(),
      });
      dispatch(updateOEEFilter(newFilter));
      onChange(newFilter);
    };
  };
  return (
    <div className="m-t-2 text-center">
      <a href="#\" className="btn btn-lg btn-secondary m-a-3" onClick={handleFilterMonth(-1)}>
        <i className="fa fa-lg fa-angle-left m-r" />
        {t('OEEInput.previousMonth')}
      </a>
      {(now.getFullYear() !== date.getFullYear() || now.getMonth() !== date.getMonth()) && (
        <a href="#\" className="btn btn-lg btn-secondary m-a-3" onClick={handleFilterMonth(1)}>
          {t('OEEInput.nextMonth')}
          <i className="fa fa-lg fa-angle-right m-l" />
        </a>
      )}
    </div>
  );
};

export default OEEInputChangeMonthButtons;
