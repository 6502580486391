import React from 'react';
import { Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../common/i18n';

const Locale: React.FC = () => {
  const { t, i18n } = useTranslation();

  const setAppLang = (lng: string): void => {
    i18n.changeLanguage(lng);
  };

  return (
    <Nav className="pull-right m-r-3 locale--select">
      <NavDropdown eventKey={2} title={<span>{t('language')}</span>} id="dropdown-locale">
        {LANGUAGES.map((lng, index) => {
          return (
            <MenuItem eventKey={index} key={index} onClick={setAppLang.bind(null, lng)}>
              <i className="fa fa-flag" /> {t('language', { lng })}
            </MenuItem>
          );
        })}
      </NavDropdown>
    </Nav>
  );
};

export default Locale;
