import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { Input, Form, Checkbox } from 'formsy-react-components';
import Select from 'react-select';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import BaseForm from '../../Form/BaseForm';
import { LineType, Unit } from '../../../../common/models/plant';

class LineForm extends BaseForm {
  constructor(props) {
    super(props);

    const {
      t,
      data: { type, unit, isShift },
    } = props;
    this.state = {
      type: type ? { value: type, label: t(`plant.lineTypes.${type}`) } : '',
      unit: unit ? { value: unit, label: t(`plant.lineUnits.${unit}`) } : '',
      showShifts: !!isShift,
    };
  }

  handleSubmit(data) {
    this.props.onSubmit(data);
  }

  typeOptions() {
    const { t } = this.props;
    return _.values(LineType).map(type => ({ value: type, label: t(`plant.lineTypes.${type}`) }));
  }

  unitOptions() {
    const { t } = this.props;
    return _.values(Unit).map(unit => ({ value: unit, label: t(`plant.lineUnits.${unit}`) }));
  }

  handleChangeType = selection => {
    this.setState({ type: selection });
  };

  handleChangeUnit = selection => {
    this.setState({ unit: selection });
  };

  isEditLine = () => {
    return !!this.props.data._id;
  };

  handleChangeShifts = (_name, value) => {
    this.setState({ showShifts: value });
  };

  checkShifts = (_values, value) => {
    if (value === undefined) {
      return true;
    }
    try {
      const shifts = parseInt(value, 10);
      if (shifts < 1) {
        return this.props.t('errors.shiftsTotal');
      }
    } catch (e) {
      return false;
    }
    return true;
  };

  render() {
    const {
      t,
      data: { name, slug, startDate, endDate, designCapacity, isShift, shifts },
    } = this.props;

    const { type, unit } = this.state;
    return (
      <Row>
        <Col lg={12}>
          <Form
            onSubmit={this.handleSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="name"
              value={name}
              label={t('plant.lineName')}
              type="text"
            />

            <label className="control-label" htmlFor="identifier">
              {t('plant.lineIdentifier')}
            </label>
            <input
              className="form-control form-control-lg m-b"
              name="slug"
              value={slug}
              type="text"
              placeholder={t('plant.willBeGenerated')}
              readOnly
              disabled
            />

            <Input type="hidden" name="slug" value={slug} />

            <label className="control-label" htmlFor="type">
              {t('plant.lineType')}
            </label>
            <Select
              name="type"
              value={type}
              options={this.typeOptions()}
              onChange={this.handleChangeType}
              placeholder={t('plant.selectLineType')}
              className="m-b"
            />

            <Input type="hidden" name="type" value={type.value} />

            <label className="control-label" htmlFor="isShift">
              {t('plant.lineIsShift')}
            </label>
            <Checkbox
              name="isShift"
              value={isShift}
              onChange={this.handleChangeShifts}
              labelClassName="hidden"
              disabled={this.isEditLine()}
            />
            {this.isEditLine() && (
              <Alert bsStyle="warning" className="edit-line-shift__alert">
                {t('plant.editLineShiftAlert')}
              </Alert>
            )}

            {this.state.showShifts && (
              <Input
                className="form-control form-control-lg"
                layout="vertical"
                name="shifts"
                value={shifts}
                label={t('plant.lineShifts')}
                type="number"
                disabled={this.isEditLine()}
                validations={{
                  checkShifts: this.checkShifts,
                }}
                required
              />
            )}

            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="startDate"
              value={moment(startDate).format('YYYY-MM-DD')}
              label={t('plant.lineStartDate')}
              type="date"
            />

            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="endDate"
              value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
              label={t('plant.lineEndDate')}
              type="date"
            />

            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="designCapacity"
              value={designCapacity}
              label={t('plant.lineDesignCapacity')}
              type="number"
            />

            <label className="control-label" htmlFor="unit">
              {t('plant.lineUnit')}
            </label>
            <Select
              name="unit"
              value={unit}
              options={this.unitOptions()}
              onChange={this.handleChangeUnit}
              placeholder={t('plant.selectLineUnit')}
              className="m-b"
            />

            <Input type="hidden" name="unit" value={unit.value} />

            <div className="text-center m-t-lg">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                formNoValidate
                disabled={!this.state.canSubmit}
              >
                {t('common.save')}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(LineForm);
