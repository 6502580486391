import React from 'react';
import { useDispatch } from 'react-redux';
import { NavDropdown, MenuItem, Nav, Navbar, NavItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { signout } from '../../../redux/modules/auth';
import Locale from '../../Locale/Locale';
import SiteNav from '../SiteNav/SiteNav';
import { mailtoAdmin } from '../../../utils/mailto';
import ProfileAvatar from '../Avatar/ProfileAvatar';
import { APP_PROFILE_PATH, APP_NEWS_PATH } from '../../../utils/routeHelper';

const Header: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <header className="header">
      <Navbar className="navbar-static-top" bsStyle="default" fluid>
        <SiteNav />
        <Nav className="pull-right">
          <NavDropdown eventKey={1} title={<ProfileAvatar />} id="dropdown-user">
            <MenuItem
              eventKey={1.1}
              onClick={() => {
                history.push(APP_PROFILE_PATH);
              }}
            >
              <i className="fa fa-user fa-fw" /> {t('menu.userProfile')}
            </MenuItem>
            <MenuItem
              className="hidden"
              eventKey={1.2}
              onClick={() => {
                /*props.onNotifications*/
              }}
            >
              <i className="fa fa-exclamation fa-fw" /> {t('menu.notifications')}
            </MenuItem>
            <MenuItem
              eventKey={1.3}
              onClick={() => {
                dispatch(signout());
              }}
            >
              <i className="fa fa-sign-out fa-fw" /> {t('menu.logout')}
            </MenuItem>
          </NavDropdown>
        </Nav>
        <Locale />
        <Nav className="pull-right m-r-3 text-upper hidden-xs-down">
          <NavItem
            onClick={() => {
              history.push(APP_NEWS_PATH);
            }}
          >
            <i className="fa fa-newspaper-o m-r" />
            {t('news.news')}
          </NavItem>
        </Nav>
        <Nav className="pull-right m-r-3 text-upper hidden-xs-down">
          <NavItem eventKey={1} href={mailtoAdmin()}>
            <i className="fa fa-envelope-o m-r" />
            {t('menu.contact')}
          </NavItem>
        </Nav>
      </Navbar>
    </header>
  );
};

export default withRouter(Header);
