import { Model } from './model';

export const CONTACTS = [
  'operationManager',
  'maintenanceManager',
  'plantDirector',
  'plantManager',
  'plantOeeDelegate',
] as const;

export enum LineType {
  type1 = 'type1',
  type2 = 'type2',
  type3 = 'type3',
  type4 = 'type4',
  type5 = 'type5',
  type6 = 'type6',
  type7 = 'type7',
  type8 = 'type8',
  type9 = 'type9',
  type10 = 'type10',
  type11 = 'type11',
  type12 = 'type12',
  type13 = 'type13',
}

export enum Activity {
  activity1 = 'activity1',
  activity2 = 'activity2',
}

export enum Unit {
  unit1 = 'unit1',
  unit2 = 'unit2',
  unit3 = 'unit3',
}

export interface LineData {
  name: string;
  type: LineType;
  startDate: Date;
  endDate?: Date;
  designCapacity?: number;
  unit?: Unit;
  position?: number;
  isShift?: boolean;
  shifts?: number;
}

export interface Line extends LineData {
  slug: string;
  position: number;
}

type ContactsList = typeof CONTACTS[number];
export type Contacts = { [contact in ContactsList]?: string };

export interface PlantData {
  // basic information
  name: string;
  slug: string;
  businessUnitId: string;
  regionId?: string;
  startYear?: Date;
  address?: string;
  coordinates?: number[];
  phone?: string;
  website?: string;
  description?: object;
  // lines
  lines?: LineData[];
}

export interface Plant extends Model, PlantData {
  // lines
  lines?: Line[];
  // virtual
  archived: boolean;
}
