import {
  API_INDEX_OEE_INPUT_PATH,
  API_CREATE_OEE_INPUT_PATH,
  API_UPDATE_OEE_INPUT_PATH,
  API_VALIDATE_OEE_INPUT_PATH,
  API_UNVALIDATE_OEE_INPUT_PATH,
} from '../../utils/routeHelper';
import { updateCollection, addToCollection } from '../../utils/collection';
import { OEEInput, OEEInputData } from '../../common/models/oeeInput';
import { error } from '../../utils/error';
import { queryToParams } from '../../utils/appHelper';
import { SIGNOUT } from './auth';

export const INDEX_OEE_INPUT = 'oee_inputs/INDEX_OEE_INPUT';
export const INDEX_OEE_INPUT_SUCCESS = 'oee_inputs/INDEX_OEE_INPUT_SUCCESS';
export const INDEX_OEE_INPUT_FAIL = 'oee_inputs/INDEX_OEE_INPUT_FAIL';
export const CREATE_OEE_INPUT = 'oee_inputs/CREATE_OEE_INPUT';
export const CREATE_OEE_INPUT_SUCCESS = 'oee_inputs/CREATE_OEE_INPUT_SUCCESS';
export const CREATE_OEE_INPUT_FAIL = 'oee_inputs/CREATE_OEE_INPUT_FAIL';
export const UPDATE_OEE_INPUT = 'oee_inputs/UPDATE_OEE_INPUT';
export const UPDATE_OEE_INPUT_SUCCESS = 'oee_inputs/UPDATE_OEE_INPUT_SUCCESS';
export const UPDATE_OEE_INPUT_FAIL = 'oee_inputs/UPDATE_OEE_INPUT_FAIL';
export const VALIDATE_OEE_INPUT = 'oee_inputs/VALIDATE_OEE_INPUT';
export const VALIDATE_OEE_INPUT_SUCCESS = 'oee_inputs/VALIDATE_OEE_INPUT_SUCCESS';
export const VALIDATE_OEE_INPUT_FAIL = 'oee_inputs/VALIDATE_OEE_INPUT_FAIL';
export const UNVALIDATE_OEE_INPUT = 'oee_inputs/UNVALIDATE_OEE_INPUT';
export const UNVALIDATE_OEE_INPUT_SUCCESS = 'oee_inputs/UNVALIDATE_OEE_INPUT_SUCCESS';
export const UNVALIDATE_OEE_INPUT_FAIL = 'oee_inputs/UNVALIDATE_OEE_INPUT_FAIL';
export const RESET_OEE_INPUT_DATA = 'oee_inputs/RESET_OEE_INPUT_DATA';

interface OEEInputState {
  data?: Array<OEEInput>;
  loading: boolean;
  creating?: boolean;
  updating?: boolean;
  validating?: boolean;
  unvalidating?: boolean;
  error?: string;
  createError?: string;
  updateError?: string;
}

export interface IndexOEEInputQuery {
  plantId: string;
  line: string;
  endYear: number;
  endMonth: number;
}

const initialState: OEEInputState = {
  data: [],
  loading: false,
  error: undefined,
};

export default function reducer(
  state: OEEInputState = initialState,
  action: ActionResult,
): OEEInputState {
  switch (action.type) {
    case INDEX_OEE_INPUT:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_OEE_INPUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_OEE_INPUT_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case CREATE_OEE_INPUT:
      return {
        ...state,
        creating: true,
        createError: undefined,
      };
    case CREATE_OEE_INPUT_SUCCESS:
      return {
        ...state,
        creating: false,
        data: addToCollection(state.data, action.result.data),
      };
    case CREATE_OEE_INPUT_FAIL:
      return {
        ...state,
        creating: false,
        createError: error(action),
      };
    case UPDATE_OEE_INPUT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_OEE_INPUT_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_OEE_INPUT_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case VALIDATE_OEE_INPUT:
      return {
        ...state,
        validating: true,
        updateError: undefined,
      };
    case VALIDATE_OEE_INPUT_SUCCESS:
      return {
        ...state,
        validating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case VALIDATE_OEE_INPUT_FAIL:
      return {
        ...state,
        validating: false,
        updateError: error(action),
      };
    case UNVALIDATE_OEE_INPUT:
      return {
        ...state,
        unvalidating: true,
        updateError: undefined,
      };
    case UNVALIDATE_OEE_INPUT_SUCCESS:
      return {
        ...state,
        unvalidating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UNVALIDATE_OEE_INPUT_FAIL:
      return {
        ...state,
        unvalidating: false,
        updateError: error(action),
      };
    case RESET_OEE_INPUT_DATA:
      return initialState;
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexOEEInput(query: IndexOEEInputQuery): ActionPromise {
  return {
    types: [INDEX_OEE_INPUT, INDEX_OEE_INPUT_SUCCESS, INDEX_OEE_INPUT_FAIL],
    promise: client => client.get(API_INDEX_OEE_INPUT_PATH(queryToParams(query))),
  };
}

export function createOEEInput(data: OEEInputData): ActionPromise {
  return {
    types: [CREATE_OEE_INPUT, CREATE_OEE_INPUT_SUCCESS, CREATE_OEE_INPUT_FAIL],
    promise: client => client.post(API_CREATE_OEE_INPUT_PATH, data),
  };
}

export function updateOEEInput(id: string, data: OEEInputData): ActionPromise {
  return {
    types: [UPDATE_OEE_INPUT, UPDATE_OEE_INPUT_SUCCESS, UPDATE_OEE_INPUT_FAIL],
    promise: client => client.put(API_UPDATE_OEE_INPUT_PATH(id), data),
  };
}

export function validateOEEInput(oeeInput: OEEInput): ActionPromise {
  return {
    types: [VALIDATE_OEE_INPUT, VALIDATE_OEE_INPUT_SUCCESS, VALIDATE_OEE_INPUT_FAIL],
    promise: client => client.put(API_VALIDATE_OEE_INPUT_PATH(oeeInput._id), oeeInput),
  };
}

export function unvalidateOEEInput(oeeInput: OEEInput): ActionPromise {
  return {
    types: [UNVALIDATE_OEE_INPUT, UNVALIDATE_OEE_INPUT_SUCCESS, UNVALIDATE_OEE_INPUT_FAIL],
    promise: client => client.put(API_UNVALIDATE_OEE_INPUT_PATH(oeeInput._id), oeeInput),
  };
}

export function resetOEEInput(): ActionPreload {
  return { type: RESET_OEE_INPUT_DATA };
}
