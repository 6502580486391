import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash';
import OEEInputHelpPopover from '../OEEInputHelpPopover';
import { PlantAndLine } from '../../../../redux/modules/plants';

interface OEEInputWasteTreatedTheadProps {
  plantAndLine: PlantAndLine;
}

const OEEInputWasteTreatedThead: React.FC<OEEInputWasteTreatedTheadProps> = ({ plantAndLine }) => {
  const { t } = useTranslation();
  const lineUnit = plantAndLine.line && plantAndLine.line.unit && (
    <div>[{t(`plant.lineUnits.${plantAndLine.line.unit}`)}]</div>
  );
  const shiftName = plantAndLine.shift ? t('plant.shiftN', { n: _.last(plantAndLine.shift) }) : '';
  return (
    <thead>
      <tr role="row">
        <th style={{ width: '30px' }} className="cell-invisible" />
        <th style={{ width: '98px' }}>{t('OEEInput.date')}</th>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover(
            plantAndLine.shift ? 'shiftWasteTreated' : 'dailyWasteTreated',
          )}
        >
          <th style={{ width: '100px' }} className="help">
            {t(plantAndLine.shift ? 'OEEInput.shiftWasteTreated' : 'OEEInput.dailyWasteTreated', {
              shift: shiftName,
            })}
            {lineUnit}
          </th>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover(plantAndLine.shift ? 'shiftLoss' : 'dailyLoss')}
        >
          <th style={{ width: '100px' }} className="help">
            {t(plantAndLine.shift ? 'OEEInput.shiftLoss' : 'OEEInput.dailyLoss', {
              shift: shiftName,
            })}
            {lineUnit}
          </th>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover(plantAndLine.shift ? 'shiftLossDetail' : 'dailyLossDetail')}
        >
          <th style={{ width: '100px' }} className="help">
            {t(plantAndLine.shift ? 'OEEInput.shiftLossDetail' : 'OEEInput.dailyLossDetail', {
              shift: shiftName,
            })}
            {lineUnit}
          </th>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover('constraint')}
        >
          <th style={{ width: '40%' }} className="help">
            {t('OEEInput.reason')}
          </th>
        </OverlayTrigger>
        <th style={{ width: '105px' }}>{t('OEEInput.comments')}</th>
        <th style={{ width: '50px' }} />
      </tr>
    </thead>
  );
};

export default React.memo(OEEInputWasteTreatedThead);
