import _ from 'lodash';
import { Plant } from '../common/models/plant';
import { BusinessUnit } from '../common/models/businessUnit';
import { Region } from '../common/models/region';
import { UPLOADED_FILE_PATH } from './routeHelper';
import { PhotoCategory, PhotoVersion } from '../common/upload';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';

export function plantBusinessUnit(plant: Plant, businessUnits: BusinessUnit[]): BusinessUnit {
  return _.find(businessUnits, { _id: plant.businessUnitId }) || {};
}

export function plantRegion(plant: Plant, regions: Region[]): Region {
  return _.find(regions, { _id: plant.regionId }) || {};
}

export function plantPhotoUrl(
  photo: string,
  version: PhotoVersion = PhotoVersion.default,
  category: PhotoCategory = PhotoCategory.photo,
): string {
  const filename = photo.split('.');
  let dir = 'plant_photos';
  if (category === PhotoCategory.diagram) {
    dir = 'plant_diagrams';
  }
  switch (version) {
    case PhotoVersion.normal:
      return `${UPLOADED_FILE_PATH}/${dir}/${filename[0]}_normal.${filename[1]}`;
    case PhotoVersion.large:
      return `${UPLOADED_FILE_PATH}/${dir}/${filename[0]}_large.${filename[1]}`;
    default:
      return `${UPLOADED_FILE_PATH}/${dir}/${photo}`;
  }
}

export function plantDescription(plant: Plant & { description: string }) {
  if (_.isString(plant.description)) {
    const blocksFromHTML = convertFromHTML(plant.description);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    return convertToRaw(state);
  }
  return plant.description;
}

export function plantLineShifts(plants: Plant[], plantId: string, line: string): string[] {
  const plant = plants.find(p => p._id === plantId);
  if (plant) {
    //@ts-ignore
    const plantLine = plant.lines.find(l => l.slug === line);
    if (plantLine && plantLine.isShift && plantLine.shifts && plantLine.shifts > 0) {
      return _.times(plantLine.shifts).map(i => `shift${i + 1}`);
    }
    return [];
  }
  return [];
}
