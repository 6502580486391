import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

/**
 * A standard bootstrap button that allows to pass a value as parameter.
 * On a click, the handler function will provide the given value as parameter.
 * @returns {ReactDOM}
 */
const ValueButton = ({ children, value, onClick, className, bsStyle, title }) => {
  const handleClick = () => {
    onClick(value);
  };
  return (
    <Button onClick={handleClick} className={className} bsStyle={bsStyle} title={title}>
      {children}
    </Button>
  );
};

ValueButton.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  bsStyle: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
};

ValueButton.defaultProps = {
  value: undefined,
  children: undefined,
  className: '',
  bsStyle: 'default',
  type: 'button',
  title: undefined,
};

export default ValueButton;
