import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ModalWrapper from '../App/Modal/ModalWrapper';

export class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props
      .onSubmit(this.email.value)
      .then(() => {
        this.props.close();
        this.setState({ errorMessage: null });
      })
      .catch(err => {
        this.setState({
          errorMessage: this.props.t(err.error.response.data.message),
        });
      });
  }

  errorMessage() {
    if (this.state.errorMessage) {
      return (
        <Alert bsStyle="danger">
          <p>{this.state.errorMessage}</p>
        </Alert>
      );
    }
  }

  render() {
    const { t } = this.props;
    return (
      <ModalWrapper
        title={t('password.resetPassword')}
        open={this.props.open}
        close={this.props.close}
      >
        {this.errorMessage()}
        <form onSubmit={this.handleSubmit}>
          <fieldset>
            <div className="form-group">
              <label htmlFor="inputEmail">{t('password.enterEmail')}</label>
              <input
                type="email"
                ref={input => {
                  this.email = input;
                }}
                id="inputEmail"
                className="form-control form-control-lg"
                placeholder={t('user.email')}
                required
                autoFocus
              />
            </div>
          </fieldset>
          <div className="text-center">
            <button className="btn btn-lg btn-primary button--primary" type="submit">
              {t('password.reset')}
            </button>
          </div>
        </form>
      </ModalWrapper>
    );
  }
}

ResetPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ResetPasswordModal);
