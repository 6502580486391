import React from 'react';
import { avatarUrl } from '../../../utils/userHelper';
import noAvatar from '../../../assets/images/no_avatar.png';

interface AvatarProps {
  version: string;
  filename?: string;
  width?: string;
}

const Avatar: React.FC<AvatarProps> = ({ version, filename, width }) => {
  const size = width || '40px';
  let avatar = (
    <img
      src={noAvatar}
      style={{ width: size, height: size }}
      alt="Avatar"
      className="navbar-profile-avatar"
    />
  );
  if (filename) {
    if (filename.substr(0, 10) === 'data:image') {
      avatar = (
        <img
          src={filename}
          style={{ width: size, height: size }}
          alt="Avatar"
          className="navbar-profile-avatar"
        />
      );
    } else {
      avatar = (
        <img
          src={avatarUrl(filename, version)}
          style={{ width: size, height: size }}
          alt="Avatar"
          className="navbar-profile-avatar"
        />
      );
    }
  }
  return avatar;
};

export default React.memo(Avatar);
