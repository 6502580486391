import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PoliciesTree from './PoliciesTree';
import { UserPolicies } from '../../../common/models/user';

const PolicyKeys = Object.keys(UserPolicies);

class Policies extends Component {
  constructor(props) {
    super(props);
    const { user, policies } = props;
    if (user && policies) {
      const userPolicies = this.getPolicies(user, policies);
      const counters = this.countNodes(policies, PolicyKeys);

      this.state = Object.assign({}, { policies: userPolicies }, { counters });
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateNode = this.updateNode.bind(this);
    this.setValueOnAllNodes = this.setValueOnAllNodes.bind(this);
  }

  getNodes(policies, prop) {
    let results = [];
    policies.forEach(n => {
      if (n[prop]) {
        results.push(n.id);
      }
      if (n.nodes.length > 0) {
        results = results.concat(this.getNodes(n.nodes, prop));
      }
    });
    return results;
  }

  getNewUserPolicies(policies) {
    const userPolicies = {};
    PolicyKeys.forEach(key => {
      userPolicies[key] = this.getNodes(policies, key);
    });

    return {
      policies: userPolicies,
    };
  }

  getPolicies(user, suez) {
    return this.buildUserPolicies(suez, user);
  }

  /**
   *
   * @param {string}    propertyKey           User policy key we act on
   *                                          e.g. 'read', 'ooeWrite'
   * @param {bool}      isChecked             policy value
   */
  setValueOnAllNodes(propertyKey, isChecked) {
    const nodes = [...this.state.policies];

    for (const node of nodes) {
      this.updateNode(node, propertyKey, isChecked);
    }

    if (propertyKey === UserPolicies.plantWrite && isChecked) {
      this.setValueOnAllNodes(UserPolicies.plantRead, isChecked);
    }
    if (isChecked === UserPolicies.plantRead && !isChecked) {
      this.setValueOnAllNodes(UserPolicies.plantWrite, isChecked);
    }

    if (propertyKey === UserPolicies.oeeWrite && isChecked) {
      this.setValueOnAllNodes(UserPolicies.oeeRead, isChecked);
    }
    if (isChecked === UserPolicies.oeeRead && !isChecked) {
      this.setValueOnAllNodes(UserPolicies.oeeWrite, isChecked);
    }

    const counters = this.countNodes(nodes, PolicyKeys);
    this.setState({ counters });
  }

  updateNode(node, key, value) {
    node[key] = value; // eslint-disable-line no-param-reassign
    if (node.nodes && node.nodes.length > 0) {
      node.nodes.forEach(n => {
        this.updateNode(n, key, value);
      });
    }
  }

  buildUserPolicies(policies, user) {
    return policies.map(n => {
      const policy = Object.assign({}, n);

      PolicyKeys.forEach(key => {
        if (user.policies[key].indexOf(n.id) !== -1) {
          policy[key] = true;
        }
      });

      if (n.nodes.length > 0) {
        policy.nodes = this.buildUserPolicies(n.nodes, user);
      }
      return policy;
    });
  }

  handleChange(policies) {
    const counters = this.countNodes(policies, PolicyKeys);
    this.setState({ policies, counters });
  }

  handleSubmit() {
    this.props.onSubmit(this.getNewUserPolicies(this.state.policies));
  }

  /**
   * browse a (policies) node trees and count 'true' values for each properties contained in a given list
   *
   * @param   Object[]  nodes           nodes list, each node match a business unit (R&R France, R&R UK,..)
   * @param   string[]  keyList         Properties names method will check (strict bool equality) aka counters list
   *                                    e.g. ['read', 'write', 'oeeRead', 'oeeWrite', 'projectTrackerRead']
   *
   * @return  Object                    An object with at least one property 'totalCount' that is total node
   *                                    contained in {nodes} and optionnal properties matching the {keyList}
   *                                    e.g. {
   *                                      totalCount: 10,
   *                                      read: 4,
   *                                      write: 10,
   *                                      oeeRead:7,
   *                                      oeeWrite:9
   *                                      ...
   *                                    }
   */
  countNodes(nodes, keyList) {
    const _countNode = (node, properties) => {
      const counters = {};

      properties.forEach(key => {
        counters[key] = node[key] === true ? 1 : 0;
      });
      counters.totalCount = 1;

      if (node.nodes.length > 0) {
        const childrenCounters = {};
        properties.forEach(key => {
          childrenCounters[key] = 0;
        });
        childrenCounters.totalCount = 0;

        node.nodes.forEach(childNode => {
          const childCounters = _countNode(childNode, properties);
          Object.keys(childCounters).forEach(key => {
            childrenCounters[key] += childCounters[key];
          });
        });

        Object.keys(childrenCounters).forEach(key => {
          counters[key] += childrenCounters[key];
        });
      }

      return counters;
    };

    const counters = {};
    keyList.forEach(key => {
      counters[key] = 0;
    });
    counters.totalCount = 0;

    nodes.forEach(node => {
      const nodeCounters = _countNode(node, keyList);
      Object.keys(nodeCounters).forEach(key => {
        counters[key] += nodeCounters[key];
      });
    });

    return counters;
  }

  render() {
    const { t } = this.props;
    const counters = this.state.counters;
    const plantReadTooltip = <Tooltip id="plantReadTooltip">{t('user.readTips')}</Tooltip>;

    return (
      <div className="policy">
        <Row>
          <Col xs={12} md={12}>
            <div className="title">
              <span className="label-neutral label label-default upper label-wrap-white-space">
                {t('user.oeeWrite')}
              </span>
              <div className="btn-group" role="group">
                <Button
                  className="btn btn-secondary btn-md-hide-label"
                  onClick={() => this.setValueOnAllNodes(UserPolicies.oeeWrite, true)}
                  active={counters[UserPolicies.oeeWrite] === counters.totalCount}
                >
                  <i className="fa fa-check-square-o" aria-hidden="true" />
                </Button>
                <Button
                  className="btn btn-secondary btn-md-hide-label"
                  onClick={() => this.setValueOnAllNodes(UserPolicies.oeeWrite, false)}
                  active={counters[UserPolicies.oeeWrite] === 0}
                >
                  <i className="fa fa-square-o" aria-hidden="true" />
                </Button>
              </div>
            </div>
            <div className="title">
              <span className="label-neutral label label-default upper label-wrap-white-space">
                {t('user.oeeRead')}
              </span>
              <div className="btn-group" role="group">
                <Button
                  className="btn btn-secondary btn-md-hide-label"
                  onClick={() => this.setValueOnAllNodes(UserPolicies.oeeRead, true)}
                  active={counters[UserPolicies.oeeRead] === counters.totalCount}
                >
                  <i className="fa fa-check-square-o" aria-hidden="true" />
                </Button>
                <Button
                  className="btn btn-secondary btn-md-hide-label"
                  onClick={() => this.setValueOnAllNodes(UserPolicies.oeeRead, false)}
                  active={counters[UserPolicies.oeeRead] === 0}
                >
                  <i className="fa fa-square-o" aria-hidden="true" />
                </Button>
              </div>
            </div>
            <div className="title">
              <span className="label-neutral label label-default upper label-wrap-white-space">
                {t('user.plantWrite')}
              </span>
              <div className="btn-group" role="group">
                <Button
                  className="btn btn-secondary btn-md-hide-label"
                  onClick={() => this.setValueOnAllNodes(UserPolicies.plantWrite, true)}
                  active={counters[UserPolicies.plantWrite] === counters.totalCount}
                >
                  <i className="fa fa-check-square-o" aria-hidden="true" />
                </Button>
                <Button
                  className="btn btn-secondary btn-md-hide-label"
                  onClick={() => this.setValueOnAllNodes(UserPolicies.plantWrite, false)}
                  active={counters[UserPolicies.plantWrite] === 0}
                >
                  <i className="fa fa-square-o" aria-hidden="true" />
                </Button>
              </div>
            </div>
            <div className="title">
              <OverlayTrigger placement="top" overlay={plantReadTooltip}>
                <span className="label-neutral label label-default upper label-wrap-white-space">
                  {t('user.plantRead')}
                </span>
              </OverlayTrigger>
              <div className="btn-group" role="group">
                <Button
                  className="btn btn-secondary btn-md-hide-label"
                  onClick={() => this.setValueOnAllNodes(UserPolicies.plantRead, true)}
                  active={counters[UserPolicies.plantRead] === counters.totalCount}
                >
                  <i className="fa fa-check-square-o" aria-hidden="true" />
                </Button>
                <Button
                  className="btn btn-secondary btn-md-hide-label"
                  onClick={() => this.setValueOnAllNodes(UserPolicies.plantRead, false)}
                  active={counters[UserPolicies.plantRead] === 0}
                >
                  <i className="fa fa-square-o" aria-hidden="true" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="m-t">
          <Col xs={12} md={12}>
            <PoliciesTree policies={this.state.policies} onChange={this.handleChange} />
            <div className="text-center m-t-md">
              <button
                type="submit"
                className="btn btn-primary btn-lg m-r-md"
                onClick={this.handleSubmit}
              >
                {t('common.save')}
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-lg"
                onClick={this.props.onCancel}
              >
                {t('common.cancel')}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

Policies.propTypes = {
  user: PropTypes.object.isRequired,
  policies: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Policies);
