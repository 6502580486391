import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InputNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value && !isNaN(nextProps.value)) {
      return { value: nextProps.value };
    } else return null;
  }

  handleChange(e) {
    const value = e.target.value;
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) {
      this.setState({ value: numberValue });
      this.props.onChange(numberValue);
    } else {
      this.setState({ value });
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <input
        type="number"
        onChange={this.handleChange}
        value={this.state.value}
        name={this.props.name}
        tabIndex={this.props.tabIndex}
        className={this.props.className}
        disabled={this.props.disabled}
      />
    );
  }
}

InputNumber.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
