import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ProfilePage, { ProfileNav } from '../../../components/App/User/ProfilePage';
import ProfileForm from '../../../components/App/User/ProfileForm';
import { updateProfile } from '../../../redux/modules/profile';
import { APP_PATH } from '../../../utils/routeHelper';

const EditProfile: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile.data);

  const handleUpdateProfile = data => {
    dispatch(updateProfile(data)).then(res => {
      if (res.type.match(/SUCCESS/)) {
        history.push(APP_PATH);
      }
    });
  };

  const handleCancel = () => {
    history.push(APP_PATH);
  };

  return (
    <ProfilePage active={ProfileNav.editProfile}>
      <ProfileForm data={profile} onSubmit={handleUpdateProfile} onCancel={handleCancel} />
    </ProfilePage>
  );
};

export default withRouter(EditProfile);
