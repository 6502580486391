import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { updateAggregations } from '../../../redux/modules/oeeDashboard';
import { getInvisibleLists, COLORS, CATEGORY_COLORS } from '../../../utils/oeeHelper';
import { CategoryItem } from '../../../common/models/categoryItem';

interface OEELegendsProps {
  legends: any[];
  aggregations: any;
  aggs: string;
  categoryItems: CategoryItem[];
  categoryColor?: string;
  colors?: string[];
  customClasses?: any;
}

const OEELegends: React.FC<OEELegendsProps> = ({
  legends,
  categoryItems,
  aggregations,
  aggs,
  colors,
  categoryColor,
  customClasses,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const _colors = colors || COLORS;

  const toggleLegend = key => {
    return () => {
      const _aggregations = produce(aggregations, draft => {
        draft[aggs] = draft[aggs] || {};
        draft[aggs].invisibleLists = draft[aggs].invisibleLists || [];
        if (draft[aggs].invisibleLists.indexOf(key) === -1) {
          draft[aggs].invisibleLists.push(key);
        } else {
          _.pull(draft[aggs].invisibleLists, key);
        }
      });
      dispatch(updateAggregations(_aggregations));
    };
  };

  const legendKeyClass = key => {
    const active = getInvisibleLists(aggs, aggregations).indexOf(key) === -1;
    return classNames({ active });
  };

  const optionCustomClasses = key => {
    const _customClasses = customClasses || {};
    return _customClasses[key] || '';
  };

  const legendsOptions = legends.map((key, i) => {
    if (_.isString(key)) {
      return (
        <li
          key={key}
          className={`${legendKeyClass(key)} ${optionCustomClasses(key)}`}
          onClick={toggleLegend(key)}
        >
          <legend style={{ backgroundColor: _colors[key] }} />
          {t(`OEE.indicators.${key}`)}
        </li>
      );
    }
    if (key.slugPath) {
      const categoryItem = _.find(categoryItems, { slug: key.categoryItemSlug });
      return (
        <li
          key={key.slugPath}
          className={legendKeyClass(key.slugPath)}
          onClick={toggleLegend(key.slugPath)}
        >
          <legend style={{ backgroundColor: categoryColor }} />
          {categoryItem.name[i18n.language]}
        </li>
      );
    }
    return (
      <li key={key.key} className={legendKeyClass(key.key)} onClick={toggleLegend(key.key)}>
        <legend style={{ backgroundColor: CATEGORY_COLORS[i] }} />
        {key.name}
      </li>
    );
  });

  return <ul className="plotly-custom-legends">{legendsOptions}</ul>;
};

export default OEELegends;
