import React from 'react';
import { addClassByTagName, removeClassByTagName } from '../../utils/vanilla';

const Print: React.FC = ({ children }) => {
  removeClassByTagName('html', 'backgroud-image');
  addClassByTagName('body', 'print');
  return <div>{children}</div>;
};

export default Print;
