import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

const EditOpeningTimeModal: FC<any> = ({ open, close, onSubmit }) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit();
    close();
  };

  return (
    <Modal show={open} onHide={close}>
      <Modal.Header>
        <Modal.Title>{t('common.warning')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('OEEInput.warningChangeOpeningTime')}</Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>{t('common.no')}</Button>
        <Button bsStyle="primary" onClick={handleSubmit}>
          {t('common.yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOpeningTimeModal;
