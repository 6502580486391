import { API_IMPORT_OEE_BUDGET_CHALLENGE_PATH } from '../../utils/routeHelper';
import { error } from '../../utils/error';

export const IMPORT_OEE_BUDGET_CHALLENGE = 'oee_budget_challenges/IMPORT_OEE_BUDGET_CHALLENGE';
export const IMPORT_OEE_BUDGET_CHALLENGE_SUCCESS =
  'oee_budget_challenges/IMPORT_OEE_BUDGET_CHALLENGE_SUCCESS';
export const IMPORT_OEE_BUDGET_CHALLENGE_FAIL =
  'oee_budget_challenges/IMPORT_OEE_BUDGET_CHALLENGE_FAIL';

interface OEEBudgetChallengeState {
  importing: boolean;
  importError?: string;
}

const initialState: OEEBudgetChallengeState = {
  importing: false,
  importError: undefined,
};

export default function reducer(
  state: OEEBudgetChallengeState = initialState,
  action: ActionResult,
): OEEBudgetChallengeState {
  switch (action.type) {
    case IMPORT_OEE_BUDGET_CHALLENGE:
      return {
        ...state,
        importing: true,
      };
    case IMPORT_OEE_BUDGET_CHALLENGE_SUCCESS:
      return {
        ...state,
        importing: false,
        importError: undefined,
      };
    case IMPORT_OEE_BUDGET_CHALLENGE_FAIL:
      return {
        ...state,
        importing: false,
        importError: error(action),
      };
    default:
      return state;
  }
}

export function importOEEBudgetChallenge(data): ActionPromise {
  const formData = new FormData();
  if (data.file && data.file[0]) {
    formData.append('file', data.file[0]);
  }
  return {
    types: [
      IMPORT_OEE_BUDGET_CHALLENGE,
      IMPORT_OEE_BUDGET_CHALLENGE_SUCCESS,
      IMPORT_OEE_BUDGET_CHALLENGE_FAIL,
    ],
    promise: client => client.post(API_IMPORT_OEE_BUDGET_CHALLENGE_PATH, formData),
  };
}
