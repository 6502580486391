import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import PostList from '../../../components/App/News/PostList';
import NewsPolicy from '../../../common/policies/news_policy';
import { User } from '../../../common/models/user';
import { News } from '../../../common/models/news';
import { APP_CREATE_NEWS_PATH } from '../../../utils/routeHelper';
import loadData from '../../../utils/loadData';
import { indexNews } from '../../../redux/modules/news';

const NewsList: React.FC = () => {
  const { t } = useTranslation();
  const currentUser: User = useSelector(state => state.profile.data);
  const news: Array<News> = useSelector(state => state.news.data);

  return (
    <div className="posts-list">
      <PageNav>
        <div className="nav-secondary__right">
          {NewsPolicy.isAuthorize(currentUser, 'create') && (
            <Link to={APP_CREATE_NEWS_PATH} className="btn btn-secondary btn-md-hide-label btn-lg">
              <i className="fa fa-plus m-r" />
              <span>{t('news.newPost')}</span>
            </Link>
          )}
        </div>
        <h2 className="nav-secondary__title">{t('news.news')}</h2>
      </PageNav>
      <PageContent>
        <Row>
          <Col lg={10} lgOffset={1}>
            <div className="card bg-white m-t-lg">
              <div className="card-block">
                <PostList news={news} />
              </div>
            </div>
          </Col>
        </Row>
      </PageContent>
    </div>
  );
};

export default React.memo(
  loadData(async dispatch => {
    await dispatch(indexNews());
  })(NewsList),
);
