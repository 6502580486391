import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LossChart from './LossChart';
import { LossType } from '../../../common/models/category';
import { COLORS, lossTotalTitle, getMaxStopOrLoss } from '../../../utils/oeeHelper';

const AvailabilityLossesChart: React.FC<any> = ({
  data,
  loading,
  plantAndLine,
  aggregations,
  categories,
  categoryItems,
  onCategoryChanged,
  layout,
  print,
}) => {
  const { t } = useTranslation();
  const plannedStopMax = getMaxStopOrLoss(
    data.plannedStopByCategory,
    'plannedStopByCategory',
    aggregations,
  );
  const unplannedStopMax = getMaxStopOrLoss(
    data.unplannedStopByCategory,
    'unplannedStopByCategory',
    aggregations,
  );
  const plannedStopMaxValue = plannedStopMax ? plannedStopMax.value : 0;
  const unplannedStopMaxValue = unplannedStopMax ? unplannedStopMax.value : 0;
  const valueMax =
    plannedStopMaxValue > unplannedStopMaxValue ? plannedStopMaxValue : unplannedStopMaxValue;
  return (
    <Row>
      <div className="col-md-12">
        <h4 className={`text-center ${print ? 'm-b-md' : 'm-b-lg'}`}>
          {lossTotalTitle(
            t('OEEDashboard.titles.availabilityLossTotal'),
            data.plannedStopByCategory
              ? data.plannedStopByCategory.concat(data.unplannedStopByCategory)
              : [],
          )}
        </h4>
      </div>
      <div className="col-md-6 col-sm-6 col-lg-6">
        <LossChart
          data={data.plannedStopByCategory}
          layout={Object.assign(
            {
              title: lossTotalTitle(
                t('OEEDashboard.titles.plannedStopTotal'),
                data.plannedStopByCategory,
              ),
              yaxis: { fixedrange: true, range: [0, valueMax] },
            },
            layout,
          )}
          plantAndLine={plantAndLine}
          categories={categories}
          categoryItems={categoryItems}
          lossType={LossType.planned}
          loading={loading}
          color={COLORS.plannedStop}
          aggregations={aggregations}
          aggs="plannedStopByCategory"
          onCategoryChanged={onCategoryChanged}
          print={print}
        />
      </div>
      <div className="col-md-6 col-sm-6 col-lg-6">
        <LossChart
          data={data.unplannedStopByCategory}
          layout={Object.assign(
            {
              title: lossTotalTitle(
                t('OEEDashboard.titles.unplannedStopTotal'),
                data.unplannedStopByCategory,
              ),
              yaxis: { fixedrange: true, range: [0, valueMax] },
            },
            layout,
          )}
          plantAndLine={plantAndLine}
          categories={categories}
          categoryItems={categoryItems}
          lossType={LossType.unplanned}
          loading={loading}
          color={COLORS.unplannedStop}
          aggregations={aggregations}
          aggs="unplannedStopByCategory"
          onCategoryChanged={onCategoryChanged}
          print={print}
        />
      </div>
    </Row>
  );
};

export default AvailabilityLossesChart;
