import i18next from 'i18next';
import * as NotificationSystem from 'react-notification-system';
import { CREATE_NEWS_FAIL, CREATE_NEWS_SUCCESS, UPDATE_NEWS_FAIL } from './news';
import {
  CREATE_PLANT_FAIL,
  CREATE_PLANT_LINE_FAIL,
  DELETE_PLANT_PHOTO_FAIL,
  SHOW_PLANT_FAIL,
  UPDATE_PLANT_FAIL,
  UPDATE_PLANT_LINE_FAIL,
  UPDATE_PLANT_PHOTO_FAIL,
} from './plants';
import { IMPORT_OEE_SUCCESS, IMPORT_OEE_FAIL, EXPORT_OEE } from './oee';
import {
  IMPORT_OEE_BUDGET_CHALLENGE_SUCCESS,
  IMPORT_OEE_BUDGET_CHALLENGE_FAIL,
} from './oeeBudgetChallenges';
import { error } from '../../utils/error';

const RESET_NOTIFICATION = 'notification/RESET_NOTIFICATION';

const initialState = {};

export default function reducer(
  state = initialState,
  action: ActionResult,
): NotificationSystem.Notification {
  const { type } = action;

  switch (type) {
    case CREATE_NEWS_SUCCESS:
      return {
        message: i18next.t('notifications.createNewsSuccess'),
        level: 'success',
      };
    case CREATE_NEWS_FAIL:
      return {
        message: i18next.t('notifications.createNewsFail', { error: error(action) }),
        level: 'error',
      };
    case UPDATE_NEWS_FAIL:
      return {
        message: i18next.t('notifications.updateNewsFail', { error: error(action) }),
        level: 'error',
      };
    case CREATE_PLANT_FAIL:
      return {
        message: i18next.t('notifications.createPlantFail', { error: error(action) }),
        level: 'error',
      };
    case SHOW_PLANT_FAIL:
      return {
        message: i18next.t('notifications.showPlantFail', { error: error(action) }),
        level: 'error',
      };
    case UPDATE_PLANT_FAIL:
      return {
        message: i18next.t('notifications.updatePlantFail', { error: error(action) }),
        level: 'error',
      };
    case UPDATE_PLANT_PHOTO_FAIL:
      return {
        message: i18next.t('notifications.updatePlantPhotoFail', { error: error(action) }),
        level: 'error',
      };
    case DELETE_PLANT_PHOTO_FAIL:
      return {
        message: i18next.t('notifications.deletePlantPhotoFail', { error: error(action) }),
        level: 'error',
      };
    case CREATE_PLANT_LINE_FAIL:
      return {
        message: i18next.t('notifications.createPlantLineFail', { error: error(action) }),
        level: 'error',
      };
    case UPDATE_PLANT_LINE_FAIL:
      return {
        message: i18next.t('notifications.updatePlantLineFail', { error: error(action) }),
        level: 'error',
      };
    case EXPORT_OEE:
      return {
        message: i18next.t('notifications.exportOEE'),
        level: 'success',
      };
    case IMPORT_OEE_SUCCESS:
      return {
        message: i18next.t('notifications.importOEESuccess'),
        level: 'success',
      };
    case IMPORT_OEE_FAIL:
      return {
        message: i18next.t('notifications.importOEEFail', { error: error(action) }),
        level: 'error',
      };
    case IMPORT_OEE_BUDGET_CHALLENGE_SUCCESS:
      return {
        message: i18next.t('notifications.importOEESuccess'),
        level: 'success',
      };
    case IMPORT_OEE_BUDGET_CHALLENGE_FAIL:
      return {
        message: i18next.t('notifications.importOEEFail', { error: error(action) }),
        level: 'error',
      };
    case RESET_NOTIFICATION:
      return {};
    default:
      if (type.match(/UPDATE/) && type.match(/SUCCESS/)) {
        return {
          message: i18next.t('notifications.updateSuccess'),
          level: 'success',
        };
      }
      if (type.match(/SAVE/) && type.match(/SUCCESS/)) {
        return {
          message: i18next.t('notifications.updateSuccess'),
          level: 'success',
        };
      }
      if (type.match(/CREATE/) && type.match(/SUCCESS/)) {
        return {
          message: i18next.t('notifications.saveSuccess'),
          level: 'success',
        };
      }
      if (type.match(/DELETE/) && type.match(/SUCCESS/)) {
        return {
          message: i18next.t('notifications.deleteSuccess'),
          level: 'success',
        };
      }
      if (type.match(/UPDATE/) && type.match(/FAIL/)) {
        return {
          message: i18next.t('notifications.updateFailure', { error: error(action) }),
          level: 'error',
        };
      }
      if (type.match(/SAVE/) && type.match(/FAIL/)) {
        return {
          message: i18next.t('notifications.updateFailure', { error: error(action) }),
          level: 'error',
        };
      }
      if (type.match(/CREATE/) && type.match(/FAIL/)) {
        return {
          message: i18next.t('notifications.saveFailure', { error: error(action) }),
          level: 'error',
        };
      }
      if (type.match(/DELETE/) && type.match(/FAIL/)) {
        return {
          message: i18next.t('notifications.deleteFailure', { error: error(action) }),
          level: 'error',
        };
      }
      return state;
  }
}

export function resetNotification() {
  return {
    type: RESET_NOTIFICATION,
  };
}
