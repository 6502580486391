import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import _ from 'lodash';
import fp from 'lodash/fp';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import plantPolicy from '../../../common/policies/plant_policy';
import { indexPlants, createPlant, deletePlant } from '../../../redux/modules/plants';
import { indexBusinessUnit } from '../../../redux/modules/businessUnits';
import { indexRegion } from '../../../redux/modules/regions';
import IdCards from '../../../components/App/Plant/IdCards';
import PlantModal from '../../../components/App/Plant/PlantModal';
import PlantFilter from '../../../components/App/Plant/PlantFilter';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import { APP_EDIT_PLANT_PATH } from '../../../utils/routeHelper';
import loadData from '../../../utils/loadData';
import { User } from '../../../common/models/user';
import { BusinessUnit } from '../../../common/models/businessUnit';
import { Region } from '../../../common/models/region';
import { Plant } from '../../../common/models/plant';
import { ALL, NONE } from '../../../common/filter';
import { identifier } from '../../../common/models/model';

interface PlantListProps extends RouteComponentProps {}
interface IPlantFilter {
  name: string;
  businessUnitId: string;
  regionId: string;
  archived: boolean;
}

function isDefaultFilter(filter: IPlantFilter) {
  const { name, businessUnitId, regionId } = filter;
  return name === NONE && businessUnitId === ALL && regionId === ALL;
}

function selectPlant(plants: Plant[], filter: IPlantFilter) {
  const { name, businessUnitId, regionId, archived } = filter;
  let plantsFiltered = _.filter(plants, p => {
    return !p.archived || (archived && p.archived);
  });
  if (isDefaultFilter(filter)) {
    return _.orderBy(plantsFiltered, 'name');
  }
  if (businessUnitId !== ALL && regionId === ALL) {
    plantsFiltered = _.filter(plantsFiltered, { businessUnitId });
  }
  if (regionId !== ALL) {
    plantsFiltered = _.filter(plantsFiltered, { regionId });
  }
  if (name !== NONE) {
    const regexValue = new RegExp(
      _.words(name)
        .join(' ')
        .toLowerCase(),
    );
    plantsFiltered = _.filter(plantsFiltered, plant => plant.name.toLowerCase().match(regexValue));
  }
  return _.orderBy(plantsFiltered, 'name');
}

const PlantList: React.FC<PlantListProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openedPlantModal, setOpenPlantModal] = useState(false);
  const user: User = useSelector(state => state.profile.data);
  const plants: Plant[] = useSelector(state => state.plants.data);
  const businessUnits: BusinessUnit[] = useSelector(state => state.businessUnits.data);
  const regions: Region[] = useSelector(state => state.regions.data);
  const [plantFilter, setPlantFilter] = useState<IPlantFilter>({
    name: NONE,
    businessUnitId: ALL,
    regionId: ALL,
    archived: false,
  });
  const plantsFiltered = useMemo(() => selectPlant(plants, plantFilter), [plants, plantFilter]);

  const handlePlantNew = data => {
    dispatch(createPlant(data)).then(action => {
      if (action && action.type.match(/SUCCESS/)) {
        closePlantModal();
        history.push(APP_EDIT_PLANT_PATH(action.result.data[identifier]));
      }
    });
  };

  const openPlantModal = () => {
    setOpenPlantModal(true);
  };

  const closePlantModal = () => {
    setOpenPlantModal(false);
  };

  const handleFilter = (filter: IPlantFilter) => {
    setPlantFilter(filter);
  };

  const handlePlantDelete = (plant: Plant) => {
    dispatch(deletePlant(plant));
  };

  const buIds = _.keys(_.groupBy(plants, 'businessUnitId'));
  const regionIds = _.keys(_.groupBy(plants, 'regionId'));

  return (
    <div className="plants">
      <PageNav>
        {plantPolicy.isAuthorize(user, 'create') && (
          <div className="nav-secondary__right">
            <Button
              className="btn-secondary btn-lg btn-md-hide-label"
              id="new-plant-btn"
              onClick={openPlantModal}
            >
              <i className="fa fa-plus m-r" />
              <span>{t('plant.newPlant')}</span>
            </Button>

            <PlantModal
              plant={{}}
              open={openedPlantModal}
              close={closePlantModal}
              businessUnits={businessUnits}
              regions={regions}
              onSubmit={handlePlantNew}
            />
          </div>
        )}

        <h2 className="nav-secondary__title">{t('plant.plants')}</h2>
      </PageNav>
      <PageContent>
        <div className="plants__filters">
          <PlantFilter
            businessUnits={_.filter(businessUnits, b => buIds.includes(b[identifier]))}
            regions={_.filter(regions, r => regionIds.includes(r[identifier]))}
            onChange={handleFilter}
            filter={plantFilter}
          />
        </div>
        <div>
          <IdCards
            plants={plantsFiltered}
            businessUnits={businessUnits}
            regions={regions}
            user={user}
            onDeletePlant={handlePlantDelete}
          />
        </div>
      </PageContent>
    </div>
  );
};

export default fp.compose(
  withRouter,
  loadData(async dispatch => {
    await dispatch(indexPlants());
    await dispatch(indexBusinessUnit());
    await dispatch(indexRegion());
  }),
)(PlantList);
