import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Form, Input } from 'formsy-react-components';
import { convertToRaw } from 'draft-js';
import TextEditor from '../TextEditor/TextEditor';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../../../common/i18n';
import BaseForm from '../Form/BaseForm';
import { identifier } from '../../../common/models/model';

class PostForm extends BaseForm {
  state = {
    action: '',
  };

  titleInputValidationAttributes(lang) {
    const { t } = this.props;
    let attributes = {};
    if (lang === DEFAULT_LANGUAGE) {
      attributes = _.merge(attributes, {
        validations: 'maxLength:140',
        validationError: t('errors.inputTextMaxLength', { max: 140 }),
        required: true,
      });
    }
    return attributes;
  }

  handleSubmit = data => {
    const formData = Object.assign({}, data);
    formData.action = this.state.action;
    formData.body = formData.body || {};
    for (const lang of LANGUAGES) {
      const editorState = this.bodyEditorState[lang];
      if (editorState) {
        formData.body[lang] = convertToRaw(editorState.getCurrentContent());
      }
    }
    this.props.onSubmit(formData);
  };

  handleBodyUpdate = lang => {
    return editorState => {
      this.bodyEditorState = this.bodyEditorState || {};
      this.bodyEditorState[lang] = editorState;
    };
  };

  buildLangTabs() {
    return LANGUAGES.map((lang, index) => {
      return (
        <Tab key={index} eventKey={index} title={lang}>
          <div className="p-t-2 p-b-1">{this.buildLangInputs(lang)}</div>
        </Tab>
      );
    });
  }

  saveAndUnpublish = () => {
    this.setState({ action: 'unpublish' }, () => this.submitBtn.click());
  };

  saveAndPublish = () => {
    this.setState({ action: 'publish' }, () => this.submitBtn.click());
  };

  defaultObject(lang) {
    return {
      [lang]: '',
    };
  }

  buildLangInputs(lang) {
    const { t } = this.props;
    let {
      data: { title, body },
    } = this.props;
    title = title || this.defaultObject(lang);
    body = body || this.defaultObject(lang);

    return (
      <span key={lang}>
        <Input
          className="form-control form-control-lg"
          layout="vertical"
          name={`title[${lang}]`}
          value={title[lang]}
          label={t('news.title')}
          type="text"
          placeholder={t('news.title')}
          {...this.titleInputValidationAttributes(lang)}
        />
        <div className="form-group">
          <label className="control-label" htmlFor="body">
            {t('news.body')}
          </label>
          <TextEditor name="body" onChange={this.handleBodyUpdate(lang)} rawState={body[lang]} />
        </div>
      </span>
    );
  }

  render() {
    const {
      t,
      data: { publishedAt },
    } = this.props;
    /* eslint-disable react/no-string-refs */
    return (
      <Row>
        <Col lg={12}>
          <Form
            onSubmit={this.handleSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            ref="form"
          >
            <Tabs
              defaultActiveKey={0}
              id="post-from-tabs"
              animation={false}
              className="post-form__tabs tabs-justified m-t-md m-b-md"
            >
              {this.buildLangTabs()}
            </Tabs>

            <Input type="hidden" name={identifier} value={this.props.data[identifier]} />
            <div className="m-y-2">
              <div className="pull-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg m-r-md"
                  ref={node => {
                    this.submitBtn = node;
                  }}
                  formNoValidate
                  disabled={!this.state.canSubmit}
                >
                  {t('news.save')}
                </button>

                {!publishedAt && (
                  <button
                    type="button"
                    className="btn btn-primary btn-lg"
                    onClick={this.saveAndPublish}
                    disabled={!this.state.canSubmit}
                  >
                    {t('news.publish')}
                  </button>
                )}

                {publishedAt && (
                  <button
                    type="button"
                    className="btn btn-primary btn-lg"
                    onClick={this.saveAndUnpublish}
                    disabled={!this.state.canSubmit}
                  >
                    {t('news.unpublish')}
                  </button>
                )}
              </div>

              <button
                type="button"
                className="btn btn-secondary btn-lg m-r-md"
                onClick={this.props.onCancel}
              >
                {t('news.cancel')}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    );
    /* eslint-enable react/no-string-refs */
  }
}

PostForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PostForm);
