import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import fp from 'lodash/fp';
import loadData from '../../../utils/loadData';
import { updateNewsAsRead, unreadNews } from '../../../redux/modules/news';
import { News } from '../../../common/models/news';
import PostsModal from '../News/PostsModal';

interface UnreadNewsState {
  postsModalOpen: boolean;
}

interface UnreadNewsProps {
  news: News[];
  unreadNews: Function;
  updateNewsAsRead: Function;
}

class UnreadNews extends Component<UnreadNewsProps, UnreadNewsState> {
  state = {
    postsModalOpen: false,
  };

  static getDerivedStateFromProps(props) {
    return {
      postsModalOpen: !_.isEmpty(props.news),
    };
  }

  closePostsModal = () => {
    this.props.updateNewsAsRead(this.props.news).then(action => {
      if (action && action.type.match(/SUCCESS/)) {
        this.setState({ postsModalOpen: false });
      }
    });
  };

  render() {
    return (
      <PostsModal
        news={this.props.news}
        bsSize="large"
        dialogClassName="posts-modal"
        open={this.state.postsModalOpen}
        close={this.closePostsModal}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    news: state.news.unread,
  };
};

const mapDispatchToProps = {
  updateNewsAsRead,
};

export default fp.compose(
  loadData(async dispatch => {
    await dispatch(unreadNews());
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(UnreadNews);
