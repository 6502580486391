import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Form, Input } from 'formsy-react-components';
import BaseForm from '../Form/BaseForm';

export class BUForm extends BaseForm {
  handleSubmit(data) {
    this.props.onSubmit(data);
  }

  render() {
    const { t } = this.props;
    return (
      <Row>
        <Col lg={12}>
          <Form
            onSubmit={this.handleSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="name"
              value={this.props.data.name}
              label={t('businessUnit.name')}
              type="text"
              placeholder={t('businessUnit.businessUnitName')}
              validations={'maxLength:255'}
              validationError={t('errors.inputTextMaxLength', { max: 255 })}
              required
            />

            <div className="text-center m-t-md">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                formNoValidate
                disabled={!this.state.canSubmit}
              >
                {t('common.save')}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(BUForm);
