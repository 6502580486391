import { UserPolicies } from '../common/models/user';

const apiRoot = process.env.REACT_APP_API_ROOT;
const apiPath = `${apiRoot}/api`;

export const HOME_PATH = '/';
export const SIGNIN_PATH = '/signin';
export const RESET_PASSWORD_PATH = '/reset_password/:token';
export const PRINT_PATH = '/print';
export const APP_PATH = '/app';
export const PROFILE_PATH = '/profile';
export const APP_PROFILE_PATH = `${APP_PATH}${PROFILE_PATH}`;
export const PROFILE_PASSWORD_PATH = `${PROFILE_PATH}/password`;
export const APP_PROFILE_PASSWORD_PATH = `${APP_PATH}${PROFILE_PASSWORD_PATH}`;
export const USERS_PATH = '/users';
export const APP_USERS_PATH = `${APP_PATH}${USERS_PATH}`;
export const EDIT_USER_PATH = `${USERS_PATH}/:id/edit`;
export const APP_EDIT_USER_PATH = (id: string) => `${APP_PATH}${EDIT_USER_PATH.replace(':id', id)}`;
export const NEWS_PATH = '/news';
export const APP_NEWS_PATH = `${APP_PATH}${NEWS_PATH}`;
export const CREATE_NEWS_PATH = `${NEWS_PATH}/create`;
export const APP_CREATE_NEWS_PATH = `${APP_PATH}${CREATE_NEWS_PATH}`;
export const EDIT_NEWS_PATH = `${NEWS_PATH}/:id/edit`;
export const APP_EDIT_NEWS_PATH = (id: string) => `${APP_PATH}${EDIT_NEWS_PATH.replace(':id', id)}`;
export const BUSINESS_UNIT_PATH = '/business_units';
export const APP_BUSINESS_UNIT_PATH = `${APP_PATH}${BUSINESS_UNIT_PATH}`;
export const REGION_PATH = '/regions';
export const APP_REGION_PATH = `${APP_PATH}${REGION_PATH}`;
export const CATEGORY_PATH = '/categories';
export const APP_CATEGORY_PATH = `${APP_PATH}${CATEGORY_PATH}`;
export const PLANTS_PATH = '/plants';
export const APP_PLANTS_PATH = `${APP_PATH}${PLANTS_PATH}`;
export const EDIT_PLANT_PATH = `${PLANTS_PATH}/:id`;
export const APP_EDIT_PLANT_PATH = (id: string) =>
  `${APP_PATH}${EDIT_PLANT_PATH.replace(':id', id)}`;
export const OEE_INPUT_PATH = '/oee/input';
export const APP_OEE_INPUT_PATH = `${APP_PATH}${OEE_INPUT_PATH}`;
export const OEE_DASHBOARD_PATH = '/oee/dashboard';
export const APP_OEE_DASHBOARD_PATH = `${APP_PATH}${OEE_DASHBOARD_PATH}`;

export const API_SIGNIN_PATH = `${apiRoot}/auth/sign_in`;
export const API_RESET_PASSWORD_PATH = `${apiRoot}/auth/reset_password`;
export const API_DEFINE_PASSWORD_PATH = `${apiRoot}/auth/password`;
export const API_CURRENT_USER_PATH = `${apiPath}/user`;
export const API_CURRENT_USER_CHANGE_PASSWORD_PATH = `${apiPath}/user/password`;
export const API_INDEX_NEWS_PATH = `${apiPath}/news`;
export const API_UNREAD_NEWS_PATH = `${apiPath}/news/unread`;
export const API_CREATE_NEWS_PATH = `${apiPath}/news`;
export const API_UPDATE_NEWS_PATH = (id: string) => `${apiPath}/news/${id}`;
export const API_UPDATE_NEWS_AS_READ_PATH = `${apiPath}/news/mark_as_read`;
export const API_DELETE_NEWS_PATH = (id: string) => `${apiPath}/news/${id}`;
export const API_INDEX_BUSINESS_UNIT_PATH = `${apiPath}/business_units`;
export const API_CREATE_BUSINESS_UNIT_PATH = `${apiPath}/business_units`;
export const API_UPDATE_BUSINESS_UNIT_PATH = (id: string) => `${apiPath}/business_units/${id}`;
export const API_DELETE_BUSINESS_UNIT_PATH = (id: string) => `${apiPath}/business_units/${id}`;
export const API_INDEX_REGION_PATH = `${apiPath}/regions`;
export const API_CREATE_REGION_PATH = `${apiPath}/regions`;
export const API_UPDATE_REGION_PATH = (id: string) => `${apiPath}/regions/${id}`;
export const API_DELETE_REGION_PATH = (id: string) => `${apiPath}/regions/${id}`;
export const API_INDEX_CATEGORY_PATH = `${apiPath}/categories`;
export const API_CREATE_CATEGORY_PATH = `${apiPath}/categories`;
export const API_UPDATE_CATEGORY_PATH = (id: string) => `${apiPath}/categories/${id}`;
export const API_DELETE_CATEGORY_PATH = (id: string) => `${apiPath}/categories/${id}`;
export const API_EXPORT_CATEGORY_PATH = `${apiPath}/categories/export/xlsx`;
export const API_INDEX_CATEGORY_ITEM_PATH = `${apiPath}/category_items`;
export const API_INDEX_PLANT_PATH = (scope: UserPolicies) => `${apiPath}/plants?scope=${scope}`;
export const API_CREATE_PLANT_PATH = `${apiPath}/plants`;
export const API_SHOW_PLANT_PATH = (id: string) => `${apiPath}/plants/${id}`;
export const API_UPDATE_PLANT_PATH = (id: string) => `${apiPath}/plants/${id}`;
export const API_DELETE_PLANT_PATH = (id: string) => `${apiPath}/plants/${id}`;
export const API_UPDATE_PLANT_PHOTO_PATH = (id: string) => `${apiPath}/plants/${id}/photos`;
export const API_DELETE_PLANT_PHOTO_PATH = (id: string, photoId: string) =>
  `${apiPath}/plants/${id}/photos/${photoId}`;
export const API_CREATE_PLANT_LINE_PATH = (id: string) => `${apiPath}/plants/${id}/lines`;
export const API_UPDATE_PLANT_LINE_PATH = (id: string, lineId: string) =>
  `${apiPath}/plants/${id}/lines/${lineId}`;
export const API_UPDATE_PLANT_LINE_POSITION_PATH = (id: string) => `${apiPath}/plants/${id}/lines`;
export const API_INDEX_USER_PATH = `${apiPath}/users`;
export const API_SHOW_USER_PATH = (id: string) => `${apiPath}/users/${id}`;
export const API_CREATE_USER_PATH = `${apiPath}/users`;
export const API_UPDATE_USER_PATH = (id: string) => `${apiPath}/users/${id}`;
export const API_DELETE_USER_PATH = (id: string) => `${apiPath}/users/${id}`;
export const API_EXPIRE_USER_PASSWORD_PATH = (id: string) =>
  `${apiPath}/users/${id}/expire_password`;
export const API_EXPORT_USER_PATH = `${apiPath}/users/export/xlsx`;
export const API_INDEX_SUEZ_PATH = (scope: UserPolicies) => `${apiPath}/suez?scope=${scope}`;
export const API_INDEX_OEE_INPUT_PATH = (params: string) => `${apiPath}/oee_inputs?${params}`;
export const API_CREATE_OEE_INPUT_PATH = `${apiPath}/oee_inputs`;
export const API_UPDATE_OEE_INPUT_PATH = (id: string) => `${apiPath}/oee_inputs/${id}`;
export const API_VALIDATE_OEE_INPUT_PATH = (id: string) => `${apiPath}/oee_inputs/${id}/validate`;
export const API_UNVALIDATE_OEE_INPUT_PATH = (id: string) =>
  `${apiPath}/oee_inputs/${id}/unvalidate`;
export const API_INDEX_OBJECTIVE_PATH = (params: string) => `${apiPath}/objectives?${params}`;
export const API_CREATE_OBJECTIVE_PATH = `${apiPath}/objectives`;
export const API_UPDATE_OBJECTIVE_PATH = (id: string) => `${apiPath}/objectives/${id}`;
export const API_EXPORT_OEE_PATH = `${apiPath}/oee/export`;
export const API_EXPORT_OEE_TO_S3_PATH = `${apiPath}/oee/export_to_s3`;
export const API_IMPORT_OEE_PATH = `${apiPath}/oee/import`;
export const API_OEE_DASHBOARD_PATH = `${apiPath}/oee/dashboard`;
export const API_PRINT_OEE_DASHBOARD_PATH = `${apiPath}/print/oee_dashboard`;
export const API_OEE_BUDGET_CHALLENGE_PATH = `${apiPath}/oee_budget_challenges`;
export const API_IMPORT_OEE_BUDGET_CHALLENGE_PATH = `${apiPath}/oee_budget_challenges/import`;
export const API_OEE_DASHBOARD_COMMENT_PATH = `${apiPath}/oee_dashboard_comments`;
export const API_SAVE_OEE_DASHBOARD_COMMENT_PATH = `${apiPath}/oee_dashboard_comments`;

export const UPLOADED_FILE_PATH = `${apiRoot}/uploads`;
