import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import {
  Form,
  Input,
  CheckboxGroup,
  Select as FormsySelect,
  RadioGroup,
} from 'formsy-react-components';
import _ from 'lodash';
import { LANGUAGES } from '../../../common/i18n';
import { LossType, TreatedWasteLossType } from '../../../common/models/category';
import { NONE } from '../../../common/filter';
import { getCategoryItemName } from '../../../utils/categoryHelper';

class CategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCategoryItemForm: false,
      showTreatedWasteLoss: !!_.find(props.data.lossType, { name: LossType.treatedWaste }),
      categoryItemSelected: props.categoryItem,
      submiting: false,
    };
    this.enableButton = this.enableButton.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.displayLineTypeModules = this.displayLineTypeModules.bind(this);
    this.displayLossTypeModules = this.displayLossTypeModules.bind(this);
    this.displayTreatedWasteLossTypeModules = this.displayTreatedWasteLossTypeModules.bind(this);
    this.toggleCategoryItemForm = this.toggleCategoryItemForm.bind(this);
    this.handleCategoryItemChange = this.handleCategoryItemChange.bind(this);
    this.buildCategoryItemForm = this.buildCategoryItemForm.bind(this);
    this.handleLossTypeChange = this.handleLossTypeChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
  }

  handleSubmit(data) {
    this.setState({ submiting: true });
    const lossType = data.lossType.map(loss => {
      const o = {
        name: loss,
        required: data.lossRequired.indexOf(loss) !== -1,
      };
      if (loss === LossType.treatedWaste) {
        o.loss = data.treatedWasteLoss || TreatedWasteLossType.none;
      }
      return o;
    });
    this.props
      .onSubmit(Object.assign({}, _.omit(data, ['lossRequired', 'treatedWasteLoss']), { lossType }))
      .catch(() => {
        this.setState({ submiting: false });
      });
  }

  enableButton() {
    this.setState({
      canSubmit: true,
    });
  }

  disableButton() {
    this.setState({
      canSubmit: false,
    });
  }

  buildAvailableListOptions() {
    return this.props.lineType.map(value => {
      return { value, label: this.props.t(`plant.lineTypes.${value}`) };
    });
  }

  buildLossTypeOptions() {
    return this.props.lossList.map(value => {
      return { value, label: this.props.t(`category.lossType.${value}`) };
    });
  }

  buildCategoryItemsOptions() {
    const options = [{ value: NONE, label: NONE }];
    _.forIn(
      _.orderBy(this.props.categoryItems, c => getCategoryItemName(c, this.props.i18n.language)),
      value => {
        options.push({
          value: value.slug,
          label: getCategoryItemName(value, this.props.i18n.language),
        });
      },
    );
    return options;
  }

  toggleCategoryItemForm(e) {
    e.preventDefault();
    this.setState({
      showCategoryItemForm: !this.state.showCategoryItemForm,
    });
  }

  categoryItemButtonText() {
    const { showCategoryItemForm } = this.state;
    const { t } = this.props;
    if (showCategoryItemForm) {
      return t('category.selectCategoryName');
    }
    return this.props.data._id ? t('category.editCategoryName') : t('category.createCategoryName');
  }

  categoryItemTitleText() {
    const { showCategoryItemForm } = this.state;
    const { t } = this.props;
    if (showCategoryItemForm) {
      return this.props.data._id
        ? t('category.editCategoryName')
        : t('category.createCategoryName');
    }
    return t('category.selectCategoryName');
  }

  handleLossTypeChange(_name, values) {
    if (values.indexOf(LossType.treatedWaste) !== -1) {
      this.setState({ showTreatedWasteLoss: true });
    } else {
      this.setState({ showTreatedWasteLoss: false });
    }
  }

  displayLineTypeModules() {
    const { t, data } = this.props;
    let output = <p>{t('category.availablesEmpty')}</p>;
    const availableListOptions = this.buildAvailableListOptions();
    if (availableListOptions.length) {
      output = (
        <CheckboxGroup name="lineType" value={data.lineType || []} options={availableListOptions} />
      );
    }
    return output;
  }

  displayLossTypeModules() {
    const { t, data } = this.props;
    let output = <p>{t('category.availablesEmpty')}</p>;
    const lossTypeOptions = this.buildLossTypeOptions();
    const values = _.map(data.lossType, l => l.name);
    if (lossTypeOptions.length) {
      output = (
        <CheckboxGroup
          rowClassName="category__edit--losstype"
          name="lossType"
          value={values}
          options={lossTypeOptions}
          onChange={this.handleLossTypeChange}
        />
      );
    }
    return output;
  }

  displayTreatedWasteLossTypeModules() {
    const { t, data } = this.props;
    const treatedWasteLoss = _.find(data.lossType, { name: LossType.treatedWaste }) || {};
    const options = Object.values(TreatedWasteLossType).map(loss => {
      return { value: loss, label: <span>{t(`category.lossType.${loss}`)}</span> };
    });
    return (
      <div>
        <RadioGroup
          rowClassName="category__edit--treatedwasteloss"
          labelClassName="m-b"
          name="treatedWasteLoss"
          value={treatedWasteLoss.loss}
          options={options}
        />
      </div>
    );
  }

  displayRequiredModules() {
    const { data } = this.props;
    const requiredListOptions = this.buildLossTypeOptions();
    const values = _.map(_.filter(data.lossType, { required: true }), l => l.name);
    return <CheckboxGroup name="lossRequired" value={values} options={requiredListOptions} />;
  }

  handleCategoryItemChange(_name, value) {
    const categoryItem = _.find(this.props.categoryItems, { slug: value });
    this.setState({ categoryItemSelected: categoryItem });
  }

  buildCategoryItemForm() {
    const { categoryItem, t } = this.props;
    return (
      <div>
        {this.props.data._id && <p>{t('category.editWarningMessage')}</p>}
        {LANGUAGES.map(lng => {
          return (
            <Input
              key={`categoryItem-name-${lng}`}
              className="form-control form-control-lg bg-white"
              layout="vertical"
              name={`categoryItem.name.${lng}`}
              value={categoryItem ? categoryItem.name[lng] : ''}
              label={t('category.name', { lng })}
              type="text"
              placeholder={t('category.name', { lng })}
              validations={'maxLength:255'}
              validationError={t('errors.inputTextMaxLength', { max: 255 })}
              addonBefore={lng.toUpperCase()}
              required
            />
          );
        })}
      </div>
    );
  }

  render() {
    const { showCategoryItemForm, showTreatedWasteLoss, categoryItemSelected } = this.state;
    const { data, t } = this.props;
    data.hiddenFromDataInputs = _.isBoolean(data.hiddenFromDataInputs)
      ? data.hiddenFromDataInputs
      : false;
    return (
      <div className="category__edit">
        <Form
          onSubmit={this.handleSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <Row className="category__edit-row">
            <Col md={6}>
              <h6 className="m-b-md m-t">{this.categoryItemTitleText()} :</h6>

              {!showCategoryItemForm && (
                <div>
                  <FormsySelect
                    name="categoryItemSlug"
                    value={this.props.data.categoryItemSlug}
                    options={this.buildCategoryItemsOptions()}
                    onChange={this.handleCategoryItemChange}
                    required
                  />
                  {categoryItemSelected && (
                    <div className="category__edit-slug">
                      <h6 className="m-r">{t('category.slug')}:</h6>
                      {categoryItemSelected.slug}
                    </div>
                  )}
                </div>
              )}

              {showCategoryItemForm && this.buildCategoryItemForm()}
            </Col>
            <Col md={2}>
              <div className="category__or">- {t('common.logic.or')} -</div>
            </Col>

            <Col md={4} className="category__toggleCategoryItemForm">
              <button className="btn btn-secondary br-3" onClick={this.toggleCategoryItemForm}>
                {this.categoryItemButtonText()}
              </button>
            </Col>
          </Row>
          <Row className="m-t-3 m-b-3">
            <Col xs={2} md={2} />
            <Col xs={8} md={8} className="horizontal-separator" />
            <Col xs={2} md={2} />
          </Row>
          <Row>
            <Col sm={4} md={4}>
              <h6 className="m-b-md m-t">{t('category.lineTypeLabel')} :</h6>
              {this.displayLineTypeModules()}
            </Col>
            <Col sm={4} md={4}>
              <h6 className="m-b-md m-t">{t('category.lossTypeLabel')} :</h6>
              {this.displayLossTypeModules()}
              {showTreatedWasteLoss && this.displayTreatedWasteLossTypeModules()}
            </Col>
            <Col sm={4} md={4} className="p-l">
              <h6 className="m-b-md m-t">{t('category.mandatoryFor')} :</h6>
              {this.displayRequiredModules()}
            </Col>
          </Row>

          <Row className="m-t-md">
            <Col xs={6} className="text-right">
              <button
                type="submit"
                className="btn btn-success btn-md"
                formNoValidate
                disabled={!this.state.canSubmit || this.state.submiting}
              >
                {t('common.save')}
              </button>
            </Col>
            <Col xs={6} className="text-left">
              <button
                type="button"
                className="btn btn-secondary btn-md"
                onClick={this.props.onCancel}
              >
                {t('common.cancel')}
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

CategoryForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  lineType: PropTypes.array.isRequired,
  lossList: PropTypes.array.isRequired,
  categoryItems: PropTypes.array.isRequired,
  categoryItem: PropTypes.object,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
};

export default withTranslation()(CategoryForm);
