import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import ValueButton from '../ValueButton/ValueButton';
import ObjectiveForm from './ObjectiveForm';
import ObjectiveEditModal from './ObjectiveEditModal';
import HistoryTable from './HistoryTable';

class ObjectiveTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedEditionModal: '',
      addOldYear: false,
    };
    this.openedEditionModal = this.openedEditionModal.bind(this);
    this.closeEditionModal = this.closeEditionModal.bind(this);
    this.handleObjectiveChanged = this.handleObjectiveChanged.bind(this);
    this.handleNewObjective = this.handleNewObjective.bind(this);
    this.handleAddOldObjective = this.handleAddOldObjective.bind(this);
  }

  buildObjectiveItem(obj) {
    const { t, line } = this.props;
    const popoverHistory = (
      <Popover
        id={`objective-${obj._id}-history`}
        className="popover-large"
        title={t('objective.history')}
      >
        <HistoryTable history={obj.valueHistory} />
      </Popover>
    );

    return (
      <tr key={obj._id}>
        <td className="text-center">{obj.year}</td>
        <td className="text-right">{`${obj.value} ${t('objective.objectiveUnit', {
          lineUnit: t(`plant.lineUnits.${line.unit}`),
        })}`}</td>
        <td className="text-center m-t-md">
          <ValueButton
            type="button"
            className="btn btn-secondary"
            value={obj._id}
            onClick={this.openedEditionModal}
          >
            <i className="fa fa-pencil-square-o" aria-label="edit" />
          </ValueButton>
          <ObjectiveEditModal
            open={this.state.openedEditionModal}
            close={this.closeEditionModal}
            onSubmit={this.handleObjectiveChanged}
            objective={obj}
            line={line}
          />
          <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={popoverHistory}>
            <button type="button" className="btn btn-secondary">
              <i className="fa fa-history" aria-label="history" />
            </button>
          </OverlayTrigger>
        </td>
      </tr>
    );
  }

  buildObjectivesList() {
    const objectivesList = [];
    _.orderBy(this.props.objectives, ['year'], ['desc']).forEach(obj => {
      objectivesList.push(this.buildObjectiveItem(obj));
    });
    return objectivesList;
  }

  openedEditionModal(objectiveId) {
    this.setState({ openedEditionModal: objectiveId });
  }

  closeEditionModal() {
    this.setState({ openedEditionModal: '' });
  }

  handleNewObjective(value, year) {
    const objective = {
      plantId: this.props.plant._id,
      startDate: moment
        .utc()
        .year(year)
        .startOf('year'),
      endDate: moment
        .utc()
        .year(year)
        .endOf('year'),
      value: parseFloat(value),
      line: this.props.line.slug,
    };
    this.props.onNewObjective(objective);
    this.setState({ addOldYear: false });
  }

  handleObjectiveChanged(objectiveId, newValue) {
    const objective = {
      value: newValue.value,
    };
    this.props.onObjectiveUpdate(objectiveId, objective);
    this.closeEditionModal();
  }

  handleAddOldObjective() {
    this.setState({ addOldYear: true });
  }

  nextInputYear() {
    let year = new Date().getFullYear();
    _.forEach(this.props.objectives, obj => {
      if (obj.year >= year) {
        year = obj.year + 1;
      }
    });
    return year;
  }

  firstSetYear() {
    let year = new Date().getFullYear();
    _.forEach(this.props.objectives, obj => {
      if (obj.year <= year) {
        year = obj.year - 1;
      }
    });
    if (year === this.nextInputYear()) {
      year -= 1;
    }
    return year;
  }

  render() {
    const { t, line } = this.props;
    const objectiveTableBody = this.buildObjectivesList();
    return (
      <table className="table table-striped table-bordered table-hover" role="grid">
        <thead>
          <tr role="row">
            <th className="text-capitalize">{t('date.year')}</th>
            <th className="text-capitalize">{t('objective.objective')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <ObjectiveForm
            year={this.nextInputYear()}
            onSubmit={this.handleNewObjective}
            lineUnit={line.unit}
          />
          {objectiveTableBody}
          {!this.state.addOldYear && (
            <tr className="text-center">
              <td>
                <button
                  type="button"
                  className="btn btn-primary"
                  id="btn-add-objective"
                  onClick={this.handleAddOldObjective}
                >
                  <i className="fa fa-plus" aria-label="add" />
                </button>
              </td>
              <td colSpan={2} />
            </tr>
          )}
          {this.state.addOldYear && (
            <ObjectiveForm
              year={this.firstSetYear()}
              lineUnit={line.unit}
              onSubmit={this.handleNewObjective}
            />
          )}
        </tbody>
      </table>
    );
  }
}

ObjectiveTable.propTypes = {
  objectives: PropTypes.array,
  onNewObjective: PropTypes.func.isRequired,
  onObjectiveUpdate: PropTypes.func.isRequired,
  plant: PropTypes.object.isRequired,
  line: PropTypes.object.isRequired,
};

export default withTranslation()(ObjectiveTable);
