import { Model } from './model';
import { LineType } from './plant';
import { CategoryItemData } from './categoryItem';

export enum LossType {
  planned = 'planned',
  unplanned = 'unplanned',
  treatedWaste = 'treatedWaste',
}

export enum TreatedWasteLossType {
  performance = 'performance',
  quality = 'quality',
  none = 'none',
}

export interface CategoryData {
  parent?: string;
  position?: number;
  categoryItemSlug?: string;
  categoryItem?: CategoryItemData;
  lineType?: LineType[];
  lossType?: {
    name: LossType;
    required: boolean;
    loss?: TreatedWasteLossType;
  }[];
}

export interface Category extends Model, CategoryData {
  categoryItemSlug: string;
  position: number;
  path?: string;
  slugPath?: string;
  level?: number;
  children?: Category[];
}
