import {
  API_INDEX_REGION_PATH,
  API_CREATE_REGION_PATH,
  API_UPDATE_REGION_PATH,
  API_DELETE_REGION_PATH,
} from '../../utils/routeHelper';
import { removeFromCollection, updateCollection, addToCollection } from '../../utils/collection';
import { Region, RegionData } from '../../common/models/region';
import { error } from '../../utils/error';
import { SIGNOUT } from './auth';

export const INDEX_REGION = 'regions/INDEX_REGION';
export const INDEX_REGION_SUCCESS = 'regions/INDEX_REGION_SUCCESS';
export const INDEX_REGION_FAIL = 'regions/INDEX_REGION_FAIL';
export const CREATE_REGION = 'regions/CREATE_REGION';
export const CREATE_REGION_SUCCESS = 'regions/CREATE_REGION_SUCCESS';
export const CREATE_REGION_FAIL = 'regions/CREATE_REGION_FAIL';
export const UPDATE_REGION = 'regions/UPDATE_REGION';
export const UPDATE_REGION_SUCCESS = 'regions/UPDATE_REGION_SUCCESS';
export const UPDATE_REGION_FAIL = 'regions/UPDATE_REGION_FAIL';
export const DELETE_REGION = 'regions/DELETE_REGION';
export const DELETE_REGION_SUCCESS = 'regions/DELETE_REGION_SUCCESS';
export const DELETE_REGION_FAIL = 'regions/DELETE_REGION_FAIL';

interface RegionState {
  data?: Array<Region>;
  loading: boolean;
  creating?: boolean;
  updating?: boolean;
  deleting?: boolean;
  error?: string;
  createError?: string;
  updateError?: string;
  deleteError?: string;
}

const initialState: RegionState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export default function reducer(
  state: RegionState = initialState,
  action: ActionResult,
): RegionState {
  switch (action.type) {
    case INDEX_REGION:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_REGION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_REGION_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case CREATE_REGION:
      return {
        ...state,
        creating: true,
        createError: undefined,
      };
    case CREATE_REGION_SUCCESS:
      return {
        ...state,
        creating: false,
        data: addToCollection(state.data, action.result.data),
      };
    case CREATE_REGION_FAIL:
      return {
        ...state,
        creating: false,
        createError: error(action),
      };
    case UPDATE_REGION:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_REGION_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_REGION_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case DELETE_REGION:
      return {
        ...state,
        deleting: true,
        deleteError: undefined,
      };
    case DELETE_REGION_SUCCESS:
      return {
        ...state,
        deleting: false,
        data: removeFromCollection(state.data, action.preload),
      };
    case DELETE_REGION_FAIL:
      return {
        ...state,
        deleting: false,
        deleteError: error(action),
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexRegion(): ActionPromise {
  return {
    types: [INDEX_REGION, INDEX_REGION_SUCCESS, INDEX_REGION_FAIL],
    promise: client => client.get(API_INDEX_REGION_PATH),
  };
}

export function createRegion(data: RegionData): ActionPromise {
  return {
    types: [CREATE_REGION, CREATE_REGION_SUCCESS, CREATE_REGION_FAIL],
    promise: client => client.post(API_CREATE_REGION_PATH, data),
  };
}

export function updateRegion(id: string, data: RegionData): ActionPromise {
  return {
    types: [UPDATE_REGION, UPDATE_REGION_SUCCESS, UPDATE_REGION_FAIL],
    promise: client => client.put(API_UPDATE_REGION_PATH(id), data),
  };
}

export function deleteRegion(id: string): ActionPromise {
  return {
    types: [DELETE_REGION, DELETE_REGION_SUCCESS, DELETE_REGION_FAIL],
    promise: client => client.delete(API_DELETE_REGION_PATH(id)),
    preload: { _id: id },
  };
}
