import React from 'react';
import { Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SiteNav: React.FC = () => {
  const { t } = useTranslation();

  if (!process.env.REACT_APP_SUEZ_EFW_URL) {
    return null;
  }

  return (
    <Nav className="pull-left m-l-md site--nav">
      <NavDropdown eventKey={0} title={<span>{t('common.hwName')}</span>} id="dropdown-sitenav">
        <MenuItem eventKey={1} href={process.env.REACT_APP_SUEZ_EFW_URL} target="_black">
          {t('common.efwName')}
        </MenuItem>
      </NavDropdown>
    </Nav>
  );
};

export default SiteNav;
