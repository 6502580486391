import React from 'react';
import { Provider } from 'react-redux';
import Routes from './Routes';

interface Props {
  store: any;
}

const Root: React.FC<Props> = ({ store }) => {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default Root;
