import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Locale from '../components/Locale/Locale';
import NewPasswordForm from '../components/Password/NewPasswordForm';
import { addClassByTagName } from '../utils/vanilla';
import { signout, definePassword } from '../redux/modules/auth';
import { HOME_PATH } from '../utils/routeHelper';
import logoWhite from '../assets/images/logo-suez-white.png';

export const ResetPassword: React.FC<RouteComponentProps> = ({ match, history }) => {
  addClassByTagName('html', 'backgroud-image');
  const dispatch = useDispatch();

  const handleDefinePassword = (password: string): Promise<void> => {
    return dispatch(definePassword(match.params['token'], password));
  };

  const handlePasswordChanged = () => {
    dispatch(signout());
    history.push(HOME_PATH);
  };

  return (
    <div className="wrap-content">
      <header className="header">
        <div className="header__logo">
          <Link to="/">
            <img src={logoWhite} alt="SUEZ EFW" className="logo" />
          </Link>
        </div>
        <nav className="header__rightnav">
          <Locale />
        </nav>
      </header>
      <section className="container-fluid">
        <NewPasswordForm onDefine={handleDefinePassword} onDefineSuccess={handlePasswordChanged} />
      </section>
    </div>
  );
};

export default withRouter(ResetPassword);
