/* global FileReader */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Input, File } from 'formsy-react-components';
import ImageCropModal from '../Modal/ImageCropModal';
import BaseForm from '../Form/BaseForm';
import Avatar from '../Avatar/Avatar';

export class ProfileForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      src: this.props.data.avatar,
      initialAvatar: this.props.data.avatar,
      cropResult: undefined,
      openImageCropModal: false,
    };
    this.handleAvatarChange = this.handleAvatarChange.bind(this);
    this.closeImageCropModal = this.closeImageCropModal.bind(this);
    this.handleCropImage = this.handleCropImage.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  openImageCropModal() {
    this.setState({ openImageCropModal: true });
  }

  closeImageCropModal() {
    this.setState({ openImageCropModal: false });
  }

  handleCropImage(crop) {
    this.closeImageCropModal();
    this.props.data.avatar = crop.b64Result;
    this.setState({ cropResult: crop.blobResult });
  }

  handleCancel() {
    this.props.data.avatar = this.state.initialAvatar;
    this.props.onCancel();
  }

  handleSubmit(data) {
    this.props.onSubmit(data);
  }

  handleAvatarChange(name, files) {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);
    this.openImageCropModal();
  }

  render() {
    const {
      data: { firstName, lastName, email, phone, avatar, isUpdatingProfile },
      t,
    } = this.props;
    const downloadLabel = (
      <span>
        <i className="fa fa-upload m-r" aria-hidden="true" /> {t('common.chooseAFile')}
      </span>
    );
    const sharedProps = {
      validations: 'maxLength:255',
      validationError: t('errors.inputTextMaxLength', { max: 255 }),
    };
    return (
      <Form onSubmit={this.handleSubmit} onValid={this.enableButton} onInvalid={this.disableButton}>
        <div className="text-center m-t-md">
          <Avatar filename={avatar} version="mini" width="100px" />
          <div className="btn-file">
            <File
              layout="vertical"
              name="avatar"
              id="avatar"
              label={downloadLabel}
              onChange={this.handleAvatarChange}
            />
            <Input type="hidden" value={this.state.cropResult} name="avatarCrop" />
            <ImageCropModal
              open={this.state.openImageCropModal}
              close={this.closeImageCropModal}
              onSubmit={this.handleCropImage}
              image={this.state.src}
              title={t('user.resizeYourAvatar')}
              aspectRatio={1}
              autoCrop
            />
          </div>
        </div>
        <Input
          className="form-control form-control-lg"
          layout="vertical"
          name="firstName"
          value={firstName}
          label={t('user.firstName')}
          type="text"
          placeholder={t('user.yourFirstName')}
          {...sharedProps}
          required
        />
        <Input
          className="form-control form-control-lg"
          layout="vertical"
          name="lastName"
          value={lastName}
          label={t('user.lastName')}
          type="text"
          placeholder={t('user.yourLastName')}
          {...sharedProps}
          required
        />
        <Input
          className="form-control form-control-lg"
          layout="vertical"
          name="email"
          value={email}
          label={t('user.email')}
          type="text"
          placeholder={t('user.yourEmail')}
          validations="isEmail"
          required
        />
        <Input
          className="form-control form-control-lg"
          layout="vertical"
          name="phone"
          value={phone}
          label={t('user.phone')}
          type="text"
          {...sharedProps}
          placeholder={t('user.yourPhoneNumber')}
        />

        <div className="text-center m-t-lg m-b-md">
          <button
            type="submit"
            className="btn btn-primary btn-lg m-r-md"
            formNoValidate
            disabled={!this.state.canSubmit || isUpdatingProfile}
          >
            {t('common.save')}
          </button>
          <button type="button" className="btn btn-lg btn-secondary" onClick={this.handleCancel}>
            {t('common.cancel')}
          </button>
        </div>
      </Form>
    );
  }
}

export default withTranslation()(ProfileForm);
