import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, Select as FormsySelect } from 'formsy-react-components';
import _ from 'lodash';
import moment from 'moment';
import { convertToRaw } from 'draft-js';
import i18n from 'i18next';
import TextEditor from '../TextEditor/TextEditor';
import StickyBar from '../StickyBar/StickyBar';
import BaseForm from '../Form/BaseForm';
import InputAddress from '../Form/InputAddress';
import { identifier } from '../../../common/models/model';

export default class PlantForm extends BaseForm {
  static getRegionsByBusinessUnit(businessUnitId, regions) {
    if (businessUnitId) {
      return _.filter(regions, { businessUnitId });
    }
    return undefined;
  }

  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      regions: PlantForm.getRegionsByBusinessUnit(props.data.businessUnitId, props.regions),
    };
    this.coordinates = props.data.coordinates;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
      regions: PlantForm.getRegionsByBusinessUnit(nextProps.data.businessUnitId, nextProps.regions),
    });
    this.coordinates = nextProps.data.coordinates;
  }

  handleSubmit(data) {
    const formData = Object.assign({}, data);
    formData.description = convertToRaw(this.descriptionEditorState.getCurrentContent());
    if (this.coordinates) {
      formData.coordinates = this.coordinates;
    }
    if (formData.address === '') {
      formData.coordinates = [];
    }
    this.props.onSubmit(formData);
  }

  businessUnitOptions() {
    const buOptions = this.props.businessUnits.map(bu => ({
      value: bu[identifier],
      label: bu.name,
    }));
    if (!this.props.data.businessUnitId) {
      buOptions.unshift({ value: '', label: i18n.t('plant.selectABusinessUnit') });
    }
    return buOptions;
  }

  changeRegions = (name, value) => {
    this.setState({
      regions: PlantForm.getRegionsByBusinessUnit(value, this.props.regions),
    });
  };

  regionsSelect() {
    if (this.state.regions) {
      return (
        <FormsySelect
          className="c-select form-control"
          layout="vertical"
          name="regionId"
          value={this.state.data.regionId}
          label={i18n.t('region.region')}
          options={this.regionsOptions()}
          required
        />
      );
    }
    return null;
  }

  regionsOptions() {
    const options = this.state.regions.map(region => {
      return { value: region._id, label: region.name };
    });
    options.unshift({ value: '', label: i18n.t('plant.selectARegion') });
    return options;
  }

  handleDescriptionUpdate = editorState => {
    this.descriptionEditorState = editorState;
  };

  handleAddressCoordinatesChanged = coordinates => {
    this.coordinates = coordinates;
  };

  render() {
    const {
      data: { name, slug, businessUnitId, website, address, description, phone, startYear },
    } = this.props;
    return (
      <Row>
        <Col lg={12}>
          <Form
            onSubmit={this.handleSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="name"
              value={name}
              label={i18n.t('plant.name')}
              type="text"
              placeholder={i18n.t('plant.nameOfPlant')}
              validations={'maxLength:255'}
              validationError={i18n.t('errors.inputTextMaxLength', { max: 255 })}
              id="input-plant-name"
              autoFocus
              required
            />

            <label className="control-label" htmlFor="name">
              {i18n.t('plant.plantIdentifier')}
            </label>
            <input
              className="form-control form-control-lg m-b"
              name="name"
              value={slug}
              type="text"
              placeholder={i18n.t('plant.willBeGenerated')}
              readOnly
            />

            <FormsySelect
              className="c-select form-control"
              layout="vertical"
              name="businessUnitId"
              value={businessUnitId}
              label={i18n.t('businessUnit.businessUnit')}
              options={this.businessUnitOptions()}
              required
              onChange={this.changeRegions}
            />

            {this.regionsSelect()}

            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="website"
              value={website}
              label={i18n.t('plant.website')}
              type="url"
              placeholder="http://www..."
            />

            <div className="form-group">
              <label className="control-label" htmlFor="description">
                {i18n.t('plant.description')}
              </label>
              <TextEditor
                name="description"
                onChange={this.handleDescriptionUpdate}
                rawState={description}
              />
            </div>

            <InputAddress
              className="form-control form-control-lg"
              layout="vertical"
              name="address"
              value={address}
              label={i18n.t('plant.address')}
              onCoordinatesChange={this.handleAddressCoordinatesChanged}
              type="text"
            />

            <Row>
              <Col md={6}>
                <Input
                  className="form-control form-control-lg"
                  layout="vertical"
                  name="phone"
                  value={phone}
                  label={i18n.t('plant.phone')}
                  type="text"
                />
              </Col>
              <Col md={6}>
                <Input
                  className="form-control form-control-lg"
                  rowClassName={startYear !== undefined && startYear !== null ? '' : 'important'}
                  layout="vertical"
                  name="startYear"
                  value={moment(startYear).format('YYYY-MM-DD')}
                  label={i18n.t('plant.startYear')}
                  type="date"
                />
              </Col>
            </Row>

            <StickyBar>
              <div className="text-center m-t-md">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  formNoValidate
                  disabled={!this.state.canSubmit}
                >
                  {i18n.t('plant.savePlant')}
                </button>
              </div>
            </StickyBar>
          </Form>
        </Col>
      </Row>
    );
  }
}
