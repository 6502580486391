import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Alert } from 'react-bootstrap';
import { Link, withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Locale from '../components/Locale/Locale';
import ResetPasswordModal from '../components/Password/ResetPasswordModal';
import { addClassByTagName } from '../utils/vanilla';
import { signin, isAuthenticated, resetPassword } from '../redux/modules/auth';
import { APP_PATH } from '../utils/routeHelper';
import logoWhite from '../assets/images/logo-suez-white.png';
import { mailtoAdmin } from '../utils/mailto';

const Signin: React.FC<RouteComponentProps> = ({ location }) => {
  addClassByTagName('html', 'backgroud-image');

  const [isOpenResetPasswordModal, setIsOpenResetPasswordModal] = useState(false);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const errorMessage = () => {
    if (auth.signingInError) {
      return (
        <Alert bsStyle="danger">
          <p>{t(`auth.${auth.signingInError.name}`, auth.signingInError)}</p>
        </Alert>
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    dispatch(
      signin({
        email: data.get('email'),
        password: data.get('password'),
      }),
    );
  };

  const openResetPasswordModal = e => {
    e.preventDefault();
    setIsOpenResetPasswordModal(true);
  };

  const closeResetPasswordModal = () => {
    setIsOpenResetPasswordModal(false);
  };

  const handleResetPassword = (email: string) => {
    return dispatch(resetPassword(email));
  };

  if (isAuthenticated(auth)) {
    const { from } = location.state || {
      from: { pathname: APP_PATH },
    };
    return <Redirect to={from} />;
  }

  return (
    <div className="wrap-content">
      <header className="header">
        <div className="header__logo">
          <Link to="/">
            <img src={logoWhite} alt="SUEZ HW" className="logo" />
          </Link>
        </div>
        <nav className="header__rightnav">
          <Locale />
        </nav>
      </header>
      <section className="container-fluid">
        <Row>
          <Col md={6} mdOffset={3} lg={4} lgOffset={4}>
            <div className="card card--login card--shadow text-center">
              <div className="card-header bg-white">
                <h2 className="card-title upper">{t('auth.signIn')}</h2>
                <h2 className="card-title upper">{t('common.hwName')}</h2>
              </div>
              <div className="card-block bg-white">
                {errorMessage()}
                <form onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="inputEmail"
                        className="form-control form-control-lg"
                        placeholder={t('user.email')}
                        autoComplete="off"
                        required
                        autoFocus
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        id="inputPassword"
                        autoComplete="off"
                        className="form-control form-control-lg"
                        placeholder={t('password.password')}
                        required
                      />
                    </div>
                    <p className="m-b-0">
                      <small>
                        <a href="#/" onClick={openResetPasswordModal}>
                          {t('password.forgotPassword')}
                        </a>
                        <ResetPasswordModal
                          open={isOpenResetPasswordModal}
                          close={closeResetPasswordModal}
                          onSubmit={handleResetPassword}
                        />
                      </small>
                    </p>
                    <p>
                      <small>
                        {t('auth.noAccount')} <a href={mailtoAdmin()}>{t('auth.emailAdmin')}</a>
                      </small>
                    </p>
                    <button
                      className="btn btn-lg btn-secondary button--primary"
                      type="submit"
                      disabled={auth.signingIn}
                    >
                      {t('auth.login')}
                    </button>
                  </fieldset>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default withRouter(Signin);
