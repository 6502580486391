import React from 'react';
import classNames from 'classnames';

interface PageContentProps {
  children: React.ReactNode;
  fluid?: boolean;
  containerLg?: boolean;
  className?: string;
}

const PageContent: React.FC<PageContentProps> = ({ fluid, containerLg, children, className }) => {
  const containerClassName = classNames({
    container: !fluid && !containerLg,
    'container-fluid': fluid,
    'container-lg': containerLg,
  });
  const contentClassName = classNames('content', className);
  return (
    <div className={contentClassName}>
      <section className={containerClassName}>{children}</section>
    </div>
  );
};

export default React.memo(PageContent);
