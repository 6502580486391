import {
  API_INDEX_CATEGORY_PATH,
  API_CREATE_CATEGORY_PATH,
  API_UPDATE_CATEGORY_PATH,
  API_DELETE_CATEGORY_PATH,
  API_EXPORT_CATEGORY_PATH,
} from '../../utils/routeHelper';
import { removeFromCollection, updateCollection, addToCollection } from '../../utils/collection';
import { error } from '../../utils/error';
import { downloadFile } from '../../utils/appHelper';
import { SIGNOUT } from './auth';

export const INDEX_CATEGORY = 'categories/INDEX_CATEGORY';
export const INDEX_CATEGORY_SUCCESS = 'categories/INDEX_CATEGORY_SUCCESS';
export const INDEX_CATEGORY_FAIL = 'categories/INDEX_CATEGORY_FAIL';
export const CREATE_CATEGORY = 'categories/CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'categories/CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'categories/CREATE_CATEGORY_FAIL';
export const UPDATE_CATEGORY = 'categories/UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'categories/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'categories/UPDATE_CATEGORY_FAIL';
export const DELETE_CATEGORY = 'categories/DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'categories/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'categories/DELETE_CATEGORY_FAIL';
export const UPDATE_CATEGORY_POSITION = 'categories/UPDATE_CATEGORY_POSITION';
export const UPDATE_CATEGORY_POSITION_SUCCESS = 'categories/UPDATE_CATEGORY_POSITION_SUCCESS';
export const UPDATE_CATEGORY_POSITION_FAIL = 'categories/UPDATE_CATEGORY_POSITION_FAIL';
export const EXPORT_CATEGORY = 'categories/EXPORT_CATEGORY';
export const EXPORT_CATEGORY_SUCCESS = 'categories/EXPORT_CATEGORY_SUCCESS';
export const EXPORT_CATEGORY_FAIL = 'categories/EXPORT_CATEGORY_FAIL';

interface CategoryState {
  data?: Array<any>;
  loading: boolean;
  creating?: boolean;
  updating?: boolean;
  deleting?: boolean;
  error?: string;
  createError?: string;
  updateError?: string;
  deleteError?: string;
}

const initialState: CategoryState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export default function reducer(
  state: CategoryState = initialState,
  action: ActionResult,
): CategoryState {
  switch (action.type) {
    case INDEX_CATEGORY:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        creating: true,
        createError: undefined,
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        creating: false,
        data: addToCollection(state.data, action.result.data),
      };
    case CREATE_CATEGORY_FAIL:
      return {
        ...state,
        creating: false,
        createError: error(action),
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        deleting: true,
        deleteError: undefined,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleting: false,
        data: removeFromCollection(state.data, action.preload),
      };
    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        deleting: false,
        deleteError: error(action),
      };
    case EXPORT_CATEGORY_SUCCESS:
      downloadFile(action.result, 'category.xlsx');
      return state;
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexCategory(): ActionPromise {
  return {
    types: [INDEX_CATEGORY, INDEX_CATEGORY_SUCCESS, INDEX_CATEGORY_FAIL],
    promise: client => client.get(API_INDEX_CATEGORY_PATH),
  };
}

export function createCategory(data: any): ActionPromise {
  return {
    types: [CREATE_CATEGORY, CREATE_CATEGORY_SUCCESS, CREATE_CATEGORY_FAIL],
    promise: client => client.post(API_CREATE_CATEGORY_PATH, data),
  };
}

export function updateCategory(id: string, data: any): ActionPromise {
  return {
    types: [UPDATE_CATEGORY, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAIL],
    promise: client => client.put(API_UPDATE_CATEGORY_PATH(id), data),
  };
}

export function updateCategoryPosition(data: any): ActionPromise {
  return {
    types: [
      UPDATE_CATEGORY_POSITION,
      UPDATE_CATEGORY_POSITION_SUCCESS,
      UPDATE_CATEGORY_POSITION_FAIL,
    ],
    promise: client => client.put(API_CREATE_CATEGORY_PATH, data),
  };
}

export function deleteCategory(id: string): ActionPromise {
  return {
    types: [DELETE_CATEGORY, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAIL],
    promise: client => client.delete(API_DELETE_CATEGORY_PATH(id)),
    preload: { _id: id },
  };
}

export function exportCategory(): ActionPromise {
  return {
    types: [EXPORT_CATEGORY, EXPORT_CATEGORY_SUCCESS, EXPORT_CATEGORY_FAIL],
    promise: client => client.get(API_EXPORT_CATEGORY_PATH, { responseType: 'blob' }),
  };
}
