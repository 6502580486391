import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import fp from 'lodash/fp';
import { withRouter, RouteComponentProps } from 'react-router';
import { updateNews, deleteNews, indexNews } from '../../../redux/modules/news';
import { getFromCollection } from '../../../utils/collection';
import loadData from '../../../utils/loadData';
import PostForm from '../../../components/App/News/PostForm';
import { News } from '../../../common/models/news';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import ConfirmButton from '../../../components/App/ConfirmButton/ConfirmButton';
import { APP_NEWS_PATH } from '../../../utils/routeHelper';

interface NewsEditProps extends RouteComponentProps, WithTranslation {
  updateNews: Function;
  deleteNews: Function;
  post: News;
}

class NewsEdit extends Component<NewsEditProps> {
  handleSubmit = data => {
    this.props.updateNews(data);
  };

  deletePost = () => {
    this.props.deleteNews(this.props.post).then(action => {
      if (action && action.type.match(/SUCCESS/)) {
        this.props.history.push(APP_NEWS_PATH);
      }
    });
  };

  render() {
    const { i18n } = this.props;
    return (
      <div className="">
        <PageNav>
          <div className="plant-band">
            <div className="nav-secondary__left">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="returnTooltip">{i18n.t('news.returnToPostsList')}</Tooltip>}
              >
                <Button
                  className="btn-secondary btn-xl"
                  onClick={() => {
                    this.props.history.push(APP_NEWS_PATH);
                  }}
                >
                  <i className="fa fa-long-arrow-left m-r" />
                </Button>
              </OverlayTrigger>
            </div>
            <h2 className="nav-secondary__title">{i18n.t('news.editPost')}</h2>
            <div className="nav-secondary__right">
              <ConfirmButton
                className="btn btn-danger btn-md-hide-label btn-lg m-r btn-round"
                onClick={this.deletePost}
                message={i18n.t('news.confirmDeleteMessage')}
                confirmButtonStyle="danger"
              >
                <i className="fa fa-trash-o" />
              </ConfirmButton>
            </div>
          </div>
        </PageNav>
        <PageContent>
          <Row>
            <Col md={8}>
              <div className="card bg-white m-t-lg">
                <div className="card-block">
                  <PostForm
                    data={this.props.post}
                    onSubmit={this.handleSubmit}
                    onCancel={this.props.history.goBack}
                    locale={i18n.language}
                  />
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card bg-white m-t-lg">
                <div className="card-header">{i18n.t('news.informations')}</div>
                <div className="card-block">
                  <div className="m-b-2">
                    <div className="number-labeled">
                      <div className="label label-neutral text-left">{i18n.t('news.author')}</div>
                      <div className="font-bold">
                        {this.props.post.author
                          ? this.props.post.author.name
                          : i18n.t('user.noExist')}
                      </div>
                    </div>
                  </div>

                  <div className="m-b-2">
                    <div className="number-labeled">
                      <div className="label label-neutral text-left">
                        {i18n.t('news.createdAt')}
                      </div>
                      <div className="font-bold">
                        {moment.utc(this.props.post.createdAt).format(i18n.t('date.long'))}
                      </div>
                    </div>
                  </div>

                  <div className="m-b-2">
                    <div className="number-labeled">
                      <div className="label label-neutral text-left">
                        {i18n.t('news.updatedAt')}
                      </div>
                      <div className="font-bold">
                        {moment.utc(this.props.post.updatedAt).format(i18n.t('date.long'))}
                      </div>
                    </div>
                  </div>

                  {this.props.post.publishedAt && (
                    <div className="m-b-2">
                      <div className="number-labeled">
                        <div className="label label-neutral text-left">
                          {i18n.t('news.publishedAt')}
                        </div>
                        <div className="font-bold">
                          {moment.utc(this.props.post.publishedAt).format(i18n.t('date.long'))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </PageContent>
      </div>
    );
  }
}

const mapStateToProps = (state, router) => {
  return {
    post: getFromCollection(state.news.data, router.match.params.id),
  };
};

const mapDispatchToProps = {
  updateNews,
  deleteNews,
};

export default fp.compose(
  withRouter,
  withTranslation(),
  loadData(async dispatch => {
    await dispatch(indexNews());
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(NewsEdit);
