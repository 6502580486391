import React, { PureComponent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import Big from 'big.js';
import classNames from 'classnames';
import produce from 'immer';
import Cascader from 'rc-cascader';
import { WithTranslation, withTranslation } from 'react-i18next';
import InputNumber from '../../Form/InputNumber';
import OEEInputComment from '../OEEInputComment';
import OEEInputValidation from '../OEEInputValidation';
import { PlantAndLine } from '../../../../redux/modules/plants';
import { OEEInput, OEEInputType } from '../../../../common/models/oeeInput';
import { Category, LossType } from '../../../../common/models/category';
import { CategoryItem } from '../../../../common/models/categoryItem';
import { Objective } from '../../../../common/models/objective';
import {
  lossTotal,
  lossDetailTotal,
  canValidateOEEInput,
  checkOEEInputErrors,
} from '../../../../common/utils/oeeInputHelper';
import { roundOEEInput, numberFormat } from '../../../../utils/oeeHelper';
import {
  buildOEEInputCategoryTreeOptions,
  getCategoryPathIds,
  getCategoryPathNames,
} from '../../../../utils/categoryHelper';

interface OEEInputWasteTreatedProps extends WithTranslation {
  plantAndLine: PlantAndLine;
  data: OEEInput;
  saveOEEInput: Function;
  canEdit: boolean;
  siblings: {
    prevOEEInput?: OEEInput;
    nextOEEInput?: OEEInput;
    prevShiftOEEInput?: OEEInput;
    nextShiftOEEInput?: OEEInput;
    otherShiftOEEInputs?: OEEInput[];
  };
  categories: Category[];
  categoryItems: CategoryItem[];
  objective?: Objective;
}

interface OEEInputWasteTreatedState {
  oeeInput: OEEInput;
  isEdit: boolean;
}

class OEEInputWasteTreated extends PureComponent<
  OEEInputWasteTreatedProps,
  OEEInputWasteTreatedState
> {
  constructor(props: OEEInputWasteTreatedProps) {
    super(props);
    this.state = {
      oeeInput: props.data,
      isEdit: false,
    };
    this.activeEditOEEInput = this.activeEditOEEInput.bind(this);
    this.onExitEditListener = this.onExitEditListener.bind(this);
    this.addLossDetail = this.addLossDetail.bind(this);
    this.handleWasteTreatedChange = this.handleWasteTreatedChange.bind(this);
    this.getPrevLoss = this.getPrevLoss.bind(this);
  }

  tbodyEl = React.createRef<HTMLTableSectionElement>();

  onExitEditListener(e) {
    // if dont click it-self
    if (
      this.tbodyEl &&
      this.tbodyEl.current &&
      !_.includes(this.tbodyEl.current.getElementsByTagName('*'), e.target)
    ) {
      const rootEl = document.getElementById('root');
      if (rootEl) {
        rootEl.removeEventListener('click', this.onExitEditListener, false);
      }
      if (!_.isEqual(this.state.oeeInput, this.props.data)) {
        // Changes on OEEInput data
        this.props.saveOEEInput(this.state.oeeInput).then(action => {
          if (action.type.match(/SUCCESS/)) {
            this.setState({ isEdit: false });
          } else {
            this.setState({ isEdit: false, oeeInput: this.props.data });
          }
        });
      } else {
        // No change on OEE data, exit edit mode
        this.setState({ isEdit: false });
      }
    }
  }

  activeEditOEEInput() {
    const { oeeInput, isEdit } = this.state;
    if (this.props.canEdit && !oeeInput.isValid && !isEdit) {
      //if (this.props.canEdit && !data.isValid && !this.state.validing) {
      this.setState({ isEdit: true });
      const rootEl = document.getElementById('root');
      if (rootEl) {
        rootEl.addEventListener('click', this.onExitEditListener, false);
      }
    }
    return false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(nextProps.data.updatedAt, prevState.oeeInput.updatedAt) || !prevState.isEdit) {
      return { oeeInput: nextProps.data };
    } else return null;
  }

  handleWasteTreatedChange(value) {
    this.setState(
      produce(draft => {
        draft.oeeInput.wasteTreated.wasteTreated = value;
        if (_.isFinite(value) && this.props.objective) {
          const totalLoss = lossTotal(draft.oeeInput, this.props.objective);
          const lossDetail = draft.oeeInput.wasteTreated.lossDetail;
          if (lossDetail.length === 1) {
            lossDetail[0].loss = totalLoss;
            if (value === 0) {
              lossDetail[0].category = undefined;
              lossDetail[0].categories = undefined;
            }
          }
        }
      }),
    );
  }

  handleLossDetailChange(i: number) {
    return value => {
      this.setState(
        produce(draft => {
          const { oeeInput } = draft;
          oeeInput.wasteTreated.lossDetail[i].loss = _.isFinite(value) ? value : undefined;
          if (value === 0 || !_.isFinite(value)) {
            oeeInput.wasteTreated.lossDetail[i].category = undefined;
            oeeInput.wasteTreated.lossDetail[i].categories = undefined;
          }
        }),
      );
    };
  }

  handleLossCategoryChange(index: number) {
    return (value: string[]) => {
      this.setState(
        produce(draft => {
          draft.oeeInput.wasteTreated.lossDetail[index].category = _.last(value);
          draft.oeeInput.wasteTreated.lossDetail[index].categories = value;
        }),
      );
    };
  }

  handleRemoveCategory(index: number) {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      this.handleLossCategoryChange(index)([]);
    };
  }

  handleCopyCategoryFromPrevLoss(index: number) {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      const prevLoss = this.getPrevLoss(index);
      this.handleLossCategoryChange(index)(prevLoss.categories);
    };
  }

  handleSaveComment(index: number) {
    return (value: string) => {
      this.setState(
        produce(draft => {
          const { oeeInput } = draft;
          oeeInput.wasteTreated.lossDetail[index].comment = value;
        }),
      );
    };
  }

  canCopyCategoryFromPrevLoss(index: number) {
    const prevLoss = this.getPrevLoss(index);
    return prevLoss && prevLoss.category;
  }

  getPrevLoss(index: number) {
    if (index === 0) {
      if (this.state.oeeInput.shift) {
        if (this.props.siblings.prevShiftOEEInput) {
          return _.last(this.props.siblings.prevShiftOEEInput.wasteTreated.lossDetail);
        }
      } else {
        if (this.props.siblings.prevOEEInput) {
          return _.last(this.props.siblings.prevOEEInput.wasteTreated.lossDetail);
        }
      }
    }
    return this.state.oeeInput.wasteTreated.lossDetail[index - 1];
  }

  getLossTotal() {
    const { objective } = this.props;
    const { oeeInput } = this.state;
    if (objective && _.isFinite(oeeInput.wasteTreated.wasteTreated)) {
      return lossTotal(oeeInput, objective);
    }
    return null;
  }

  addLossDetail(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.oeeInput.isValid || !this.state.isEdit) {
      return false;
    }
    const oeeInput = produce(this.state.oeeInput, (draft: any) => {
      const loss: any = {};
      if (this.props.objective) {
        const totalLoss = lossTotal(draft, this.props.objective);
        const totalLossDetail = lossDetailTotal(draft);
        if (totalLoss !== undefined && totalLossDetail !== undefined) {
          loss.loss = Number(Big(totalLoss).minus(totalLossDetail));
        }
      }
      draft.wasteTreated.lossDetail.push(loss);
    });
    this.props.saveOEEInput(oeeInput);
  }

  removeLossDetail(i: number) {
    return (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.state.oeeInput.isValid || !this.state.isEdit) {
        return false;
      }
      const oeeInput = produce(this.state.oeeInput, (draft: any) => {
        draft.wasteTreated.lossDetail.splice(i, 1);
      });
      this.props.saveOEEInput(oeeInput);
    };
  }

  buildAddRemoveLossDetailCell(index: number) {
    const hideBtn =
      this.state.oeeInput.isValid ||
      !this.props.canEdit ||
      !this.state.isEdit ||
      this.state.oeeInput.availability.openingTime === 0;
    const className = classNames('oee-input__cell cell-invisible', {
      hideBtn,
    });
    if (index === 0) {
      return (
        <td className={className} onClick={this.addLossDetail}>
          {!hideBtn && (
            <a href="#/" className="btn">
              <i className="fa fa-plus-circle" />
            </a>
          )}
        </td>
      );
    }
    return (
      <td className={className} onClick={this.removeLossDetail(index)}>
        {!hideBtn && (
          <a href="#/" className="btn">
            <i className="fa fa-minus" />
          </a>
        )}
      </td>
    );
  }

  oeeInputStatus() {
    const { oeeInput } = this.state;
    const {
      categories,
      objective,
      siblings: { otherShiftOEEInputs },
    } = this.props;
    const errors = checkOEEInputErrors(
      oeeInput,
      categories,
      objective,
      OEEInputType.wasteTreated,
      otherShiftOEEInputs,
    );
    if (oeeInput.isValid) return 'oee-input__group oee-input__group--validated';
    if (errors.length === 0) {
      return 'oee-input__group oee-input__group--completed';
    }
    return 'oee-input__group oee-input__group--incompleted';
  }

  render() {
    const { oeeInput, isEdit } = this.state;
    const {
      t,
      i18n,
      data,
      canEdit,
      plantAndLine: { line },
      siblings,
      categories,
      categoryItems,
      objective,
    } = this.props;
    const copyCategoryFromPrevLossTooltip = (
      <Tooltip id="copyPrevOEECategoryTooltip">{t('OEEInput.copyConstraintFromPrevLoss')}</Tooltip>
    );
    return (
      <tbody ref={this.tbodyEl} className={this.oeeInputStatus()} onClick={this.activeEditOEEInput}>
        {oeeInput.wasteTreated.lossDetail.map((s, k) => {
          const isFirstLine = k === 0;
          const categoryPathNames = getCategoryPathNames(
            categories,
            categoryItems,
            s.category,
            i18n.language,
          );
          return (
            <tr className="oee-input__row" key={k}>
              {this.buildAddRemoveLossDetailCell(k)}

              <td className="oee-input__cell text-center">
                {isFirstLine && moment.utc(oeeInput.date).format(t('date.short'))}
              </td>

              <td className="oee-input__cell text-center">
                {isFirstLine && !isEdit && (
                  <span>
                    {numberFormat(
                      roundOEEInput(oeeInput.wasteTreated.wasteTreated, 2),
                      i18n.language,
                    )}
                  </span>
                )}
                {isFirstLine && isEdit && (
                  <InputNumber
                    onChange={this.handleWasteTreatedChange}
                    name="wasteTreated"
                    value={oeeInput.wasteTreated.wasteTreated}
                    disabled={oeeInput.availability.openingTime === 0}
                  />
                )}
              </td>

              <td className="oee-input__cell text-center">
                {isFirstLine && (
                  <div>{numberFormat(roundOEEInput(this.getLossTotal(), 2), i18n.language)}</div>
                )}
              </td>

              <td className="oee-input__cell text-center">
                {!isEdit && <span>{numberFormat(roundOEEInput(s.loss, 2), i18n.language)}</span>}
                {isEdit && (
                  <InputNumber
                    onChange={this.handleLossDetailChange(k)}
                    name={`loss${k}`}
                    value={s.loss}
                    disabled={oeeInput.availability.openingTime === 0}
                  />
                )}
              </td>

              <td className="oee-input__cell">
                {!isEdit && (
                  <span style={{ display: 'inline-block' }}>
                    {categoryPathNames.map((val, index) => {
                      return (
                        <span key={index}>
                          {val}
                          {index < categoryPathNames.length - 1 && (
                            <i className="fa fa-angle-right text-gray-light-mid m-l m-r" />
                          )}
                        </span>
                      );
                    })}
                  </span>
                )}
                {isEdit && s.loss !== 0 && line && (
                  <Cascader
                    options={buildOEEInputCategoryTreeOptions(
                      categories,
                      categoryItems,
                      line.type,
                      LossType.treatedWaste,
                      oeeInput.date,
                      i18n.language,
                    )}
                    defaultValue={getCategoryPathIds(categories, s.category)}
                    onChange={this.handleLossCategoryChange(k)}
                    changeOnSelect
                    disabled={oeeInput.availability.openingTime === 0}
                  >
                    <div className="rc-cascader-input-group">
                      <input type="text" value={categoryPathNames.join(', ')} readOnly />
                      {s.category && (
                        <a href="#/" onClick={this.handleRemoveCategory(k)} className="remove">
                          <i className="fa fa-times" />
                        </a>
                      )}
                      {this.canCopyCategoryFromPrevLoss(k) && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={copyCategoryFromPrevLossTooltip}
                        >
                          <a
                            href="#/"
                            onClick={this.handleCopyCategoryFromPrevLoss(k)}
                            className="copy"
                          >
                            <i className="fa fa-arrow-down" />
                          </a>
                        </OverlayTrigger>
                      )}
                    </div>
                  </Cascader>
                )}
              </td>
              <td className="oee-input__cell text-center">
                <OEEInputComment
                  comment={s.comment}
                  isEdit={isEdit}
                  onSaveComment={this.handleSaveComment(k)}
                />
              </td>
              <td className="oee-input__cell">
                {isFirstLine && data._id && canEdit && !isEdit && (
                  <OEEInputValidation
                    oeeInput={data}
                    categories={categories}
                    objective={objective}
                    oeeInputType={OEEInputType.wasteTreated}
                    canValid={canValidateOEEInput(data, siblings.prevOEEInput, line)}
                    otherOEEInputs={siblings.otherShiftOEEInputs}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default withTranslation()(OEEInputWasteTreated);
