import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import fp from 'lodash/fp';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  showPlant,
  updatePlant,
  deletePlantPhoto,
  updatePlantPhoto,
  deletePlant,
  createPlantLine,
  updatePlantLine,
  updatePlantLinePosition,
} from '../../../redux/modules/plants';
import PlantPolicy from '../../../common/policies/plant_policy';
import { indexBusinessUnit } from '../../../redux/modules/businessUnits';
import { indexRegion } from '../../../redux/modules/regions';
import PlantBand from '../../../components/App/Plant/PlantBand';
import PlantInfo from '../../../components/App/Plant/PlantInfo';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import loadData from '../../../utils/loadData';
import ConfirmButton from '../../../components/App/ConfirmButton/ConfirmButton';
import { identifier } from '../../../common/models/model';
import { Plant } from '../../../common/models/plant';
import { BusinessUnit } from '../../../common/models/businessUnit';
import { Region } from '../../../common/models/region';
import { User } from '../../../common/models/user';
import { APP_PLANTS_PATH } from '../../../utils/routeHelper';

interface PlantEditProps extends RouteComponentProps, WithTranslation {
  plant: Plant;
  businessUnits: BusinessUnit[];
  regions: Region[];
  getPlant: Function;
  updatePlant: Function;
  deletePlantPhoto: Function;
  updatePlantPhoto: Function;
  auth: object;
  deletePlant: Function;
  user: User;
  createPlantLine: Function;
  updatePlantLine: Function;
  updatePlantLinePosition: Function;
}

const PlantEdit: React.FC<PlantEditProps> = props => {
  const setDefaultPhoto = data => {
    const sendData = Object.assign({}, data);
    sendData.id = props.plant[identifier];
    props.updatePlant(sendData);
  };

  const returnPlantList = () => {
    props.history.push(APP_PLANTS_PATH);
  };

  const handleUpdateBasicInfo = data => {
    props.updatePlant(props.plant[identifier], data);
  };

  const handleUpdateByType = (type, data) => {
    props.updatePlant(props.plant[identifier], { [type]: data });
  };

  const reloadPlant = () => {
    props.getPlant(props.plant[identifier]);
  };

  const handleDeletePhoto = (photoId: string) => {
    props.deletePlantPhoto(props.plant[identifier], photoId);
  };

  const handleUpdatePhoto = photos => {
    props.updatePlantPhoto(props.plant[identifier], photos);
  };

  const handlePlantDelete = (plant: Plant) => {
    return () => {
      props.deletePlant(plant).then(action => {
        if (action && action.type.match(/SUCCESS/)) {
          returnPlantList();
        }
      });
    };
  };

  return (
    <div className="plant-edit-infos">
      <PageNav fluid>
        <PlantBand
          plant={props.plant || {}}
          backButtonText={props.t('plant.plantsList')}
          onClickBackButton={returnPlantList}
        >
          {props.plant && PlantPolicy.isAuthorize(props.user, 'destroy', props.plant[identifier]) && (
            <ConfirmButton
              className="btn btn-danger btn-md-hide-label btn-lg m-r btn-round"
              onClick={handlePlantDelete(props.plant)}
              message={props.t('plant.removeThisPlant')}
              confirmButtonStyle="danger"
            >
              <i className="fa fa-trash-o" />
            </ConfirmButton>
          )}
        </PlantBand>
      </PageNav>

      <PageContent className="bg-gray-light" containerLg>
        <PlantInfo
          plant={props.plant || {}}
          businessUnits={props.businessUnits}
          regions={props.regions}
          users={[]}
          onSubmitBasicInfo={handleUpdateBasicInfo}
          onSubmitByType={handleUpdateByType}
          onUploadPhotosSuccess={reloadPlant}
          onSetDefaultPhoto={setDefaultPhoto}
          onDeletePhoto={handleDeletePhoto}
          onPhotoUpdate={handleUpdatePhoto}
          onAddLine={props.createPlantLine}
          onUpdateLine={props.updatePlantLine}
          onUpdateLinePosition={props.updatePlantLinePosition}
          auth={props.auth}
        />
      </PageContent>
    </div>
  );
};

const mapStateToProps = (state, router) => {
  return {
    plant: _.find(state.plants.data, { [identifier]: router.match.params.id }),
    businessUnits: state.businessUnits.data,
    regions: state.regions.data,
    auth: state.auth,
    user: state.profile.data,
  };
};

const mapDispatchToProps = {
  showPlant,
  updatePlant,
  deletePlantPhoto,
  updatePlantPhoto,
  deletePlant,
  createPlantLine,
  updatePlantLine,
  updatePlantLinePosition,
};

export default fp.compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  loadData(async (dispatch, state, { match }) => {
    const id = match.params.id;
    await dispatch(showPlant(id));
    if (_.isEmpty(state.businessUnits.data)) {
      await dispatch(indexBusinessUnit());
    }
    if (_.isEmpty(state.regions.data)) {
      await dispatch(indexRegion());
    }
  }),
)(PlantEdit);
