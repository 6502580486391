import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import ModalWrapper from '../Modal/ModalWrapper';
import OEEImportForm from './OEEImportForm';

interface OEEImportModalProps {
  open: boolean;
  close: (...args: any[]) => any;
  onSubmit: Function;
  isBudget?: boolean;
}

const OEEImportModal: React.FC<OEEImportModalProps> = ({ open, close, onSubmit, isBudget }) => {
  const { t } = useTranslation();
  const importing = useSelector(state => {
    return isBudget ? state.oeeBudgetChallenges.importing : state.oee.importing;
  });

  return (
    <ModalWrapper title={t('OEE.xlsImport')} open={open} close={close}>
      {!isBudget && (
        <Alert bsStyle="warning">
          <div dangerouslySetInnerHTML={{ __html: t('OEE.xlsImportAlert') }} />
        </Alert>
      )}
      <OEEImportForm onSubmit={onSubmit} importing={importing} />
    </ModalWrapper>
  );
};

export default OEEImportModal;
