import { API_OEE_DASHBOARD_PATH, API_PRINT_OEE_DASHBOARD_PATH } from '../../utils/routeHelper';
import { error } from '../../utils/error';
import { OEEDurationType } from '../../common/models/oee';
import { downloadFile } from '../../utils/appHelper';

export const FETCH_OEE_DASHBOARD = 'oee_dashobard/FETCH_OEE_DASHBOARD';
export const FETCH_OEE_DASHBOARD_SUCCESS = 'oee_dashobard/FETCH_OEE_DASHBOARD_SUCCESS';
export const FETCH_OEE_DASHBOARD_FAIL = 'oee_dashobard/FETCH_OEE_DASHBOARD_FAIL';
export const SET_AGGREGATION_TYPE = 'oee_dashobard/SET_AGGREGATION_TYPE';
export const UPDATE_OEE_DASHBOARD_AGGREGATIONS = 'oee_dashobard/UPDATE_OEE_DASHBOARD_AGGREGATIONS';
export const PRINT_OEE_DASHBOARD = 'oee_dashboard/PRINT_OEE_DASHBOARD';
export const PRINT_OEE_DASHBOARD_SUCCESS = 'oee_dashboard/PRINT_OEE_DASHBOARD_SUCCESS';
export const PRINT_OEE_DASHBOARD_FAIL = 'oee_dashboard/PRINT_OEE_DASHBOARD_FAIL';
export const RESET_OEE_DASHBOARD = 'oee_dashboard/RESET_OEE_DASHBOARD';

interface OEEDashboardState {
  data?: any;
  loading: boolean;
  error?: string;
  aggregationType: OEEDurationType;
  aggregations: any;
  printing: boolean;
  printError?: string;
}

export interface OEEDashboardQuery {
  plantId: string;
  //plant: string;
  line: string;
  shift?: string;
  date: Date;
  aggregationType: OEEDurationType;
  aggregations: any;
}

const initialState: OEEDashboardState = {
  data: {},
  loading: false,
  error: undefined,
  aggregationType: OEEDurationType.daily,
  aggregations: {},
  printing: false,
  printError: undefined,
};

export default function reducer(state = initialState, action: ActionResult): OEEDashboardState {
  switch (action.type) {
    case FETCH_OEE_DASHBOARD:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case FETCH_OEE_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.result.data,
        },
      };
    case FETCH_OEE_DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case SET_AGGREGATION_TYPE:
      return {
        ...state,
        aggregationType: action.preload,
      };
    case UPDATE_OEE_DASHBOARD_AGGREGATIONS:
      return {
        ...state,
        aggregations: action.preload,
      };
    case PRINT_OEE_DASHBOARD:
      return {
        ...state,
        printing: true,
      };
    case PRINT_OEE_DASHBOARD_SUCCESS:
      downloadFile(action.result, 'oee_dashboard.pdf');
      return {
        ...state,
        printing: false,
        printError: undefined,
      };
    case PRINT_OEE_DASHBOARD_FAIL:
      return {
        ...state,
        printing: false,
        printError: error(action),
      };
    case RESET_OEE_DASHBOARD:
      return initialState;
    default:
      return state;
  }
}

export function fetchOEEDashboard(query: OEEDashboardQuery): ActionPromise {
  return {
    types: [FETCH_OEE_DASHBOARD, FETCH_OEE_DASHBOARD_SUCCESS, FETCH_OEE_DASHBOARD_FAIL],
    promise: client => client.post(API_OEE_DASHBOARD_PATH, query),
  };
}

export function setAggregationType(value: OEEDurationType): ActionPreload {
  return {
    type: SET_AGGREGATION_TYPE,
    preload: value,
  };
}

export function updateAggregations(value: any): ActionPreload {
  return {
    type: UPDATE_OEE_DASHBOARD_AGGREGATIONS,
    preload: value,
  };
}

export function printOEEDashboard(query): ActionPromise {
  return {
    types: [PRINT_OEE_DASHBOARD, PRINT_OEE_DASHBOARD_SUCCESS, PRINT_OEE_DASHBOARD_FAIL],
    promise: client => client.post(API_PRINT_OEE_DASHBOARD_PATH, query, { responseType: 'blob' }),
  };
}

export function resetOEEDashboard(): ActionPreload {
  return { type: RESET_OEE_DASHBOARD };
}
