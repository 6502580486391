import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Locale from '../components/Locale/Locale';
import { addClassByTagName } from '../utils/vanilla';
import logoWhite from '../assets/images/logo-suez-white.png';
import logoSuezHW from '../assets/images/logo-suez-hw.png';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  addClassByTagName('html', 'backgroud-image');

  return (
    <div className="wrap-content">
      <header className="header">
        <div className="header__logo">
          <Link to="/">
            <img src={logoWhite} alt="SUEZ HW" className="logo" />
          </Link>
        </div>
        <nav className="header__rightnav">
          <Locale />
        </nav>
      </header>
      <div className="page-wrapper container-fluid m-t-lg">
        <Row>
          <Col md={10} mdOffset={1}>
            <div className="jumbotron text-center">
              <img src={logoSuezHW} alt="SUEZ HW" className="logo-suez-hw m-b-lg" />
              <h1>{t('common.hwName')}</h1>
              <p className="font-bold m-t-lg m-b-lg">{t('home.description')}</p>
              <div>
                <Link to="/app" className="btn btn-lg btn-success" id="access-app-btn">
                  {t('home.accessApp')}
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <footer className="footer text-center">
          <small>© SUEZ {year}</small>
        </footer>
      </div>
    </div>
  );
};

export default Home;
