import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const HistoryTable = ({ history, valueTransform, className, t }) => {
  const buildHistoryItem = function buildHistoryItem(item) {
    const userName = item.user ? item.user.name : t('user.noExist');
    return (
      <tr key={item._id}>
        <td>{moment(item.updatedAt).format(t('date.short'))}</td>
        <td className="text-capitalize">{userName}</td>
        <td>{valueTransform(item.value)}</td>
      </tr>
    );
  };

  return (
    <table className={`table table-striped table-bordered ${className}`} role="grid">
      <thead>
        <tr role="row">
          <th className="text-capitalize">{t('date.date')}</th>
          <th className="text-capitalize">{t('user.fullName')}</th>
          <th className="text-capitalize">{t('objective.value')}</th>
        </tr>
      </thead>
      <tbody>{history.map(obj => buildHistoryItem(obj))}</tbody>
    </table>
  );
};

HistoryTable.propTypes = {
  history: PropTypes.array.isRequired,
  valueTransform: PropTypes.func,
  className: PropTypes.string,
};

HistoryTable.defaultProps = {
  history: [],
  valueTransform: value => value,
  className: '',
};

export default withTranslation()(React.memo(HistoryTable));
