import { API_INDEX_CATEGORY_ITEM_PATH } from '../../utils/routeHelper';
import { error } from '../../utils/error';
import { SIGNOUT } from './auth';

export const INDEX_CATEGORY_ITEM = 'regions/INDEX_CATEGORY_ITEM';
export const INDEX_CATEGORY_ITEM_SUCCESS = 'regions/INDEX_CATEGORY_ITEM_SUCCESS';
export const INDEX_CATEGORY_ITEM_FAIL = 'regions/INDEX_CATEGORY_ITEM_FAIL';

interface CategoryItemState {
  data?: Array<any>;
  loading: boolean;
  error?: string;
}

const initialState: CategoryItemState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export default function reducer(
  state: CategoryItemState = initialState,
  action: ActionResult,
): CategoryItemState {
  switch (action.type) {
    case INDEX_CATEGORY_ITEM:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_CATEGORY_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexCategoryItem(): ActionPromise {
  return {
    types: [INDEX_CATEGORY_ITEM, INDEX_CATEGORY_ITEM_SUCCESS, INDEX_CATEGORY_ITEM_FAIL],
    promise: client => client.get(API_INDEX_CATEGORY_ITEM_PATH),
  };
}
