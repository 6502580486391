import {
  API_OEE_DASHBOARD_COMMENT_PATH,
  API_SAVE_OEE_DASHBOARD_COMMENT_PATH,
} from '../../utils/routeHelper';
import { OEEDashboardCommentData } from '../../common/models/oeeDashboardComment';
import { error } from '../../utils/error';
import { updateCollection } from '../../utils/collection';

export const FETCH_OEE_DASHBOARD_COMMENT = 'oee_dashboard_comment/FETCH_OEE_DASHBOARD_COMMENT';
export const FETCH_OEE_DASHBOARD_COMMENT_SUCCESS =
  'oee_dashboard_comment/FETCH_OEE_DASHBOARD_COMMENT_SUCCESS';
export const FETCH_OEE_DASHBOARD_COMMENT_FAIL =
  'oee_dashboard_comment/SAVE_OEE_DASHBOARD_COMMENT_FAIL';
export const SAVE_OEE_DASHBOARD_COMMENT = 'oee_dashboard_comment/SAVE_OEE_DASHBOARD_COMMENT';
export const SAVE_OEE_DASHBOARD_COMMENT_SUCCESS =
  'oee_dashboard_comment/SAVE_OEE_DASHBOARD_COMMENT_SUCCESS';
export const SAVE_OEE_DASHBOARD_COMMENT_FAIL =
  'oee_dashboard_comment/SAVE_OEE_DASHBOARD_COMMENT_FAIL';

interface OEEDashboardCommentState {
  data: any;
  loading: boolean;
  error?: string;
  saving: boolean;
  saveError?: string;
}

const initialState: OEEDashboardCommentState = {
  data: {
    oeeInputs: [],
    comments: [],
    otherComments: [],
  },
  loading: false,
  error: undefined,
  saving: false,
  saveError: undefined,
};

export default function reducer(
  state: OEEDashboardCommentState = initialState,
  action: ActionResult,
): OEEDashboardCommentState {
  switch (action.type) {
    case FETCH_OEE_DASHBOARD_COMMENT:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case FETCH_OEE_DASHBOARD_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case FETCH_OEE_DASHBOARD_COMMENT_FAIL:
      return {
        ...state,
        loading: false,
        data: {
          oeeInputs: [],
          comments: [],
          otherComments: [],
        },
        error: error(action),
      };
    case SAVE_OEE_DASHBOARD_COMMENT:
      return {
        ...state,
        saving: true,
        saveError: undefined,
      };
    case SAVE_OEE_DASHBOARD_COMMENT_SUCCESS:
      const comments = updateCollection(state.data.comments, action.result.data);
      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          comments,
        },
      };
    case SAVE_OEE_DASHBOARD_COMMENT_FAIL:
      return {
        ...state,
        saving: false,
        saveError: error(action),
      };
    default:
      return state;
  }
}

export function fetchOEEDashboardComment(query): ActionPromise {
  return {
    types: [
      FETCH_OEE_DASHBOARD_COMMENT,
      FETCH_OEE_DASHBOARD_COMMENT_SUCCESS,
      FETCH_OEE_DASHBOARD_COMMENT_FAIL,
    ],
    promise: client => client.post(API_OEE_DASHBOARD_COMMENT_PATH, query),
  };
}

export function saveOEEDashboardComment(data: OEEDashboardCommentData): ActionPromise {
  return {
    types: [
      SAVE_OEE_DASHBOARD_COMMENT,
      SAVE_OEE_DASHBOARD_COMMENT_SUCCESS,
      SAVE_OEE_DASHBOARD_COMMENT_FAIL,
    ],
    promise: client => client.put(API_SAVE_OEE_DASHBOARD_COMMENT_PATH, data),
  };
}
