import React from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip, Label } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

const PlantBand = ({ plant, backButtonText, onClickBackButton, children, t }) => {
  return (
    <div className="plant-band">
      <div className="nav-secondary__left">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="returnTooltip">{backButtonText}</Tooltip>}
        >
          <Button className="btn-secondary btn-xl" onClick={onClickBackButton}>
            <i className="fa fa-long-arrow-left m-r" />
          </Button>
        </OverlayTrigger>
      </div>
      <div className="nav-secondary__right">{children}</div>
      <h2 className="nav-secondary__title">
        {plant.name}
        {plant.archived && <Label className="m-l-lg">{t('plant.archived')}</Label>}
      </h2>
    </div>
  );
};

PlantBand.propTypes = {
  plant: PropTypes.object.isRequired,
  backButtonText: PropTypes.string.isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  children: PropTypes.any,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PlantBand);
