import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formsy-react-components';
import { withTranslation } from 'react-i18next';

class ObjectiveForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      canSubmit: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.setValue = this.setValue.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  setValue(event) {
    this.setState({ value: event.target.value });
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  handleSubmit() {
    this.props.onSubmit(this.state.value, this.props.year);
    this.setState({ value: '' });
  }

  handleCancel() {
    this.setState({ value: '' });
  }

  render() {
    const { year, lineUnit, t } = this.props;
    return (
      <tr>
        <td className="text-center">
          <span>{year}</span>
          <input name="year" value={year} type="hidden" />
        </td>
        <td>
          <Form
            onSubmit={this.handleSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            <div className="input-group">
              <input
                className="form-control"
                onChange={this.setValue}
                name="value"
                value={this.state.value}
                type="number"
                required
              />
              <span className="input-group-addon">
                {t('objective.objectiveUnit', { lineUnit: t(`plant.lineUnits.${lineUnit}`) })}
              </span>
            </div>
          </Form>
        </td>
        <td className="text-center m-t-md">
          <button
            type="button"
            className="btn btn-primary btn-save-objective"
            disabled={!this.state.value}
            onClick={this.handleSubmit}
          >
            <i className="fa fa-floppy-o" aria-label="save" />
          </button>
          <button type="button" className="btn btn-secondary" onClick={this.handleCancel}>
            <i className="fa fa-ban" aria-label="cancel" />
          </button>
        </td>
      </tr>
    );
  }
}

ObjectiveForm.propTypes = {
  year: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  lineUnit: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ObjectiveForm);
