import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import classNames from 'classnames';
import Notification from '../../components/App/Notification/Notification';
import Sidebar from '../../components/App/Sidebar/Sidebar';
import Header from '../../components/App/Header/Header';
import { removeClassByTagName } from '../../utils/vanilla';
import * as routeHelper from '../../utils/routeHelper';
import { isAdmin } from '../../utils/userHelper';
import AlertLogout from '../../components/App/AlertLogout/AlertLogout';

function buildSidebar(currentUser): Array<any> {
  const items = [
    { path: routeHelper.APP_PATH, text: 'sidebar.homepage', icon: 'fa-home' },
    {
      text: 'sidebar.OEE',
      icon: 'fa-line-chart',
      children: [
        { path: routeHelper.APP_OEE_INPUT_PATH, text: 'sidebar.oeeInput' },
        { path: routeHelper.APP_OEE_DASHBOARD_PATH, text: 'sidebar.oeeDashboard' },
      ],
    },
    //{
    //text: 'sidebar.KPIsInPowerBI',
    //icon: 'fa-flag',
    //externalUrl:
    //'https://app.powerbi.com/Redirect?action=OpenApp&appId=6569f5b3-c96d-41e6-9ce0-6d9482cad2ca&ctid=f4a12867-922d-4b9d-bb85-9ee7898512a0',
    //},
    //{
    //text: 'sidebar.support',
    //icon: 'fa-support',
    //externalUrl: 'https://one4u.sharepoint.com/sites/DIPIRRWASTE/HW/SitePages/Home.aspx',
    //},
    //{
    //text: 'sidebar.helpPage',
    //icon: 'fa-question-circle',
    //externalUrl:
    //'https://one4u.sharepoint.com/sites/DIPIRRWASTE/HW/SitePages/Tools%20-%20R%26R%20-%20Hazardous%20Waste.aspx',
    //},
    {
      text: 'sidebar.users',
      icon: 'fa-users',
      children: [{ path: routeHelper.APP_USERS_PATH, text: 'sidebar.searchUser' }],
    },
  ];
  if (isAdmin(currentUser)) {
    items.push({
      text: 'sidebar.configuration',
      icon: 'fa-cogs',
      children: [
        { path: routeHelper.APP_BUSINESS_UNIT_PATH, text: 'sidebar.businessUnits' },
        { path: routeHelper.APP_REGION_PATH, text: 'sidebar.regions' },
        { path: routeHelper.APP_PLANTS_PATH, text: 'sidebar.plants' },
        { path: routeHelper.APP_CATEGORY_PATH, text: 'sidebar.categories' },
      ],
    });
  }
  return items;
}

const idleTimeout = process.env.REACT_APP_SECURITY_LOGOUT_AFTER_IDLE_TIME || '1';

const App: React.FC<RouteComponentProps> = ({ children, location: { pathname }, history }) => {
  removeClassByTagName('html', 'backgroud-image');
  const currentUser = useSelector(state => state.profile.data);

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * parseInt(idleTimeout, 10),
    debounce: 500,
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    //TODO: maybe have better solution
    if (currentUser.passwordIsExpired) {
      history.replace(routeHelper.APP_PROFILE_PASSWORD_PATH);
    }
  }, [pathname, currentUser, history]);

  const wrapperClass = useMemo(() => {
    return classNames('wrapper', {
      'app--home': pathname === routeHelper.APP_PATH,
    });
  }, [pathname]);

  return (
    <div className={wrapperClass}>
      <AlertLogout getRemainingTime={getRemainingTime} />
      <Header />
      <Sidebar sidebarItems={buildSidebar(currentUser)} currentPath={pathname} />
      <div className="page-wrapper">
        <Notification />
        {children}
        <footer className="footer" />
      </div>
    </div>
  );
};

export default withRouter(App);
