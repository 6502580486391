import React from 'react';
import ReactDOM from 'react-dom';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import it from 'date-fns/locale/it';
import './index.scss';
import Root from './containers/Root';
import configureStore from './redux/store/configureStore';
import * as serviceWorker from './serviceWorker';
import './lib/i18n';
import { DEFAULT_LANGUAGE } from './common/i18n';

registerLocale('en', enGb);
registerLocale('fr', fr);
registerLocale('es', es);
registerLocale('it', it);
setDefaultLocale(DEFAULT_LANGUAGE);

const store = configureStore({});
const rootEl = document.getElementById('root');

ReactDOM.render(<Root store={store} />, rootEl);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module['hot']) {
  // Enable Webpack hot module replacement for containers
  module['hot'].accept('./containers/Root', () => {
    ReactDOM.render(<Root store={store} />, rootEl);
  });
}
