import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import OEEExportModal from './OEEExportModal';

const OEEExportButton: React.FC = () => {
  const { t } = useTranslation();
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);

  const toggleExportModal = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpenExportModal(!isOpenExportModal);
    return false;
  };

  const xlsExportTooltip = <Tooltip id="xlsExportTooltip">{t('OEE.xlsExport')}</Tooltip>;
  return (
    <span>
      <OverlayTrigger placement="bottom" overlay={xlsExportTooltip}>
        <Button
          className="btn btn-secondary btn-md-hide-label m-r btn-round"
          onClick={toggleExportModal}
        >
          <i className="fa fa-file-excel-o" aria-hidden="true" />
        </Button>
      </OverlayTrigger>
      <OEEExportModal open={isOpenExportModal} close={toggleExportModal} />
    </span>
  );
};

export default React.memo(OEEExportButton);
