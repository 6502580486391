export enum PhotoCategory {
  photo = 'photo',
  diagram = 'diagram',
}

export enum PhotoVersion {
  normal = 'normal',
  large = 'large',
  default = 'default',
}
