function addClassFromElements(elements, className) {
  for (let i = 0, l = elements.length; i < l; i++) {
    elements[i].classList.add(className);
  }
}

function removeClassFromElements(elements, className) {
  for (let i = 0, l = elements.length; i < l; i++) {
    elements[i].classList.remove(className);
  }
}

export function addClassByTagName(tagName, className) {
  const elements = document.getElementsByTagName(tagName);
  addClassFromElements(elements, className);
}

export function removeClassByTagName(tagName, className) {
  const elements = document.getElementsByTagName(tagName);
  removeClassFromElements(elements, className);
}

export function addClassByClassName(name, className) {
  const elements = document.getElementsByClassName(name);
  addClassFromElements(elements, className);
}

export function removeClassByClassName(name, className) {
  const elements = document.getElementsByClassName(name);
  removeClassFromElements(elements, className);
}

export function toggleClass(element, className) {
  if (element.classList.contains(className)) {
    element.classList.remove(className);
  } else {
    element.classList.add(className);
  }
}

export function toggleClassByClassName(name, className) {
  const elements = document.getElementsByClassName(name);
  for (let i = 0, l = elements.length; i < l; i++) {
    toggleClass(elements[i], className);
  }
}

export function toggleClassByTagName(name, className) {
  const elements = document.getElementsByTagName(name);
  for (let i = 0, l = elements.length; i < l; i++) {
    toggleClass(elements[i], className);
  }
}

/**
 * Get parent node for given tagname
 * @param  {Object} node    DOM node
 * @param  {String} tagname HTML tagName
 * @return {Object}         Parent node
 */
export function getParentByTagName(node, tagname) {
  if (node === null || tagname === '') return null;
  const parent = node.parentNode;

  if (parent.tagName === tagname.toUpperCase() && parent.tagName !== 'HTML') {
    return parent;
  }
  return getParentByTagName(parent.parentNode, tagname);
}
