import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../Modal/ModalWrapper';
import OEEExportForm from './OEEExportForm';
import { exportOEE } from '../../../redux/modules/oee';

interface OEEExportModalProps {
  open: boolean;
  close: (...args: any[]) => any;
}

const OEEExportModal: React.FC<OEEExportModalProps> = ({ open, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = data => {
    dispatch(exportOEE(data));
    close();
  };

  return (
    <ModalWrapper title={t('OEE.xlsExport')} open={open} close={close} bsSize="large">
      <OEEExportForm onSubmit={handleSubmit} />
    </ModalWrapper>
  );
};

export default React.memo(OEEExportModal);
