import React from 'react';

const Loader: React.FC<{ className?: string }> = ({ className = 'loader' }) => {
  return (
    <div className={className}>
      <div className="ball-pulse">
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default React.memo(Loader);
