import axios from 'axios';
import {
  API_SIGNIN_PATH,
  API_RESET_PASSWORD_PATH,
  API_DEFINE_PASSWORD_PATH,
} from '../../utils/routeHelper';
import _ from 'lodash';

export const SIGNIN = 'auth/SIGNIN';
export const SIGNIN_SUCCESS = 'auth/SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'auth/SIGNIN_FAIL';
export const SIGNOUT = 'auth/SIGNOUT';
export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'auth/RESET_PASSWORD_FAIL';
export const DEFINE_PASSWORD = 'auth/DEFINE_PASSWORD';
export const DEFINE_PASSWORD_SUCCESS = 'auth/DEFINE_PASSWORD_SUCCESS';
export const DEFINE_PASSWORD_FAIL = 'auth/DEFINE_PASSWORD_FAIL';

// set access tokon only for print
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('access_token') || window.sessionStorage.getItem('suez-hw-auth-token');
const exp = window.sessionStorage.getItem('suez-hw-auth-exp');
const initialState = {
  access: {
    token,
    exp,
  },
};

axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null;

export default function reducer(state = initialState, action: ActionResult) {
  switch (action.type) {
    case SIGNIN:
      return {
        ...state,
        signingIn: true,
        signingInError: null,
      };
    case SIGNIN_SUCCESS:
      const { token, exp } = action.result.data;
      window.sessionStorage.setItem('suez-hw-auth-token', token);
      window.sessionStorage.setItem('suez-hw-auth-exp', exp);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return {
        ...state,
        signingIn: false,
        access: {
          token,
          exp,
        },
      };
    case SIGNIN_FAIL:
      return {
        ...state,
        signingIn: false,
        access: {
          token: null,
          exp: null,
        },
        signingInError: _.get(action, 'error.response.data'),
      };
    case SIGNOUT:
      window.sessionStorage.removeItem('suez-hw-auth-token');
      window.sessionStorage.removeItem('suez-hw-auth-exp');
      axios.defaults.headers.common['Authorization'] = null;
      return {
        ...state,
        signingOut: false,
        access: {
          token: null,
          exp: null,
        },
      };
    default:
      return state;
  }
}

export function signin(data: any): ActionPromise {
  return {
    types: [SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAIL],
    promise: client => client.post(API_SIGNIN_PATH, data),
  };
}
export function signout(): ActionPreload {
  return {
    type: SIGNOUT,
  };
}

export function resetPassword(email: string): ActionPromise {
  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
    promise: client => client.post(API_RESET_PASSWORD_PATH, { email }),
  };
}

export function definePassword(token: string, password: string): ActionPromise {
  return {
    types: [DEFINE_PASSWORD, DEFINE_PASSWORD_SUCCESS, DEFINE_PASSWORD_FAIL],
    promise: client =>
      client.post(API_DEFINE_PASSWORD_PATH, {
        token,
        password,
      }),
  };
}

export function isAccessTokenExpired(auth) {
  if (auth.access.token && auth.access.exp) {
    return 1000 * auth.access.exp - new Date().getTime() < 5000;
  }
  return true;
}
export function isAuthenticated(auth) {
  return !isAccessTokenExpired(auth);
}
