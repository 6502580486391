import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

export default class SelectFilter extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value };
    } else return null;
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
    this.props.onChange(e.target.value);
  }

  render() {
    return (
      <select
        className="form-control c-select"
        onChange={this.handleChange}
        value={this.state.value}
        id={this.props.id}
        disabled={this.props.disabled}
        name={this.props.name}
      >
        {this.props.options.map((o, i) => (
          <option key={i} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    );
  }
}

SelectFilter.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};
