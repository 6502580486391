import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import SidebarItemLink from './SidebarItemLink';

class SidebarItem extends Component {
  constructor(props) {
    super(props);

    this.onLinkClick = this.onLinkClick.bind(this);
    this.onChildOpenToggled = this.onChildOpenToggled.bind(this);

    this.state = {
      isOpen: false,
      openedChild: this.getCurrentChild(),
    };
  }

  componentDidMount() {
    this.updateOpenState(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateOpenState(newProps);
  }

  onLinkClick() {
    if (this.props.item.children) {
      if (this.props.onOpenToggled === undefined) {
        this.setState({ isOpen: !this.state.isOpen });
      } else {
        this.props.onOpenToggled(this.props.item, !this.state.isOpen);
      }
    } else {
      this.props.onItemClicked(this.props.item);
    }
  }

  onChildOpenToggled(item, isOpen) {
    this.setState({ openedChild: isOpen ? item : null });
  }

  getCurrentChild() {
    if (!this.props.item.children) return null;
    return this.props.item.children.filter(item => {
      if (!item.children) return false;
      return item.children.filter(i => {
        return this.props.currentPath.includes(i.path);
      })[0];
    })[0];
  }

  updateOpenState(props) {
    this.setState({
      isOpen: Boolean(props.openedSibling && props.openedSibling.text === props.item.text),
    });
  }

  render() {
    const { item, t } = this.props;
    const { isOpen } = this.state;
    const sidebarItemClassNames = classNames('sidebar-item', {
      'sidebar-item--open': isOpen,
    });

    return (
      <li className={sidebarItemClassNames}>
        <SidebarItemLink
          item={item}
          onClick={this.onLinkClick}
          currentPath={this.props.currentPath}
        >
          <i className={`sidebar-item__icon fa fa-fw ${item.icon || ''}`} />
          <span className={`sidebar-item__infos`}>
            <span className="sidebar-item__text">
              {t(item.text)}
              {item.label && <span className="label label-info">{t(item.label)}</span>}
            </span>
            {item.children && (
              <i className={`sidebar-item__arrow fa fa-angle-${isOpen ? 'up' : 'down'}`} />
            )}
          </span>
        </SidebarItemLink>

        {item.children && (
          <Collapse className="sidebar-item__children" in={isOpen}>
            <ul>
              {item.children.map((child, i) => (
                <SidebarItem
                  key={i}
                  item={child}
                  currentPath={this.props.currentPath}
                  onItemClicked={this.props.onItemClicked}
                  onOpenToggled={this.onChildOpenToggled}
                  openedSibling={this.state.openedChild}
                  t={t}
                />
              ))}
            </ul>
          </Collapse>
        )}
      </li>
    );
  }
}

SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
  currentPath: PropTypes.string.isRequired,
  onOpenToggled: PropTypes.func,
  onItemClicked: PropTypes.func,
  openedSibling: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(SidebarItem);
