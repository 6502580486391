import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import ModalWrapper from '../Modal/ModalWrapper';
import { exportOEEToS3 } from '../../../redux/modules/oee';

const OEEExportToS3Button: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);

  const toggleExportModal = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpenExportModal(!isOpenExportModal);
    return false;
  };

  const handleSubmit = data => {
    dispatch(exportOEEToS3()).then(action => {
      if (action.type.match(/SUCCESS/)) {
        setIsOpenExportModal(false);
      }
    });
  };

  const xlsExportTooltip = <Tooltip id="xlsExportS3Tooltip">{t('OEE.xlsExportS3')}</Tooltip>;
  return (
    <span>
      <OverlayTrigger placement="bottom" overlay={xlsExportTooltip}>
        <Button
          className="btn btn-secondary btn-md-hide-label m-r btn-round"
          onClick={toggleExportModal}
        >
          <i className="fa fa-refresh" aria-hidden="true" />
        </Button>
      </OverlayTrigger>
      <ModalWrapper title={t('OEE.xlsExportS3')} open={isOpenExportModal} close={toggleExportModal}>
        <p>{t('OEE.xlsExportS3Info')}</p>
        <div className="text-center m-t-lg m-b-md">
          <button type="button" className="btn btn-primary btn-lg m-r-md" onClick={handleSubmit}>
            {t('common.valid')}
          </button>
          <button type="button" className="btn btn-lg btn-secondary" onClick={toggleExportModal}>
            {t('common.cancel')}
          </button>
        </div>
      </ModalWrapper>
    </span>
  );
};

export default React.memo(OEEExportToS3Button);
