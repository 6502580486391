import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import DropzoneComponent from 'react-dropzone-component';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

class UploadPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgressBar: false,
      progress: 0,
    };
    this.onSending = this.onSending.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onComplete = this.onComplete.bind(this);
  }

  onSending() {
    this.setState({ showProgressBar: true });
  }

  onUploadProgress(progress) {
    this.setState({ progress });
  }

  onComplete() {
    this.setState({ showProgressBar: false, progress: 0 });
  }

  render() {
    const { t } = this.props;
    const componentConfig = {
      postUrl: this.props.postUrl,
    };
    const previewTemplate = '<div id="preview-template" style="display: none;"></div>';
    const djsConfig = {
      previewTemplate,
      uploadMultiple: true,
      parallelUploads: 5,
      paramName: function paramName() {
        return 'photos';
      },
      acceptedFiles: 'image/*',
      dictDefaultMessage: t('plant.dropFilesHereToUpload'),
      ...this.props.djsConfig,
    };
    const eventHandlers = {
      sendingmultiple: this.onSending,
      totaluploadprogress: this.onUploadProgress,
      successmultiple: this.props.onSuccess,
      completemultiple: this.onComplete,
    };

    const progressClassName = classNames({ invisible: !this.state.showProgressBar });

    return (
      <div className="photoUploader">
        <DropzoneComponent
          config={componentConfig}
          eventHandlers={eventHandlers}
          djsConfig={djsConfig}
        />
        <ProgressBar
          now={this.state.progress}
          label={`${this.state.progress}%`}
          className={progressClassName}
        />
      </div>
    );
  }
}

UploadPhotos.propTypes = {
  postUrl: PropTypes.string.isRequired,
  djsConfig: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default withTranslation()(UploadPhotos);
