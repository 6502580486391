import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ModalWrapper from '../../Modal/ModalWrapper';
import LineForm from './LineForm';

const LineModal = ({ data, open, close, onSubmit, t }) => {
  return (
    <ModalWrapper title={t('plant.addLine')} open={open} close={close}>
      <LineForm data={data} onSubmit={onSubmit} />
    </ModalWrapper>
  );
};

LineModal.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation()(LineModal);
