import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../Modal/ModalWrapper';
import UserForm from './UserForm';

interface UserNewModalProps {
  user: any;
  open: boolean;
  close: (...args: any[]) => any;
  onSubmit: Function;
}

const UserNewModal: React.FC<UserNewModalProps> = ({ user, open, close, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper title={t('user.newUser')} open={open} close={close}>
      <UserForm data={user} onSubmit={onSubmit} onCancel={close} />
    </ModalWrapper>
  );
};

export default React.memo(UserNewModal);
