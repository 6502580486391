import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Nav, NavItem, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import produce from 'immer';
import moment from 'moment';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import OEEExportButton from '../../../components/App/OEE/OEEExportButton';
import OEEFilter from '../../../components/App/OEE/OEEFilter';
import OEEChart from '../../../components/App/OEEDashboard/OEEChart';
import OEESelectedChart from '../../../components/App/OEEDashboard/OEESelectedChart';
import OEEGaugeChart from '../../../components/App/OEEDashboard/OEEGaugeChart';
import AvailabilityLossesChart from '../../../components/App/OEEDashboard/AvailabilityLossesChart';
import TreatedWasteLossesChart from '../../../components/App/OEEDashboard/TreatedWasteLossesChart';
import OEEDashboardComment from '../../../components/App/OEEDashboard/OEEDashboardComment';
import OEEImportModal from '../../../components/App/OEE/OEEImportModal';
import loadData from '../../../utils/loadData';
import { indexPlants, getPlantAndLineByOEEFilterSelector } from '../../../redux/modules/plants';
import { indexBusinessUnit } from '../../../redux/modules/businessUnits';
import { OEEFilterState } from '../../../redux/modules/oeeFilter';
import {
  fetchOEEDashboard,
  setAggregationType,
  updateAggregations,
  printOEEDashboard,
  resetOEEDashboard,
} from '../../../redux/modules/oeeDashboard';
import { indexCategory } from '../../../redux/modules/categories';
import { indexCategoryItem } from '../../../redux/modules/categoryItems';
import { importOEEBudgetChallenge } from '../../../redux/modules/oeeBudgetChallenges';
import { fetchOEEDashboardComment } from '../../../redux/modules/oeeDashboardComments';
import { isAdmin } from '../../../utils/userHelper';
import { Plant } from '../../../common/models/plant';
import { BusinessUnit } from '../../../common/models/businessUnit';
import { UserPolicies } from '../../../common/models/user';
import { OEEDurationType } from '../../../common/models/oee';
import { NONE } from '../../../common/filter';

function loadOEEDashboard(
  dispatch,
  filter: OEEFilterState,
  aggregationType: OEEDurationType,
  aggregations = {},
) {
  const { plantId, line, date, shift, showShift } = filter;
  if (plantId !== NONE && line !== NONE) {
    dispatch(
      fetchOEEDashboard({
        plantId,
        line,
        shift: showShift && shift !== NONE ? shift : undefined,
        date,
        aggregationType,
        aggregations,
      }),
    );
    dispatch(
      fetchOEEDashboardComment({
        plantId,
        line,
        shift: showShift && shift !== NONE ? shift : undefined,
        date,
        aggregationType,
      }),
    );
  } else {
    dispatch(resetOEEDashboard());
  }
}

const OEEDashboard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const businessUnits: BusinessUnit[] = useSelector(state => state.businessUnits.data);
  const plants: Plant[] = useSelector(state => state.plants.data);
  const oeeFilter: OEEFilterState = useSelector(state => state.oeeFilter);
  const oeeData: any = useSelector(state => state.oeeDashboard.data);
  const oeeDataLoading: boolean = useSelector(state => state.oeeDashboard.loading);
  const printing: boolean = useSelector(state => state.oeeDashboard.printing);
  const plantAndLine = useSelector(getPlantAndLineByOEEFilterSelector);
  const aggregationType: OEEDurationType = useSelector(state => state.oeeDashboard.aggregationType);
  const aggregations: any = useSelector(state => state.oeeDashboard.aggregations);
  const categories = useSelector(state => state.categories.data);
  const categoryItems = useSelector(state => state.categoryItems.data);
  const currentUser = useSelector(state => state.profile.data);
  const [isOpenOEEBudgetChallengeImportModal, setIsOpenOEEBudgetChallengeImportModal] = useState(
    false,
  );
  const handleFilterChanged = (filter: OEEFilterState) => {
    // reset aggregations
    dispatch(updateAggregations({}));
    loadOEEDashboard(dispatch, filter, aggregationType);
  };
  const handleAggregationTypeChanged = (value: OEEDurationType) => {
    dispatch(setAggregationType(value));
    // reset aggregations
    dispatch(updateAggregations({}));
    loadOEEDashboard(dispatch, oeeFilter, value);
  };
  const handleCategoryChanged = (value: any, aggs: string) => {
    const _aggregations = produce(aggregations, draft => {
      draft[aggs] = draft[aggs] || {};
      draft[aggs].category = value;
    });
    dispatch(updateAggregations(_aggregations));
    loadOEEDashboard(dispatch, oeeFilter, aggregationType, {
      [aggs]: {
        category: value,
      },
    });
  };
  const toggleOEEBudgetChallengeImportModal = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpenOEEBudgetChallengeImportModal(!isOpenOEEBudgetChallengeImportModal);
    return false;
  };
  const handleImportOEEOEEBudgetChallenge = data => {
    dispatch(importOEEBudgetChallenge(data)).then(action => {
      if (action.type.match(/SUCCESS/)) {
        // reset aggregations
        dispatch(updateAggregations({}));
        loadOEEDashboard(dispatch, oeeFilter, aggregationType);
        setIsOpenOEEBudgetChallengeImportModal(false);
      }
    });
  };
  const printOEEDashboardPDF = () => {
    if (!printing) {
      dispatch(
        printOEEDashboard({
          plantId: oeeFilter.plantId,
          line: oeeFilter.line,
          shift: oeeFilter.showShift ? oeeFilter.shift : undefined,
          date: oeeFilter.date,
          aggregationType,
          aggregations,
        }),
      );
    }
  };

  const buildMaxDate = () => {
    if (aggregationType !== OEEDurationType.daily && !oeeDataLoading && oeeData.maxDate) {
      const maxDate = oeeData.maxDate;
      return (
        <div className="oee-dashboard__maxdate">
          <i className="fa fa-info-circle m-r" />
          {maxDate && maxDate.value
            ? t('OEEDashboard.dataMaxDate', {
                date: moment.utc(maxDate.value).format(t('date.short')),
              })
            : t('common.noData')}
        </div>
      );
    }
    return null;
  };

  const oeeImportTooltip = (
    <Tooltip id="oeeImportTooltip">{t('OEE.importOEEBudgetChallenge')}</Tooltip>
  );
  const printPdfTooltip = <Tooltip id="printPdfTooltip">{t('OEEDashboard.printPdf')}</Tooltip>;
  return (
    <div className="oee-dashboard">
      <PageNav fluid>
        <div className="plant-band">
          <h2 className="nav-secondary__title">{t('sidebar.oeeDashboard')}</h2>
          <div className="nav-secondary__right">
            <OEEExportButton />
            {plantAndLine.plant && plantAndLine.line && (
              <OverlayTrigger placement="bottom" overlay={printPdfTooltip}>
                <Button
                  className="btn btn-secondary btn-md-hide-label btn-round"
                  onClick={printOEEDashboardPDF}
                >
                  <i className="fa fa-file-pdf-o" aria-hidden="true" />
                </Button>
              </OverlayTrigger>
            )}
            {isAdmin(currentUser) && (
              <Fragment>
                <OverlayTrigger placement="bottom" overlay={oeeImportTooltip}>
                  <a
                    href="/#"
                    onClick={toggleOEEBudgetChallengeImportModal}
                    className="oee-input__main-action btn btn-update-oee-input"
                  >
                    <div className="btn btn-secondary btn-round">
                      <i className="fa fa-cloud-upload" />
                    </div>
                  </a>
                </OverlayTrigger>
                <OEEImportModal
                  open={isOpenOEEBudgetChallengeImportModal}
                  close={toggleOEEBudgetChallengeImportModal}
                  onSubmit={handleImportOEEOEEBudgetChallenge}
                />
              </Fragment>
            )}
          </div>
        </div>
      </PageNav>
      <PageContent fluid>
        <div className="oee-input__topbar card card-block m-t-lg">
          <OEEFilter
            onChange={handleFilterChanged}
            businessUnits={businessUnits}
            plants={plants}
            showMonthYearPicker={aggregationType === OEEDurationType.monthly}
            oeeDashboard={true}
          />
          {buildMaxDate()}
        </div>
        <Nav bsStyle="tabs" activeKey={aggregationType} onSelect={handleAggregationTypeChanged}>
          <NavItem eventKey={OEEDurationType.monthly}>
            {t('OEEDashboard.aggregationType.monthly')}
          </NavItem>
          <NavItem eventKey={OEEDurationType.weekly}>
            {t('OEEDashboard.aggregationType.weekly')}
          </NavItem>
          <NavItem eventKey={OEEDurationType.daily}>
            {t('OEEDashboard.aggregationType.daily')}
          </NavItem>
        </Nav>
        <div className="oee-dashboard__main">
          <div className="card">
            <div className="card-block">
              <Row>
                <div className="col-md-12">
                  <h4 className="m-t-md m-b-lg text-center">
                    {t('OEEDashboard.titles.oeeByAggregationType', {
                      aggregationType: t(`OEEDashboard.aggregationType.${aggregationType}`),
                    })}
                  </h4>
                </div>
                <div className="col-md-6">
                  <OEEGaugeChart
                    data={oeeData}
                    layout={{ height: 370, width: 520 }}
                    filter={oeeFilter}
                    aggregationType={aggregationType}
                    loading={oeeDataLoading}
                  />
                </div>
                <div className="col-md-6">
                  <OEESelectedChart
                    data={oeeData}
                    layout={{}}
                    filter={oeeFilter}
                    aggregationType={aggregationType}
                    loading={oeeDataLoading}
                  />
                </div>
              </Row>
            </div>
          </div>
          <div className="card">
            <div className="card-block">
              <AvailabilityLossesChart
                data={oeeData}
                plantAndLine={plantAndLine}
                categories={categories}
                categoryItems={categoryItems}
                loading={oeeDataLoading}
                aggregations={aggregations}
                onCategoryChanged={handleCategoryChanged}
              />
            </div>
          </div>
          <div className="card">
            <div className="card-block">
              <TreatedWasteLossesChart
                data={oeeData}
                plantAndLine={plantAndLine}
                categories={categories}
                categoryItems={categoryItems}
                loading={oeeDataLoading}
                aggregations={aggregations}
                onCategoryChanged={handleCategoryChanged}
              />
            </div>
          </div>
          <div className="card">
            <div className="card-block">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="m-t-md m-b-lg text-center">
                    {t('OEEDashboard.oeeDashboardCommentTitle', {
                      aggregationType: t(`OEEDashboard.aggregationType.${aggregationType}`),
                    })}
                  </h4>
                </div>
              </div>
              <OEEDashboardComment
                plantAndLine={plantAndLine}
                filter={oeeFilter}
                aggregationType={aggregationType}
                print={false}
              />
            </div>
          </div>
          <div className="card">
            <div className="card-block">
              <Row>
                <div className="col-md-12">
                  <h4 className="m-t-md m-b-lg text-center">
                    {t('OEEDashboard.titles.oeeEvolution', {
                      aggregationType: t(`OEEDashboard.aggregationType.${aggregationType}`),
                    })}
                  </h4>
                </div>
                <div className="col-md-12">
                  <OEEChart
                    data={oeeData}
                    layout={{
                      height: 270,
                      margin: { t: 5, b: 65, l: 25 },
                    }}
                    filter={oeeFilter}
                    aggregationType={aggregationType}
                    loading={oeeDataLoading}
                    aggregations={aggregations}
                    aggs="oee"
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default loadData(async (dispatch, state) => {
  await dispatch(indexBusinessUnit());
  await dispatch(indexPlants(UserPolicies.oeeRead));
  await dispatch(indexCategory());
  await dispatch(indexCategoryItem());
  const filter = state.oeeFilter;
  const aggregationType = state.oeeDashboard.aggregationType;
  loadOEEDashboard(dispatch, filter, aggregationType);
})(OEEDashboard);
