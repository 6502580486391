/* global window document */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class StickyBar extends Component {
  constructor(props) {
    super(props);
    this.scrollContainer = window;

    this.state = {
      isSticky: false,
    };

    this.update = this.update.bind(this);
    this.setup = this.setup.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.update);
    window.addEventListener('resize', this.setup);

    this.setup();
  }

  UNSAFE_componentWillReceiveProps() {
    this.setup();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.update);
    window.removeEventListener('resize', this.setup);
  }

  setup() {
    this.containerOffsetTop = this.containerEl.getBoundingClientRect().top;
    this.setState({
      height: this.containerEl.offsetHeight,
      width: this.containerEl.offsetWidth,
    });

    this.update();
  }

  update() {
    if (this.el) {
      // If sticky-bar is in the viewport by default
      if (
        this.scrollContainer.scrollY === 0 &&
        this.containerOffsetTop + this.state.height <= this.scrollContainer.innerHeight
      ) {
        this.unstick();
        return;
      }

      // If sticky-bar is visible or above the viewport
      if (
        this.scrollContainer.scrollY + this.scrollContainer.innerHeight >
        this.containerOffsetTop + this.el.offsetHeight
      ) {
        this.unstick();
      } else {
        this.stick();
      }
    }
  }

  stick() {
    if (this.state.isSticky) return;
    this.setState({ isSticky: true });
  }

  unstick() {
    if (!this.state.isSticky) return;
    this.setState({ isSticky: false });
  }

  render() {
    const { children } = this.props;
    const className = classNames({
      'sticky-bar': true,
      'sticky-bar__fixed': this.state.isSticky,
    });
    const containerStyle = { height: `${this.state.height}px` };
    const elStyle = { width: this.state.isSticky ? `${this.state.width}px` : 'auto' };
    return (
      <div
        className="sticky-bar-container"
        ref={div => {
          this.containerEl = div;
        }}
        style={containerStyle}
      >
        <div
          className={className}
          ref={div => {
            this.el = div;
          }}
          style={elStyle}
        >
          {children}
        </div>
      </div>
    );
  }
}

StickyBar.propTypes = {
  children: PropTypes.node.isRequired,
};
