import React from 'react';
import Plotly from 'plotly.js-basic-dist';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import Loader from '../Loader/Loader';
import createPlotlyComponent from 'react-plotly.js/factory';

interface PlotlyChartProps extends WithTranslation {
  data: any;
  layout?: any;
  config?: any;
  loading?: boolean;
  onClick?: any;
  image?: any;
  titleSize?: any;
}

class PlotlyChart extends React.Component<PlotlyChartProps, any> {
  static defaultProps = {
    layout: {},
    config: {},
  };

  private plotlyImage = React.createRef<HTMLImageElement>();

  onInitialized = (figure, graphDiv) => {
    if (this.props.image) {
      Plotly.toImage(graphDiv, this.props.image).then(image => {
        if (this.plotlyImage.current) {
          this.plotlyImage.current.src = image;
        }
      });
    }
  };

  render() {
    const { data, loading, layout, config, onClick, image, titleSize, t } = this.props;
    const conf = Object.assign({}, config, { displayModeBar: false });
    const _layout = _.clone(layout);
    if (_layout.title) {
      _layout.title = {
        text: layout.title,
        font: {
          size: titleSize ? titleSize : 18,
        },
      };
    }
    // must recreate Plot component every update, otherwise onClick function cant update
    const Plot = createPlotlyComponent(Plotly);
    return (
      <div>
        <Plot
          style={data.length === 0 || loading || image ? { display: 'none' } : {}}
          data={data}
          layout={_layout}
          config={conf}
          onClick={onClick}
          onInitialized={this.onInitialized}
        />
        {data.length === 0 && !loading && (
          <div style={{ height: layout.height }} className="text-center">
            {layout.title && <h4 className="plotly-title">{layout.title}</h4>}
            <div className="has-no-data">
              <i className="fa fa-fw fa-bar-chart" /> <span>{t('common.noData')}</span>
            </div>
          </div>
        )}
        {loading && (
          <div style={{ height: layout.height }} className="is-loading text-center">
            <Loader />
          </div>
        )}
        {image && <img src="" alt="" ref={this.plotlyImage} />}
      </div>
    );
  }
}

export default withTranslation()(PlotlyChart);
