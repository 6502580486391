import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import StickyBar from '../StickyBar/StickyBar';
import { CONTACTS } from '../../../common/models/plant';

class ContactsForm extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  contactsOptions() {
    return this.props.users.map(u => ({ value: u.id, label: u.name }));
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = {};
    _.keys(this.state.data).forEach(role => {
      let contact = this.state.data[role] || { id: null };
      if (typeof contact === 'string') {
        contact = { id: contact };
      }
      data[role] = contact.id;
    });
    this.props.onSubmit(data);
  }

  changeContact(role) {
    return option => {
      let userId = null;
      if (option) {
        userId = option.value;
      }
      this.setState({
        data: {
          ...this.state.data,
          [role]: { id: userId },
        },
      });
    };
  }

  buildInputs() {
    const { t } = this.props;
    return CONTACTS.map(role => {
      let contact = this.state.data[role] || {};
      if (typeof contact === 'string') {
        contact = { id: contact };
      }
      return (
        <fieldset className="form-group" key={role}>
          <label htmlFor={role}>{t(`plant.${role}`)} :</label>
          <Select
            name={role}
            value={contact.id}
            options={this.contactsOptions()}
            onChange={this.changeContact(role)}
            placeholder={t('views.select')}
          />
        </fieldset>
      );
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Row>
        <Col md={8}>
          <form onSubmit={this.handleSubmit}>
            {this.buildInputs()}

            <StickyBar>
              <div className="text-center m-t-md">
                <button type="submit" className="btn btn-primary btn-lg">
                  {t('user.save')}
                </button>
              </div>
            </StickyBar>
          </form>
        </Col>
      </Row>
    );
  }
}

ContactsForm.propTypes = {
  data: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation()(ContactsForm);
