import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import fp from 'lodash/fp';
import { ButtonToolbar } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import SelectFilter from '../Filter/SelectFilter';
import Switch from '../Switch/Switch';
import { Plant } from '../../../common/models/plant';
import { BusinessUnit } from '../../../common/models/businessUnit';
import { OEEFilterState, updateOEEFilter } from '../../../redux/modules/oeeFilter';
import { NONE, ALL } from '../../../common/filter';
import { plantLineShifts } from '../../../utils/plantHelper';

interface OEEFilterProps extends WithTranslation {
  filter: OEEFilterState;
  plants: Plant[];
  businessUnits: BusinessUnit[];
  onChange: Function;
  updateOEEFilter: Function;
  showMonthYearPicker?: boolean;
  oeeDashboard?: boolean;
}

const oeeMinDate = moment(process.env.REACT_APP_OEE_MIN_DATE).toDate();

class OEEFilter extends PureComponent<OEEFilterProps, any> {
  static defaultProps = {
    showMonthYearPicker: true,
  };

  constructor(props: OEEFilterProps) {
    super(props);
    const { filter, plants } = props;
    const _plants =
      filter.businessUnitId === ALL
        ? plants
        : plants.filter(
            p =>
              p.businessUnitId === filter.businessUnitId &&
              (!p.archived || (filter.showArchived && p.archived)),
          );
    this.state = {
      plants: _plants,
    };
    this.filterByPlant = this.filterByPlant.bind(this);
    this.filterByBU = this.filterByBU.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleArchivedChange = this.handleArchivedChange.bind(this);
    this.handleShowShiftChange = this.handleShowShiftChange.bind(this);
  }

  buildPlantsOptions() {
    const { t, filter } = this.props;
    const { plants } = this.state;
    let plantsFiltered = _.filter(plants, p => {
      return !p.archived || (filter.showArchived && p.archived);
    });
    let plantsOptions = plantsFiltered.map(p => {
      let name = p.name;
      if (p.archived) {
        name = `${name} (${t('plant.archived')})`;
      }
      return { value: p._id, label: name };
    });
    plantsOptions = _.orderBy(plantsOptions, 'label');
    plantsOptions.unshift({ value: NONE, label: t('OEE.selectPlant') });
    return plantsOptions;
  }

  buildBusinessUnitOptions() {
    const { t } = this.props;
    let buOptions = this.props.businessUnits.map(bu => {
      return { value: bu._id, label: bu.name };
    });
    buOptions = _.orderBy(buOptions, 'label');
    buOptions.unshift({ value: ALL, label: t('businessUnit.businessUnit') });
    return buOptions;
  }

  handleFilter(filter) {
    this.props.updateOEEFilter(filter);
    this.props.onChange(filter);
  }

  filterByPlant(plantId: string) {
    const { plants, filter } = this.props;
    const plant = plants.find(p => p._id === plantId);
    if (plant && plant.lines && plant.lines.length > 0) {
      const line = _.orderBy(plant.lines, ['position'])[0];
      const newFilter = Object.assign({}, filter, { plantId, line: line.slug });
      if (line.isShift) {
        newFilter.shift = 'shift1';
      } else {
        newFilter.shift = NONE;
      }
      this.handleFilter(newFilter);
    } else {
      this.handleFilter(
        Object.assign({}, filter, {
          plantId: NONE,
          line: NONE,
          shift: NONE,
        }),
      );
    }
  }

  filterByBU(buId: string) {
    const { plants, filter } = this.props;
    const _plants =
      buId === ALL
        ? plants
        : plants.filter(
            p => p.businessUnitId === buId && (!p.archived || (filter.showArchived && p.archived)),
          );
    this.setState(
      {
        plants: _plants,
      },
      () => {
        const newFilter = Object.assign({}, filter, { businessUnitId: buId, plantId: NONE });
        this.handleFilter(newFilter);
      },
    );
  }

  handleLineOrShiftChange(type: string) {
    return event => {
      event.preventDefault();
      const value = event.currentTarget.value;
      if (value !== this.props.filter[type]) {
        const newValue = { [type]: value };
        if (type === 'line') {
          const { plants } = this.props;
          const { plantId } = this.props.filter;
          const plant = plants.find(p => p._id === plantId);
          if (plant) {
            //@ts-ignore
            const plantLine = plant.lines.find(l => l.slug === value);
            if (plantLine) {
              newValue.shift = plantLine.isShift ? 'shift1' : NONE;
            }
          }
        }
        const newFilter = Object.assign({}, this.props.filter, newValue);
        this.handleFilter(newFilter);
      }
    };
  }

  buildPlantLineOptions() {
    const options: any[] = [];
    const { plants } = this.props;
    const { plantId } = this.props.filter;
    const plant = plants.find(p => p._id === plantId);
    if (plant && plant.lines) {
      _.orderBy(plant.lines, ['position']).forEach(l => {
        options.push({ value: l.slug, label: l.name });
      });
    }
    return options;
  }

  buildLineSelector() {
    const lines = this.buildPlantLineOptions()
      // Build a button per option
      .map(option => {
        const isSelected = option.value === this.props.filter.line;
        const className = ['btn-secondary-outline', 'btn btn-secondary m-r btn-round'];
        if (isSelected) {
          // Remove btn-secondary-outline
          className.shift();
        }
        return (
          <button
            value={option.value}
            onClick={this.handleLineOrShiftChange('line')}
            className={className.join(' ')}
            key={option.value}
          >
            {option.label}
          </button>
        );
      });
    return <ButtonToolbar>{lines}</ButtonToolbar>;
  }

  hasLineShift() {
    const { plants } = this.props;
    const { plantId, line } = this.props.filter;
    const plant = plants.find(p => p._id === plantId);
    if (plant) {
      //@ts-ignore
      const plantLine = plant.lines.find(l => l.slug === line);
      if (plantLine && !plantLine.isShift) {
        return false;
      }
    } else {
      return false;
    }
    return true;
  }

  buildShiftSelector() {
    if (!this.hasLineShift()) return null;
    //if (this.props.oeeDashboard && !this.props.filter.showShift) return null;
    if (this.props.oeeDashboard) return null;
    const { plants } = this.props;
    const { plantId, line } = this.props.filter;
    const shifts = plantLineShifts(plants, plantId, line)
      // Build a button per option
      .map((value: string) => {
        const isSelected = value === this.props.filter.shift;
        const className = ['btn-secondary-outline', 'btn btn-secondary m-r btn-round'];
        if (isSelected) {
          // Remove btn-secondary-outline
          className.shift();
        }
        const n = _.last(value);
        return (
          <button
            value={value}
            onClick={this.handleLineOrShiftChange('shift')}
            className={className.join(' ')}
            key={value}
          >
            {this.props.t('plant.shiftN', { n })}
          </button>
        );
      });
    return <ButtonToolbar>{shifts}</ButtonToolbar>;
  }

  handleDateChange(date: Date) {
    const filter = Object.assign({}, this.props.filter, {
      date,
    });
    this.handleFilter(filter);
  }

  getMaxDate() {
    const today = moment();
    if (this.props.showMonthYearPicker) {
      return today.endOf('month').toDate();
    }
    return today.toDate();
  }

  getDateFormat() {
    const { i18n } = this.props;
    if (this.props.showMonthYearPicker) {
      return 'MMM y';
    }
    return i18n.language === 'fr' ? 'dd/MM/y' : 'MM/dd/y';
  }

  handleArchivedChange(value: boolean) {
    const filter = Object.assign({}, this.props.filter, {
      showArchived: value,
      plantId: NONE,
      line: NONE,
      shift: NONE,
    });
    this.handleFilter(filter);
  }

  handleShowShiftChange(value: boolean) {
    const filter = Object.assign({}, this.props.filter, {
      showShift: value,
    });
    this.handleFilter(filter);
  }

  render() {
    const { t, i18n, showMonthYearPicker } = this.props;
    const { businessUnitId, plantId, date } = this.props.filter;
    return (
      <div className="oee-input__filters">
        <form className="form-inline">
          <div className="oee-input__filter form-group">
            <SelectFilter
              options={this.buildBusinessUnitOptions()}
              onChange={this.filterByBU}
              value={businessUnitId}
            />
          </div>
          <div className="oee-input__filter form-group">
            <SelectFilter
              options={this.buildPlantsOptions()}
              onChange={this.filterByPlant}
              value={plantId}
            />
          </div>
          <div className="oee-input__filter oee-input__filter--fixed-width form-group form-group-override">
            {this.buildLineSelector()}
          </div>
          <div className="oee-input__filter form-group">
            <DatePicker
              selected={date}
              onChange={this.handleDateChange}
              minDate={oeeMinDate}
              maxDate={this.getMaxDate()}
              dateFormat={this.getDateFormat()}
              locale={i18n.language}
              showMonthYearPicker={showMonthYearPicker}
            />
          </div>
          <div className="oee-input__filter oee-input__filter--fixed-width form-group form-group-override">
            {this.buildShiftSelector()}
          </div>
          <div className="oee-input__filter--switch">
            <Switch
              className="oee-filter--switch"
              title={t('plant.showArchived')}
              value={this.props.filter.showArchived}
              onChange={this.handleArchivedChange}
            />
            {this.props.oeeDashboard && this.hasLineShift() && (
              <Switch
                className="oee-filter--switch m-l-md invisible"
                title={t('plant.showShift')}
                value={this.props.filter.showShift}
                onChange={this.handleShowShiftChange}
                disable
              />
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filter: state.oeeFilter,
  };
};

const mapDispatchToProps = {
  updateOEEFilter,
};

export default fp.compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(OEEFilter);
