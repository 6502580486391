import React from 'react';
import { PropTypes } from 'prop-types';
import { Form, File } from 'formsy-react-components';
import { withTranslation } from 'react-i18next';
import BaseForm from '../Form/BaseForm';

function checkFileExtComply(formats, errorMessage) {
  return (values, value) => {
    if (value && value[0]) {
      const filename = value[0].name;
      for (const f of formats) {
        const ext = filename.substr(filename.lastIndexOf('.') + 1);
        if (ext.toLowerCase() === f.toLowerCase()) {
          return true;
        }
      }
    }
    return errorMessage;
  };
}

class OEEImportForm extends BaseForm {
  handleSubmit(data) {
    this.props.onSubmit(data);
  }

  render() {
    const { t } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} onValid={this.enableButton} onInvalid={this.disableButton}>
        <File
          name="file"
          labelClassName={[{ 'col-sm-3': false }, 'col-sm-4 m-l']}
          elementWrapperClassName={[{ 'col-sm-9': false }, 'col-sm-7']}
          label={t('OEE.chooseAnXls')}
          disabled={this.props.importing}
          validations={{
            formatValidation: checkFileExtComply(['xls', 'xlsx'], t('errors.extensionInvalid')),
          }}
          required
        />

        <div className={`text-center m-t-md ${this.props.importing ? '' : 'hidden'}`}>
          <i className="fa fa-cog fa-spin fa-3x fa-fw margin-bottom" />
          <br />
          <span>{t('OEE.importPending')}</span>
        </div>
        <div className="text-center m-t">
          <button
            type="submit"
            className="btn btn-primary btn-lg"
            formNoValidate
            disabled={!this.state.canSubmit || this.props.importing}
          >
            {t('common.save')}
          </button>
        </div>
      </Form>
    );
  }
}

OEEImportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  importing: PropTypes.bool,
};

export default withTranslation()(OEEImportForm);
