import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import OEEDashboardCommentForm from './OEEDashboardCommentForm';
import { saveOEEDashboardComment } from '../../../redux/modules/oeeDashboardComments';
import { UserPolicies } from '../../../common/models/user';
import { OEEInputType } from '../../../common/models/oeeInput';
import { NONE } from '../../../common/filter';
import { isAuthorizeResource } from '../../../utils/userHelper';

const getOEEDashboardCommentByType = (comments, commentType) => {
  return _.find(comments, { commentType }) || {};
};

const filterOEEDashboardCommentByType = (comments, commentType) => {
  return _.filter(comments, { commentType });
};

const OEEDashboardComment = ({ plantAndLine, filter, aggregationType, print }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { plant, line, shift } = plantAndLine;
  const { showShift } = filter;
  const currentUser = useSelector(state => state.profile.data);
  const oeeDashboardComments = useSelector(state => state.oeeDashboardComments.data);

  const isPermit =
    !print &&
    plant &&
    line &&
    isAuthorizeResource(currentUser, UserPolicies.oeeWrite, plantAndLine.plant._id);

  const handleSaveComment = data => {
    dispatch(
      saveOEEDashboardComment({
        plantId: plant._id,
        line: line.slug,
        shift: showShift && shift !== NONE ? shift : undefined,
        date: filter.date,
        ...data,
      }),
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <h5 className="pointer">{t('OEEInput.availability')}</h5>
        <div className="m-l-lg m-r-lg">
          <OEEDashboardCommentForm
            data={getOEEDashboardCommentByType(
              oeeDashboardComments.comments,
              OEEInputType.availability,
            )}
            oeeInputs={oeeDashboardComments.oeeInputs}
            oeeInputType={OEEInputType.availability}
            aggregationType={aggregationType}
            otherComments={filterOEEDashboardCommentByType(
              oeeDashboardComments.otherComments,
              OEEInputType.availability,
            )}
            disabled={!isPermit}
            onSubmit={handleSaveComment}
            print={print}
          />
        </div>
        <h5 className="pointer">{t('OEEInput.wasteTreated')}</h5>
        <div className="m-l-lg m-r-lg">
          <OEEDashboardCommentForm
            data={getOEEDashboardCommentByType(
              oeeDashboardComments.comments,
              OEEInputType.wasteTreated,
            )}
            oeeInputs={oeeDashboardComments.oeeInputs}
            oeeInputType={OEEInputType.wasteTreated}
            aggregationType={aggregationType}
            otherComments={filterOEEDashboardCommentByType(
              oeeDashboardComments.otherComments,
              OEEInputType.wasteTreated,
            )}
            disabled={!isPermit}
            onSubmit={handleSaveComment}
            print={print}
          />
        </div>
      </div>
    </div>
  );
};

export default OEEDashboardComment;
