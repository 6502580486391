import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem } from 'react-bootstrap';
import ScrollUpButton from 'react-scroll-up-button';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import OEEFilter from '../../../components/App/OEE/OEEFilter';
import OEEExportButton from '../../../components/App/OEE/OEEExportButton';
import OEEExportToS3Button from '../../../components/App/OEE/OEEExportToS3Button';
import OEEInputActions from '../../../components/App/OEEInput/OEEInputActions';
import OEEInputTable from '../../../components/App/OEEInput/OEEInputTable';
import OEEInputChangeMonthButtons from '../../../components/App/OEEInput/OEEInputChangeMonthButtons';
import OEEInputHelpButton from '../../../components/App/OEEInput/OEEInputHelpButton';
import loadData from '../../../utils/loadData';
import { isAdmin } from '../../../utils/userHelper';
import { indexPlants, getPlantAndLineByOEEFilterSelector } from '../../../redux/modules/plants';
import { indexOEEInput, resetOEEInput } from '../../../redux/modules/oeeInputs';
import { indexCategory } from '../../../redux/modules/categories';
import { indexCategoryItem } from '../../../redux/modules/categoryItems';
import { indexBusinessUnit } from '../../../redux/modules/businessUnits';
import { OEEFilterState } from '../../../redux/modules/oeeFilter';
import { indexObjective } from '../../../redux/modules/objectives';
import { Plant } from '../../../common/models/plant';
import { BusinessUnit } from '../../../common/models/businessUnit';
import { UserPolicies } from '../../../common/models/user';
import { OEEInputType } from '../../../common/models/oeeInput';
import { Category } from '../../../common/models/category';
import { CategoryItem } from '../../../common/models/categoryItem';
import { NONE } from '../../../common/filter';

interface OEEInputProps {}

function loadOEEInputsAndObjectives(dispatch, filter) {
  const { plantId, line, date } = filter;
  if (plantId !== NONE && line !== NONE) {
    // dont need add shift, because we need load show shifts data of line to check prev/next shift of a shift
    const data: any = {
      plantId,
      line,
      endYear: date.getFullYear(),
      endMonth: date.getMonth(),
    };
    dispatch(indexOEEInput(data));
    dispatch(indexObjective({ plantId, line }));
  } else {
    dispatch(resetOEEInput());
  }
}

const OEEInputList: React.FC<OEEInputProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.profile.data);
  const plants: Plant[] = useSelector(state => state.plants.data);
  const businessUnits: BusinessUnit[] = useSelector(state => state.businessUnits.data);
  const plantAndLine = useSelector(getPlantAndLineByOEEFilterSelector);
  const categories: Category[] = useSelector(state => state.categories.data);
  const categoryItems: CategoryItem[] = useSelector(state => state.categoryItems.data);
  const [oeeInputType, setOEEInputType] = useState(OEEInputType.availability);

  const handleFilterChanged = (filter: OEEFilterState) => {
    loadOEEInputsAndObjectives(dispatch, filter);
  };

  return (
    <div className="oee">
      <PageNav fluid>
        <div className="plant-band">
          <h2 className="nav-secondary__title">{t('sidebar.oeeInput')}</h2>
          <div className="nav-secondary__right">
            <OEEExportButton />
            {isAdmin(currentUser) && <OEEExportToS3Button />}
          </div>
        </div>
      </PageNav>
      <PageContent containerLg>
        <div className="oee-input">
          <div className="oee-input__topbar card card-block m-t-lg">
            <OEEFilter
              onChange={handleFilterChanged}
              plants={plants}
              businessUnits={businessUnits}
            />
          </div>
          <Nav
            bsStyle="tabs"
            activeKey={oeeInputType}
            onSelect={(value: OEEInputType) => setOEEInputType(value)}
          >
            <NavItem eventKey={OEEInputType.wasteTreated}>{t('OEEInput.wasteTreated')}</NavItem>
            <NavItem eventKey={OEEInputType.availability}>{t('OEEInput.availability')}</NavItem>
          </Nav>
          <div className="oee-input__main card card-block">
            <div className="oee-input__head">
              <div className="oee-input__main-actions"></div>
              <OEEInputActions oeeInputType={oeeInputType} plantAndLine={plantAndLine} />
              <div className="oee-input__labels">
                <span className="oee-input__label oee-input__label--incompleted">
                  <i className="fa fa-exclamation-circle m-r-xs" />
                  {t('OEEInput.dataToComplete')}
                </span>
                <span className="oee-input__label oee-input__label--completed">
                  <i className="fa fa-check-circle m-r-xs" />
                  {t('OEEInput.completedData')}
                </span>
                <span className="oee-input__label oee-input__label--validated">
                  <i className="fa fa-check-circle m-r-xs" />
                  {t('OEEInput.validatedData')}
                </span>
                <OEEInputHelpButton />
              </div>
            </div>
            <OEEInputTable
              oeeInputType={oeeInputType}
              plantAndLine={plantAndLine}
              categories={categories}
              categoryItems={categoryItems}
              plants={plants}
            />
            {plantAndLine.plant && plantAndLine.line && (
              <OEEInputChangeMonthButtons onChange={handleFilterChanged} />
            )}
          </div>
        </div>
      </PageContent>
      <ScrollUpButton
        ContainerClassName="scrollup--container"
        TransitionClassName="scrollup--transition"
      >
        <span>
          <i className="fa fa-arrow-circle-up"></i>
        </span>
      </ScrollUpButton>
    </div>
  );
};

export default loadData(async (dispatch, state) => {
  await dispatch(indexBusinessUnit());
  await dispatch(indexPlants(UserPolicies.oeeRead));
  await dispatch(indexCategory());
  await dispatch(indexCategoryItem());
  loadOEEInputsAndObjectives(dispatch, state.oeeFilter);
})(OEEInputList);
