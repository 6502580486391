import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LossChart from './LossChart';
import { LossType, TreatedWasteLossType } from '../../../common/models/category';
import {
  COLORS,
  lossTotalTitle,
  getMaxStopOrLoss,
  getMinStopOrLoss,
} from '../../../utils/oeeHelper';

const TreatedWasteLossesChart: React.FC<any> = ({
  data,
  loading,
  plantAndLine,
  aggregations,
  categories,
  categoryItems,
  onCategoryChanged,
  layout,
  print,
}) => {
  const { t } = useTranslation();
  const performanceLossMax = getMaxStopOrLoss(
    data.performanceLossByCategory,
    'performanceLossByCategory',
    aggregations,
  );
  const qualityLossMax = getMaxStopOrLoss(
    data.qualityLossByCategory,
    'qualityLossByCategory',
    aggregations,
  );
  const performanceLossMaxValue = performanceLossMax ? performanceLossMax.value : 0;
  const qualityLossMaxValue = qualityLossMax ? qualityLossMax.value : 0;
  const valueMax =
    performanceLossMaxValue > qualityLossMaxValue ? performanceLossMaxValue : qualityLossMaxValue;

  const performanceLossMin = getMinStopOrLoss(
    data.performanceLossByCategory,
    'performanceLossByCategory',
    aggregations,
  );
  const qualityLossMin = getMinStopOrLoss(
    data.qualityLossByCategory,
    'qualityLossByCategory',
    aggregations,
  );
  const performanceLossMinValue = performanceLossMin ? performanceLossMin.value : 0;
  const qualityLossMinValue = qualityLossMin ? qualityLossMin.value : 0;
  let valueMin =
    performanceLossMinValue < qualityLossMinValue ? performanceLossMinValue : qualityLossMinValue;
  valueMin = valueMin > 0 ? 0 : valueMin;
  return (
    <Row>
      <div className="col-md-12">
        <h4 className={`text-center ${print ? 'm-b-md' : 'm-b-lg'}`}>
          {lossTotalTitle(
            t('OEEDashboard.titles.performanceQualityLossTotal'),
            data.performanceLossByCategory
              ? data.performanceLossByCategory.concat(data.qualityLossByCategory)
              : [],
          )}
        </h4>
      </div>
      <div className="col-md-8 col-sm-8 col-lg-8">
        <LossChart
          data={data.performanceLossByCategory}
          layout={Object.assign(
            {
              title: lossTotalTitle(
                t('OEEDashboard.titles.performanceLossTotal'),
                data.performanceLossByCategory,
              ),
              yaxis: { fixedrange: true, range: [valueMin, valueMax] },
            },
            layout,
          )}
          plantAndLine={plantAndLine}
          categories={categories}
          categoryItems={categoryItems}
          lossType={LossType.treatedWaste}
          loss={TreatedWasteLossType.performance}
          loading={loading}
          color={COLORS.performance}
          aggregations={aggregations}
          aggs="performanceLossByCategory"
          onCategoryChanged={onCategoryChanged}
          print={print}
        />
      </div>
      <div className="col-md-4 col-sm-4 col-lg-4">
        <LossChart
          data={data.qualityLossByCategory}
          layout={Object.assign(
            {
              title: lossTotalTitle(
                t('OEEDashboard.titles.qualityLossTotal'),
                data.qualityLossByCategory,
              ),
              yaxis: { fixedrange: true, range: [valueMin, valueMax] },
            },
            layout,
          )}
          plantAndLine={plantAndLine}
          categories={categories}
          categoryItems={categoryItems}
          lossType={LossType.treatedWaste}
          loss={TreatedWasteLossType.quality}
          loading={loading}
          color={COLORS.quality}
          aggregations={aggregations}
          aggs="qualityLossByCategory"
          onCategoryChanged={onCategoryChanged}
          print={print}
        />
      </div>
    </Row>
  );
};

export default TreatedWasteLossesChart;
