import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { signout } from '../../../redux/modules/auth';

const threshold = process.env.REACT_APP_SECURITY_ALERT_TIME_BEFORE_LOGOUT || '20';

interface AlertLogoutProps {
  getRemainingTime: any;
}

const AlertLogout: React.FC<AlertLogoutProps> = ({ getRemainingTime }) => {
  const { t } = useTranslation();
  const [remaining, setRemaining] = useState(Infinity);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (getRemainingTime()) {
        setRemaining(getRemainingTime());
      } else {
        dispatch(signout());
      }
    }, 500);
    return () => clearInterval(interval);
  }, [dispatch, getRemainingTime]);

  const layerClasses = classNames({
    'alert-logout': true,
    'show-alert': remaining <= parseInt(threshold, 10) * 1000,
  });
  return (
    <div className={layerClasses}>
      <div className="alert-info">
        <p>
          <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
        </p>
        <p>
          {t('securityAlert.imminentLogout', {
            remaining: Math.round(remaining / 1000),
            timeout: process.env.REACT_APP_SECURITY_LOGOUT_AFTER_IDLE_TIME || '1',
          })}
        </p>
      </div>
    </div>
  );
};

export default React.memo(AlertLogout);
