import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PlotlyChart from '../PlotlyChart/PlotlyChart';
import { OEE_INDICATORS, COLORS, getTimeDuration } from '../../../utils/oeeHelper';
import { getRangeByAggregation } from '../../../common/utils/oeeDashboardHelper';

function getOEERealBudgetChallenge(data, filter): any {
  const { endDate } = getRangeByAggregation('oee', filter);
  const date = moment(endDate).startOf(getTimeDuration(filter.aggregationType));
  const oee = _.find(data.oee, d => {
    return date.isSame(d.key, getTimeDuration(filter.aggregationType));
  });
  const oeeBudgetChallenge = _.find(data.oeeBudgetChallenges, d => {
    return date.isSame(d.date, getTimeDuration(filter.aggregationType));
  });
  const oeeData: any = {};
  OEE_INDICATORS.forEach(indicator => {
    const real = oee && oee.openingTime.value !== 0 ? _.round(oee[indicator].value, 1) : null;
    let budget: any = null;
    let challenge: any = null;
    if (oeeBudgetChallenge) {
      if (oeeBudgetChallenge.openingTime !== 0) {
        if (_.isFinite(oeeBudgetChallenge[indicator])) {
          budget = _.round(oeeBudgetChallenge[indicator] * 100, 1);
        }
        if (_.isFinite(oeeBudgetChallenge[`${indicator}Challenge`])) {
          challenge = _.round(oeeBudgetChallenge[`${indicator}Challenge`] * 100, 1);
        }
      }
    } else if (_.isFinite(real)) {
      budget = 100;
      challenge = 100;
    }
    oeeData[indicator] = {
      real,
      budget,
      challenge,
    };
  });
  return oeeData;
}

function buildOEESelectedChartData(data, filter, t, showText = false) {
  const traces: any[] = [];
  const oeeData = getOEERealBudgetChallenge(data, filter);
  const indicators = OEE_INDICATORS.slice().reverse();
  const reals = indicators.map(i => oeeData[i].real);
  const budgets = indicators.map(i => oeeData[i].budget);
  const challenges = indicators.map(i => oeeData[i].challenge);
  const y = indicators.map(i => t(`OEE.indicators.${i}`) + '  ');
  const hasRealOEE = reals.every(v => _.isFinite(v));
  const hasBudgetChallenge =
    challenges.every(v => _.isFinite(v)) && budgets.every(v => _.isFinite(v));
  if (hasBudgetChallenge) {
    traces.push({
      x: [120, 120, 120, 120],
      y,
      type: 'bar',
      marker: { color: COLORS.oeeBudgetChallenge },
      orientation: 'h',
      hoverinfo: 'skip',
    });
    traces.push({
      x: challenges,
      y,
      type: 'bar',
      marker: { color: COLORS.oeeWasteTreatedBudgetChallenge },
      orientation: 'h',
      name: t('OEEDashboard.oeeWasteTreatedChallenge'),
      hoverinfo: 'x+name',
    });
    traces.push({
      x: budgets,
      y,
      type: 'bar',
      marker: { color: COLORS.oeeWasteTreatedBudgetBudget },
      orientation: 'h',
      name: t('OEEDashboard.oeeWasteTreatedBudget'),
      hoverinfo: 'x+name',
    });
  }
  if (hasRealOEE) {
    const width = hasBudgetChallenge ? [0.3, 0.3, 0.3, 0.3] : [];
    const text = {
      text: reals.map(v => {
        return `${_.round(v, 1)}%`;
      }),
      textposition: 'auto',
    };
    traces.push({
      x: reals,
      y,
      type: 'bar',
      marker: {
        color: indicators.map(i =>
          i !== 'oeeWasteTreated' ? COLORS[i] : COLORS.oeeWasteTreatedReal,
        ),
      },
      orientation: 'h',
      name: t('OEEDashboard.oeeWasteTreatedReal'),
      width,
      hoverinfo: 'x+name',
      ...text,
    });
  }
  return traces;
}

const barLayout = {
  height: 300,
  autosize: true,
  showlegend: false,
  margin: { t: 5, b: 25, l: 120, r: 30 },
  font: {
    family: 'Montserrat, sans-serif',
  },
  barmode: 'overlay',
};

const OEESelectedChart: React.FC<any> = ({
  data,
  layout,
  filter,
  aggregationType,
  loading,
  print,
}) => {
  const { t } = useTranslation();
  const q = Object.assign({ aggregationType }, filter);
  return (
    <div>
      <PlotlyChart
        data={buildOEESelectedChartData(data, q, t, print)}
        layout={_.merge(
          {
            xaxis: { range: [0, 120], fixedrange: false },
          },
          barLayout,
          layout,
        )}
        loading={loading}
      />
    </div>
  );
};

export default OEESelectedChart;
