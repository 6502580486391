import React from 'react';
import { Button, Accordion, Panel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';
import TextEditor from '../TextEditor/TextEditor';
import { shouldDisplayPostBody, displayPostBody } from '../../../utils/newsHelper';
import { useTranslation } from 'react-i18next';
import NewsPolicy from '../../../common/policies/news_policy';
import { User } from '../../../common/models/user';
import { News } from '../../../common/models/news';
import { APP_EDIT_NEWS_PATH } from '../../../utils/routeHelper';
import { DEFAULT_LANGUAGE } from '../../../common/i18n';

interface PostListProps extends RouteComponentProps {
  news: News[];
  isBoxed?: boolean;
}

const PostList: React.FC<PostListProps> = ({ news, history, isBoxed }) => {
  const { t, i18n } = useTranslation();
  const currentUser: User = useSelector(state => state.profile.data);
  const locale: string = i18n.language;

  const onEditPost = (post: News) => {
    history.push(APP_EDIT_NEWS_PATH(post._id));
  };

  const buildPostsList = () => {
    return news.map((post: News) => {
      const postTitle = post.title[locale] || post.title[DEFAULT_LANGUAGE];
      const labelClassNames = classNames('label m-r', {
        'label-primary': post.publishedAt,
        'label-default': !post.publishedAt,
      });
      const userCanEdit = currentUser && NewsPolicy.isAuthorize(currentUser, 'edit');
      const panelHeader = (
        <div>
          {userCanEdit && (
            <Button className="btn btn-secondary pull-right p-l-2" onClick={() => onEditPost(post)}>
              <i className="fa fa-edit" /> {t('news.edit')}
            </Button>
          )}

          <Panel.Title>
            {shouldDisplayPostBody(post, locale) && (
              <i className="post-chevron fa fa-chevron-down m-r" />
            )}
            {postTitle}
          </Panel.Title>

          <span className="text-gray-light-mid">
            {userCanEdit && (
              <span className={labelClassNames}>
                {' '}
                {t(post.publishedAt ? 'news.published' : 'news.unpublished')}{' '}
              </span>
            )}
            {!userCanEdit && post.publishedAt && <span> {t('news.publishedAt')} </span>}
            {post.publishedAt && moment.utc(post.publishedAt).format(t('date.short'))}
          </span>
        </div>
      );
      return (
        <Panel eventKey={post._id} key={post._id} className="posts__item">
          <Panel.Heading>{panelHeader}</Panel.Heading>
          <Panel.Body>
            {shouldDisplayPostBody(post, locale) && (
              <div className="p-b-2">
                <TextEditor rawState={displayPostBody(post, locale)} readOnly />
              </div>
            )}
          </Panel.Body>
        </Panel>
      );
    });
  };
  const postsList = buildPostsList();
  const postsClassNames = classNames('posts', {
    'posts--boxed': isBoxed,
  });
  if (_.isEmpty(postsList)) return <span>{t('news.noPostToDisplay')}</span>;
  return (
    <Accordion id="post-list" className={postsClassNames}>
      {postsList}
    </Accordion>
  );
};

export default withRouter(PostList);
