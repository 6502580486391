import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { APP_NEWS_PATH } from '../../../utils/routeHelper';
import { withTranslation } from 'react-i18next';
import PostList from './PostList';
import ModalWrapper from '../Modal/ModalWrapper';

class PostsModal extends PureComponent {
  render() {
    const { open, close, news, t, ...others } = this.props;
    return (
      <ModalWrapper
        open={open}
        close={close}
        hideCloseButton
        backdrop="static"
        keyboard={false}
        {...others}
      >
        <div className="clearfix">
          <Link className="btn btn-secondary pull-right" to={APP_NEWS_PATH}>
            {t('news.goToAllPosts')}
          </Link>

          <h2>{t('news.lastPosts')}</h2>
        </div>

        <PostList news={news} isBoxed />

        <button type="button" className="btn btn-primary btn-lg m-t-3" onClick={this.props.close}>
          {t('news.ok!')}
        </button>
      </ModalWrapper>
    );
  }
}

PostsModal.propTypes = {
  news: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PostsModal);
