import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import classNames from 'classnames';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import OEEInputValidationModal from './OEEInputValidationModal';
import { validateOEEInput, unvalidateOEEInput } from '../../../redux/modules/oeeInputs';
import { OEEInput, OEEInputType } from '../../../common/models/oeeInput';
import { Objective } from '../../../common/models/objective';
import { Category } from '../../../common/models/category';
import { checkOEEInputErrors } from '../../../common/utils/oeeInputHelper';

interface OEEInputValidationProps {
  oeeInput: OEEInput;
  categories: Category[];
  objective?: Objective;
  oeeInputType: OEEInputType;
  canValid: boolean;
  otherOEEInputs?: OEEInput[];
}

const OEEInputValidation: React.FC<OEEInputValidationProps> = ({
  oeeInput,
  categories,
  objective,
  oeeInputType,
  canValid,
  otherOEEInputs,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenValidationHistory, setIsOpenValidationHistory] = useState(false);
  const errors = checkOEEInputErrors(oeeInput, categories, objective, oeeInputType, otherOEEInputs);

  const handleValid = () => {
    dispatch(validateOEEInput(oeeInput));
  };

  const handleUnvalid = () => {
    return dispatch(unvalidateOEEInput(oeeInput)).then(action => {
      if (action.type.match(/SUCCESS/)) {
        setIsOpenValidationHistory(false);
      }
      return action;
    });
  };

  const toggleValidationHistoryModal = e => {
    if (e) {
      e.preventDefault();
    }
    setIsOpenValidationHistory(!isOpenValidationHistory);
  };

  const validClassName = classNames('btn btn-secondary oee-input__valid-btn', {
    disabled: !canValid,
  });

  const errorsPopover = (
    <Popover
      id="validationError"
      className="popover-md"
      title={t('OEEInput.error.validationError')}
    >
      {errors.map((error, i) => (
        <p key={i}>{t(error.message, error.params)}</p>
      ))}
    </Popover>
  );

  if (errors.length === 0) {
    if (!oeeInput.isValid) {
      return (
        <ConfirmButton
          className={validClassName}
          onClick={handleValid}
          message={t('OEEInput.validMessage')}
          confirmButtonStyle="primary"
          element="a"
        >
          <i className="fa fa-check-circle" />
        </ConfirmButton>
      );
    } else {
      return (
        <a
          href="#/"
          className="btn btn-secondaray oee-input__valid-btn"
          onClick={toggleValidationHistoryModal}
        >
          <i className="fa fa-check-circle" />
          <OEEInputValidationModal
            open={isOpenValidationHistory}
            close={toggleValidationHistoryModal}
            validationHistory={oeeInput.validationHistory}
            onUnvalidOEEInput={handleUnvalid}
          />
        </a>
      );
    }
  } else {
    return (
      <OverlayTrigger placement="left" overlay={errorsPopover}>
        <span className="oee-input__errors">
          <i className="fa fa-exclamation-circle" />
        </span>
      </OverlayTrigger>
    );
  }
};

export default OEEInputValidation;
