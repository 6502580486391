import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Category, LossType } from '../common/models/category';
import { CategoryItem } from '../common/models/categoryItem';
import { LineType } from '../common/models/plant';
import { DEFAULT_LANGUAGE } from '../common/i18n';
import { identifier } from '../common/models/model';
const {
  REACT_APP_OEE_HIDE_CATEGORY_DATE,
  REACT_APP_OEE_HIDE_CATEGORY_SLUG,
  REACT_APP_OEE_HIDE_CATEGORY_SLUG_2,
} = process.env;

export const buildCategoryTree = (
  list: Category[],
  formator: (c: Category) => {} = c => c,
): Category[] => {
  const _list = _.cloneDeep(list);
  const rootNodes: any = [];

  for (const currentNode of _list) {
    if (!currentNode.children) {
      currentNode.children = [];
    }

    if (currentNode.parent) {
      const parent = _.find(_list, { [identifier]: currentNode.parent });
      if (parent) {
        if (!parent.children) {
          parent.children = [];
        }
        parent.children.splice(currentNode.position, 0, formator(currentNode));
      } else {
        console.warn('Category: cant find category parent', currentNode);
      }
    } else {
      rootNodes.splice(currentNode.position, 0, formator(currentNode));
    }
  }

  return rootNodes;
};

function oeeInputCategoryTreeFormator(
  categoryItems: CategoryItem[],
  lossType: LossType,
  locale: string = DEFAULT_LANGUAGE,
) {
  return (category: Category) => {
    const categoryItem = _.find(categoryItems, { slug: category.categoryItemSlug });
    const required = _.find(category.lossType, { name: lossType }).required;
    const label = React.createElement('span', {
      className: required ? 'required' : '',
      children: categoryItem ? getCategoryItemName(categoryItem, locale) : 'UNKNOWN CATEGORY',
    });
    return {
      label,
      value: category[identifier],
      categoryItemSlug: category.categoryItemSlug,
      children: category.children,
    };
  };
}

export const buildOEEInputCategoryTreeOptions = (
  categories: Category[],
  categoryItems: CategoryItem[],
  lineType: LineType,
  lossType: LossType,
  oeeInputDate: Date,
  locale: string = DEFAULT_LANGUAGE,
) => {
  const date = moment(oeeInputDate);
  const list = _.filter(categories, (c: Category) => {
    // show no_info category before janvier 2020
    if (
      REACT_APP_OEE_HIDE_CATEGORY_SLUG &&
      date.isSameOrAfter(REACT_APP_OEE_HIDE_CATEGORY_DATE, 'day') &&
      c.categoryItemSlug === REACT_APP_OEE_HIDE_CATEGORY_SLUG
    ) {
      return false;
    }
    // show short_time_stoppage category after janvier 2020 for availability unplanned stop
    if (
      REACT_APP_OEE_HIDE_CATEGORY_SLUG_2 &&
      lossType === LossType.unplanned &&
      date.isBefore(REACT_APP_OEE_HIDE_CATEGORY_DATE, 'day') &&
      c.categoryItemSlug === REACT_APP_OEE_HIDE_CATEGORY_SLUG_2
    ) {
      return false;
    }
    return _.includes(c.lineType, lineType) && _.find(c.lossType, { name: lossType });
  });
  return buildCategoryTree(list, oeeInputCategoryTreeFormator(categoryItems, lossType, locale));
};

export const findCategoryById = (categories: Category[], id: string): Category | void => {
  return _.find(categories, { [identifier]: id });
};

export const getCategoryPathIds = (categories: Category[], id: string): string[] => {
  const category = findCategoryById(categories, id);
  return category && category.path ? category.path.split('#') : [];
};

export const getCategoryPathNames = (
  categories: Category[],
  categoryItems: CategoryItem[],
  id: string,
  locale: string = DEFAULT_LANGUAGE,
): string[] => {
  const category = findCategoryById(categories, id);
  if (category && category.slugPath) {
    return category.slugPath.split('#').map((slug: string) => {
      const categoryItem = _.find(categoryItems, { slug });
      return categoryItem ? getCategoryItemName(categoryItem, locale) : 'UNKNOWN CATEGORY';
    });
  }
  return [];
};

export const getCategoryItemName = (
  categoryItem: CategoryItem,
  lang: string = DEFAULT_LANGUAGE,
): string => {
  const name = categoryItem.name[lang];
  return name ? name : categoryItem.name[DEFAULT_LANGUAGE];
};
