import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import UserTable from '../../../components/App/User/UserTable';
import UserNewModal from '../../../components/App/User/UserNewModal';
import UserFilter from '../../../components/App/User/UserFilter';
import UserExportButton from '../../../components/App/User/UserExportButton';
import loadData from '../../../utils/loadData';
import { indexUser } from '../../../redux/modules/users';
import { createUser } from '../../../redux/modules/users';
import { User, UserData } from '../../../common/models/user';
import { isAdmin } from '../../../utils/userHelper';
import { ALL, NONE } from '../../../common/filter';

interface IUserFilter {
  name: string;
  role: string;
}

function selectUser(users: User[], filter: IUserFilter) {
  const { name, role } = filter;
  let usersFiltered = users;
  if (role !== ALL) {
    usersFiltered = _.filter(usersFiltered, { role });
  }
  if (name !== NONE) {
    const regexValue = new RegExp(
      _.words(name)
        .join(' ')
        .toLowerCase(),
    );
    usersFiltered = _.filter(usersFiltered, user => user.name.toLowerCase().match(regexValue));
  }
  return _.orderBy(usersFiltered, 'name');
}

const UserList: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const users = useSelector(state => state.users.data);
  const currentUser = useSelector(state => state.profile.data);
  const [isOpenUserNewModal, setIsOpenUserNewModal] = useState(false);
  const [userFilter, setUserFilter] = useState<IUserFilter>({
    name: NONE,
    role: ALL,
  });
  const usersFiltered = useMemo(() => selectUser(users, userFilter), [users, userFilter]);

  const openUserNewModal = e => {
    e.preventDefault();
    setIsOpenUserNewModal(true);
  };

  const closeUserNewModal = () => {
    setIsOpenUserNewModal(false);
  };

  const handleUserNew = (data: UserData) => {
    dispatch(createUser(data)).then(closeUserNewModal);
  };

  return (
    <div className="users-list">
      <PageNav>
        {isAdmin(currentUser) && (
          <div className="nav-secondary__right">
            <Button className="btn-lg btn-secondary btn-md-hide-label" onClick={openUserNewModal}>
              <i className="fa fa-plus m-r" />
              <span>{t('user.newUser')}</span>
            </Button>
            <UserNewModal
              user={{}}
              open={isOpenUserNewModal}
              close={closeUserNewModal}
              onSubmit={handleUserNew}
            />
            <UserExportButton />
          </div>
        )}

        <h2 className="nav-secondary__title">{t('user.users')}</h2>
      </PageNav>
      <PageContent>
        <div className="card bg-white m-t-lg">
          <div className="card-block">
            <div className="users__filters">
              <UserFilter currentUser={currentUser} onChange={setUserFilter} filter={userFilter} />
            </div>
          </div>
        </div>

        <div className="card bg-white m-t-lg">
          <div className="card-block">
            <UserTable users={usersFiltered} />
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default loadData(async dispatch => {
  await dispatch(indexUser());
})(UserList);
