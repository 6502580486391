import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Avatar from '../Avatar/Avatar';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { updateUser, expireUserPassword } from '../../../redux/modules/users';
import { User } from '../../../common/models/user';
import { APP_EDIT_USER_PATH } from '../../../utils/routeHelper';
import { isAdmin } from '../../../utils/userHelper';

interface UserTableProps {
  users: User[];
}

const UserTable: React.FC<UserTableProps> = ({ users }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.profile.data);

  const disableUser = (user: User) => {
    return () => {
      dispatch(updateUser(user._id, { disabled: true }));
    };
  };

  const expirePassword = (user: User) => {
    return () => {
      dispatch(expireUserPassword(user._id));
    };
  };

  const buildUserStatsTooltip = (user: User) => {
    const signInCount = user.signInStats ? user.signInStats.signInCount : 0;
    return (
      <Tooltip id={user._id}>
        <div>{t('user.countSignin', { signInCount })}</div>
        {user.signInStats && user.signInStats.currentSignInAt && (
          <div>
            {t('user.lastSigninAt')}
            {moment(user.signInStats.currentSignInAt).format(t('date.long'))}
          </div>
        )}
        <div>
          {t('user.lastPasswordChangedAt')}
          {moment(user.lastPasswordChangedAt).format(t('date.long'))}
        </div>
      </Tooltip>
    );
  };

  const buildUserList = () => {
    return users.map(user => (
      <tr key={user._id} className={user.disabled ? 'disabled-user' : ''}>
        <td>
          <Avatar filename={user.avatar} version="mini" width="60px" />
          <span>{user.name}</span>
          {user.disabled && <span className="label label-info">{t('user.disabled')}</span>}
        </td>
        <td>{user.email}</td>
        <td>{user.phone}</td>
        <td>
          {isAdmin(currentUser) && (
            <ButtonGroup className="pull-right">
              <OverlayTrigger placement="bottom" overlay={buildUserStatsTooltip(user)}>
                <a
                  href="#/"
                  onClick={e => e.preventDefault()}
                  className="list-action btn-link text-muted btn btn-default"
                >
                  <i className="fa fa-info-circle" />
                </a>
              </OverlayTrigger>
              {!user.disabled && (
                <React.Fragment>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`edit-${user._id}`}>{t('user.editUser')}</Tooltip>}
                  >
                    <Link
                      to={APP_EDIT_USER_PATH(user._id)}
                      className="list-action btn-link text-muted btn btn-default"
                    >
                      <i className="fa fa-edit" />
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`expire-password-${user._id}`}>
                        {t('user.expireUserPasswordTooltip')}
                      </Tooltip>
                    }
                  >
                    <ConfirmButton
                      className="list-action btn-link text-danger text-danger-hover btn btn-default"
                      onClick={expirePassword(user)}
                      message={t('user.expireUserPassword')}
                      confirmButtonStyle="danger"
                      element="a"
                    >
                      <i className="fa fa-clock-o" />
                    </ConfirmButton>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`disable-${user._id}`}>{t('user.disableUserTooltip')}</Tooltip>
                    }
                  >
                    <ConfirmButton
                      className="list-action btn-link text-danger text-danger-hover btn btn-default"
                      onClick={disableUser(user)}
                      message={t('user.disableThisUser')}
                      confirmButtonStyle="danger"
                      element="a"
                    >
                      <i className="fa fa-ban" />
                    </ConfirmButton>
                  </OverlayTrigger>
                </React.Fragment>
              )}
            </ButtonGroup>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped table-vcenter">
        <thead>
          <tr role="row">
            <th style={{ width: '20%' }}>{t('user.users')}</th>
            <th style={{ width: '40%' }}>{t('user.email')}</th>
            <th style={{ width: '20%' }}>{t('user.phone')}</th>
            <th style={{ width: '20%' }} />
          </tr>
        </thead>
        <tbody>{buildUserList()}</tbody>
      </table>
    </div>
  );
};

export default UserTable;
