import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import PlotlyChart from '../PlotlyChart/PlotlyChart';
import OEELegends from './OEELegends';
import OEECategorySelect from './OEECategorySelect';
import { isHaveData, getInvisibleLists } from '../../../utils/oeeHelper';
import { filterCategories } from '../../../common/utils/categoryHelper';

function buildLossChartData(
  data,
  categories,
  categoryItems,
  invisibleLists,
  color,
  locale,
  showText = false,
): any {
  if (!isHaveData(data)) {
    return [];
  }
  const traces: any[] = [];
  categories.forEach((category, i) => {
    let dataByKey: any = null;
    let name: any = null;
    let value: any = null;
    let isVisible: any = true;
    dataByKey = _.find(data, ['key', category.slugPath]);
    const categoryItem = _.find(categoryItems, { slug: category.categoryItemSlug });
    name = categoryItem.name[locale];
    isVisible = invisibleLists.indexOf(category.slugPath) === -1;
    if (dataByKey) {
      value = _.round(dataByKey.value);
    }
    let text = {};
    if (showText) {
      text = {
        text: [value],
        // HACK FIX: show value === 1 in print bug
        textposition: value <= 1 ? 'outside' : 'auto',
      };
    }
    traces.push({
      x: [name],
      y: [value],
      name,
      type: 'bar',
      marker: { color },
      visible: isVisible,
      category,
      ...text,
    });
  });
  return traces;
}

const barLayout = {
  height: 400,
  margin: { t: 50, b: 150, l: 25 },
  autosize: true,
  showlegend: false,
  barmode: 'group',
  font: {
    family: 'Montserrat, sans-serif',
  },
  hoverlabel: { namelength: -1 },
};

const LossChart: React.FC<any> = ({
  data,
  layout,
  plantAndLine,
  categories,
  categoryItems,
  lossType,
  loss,
  loading,
  color,
  aggregations,
  aggs,
  onCategoryChanged,
  print,
}) => {
  const { i18n } = useTranslation();
  const categoryParent = _.get(aggregations, `${aggs}.category`);
  let lossCategories = _.filter(categories, c => c.parent === categoryParent);
  lossCategories = plantAndLine.line
    ? filterCategories(lossCategories, plantAndLine.line.type, lossType, loss)
    : [];

  const updateCategory = (options: any = {}) => {
    return data => {
      let category = data;
      if (options.plotly) {
        category = data.points[0].data.category._id;
      }
      onCategoryChanged(category, aggs);
    };
  };

  return (
    <div>
      <PlotlyChart
        data={buildLossChartData(
          data,
          lossCategories,
          categoryItems,
          getInvisibleLists(aggs, aggregations),
          color,
          i18n.language,
          print,
        )}
        layout={_.merge({}, barLayout, layout)}
        loading={loading}
        onClick={updateCategory({ plotly: true })}
        titleSize={print ? 16 : 18}
      />
      {isHaveData(data) && !print && (
        <OEECategorySelect
          categories={categories}
          categoryItems={categoryItems}
          categoryId={categoryParent}
          subCategories={lossCategories}
          onChange={updateCategory()}
          printMode={print}
        />
      )}
      {isHaveData(data) && !print && (
        <OEELegends
          legends={lossCategories}
          categoryItems={categoryItems}
          aggregations={aggregations}
          aggs={aggs}
          categoryColor={color}
        />
      )}
    </div>
  );
};

export default LossChart;
