import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import ProfilePage, { ProfileNav } from '../../../components/App/User/ProfilePage';
import PasswordForm from '../../../components/App/User/PasswordForm';
import { changePassword } from '../../../redux/modules/profile';
import { APP_PATH } from '../../../utils/routeHelper';

const Password: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile.data);
  const [passwordError, setPasswordError] = useState('');
  const { t } = useTranslation();

  const handleChangePassword = data => {
    dispatch(changePassword(data))
      .then(res => {
        if (res.type.match(/SUCCESS/)) {
          history.push(APP_PATH);
        }
      })
      .catch(e => {
        let err = e.error.response.data.name || e.error.response.data.message;
        if (err === 'IncorrectPasswordError') {
          err = 'password.passwordIncorrect';
        }
        setPasswordError(err);
      });
  };

  const handleCancel = () => {
    history.push(APP_PATH);
  };

  return (
    <ProfilePage active={ProfileNav.changePassword}>
      {profile.passwordExpired && <Alert bsStyle="danger">{t('password.passwordExpired')}</Alert>}
      {passwordError && <Alert bsStyle="danger">{t(passwordError)}</Alert>}
      <PasswordForm data={{}} onSubmit={handleChangePassword} onCancel={handleCancel} />
    </ProfilePage>
  );
};

export default withRouter(Password);
