import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import getHeaders from '../../../utils/getHeaders';
import { plantDescription } from '../../../utils/plantHelper';
import { API_UPDATE_PLANT_PHOTO_PATH } from '../../../utils/routeHelper';
import PlantForm from './PlantForm';
import ContactsForm from './ContactsForm';
import UploadPhotos from '../Uploader/UploadPhotos';
import PlantPhotos from './PlantPhotos';
import PlantLines from './Line/PlantLines';
import { identifier } from '../../../common/models/model';

class PlantInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: 'basicInfo',
      photosCategory: 'photos',
    };
    this.changePhotosCategory = this.changePhotosCategory.bind(this);
    this.handleUploadPhotosSuccess = this.handleUploadPhotosSuccess.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.setDefaultPhoto = this.setDefaultPhoto.bind(this);
    this.updatePhoto = this.updatePhoto.bind(this);
  }

  getPlantInfoView() {
    switch (this.state.config) {
      case 'basicInfo':
        return this.basicInfoView();
      case 'photos':
        return this.photosView();
      case 'contacts':
        return this.contactsView();
      case 'attachments':
        return this.attachmentsView();
      default:
        return this.basicInfoView();
    }
  }

  basicInfoView() {
    // eslint-disable-line react/sort-comp
    const { plant, businessUnits, regions, onSubmitBasicInfo } = this.props;
    plant.description = plantDescription(plant);
    return (
      <PlantForm
        data={plant}
        businessUnits={businessUnits}
        regions={regions}
        onSubmit={onSubmitBasicInfo}
      />
    );
  }

  handleSubmitContacts() {
    return data => {
      this.props.onSubmitByType('contacts', data);
    };
  }

  contactsView() {
    const { plant } = this.props;
    return (
      <ContactsForm
        data={plant.contacts || {}}
        users={this.props.users}
        onSubmit={this.handleSubmitContacts()}
      />
    );
  }

  photosView() {
    let headers = {};
    if (this.props.auth) {
      headers = getHeaders(this.props.auth, false);
    }
    const category = this.state.photosCategory;
    const params = { category: this.state.photosCategory };
    const { t } = this.props;
    return (
      <div>
        <Tabs activeKey={category} onSelect={this.changePhotosCategory} id="photos-view-filter">
          <Tab eventKey="photos" title={t('plant.photos')} />
          <Tab eventKey="diagrams" title={t('plant.diagrams')} />
        </Tabs>
        {/* must destroy and remontre UploadPhotos when change category,
             because DropzoneComponent has a bug that cant dynamically update djsConfig.
          */}
        {category === 'photos' && (
          <UploadPhotos
            postUrl={API_UPDATE_PLANT_PHOTO_PATH(this.props.plant[identifier])}
            djsConfig={{ headers, params }}
            onSuccess={this.handleUploadPhotosSuccess}
          />
        )}
        {category === 'diagrams' && (
          <UploadPhotos
            postUrl={API_UPDATE_PLANT_PHOTO_PATH(this.props.plant[identifier])}
            djsConfig={{ headers, params }}
            onSuccess={this.handleUploadPhotosSuccess}
          />
        )}
        <PlantPhotos
          className="m-t-md"
          plant={this.props.plant || {}}
          category={category}
          onDelete={this.deletePhoto}
          onSetDefault={this.setDefaultPhoto}
          onUpdate={this.updatePhoto}
        />
      </div>
    );
  }

  changePhotosCategory(photosCategory) {
    if (photosCategory) {
      this.setState({
        photosCategory,
      });
    }
  }

  deletePhoto(photo, category) {
    this.props.onDeletePhoto({ photo, category });
  }

  setDefaultPhoto(photo) {
    this.props.onSetDefaultPhoto({ defaultPhoto: photo });
  }

  updatePhoto(data) {
    this.props.onPhotoUpdate(data);
  }

  handleUploadPhotosSuccess() {
    this.props.onUploadPhotosSuccess();
  }

  attachmentsView() {
    return (
      <PlantLines
        plant={this.props.plant || { attachments: [] }}
        onAddLine={this.props.onAddLine}
        onUpdateLine={this.props.onUpdateLine}
        onUpdateLinePosition={this.props.onUpdateLinePosition}
      />
    );
  }

  selectInfo(config) {
    return e => {
      e.preventDefault();
      this.setState({ config });
    };
  }

  render() {
    const { t } = this.props;
    const className = config => {
      return classNames({ active: config === this.state.config });
    };
    return (
      <Row className="tabs tabs--primary">
        <Col md={3} className="tabs-nav">
          <ul>
            <li className={className('basicInfo')}>
              <a href="basicInfo" onClick={this.selectInfo('basicInfo')}>
                {t('plant.basicInfo')}
              </a>
            </li>
            <li className={`${className('photos')} hidden`}>
              <a href="photos" onClick={this.selectInfo('photos')}>
                {t('plant.photos')}
              </a>
            </li>
            <li className={`${className('contacts')} hidden`}>
              <a href="contacts" onClick={this.selectInfo('contacts')}>
                {t('plant.contacts')}
              </a>
            </li>
            <li className={className('attachments')}>
              <a href="lines" onClick={this.selectInfo('attachments')}>
                {t('plant.lines')}
              </a>
            </li>
          </ul>
        </Col>
        <Col md={9} className="tabs-content">
          {this.getPlantInfoView()}
        </Col>
      </Row>
    );
  }
}

PlantInfo.propTypes = {
  plant: PropTypes.object,
  businessUnits: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  onSubmitBasicInfo: PropTypes.func.isRequired,
  onUploadPhotosSuccess: PropTypes.func.isRequired,
  onSubmitByType: PropTypes.func.isRequired,
  auth: PropTypes.object,
  onSetDefaultPhoto: PropTypes.func.isRequired,
  onDeletePhoto: PropTypes.func.isRequired,
  onPhotoUpdate: PropTypes.func.isRequired,
  onAddLine: PropTypes.func.isRequired,
  onUpdateLine: PropTypes.func.isRequired,
};

export default withTranslation()(PlantInfo);
