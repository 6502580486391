import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import RegionTable from '../../../components/App/Region/RegionTable';
import RegionModal from '../../../components/App/Region/RegionModal';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import SelectFilter from '../../../components/App/Filter/SelectFilter';
import { createRegion } from '../../../redux/modules/regions';
import { indexBusinessUnit } from '../../../redux/modules/businessUnits';
import loadData from '../../../utils/loadData';
import { ALL } from '../../../common/filter';

export const RegionList: React.FC = () => {
  const dispatch = useDispatch();
  const businessUnits = useSelector(state => state.businessUnits.data);
  const { t } = useTranslation();
  const [isOpenRegionModal, setIsOpenRegionModal] = useState(false);
  const [businessUnitId, setBusinessUnitId] = useState('');

  const buildBusinessUnitOptions = () => {
    const buOptions = businessUnits.map(bu => {
      return { value: bu._id, label: bu.name };
    });
    buOptions.unshift({ value: ALL, label: t('region.allBusinessUnits') });
    return buOptions;
  };

  const handleRegionNew = data => {
    dispatch(createRegion(data)).then(closeRegionModal);
  };

  const handleFilter = buId => {
    setBusinessUnitId(buId);
  };

  const openRegionModal = e => {
    e.preventDefault();
    setIsOpenRegionModal(true);
  };

  const closeRegionModal = () => {
    setIsOpenRegionModal(false);
  };

  return (
    <div className="regions-list">
      <PageNav>
        <div className="nav-secondary__right">
          <Button className="btn-lg btn-secondary btn-md-hide-label" onClick={openRegionModal}>
            <i className="fa fa-plus m-r" />
            <span>{t('region.newRegion')}</span>
          </Button>
          <RegionModal
            region={{}}
            businessUnits={businessUnits}
            open={isOpenRegionModal}
            close={closeRegionModal}
            onSubmit={handleRegionNew}
            users={[]}
          />
        </div>

        <h2 className="nav-secondary__title">{t('region.regions')}</h2>
      </PageNav>
      <PageContent>
        <div className="card bg-white m-t-lg">
          <div className="card-block">
            <div className="card-header">
              <div className="filter-group">
                <div className="filter-label">{t('region.filterByBusinessUnit')} </div>
                <SelectFilter
                  options={buildBusinessUnitOptions()}
                  onChange={handleFilter}
                  value={businessUnitId}
                />
              </div>
            </div>
            <RegionTable businessUnits={businessUnits} businessUnitId={businessUnitId} />
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default loadData(async dispatch => {
  await dispatch(indexBusinessUnit());
})(RegionList);
