import policyCreator from './policy_creator';
import { Role, User } from '../models/user';

const plantPolicies = {
  create: function create(user: User) {
    return user.role === Role.admin;
  },

  edit: function edit(user: User) {
    return user.role === Role.admin;
  },

  destroy: function destroy(user: User) {
    return user.role === Role.admin;
  },
};

export default policyCreator(plantPolicies);
