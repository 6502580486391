import React from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, Select as FormsySelect } from 'formsy-react-components';
import { withTranslation } from 'react-i18next';
//import { REGION_CONTACTS_ROLE } from '../../../common/models/region';
import BaseForm from '../Form/BaseForm';

export class RegionForm extends BaseForm {
  constructor(props) {
    super(props);
    this.businessUnitOptions = this.businessUnitOptions.bind(this);
  }

  handleSubmit(data) {
    const formData = Object.assign({}, data);
    // reset value to null if it is empty, it allows remove its value in DB
    //REGION_CONTACTS_ROLE.forEach(role => {
    //if (formData[role] === '' || formData[role] === undefined) {
    //formData[role] = null;
    //}
    //});
    this.props.onSubmit(formData);
  }

  businessUnitOptions() {
    const buOptions = this.props.businessUnits.map(bu => ({ value: bu._id, label: bu.name }));
    buOptions.unshift({ value: '', label: this.props.t('region.selectABusinessUnit') });
    return buOptions;
  }

  /**
   * Parse the list of users (provided in this.props.users) and return an "Select->options"-compatible list
   * with labels and values.
   * @return {Array<{value:string, label:string}>}
   */
  contactsOptions() {
    return this.props.users.map(u => ({ value: u._id, label: u.name }));
  }

  render() {
    const { t } = this.props;
    return (
      <Row>
        <Col lg={12}>
          <Form
            onSubmit={this.handleSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="name"
              value={this.props.data.name}
              label={t('region.name')}
              type="text"
              placeholder={t('region.regionName')}
              validations={'maxLength:255'}
              validationError={t('errors.inputTextMaxLength', { max: 255 })}
              required
            />

            <FormsySelect
              className="c-select form-control"
              layout="vertical"
              name="businessUnitId"
              value={this.props.data.businessUnitId}
              label={t('businessUnit.businessUnit')}
              options={this.businessUnitOptions()}
              required
            />

            {[].map(role => (
              <FormsySelect
                className="c-select form-control"
                layout="vertical"
                key={role}
                name={role}
                value={this.props.data[role]}
                label={t(`region.${role}`)}
                options={this.contactsOptions()}
              />
            ))}

            <div className="text-center m-t-md">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                formNoValidate
                disabled={!this.state.canSubmit}
              >
                {t('common.save')}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

RegionForm.propTypes = {
  users: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(RegionForm);
