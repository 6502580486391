export default function getHeaders(auth: { accessToken: string }, json: boolean = true): object {
  const headers = json
    ? {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      }
    : {};
  return Object.assign(headers, {
    Authorization: `Bearer ${auth.accessToken}`,
  });
}
