import _ from 'lodash';
import { convertFromRaw } from 'draft-js';
import { DEFAULT_LANGUAGE } from '../common/i18n';

function _isEmpty(body) {
  if (!body) {
    return true;
  }
  if (!body.entityMap) {
    body.entityMap = {}; // eslint-disable-line no-param-reassign
  }
  const contentState = convertFromRaw(body);
  return _.trim(contentState.getPlainText()).length === 0;
}

export function isPostBodyEmpty(post, locale) {
  if (!post.body) {
    return true;
  }
  return _isEmpty(post.body[locale]);
}

export function getPostBody(post, locale) {
  if (isPostBodyEmpty(post, locale)) {
    return undefined;
  }
  return post.body[locale];
}

export function shouldDisplayPostBody(post, locale) {
  return !isPostBodyEmpty(post, locale) || !isPostBodyEmpty(post, DEFAULT_LANGUAGE);
}

export function displayPostBody(post, locale) {
  if (!isPostBodyEmpty(post, locale)) return post.body[locale];
  if (!isPostBodyEmpty(post, DEFAULT_LANGUAGE)) return post.body[DEFAULT_LANGUAGE];
  return null;
}
