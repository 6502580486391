import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';
import fp from 'lodash/fp';
import moment from 'moment';
import OEEInputAvailabilityThead from './OEEInputAvailability/OEEInputAvailabilityThead';
import OEEInputWasteTreatedThead from './OEEInputWasteTreated/OEEInputWasteTreatedThead';
import OEEInputAvailability from './OEEInputAvailability/OEEInputAvailability';
import OEEInputWasteTreated from './OEEInputWasteTreated/OEEInputWasteTreated';
import Loader from '../Loader/Loader';
import { createOEEInput, updateOEEInput } from '../../../redux/modules/oeeInputs';
import { OEEFilterState } from '../../../redux/modules/oeeFilter';
import { PlantAndLine } from '../../../redux/modules/plants';
import { getObjectiveSelector } from '../../../redux/modules/objectives';
import { OEEInputType, OEEInput, OEE_INPUT_OPENING_TIME } from '../../../common/models/oeeInput';
import { User, UserPolicies } from '../../../common/models/user';
import { Category } from '../../../common/models/category';
import { CategoryItem } from '../../../common/models/categoryItem';
import { Objective } from '../../../common/models/objective';
import { NONE } from '../../../common/filter';
import { Plant } from '../../../common/models/plant';
import { isAuthorizeResource } from '../../../utils/userHelper';
import { oeeInputIsInRange } from '../../../common/utils/oeeInputHelper';
import EditOpeningTimeModal from './EditOpeningTimeModal';
import { plantLineShifts } from '../../../utils/plantHelper';

interface OEEInputTableProps extends WithTranslation {
  currentUser: User;
  data: OEEInput[];
  filter: OEEFilterState;
  loading: boolean;
  createOEEInput: Function;
  updateOEEInput: Function;
  oeeInputType: OEEInputType;
  plants: Plant[];
  plantAndLine: PlantAndLine;
  categories: Category[];
  categoryItems: CategoryItem[];
  objective?: Objective;
}

function buildLoading(colSpan) {
  return (
    <tbody className="oee-input__group">
      <tr>
        <td className="oee-input__cell cell-invisible" />
        <td className="oee-input__cell p-t-lg p-b-lg text-center" colSpan={colSpan}>
          <Loader />
        </td>
      </tr>
    </tbody>
  );
}

function buildNoDataTable(colSpan, t) {
  return (
    <tbody className="oee-input__group">
      <tr>
        <td className="oee-input__cell cell-invisible" />
        <td className="oee-input__cell p-t-lg p-b-lg" colSpan={colSpan}>
          <h5 className="text-center m-t-lg m-b-lg">{t('OEEInput.pleaseSelectAPlant')}</h5>
        </td>
      </tr>
    </tbody>
  );
}

class OEEInputTable extends PureComponent<OEEInputTableProps, any> {
  constructor(props: OEEInputTableProps) {
    super(props);
    this.state = {
      openEditOpeningTimeAlert: false,
    };
    this.handleSaveOEEInput = this.handleSaveOEEInput.bind(this);
  }

  handleSaveOEEInput(oeeInput) {
    if (oeeInput._id) {
      return this.props.updateOEEInput(oeeInput._id, oeeInput);
    }
    return this.props.createOEEInput(oeeInput);
  }

  getOEEInputs() {
    const { data } = this.props;
    const { plantId, line, date, shift } = this.props.filter;
    if (plantId === NONE || line === NONE) {
      return [];
    }
    const oeeInputs: any[] = [];
    const now = moment();
    const daysInMonth =
      date.getFullYear() === now.year() && date.getMonth() === now.month()
        ? now.date()
        : moment(date).daysInMonth();
    for (let d = 1; d <= daysInMonth; d++) {
      const oeeDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), d)).toISOString();
      const q: any = { plantId, line, date: oeeDate };
      if (shift) q.shift = shift;
      const oeeInput = _.find(data, q) || {
        ...q,
        availability: {
          stopDetail: [{}],
        },
        wasteTreated: {
          lossDetail: [{}],
        },
      };
      oeeInputs.push(oeeInput);
    }
    return oeeInputs;
  }

  prevShiftOEEInput(date, shift, shifts) {
    const { data } = this.props;
    let prevDate, prevShift;
    if (shifts.length === 1) {
      prevDate = moment.utc(date).subtract(1, 'day');
      prevShift = shift;
    } else {
      const i = shifts.indexOf(shift);
      if (i === 0) {
        prevDate = moment.utc(date).subtract(1, 'day');
        prevShift = shifts[shifts.length - 1];
      } else {
        prevDate = moment.utc(date);
        prevShift = shifts[i - 1];
      }
    }
    const _prevShiftOEEInput = _.find(data, { date: prevDate.toISOString(), shift: prevShift });
    if (_prevShiftOEEInput && _.isFinite(_prevShiftOEEInput.availability.openingTime)) {
      if (_prevShiftOEEInput.availability.openingTime === 0) {
        return this.prevShiftOEEInput(prevDate.toDate(), prevShift, shifts);
      }
      return _prevShiftOEEInput;
    }
    return;
  }

  nextShiftOEEInput(date, shift, shifts) {
    const { data } = this.props;
    let nextDate, nextShift;
    if (shifts.length === 1) {
      nextDate = moment.utc(date).add(1, 'day');
      nextShift = shift;
    } else {
      const i = shifts.indexOf(shift);
      if (i < shifts.length - 1) {
        nextDate = moment.utc(date);
        nextShift = shifts[i + 1];
      } else {
        nextDate = moment.utc(date).add(1, 'day');
        nextShift = shifts[0];
      }
    }
    const _nextShiftOEEInput = _.find(data, { date: nextDate.toISOString(), shift: nextShift });
    if (_nextShiftOEEInput && _.isFinite(_nextShiftOEEInput.availability.openingTime)) {
      if (_nextShiftOEEInput.availability.openingTime === 0) {
        return this.nextShiftOEEInput(nextDate.toDate(), nextShift, shifts);
      }
      return _nextShiftOEEInput;
    }
    return;
  }

  getSiblings(currentDate) {
    const { data, plants } = this.props;
    const { plantId, line, shift } = this.props.filter;
    // shift
    let prevShiftOEEInput, nextShiftOEEInput, otherShiftOEEInputs;
    if (shift) {
      const shifts = plantLineShifts(plants, plantId, line);
      prevShiftOEEInput = this.prevShiftOEEInput(currentDate, shift, shifts);
      nextShiftOEEInput = this.nextShiftOEEInput(currentDate, shift, shifts);

      // other shift oeeinputs in same date
      otherShiftOEEInputs = _.filter(data, o => {
        return (
          o.shift !== shift &&
          o.plantId === plantId &&
          o.line === line &&
          o.date === moment.utc(currentDate).toISOString()
        );
      });
    }
    // line
    const prevDate = moment.utc(currentDate).subtract(1, 'day');
    const baseOEEInputQuery: any = { plantId, line };
    if (shift) {
      baseOEEInputQuery.shift = shift;
    }
    const prevOEEInput = _.find(
      data,
      Object.assign({ date: prevDate.toISOString() }, baseOEEInputQuery),
    );
    const nextDate = moment.utc(currentDate).add(1, 'day');
    const nextOEEInput = _.find(
      data,
      Object.assign({ date: nextDate.toISOString() }, baseOEEInputQuery),
    );
    return {
      prevOEEInput,
      nextOEEInput,
      prevShiftOEEInput,
      nextShiftOEEInput,
      otherShiftOEEInputs,
    };
  }

  canEdit(oeeInput, siblings): boolean {
    const {
      currentUser,
      plantAndLine: { plant, line },
      oeeInputType,
      objective,
    } = this.props;
    if (!line || !plant) {
      return false;
    }
    if (oeeInputType === OEEInputType.wasteTreated && !objective) {
      return false;
    }
    const inRange = oeeInputIsInRange(oeeInput.date, line);
    const isPermit = isAuthorizeResource(currentUser, UserPolicies.oeeWrite, plant._id);
    return inRange && isPermit;
  }

  buildTableThead() {
    const { oeeInputType, plantAndLine } = this.props;
    if (oeeInputType === OEEInputType.wasteTreated) {
      return <OEEInputWasteTreatedThead plantAndLine={plantAndLine} />;
    }
    return <OEEInputAvailabilityThead plantAndLine={plantAndLine} />;
  }

  buildTableBody() {
    const { oeeInputType, plantAndLine, categories, categoryItems, objective } = this.props;
    return this.getOEEInputs().map((oeeInput, i) => {
      const siblings = this.getSiblings(oeeInput.date);
      if (oeeInputType === OEEInputType.wasteTreated) {
        return (
          <OEEInputWasteTreated
            key={i}
            data={oeeInput}
            plantAndLine={plantAndLine}
            siblings={siblings}
            saveOEEInput={this.handleSaveOEEInput}
            canEdit={this.canEdit(oeeInput, siblings)}
            categories={categories}
            categoryItems={categoryItems}
            objective={objective}
          />
        );
      }
      return (
        <OEEInputAvailability
          key={i}
          data={oeeInput}
          plantAndLine={plantAndLine}
          siblings={siblings}
          saveOEEInput={this.handleSaveOEEInput}
          canEdit={this.canEdit(oeeInput, siblings)}
          categories={categories}
          categoryItems={categoryItems}
          objective={objective}
          toggleOpenEditOpeningTimeAlert={this.toggleOpenEditOpeningTimeAlert}
        />
      );
    });
  }

  toggleOpenEditOpeningTimeAlert = (updateOpeningTime: Function) => {
    this.setState({
      openEditOpeningTimeAlert: !this.state.openEditOpeningTimeAlert,
      updateOpeningTime,
    });
  };

  render() {
    const {
      oeeInputType,
      loading,
      plantAndLine: { plant, line, shift },
      filter,
      objective,
      t,
    } = this.props;
    const colSpan = oeeInputType === OEEInputType.wasteTreated ? 7 : 8;
    return (
      <div className="table-responsive">
        {oeeInputType === OEEInputType.wasteTreated && !objective && line && (
          <Alert bsStyle="warning" className="m-l-lg">
            {t('objective.missObjectiveFor', { line: line.name, year: filter.date.getFullYear() })}
          </Alert>
        )}
        {oeeInputType === OEEInputType.wasteTreated && objective && line && (
          <p className="objective-for-year">
            {t('objective.objectiveForYear', {
              year: filter.date.getFullYear(),
              value: shift
                ? _.round(objective.value, 1)
                : _.round(OEE_INPUT_OPENING_TIME * objective.value, 1),
              unit: t(`objective.${shift ? 'objectiveUnit' : 'objectiveUnitDay'}`, {
                lineUnit: t(`plant.lineUnits.${line.unit}`),
              }),
            })}
          </p>
        )}
        <table className="oee-input__table">
          {this.buildTableThead()}
          {!loading && this.buildTableBody()}
          {loading && buildLoading(colSpan)}
          {(!plant || !line) && buildNoDataTable(colSpan, t)}
        </table>
        <EditOpeningTimeModal
          open={this.state.openEditOpeningTimeAlert}
          close={this.toggleOpenEditOpeningTimeAlert}
          onSubmit={this.state.updateOpeningTime}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.profile.data,
    data: state.oeeInputs.data,
    filter: state.oeeFilter,
    loading: state.oeeInputs.loading,
    objective: getObjectiveSelector(state),
  };
};

const mapDispatchToProps = {
  createOEEInput,
  updateOEEInput,
};

export default fp.compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(OEEInputTable);
