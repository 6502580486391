import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './Home';
import Signin from './Signin';
import ResetPassword from './ResetPassword';
import App from './App/App';
import AppHome from './App/AppHome';
import EditProfile from './App/User/EditProfile';
import Password from './App/User/Password';
import { isAuthenticated } from '../redux/modules/auth';
import * as routeHelper from '../utils/routeHelper';
import NewsList from './App/News/NewsList';
import NewsEdit from './App/News/NewsEdit';
import NewsCreate from './App/News/NewsCreate';
import BUList from './App/BusinessUnit/BUList';
import RegionList from './App/Region/RegionList';
import Categories from './App/Category/Categories';
import PlantList from './App/Plant/PlantList';
import PlantEdit from './App/Plant/PlantEdit';
import UserList from './App/User/UserList';
import UserEdit from './App/User/UserEdit';
import OEEInputList from './App/OEEInput/OEEInputList';
import OEEDashboard from './App/OEEDashboard/OEEDashboard';
import Print from './Print/Print';
import PrintOEEDashboard from './Print/PrintOEEDashboard';
import loadData from '../utils/loadData';
import { isAdmin } from '../utils/userHelper';
import { getProfile } from '../redux/modules/profile';

const AppRoutes: React.FC<RouteComponentProps> = ({ match }) => {
  const currentUser = useSelector(
    state => state.profile.data,
    (prevData, nextData) => {
      return prevData._id === nextData._id && prevData.role === nextData.role;
    },
  );
  return (
    <App>
      <Route path={match.url} exact component={AppHome} />
      <Route path={`${match.url}${routeHelper.PROFILE_PATH}`} exact component={EditProfile} />
      <Route path={`${match.url}${routeHelper.PROFILE_PASSWORD_PATH}`} exact component={Password} />
      <Route path={`${match.url}${routeHelper.USERS_PATH}`} exact component={UserList} />
      <Route path={`${match.url}${routeHelper.NEWS_PATH}`} exact component={NewsList} />
      <Route path={`${match.url}${routeHelper.OEE_INPUT_PATH}`} exact component={OEEInputList} />
      <Route
        path={`${match.url}${routeHelper.OEE_DASHBOARD_PATH}`}
        exact
        component={OEEDashboard}
      />
      {isAdmin(currentUser) && (
        <Fragment>
          <Route
            path={`${match.url}${routeHelper.CREATE_NEWS_PATH}`}
            exact
            component={NewsCreate}
          />
          <Route path={`${match.url}${routeHelper.EDIT_NEWS_PATH}`} exact component={NewsEdit} />
          <Route path={`${match.url}${routeHelper.EDIT_USER_PATH}`} exact component={UserEdit} />
          <Route path={`${match.url}${routeHelper.BUSINESS_UNIT_PATH}`} exact component={BUList} />
          <Route path={`${match.url}${routeHelper.REGION_PATH}`} exact component={RegionList} />
          <Route path={`${match.url}${routeHelper.CATEGORY_PATH}`} exact component={Categories} />
          <Route path={`${match.url}${routeHelper.PLANTS_PATH}`} exact component={PlantList} />
          <Route path={`${match.url}${routeHelper.EDIT_PLANT_PATH}`} exact component={PlantEdit} />
        </Fragment>
      )}
    </App>
  );
};

const AppRoutesWithData = loadData(async dispatch => {
  await dispatch(getProfile());
})(AppRoutes);

interface AuthRouteProps {
  path: string;
  component: React.FC<RouteComponentProps>;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ path, component }) => {
  const auth = useSelector(state => state.auth);

  return (
    <Route
      path={path}
      render={props => {
        return isAuthenticated(auth) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: routeHelper.SIGNIN_PATH,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

const PrintRoutes: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <Print>
      <Route
        path={`${match.url}${routeHelper.OEE_DASHBOARD_PATH}`}
        exact
        component={PrintOEEDashboard}
      />
    </Print>
  );
};

const Routes: React.FC = () => {
  return (
    <Router>
      <Route path={routeHelper.HOME_PATH} exact component={Home} />
      <Route path={routeHelper.SIGNIN_PATH} exact component={Signin} />
      <Route path={routeHelper.RESET_PASSWORD_PATH} component={ResetPassword} />
      <Route path={routeHelper.PRINT_PATH} component={PrintRoutes} />
      <AuthRoute path={routeHelper.APP_PATH} component={AppRoutesWithData} />
    </Router>
  );
};

export default Routes;
