import moment from 'moment';
import { NONE, ALL } from '../../common/filter';
import { SIGNOUT } from './auth';

export const UPDATE_OEE_FILTER = 'oee_filter/UPDATE_OEE_FILTER';

export interface OEEFilterState {
  businessUnitId: string;
  plantId: string;
  line: string;
  shift?: string;
  date: Date;
  showArchived: boolean;
  showShift: boolean;
}

const current = moment();
const initialState: OEEFilterState = {
  businessUnitId: ALL,
  plantId: NONE,
  line: NONE,
  shift: NONE,
  date: current.toDate(),
  showArchived: false,
  showShift: false,
};

export default function reducer(
  state: OEEFilterState = initialState,
  action: ActionResult,
): OEEFilterState {
  switch (action.type) {
    case UPDATE_OEE_FILTER:
      return {
        ...state,
        ...action.preload,
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function updateOEEFilter(filter: OEEFilterState): ActionPreload {
  return {
    type: UPDATE_OEE_FILTER,
    preload: filter,
  };
}

export const oeeFilterSelector = state => state.oeeFilter;
