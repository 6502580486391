import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import RegionModal from '../Region/RegionModal';
import { indexRegion, updateRegion, deleteRegion } from '../../../redux/modules/regions';
import { BusinessUnit } from '../../../common/models/businessUnit';
import { Region } from '../../../common/models/region';
import loadData from '../../../utils/loadData';

interface RegionTableProps {
  businessUnits: BusinessUnit[];
  businessUnitId?: string;
}

const RegionTable: React.FC<RegionTableProps> = ({ businessUnits, businessUnitId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenRegionModal, setIsOpenRegionModal] = useState(false);
  const [region, setRegion] = useState({} as Region);
  const regions = useSelector(state => state.regions.data);
  const regionsFiltred = useMemo(() => {
    if (businessUnitId && businessUnitId !== 'all') {
      return _.filter(regions, { businessUnitId });
    }
    return regions;
  }, [regions, businessUnitId]);

  const editRegion = region => {
    return () => {
      setRegion(region);
      setIsOpenRegionModal(true);
    };
  };

  const closeRegionModal = () => {
    setIsOpenRegionModal(false);
  };

  const handleRegionEdit = data => {
    dispatch(updateRegion(region._id, data)).then(closeRegionModal);
  };

  const buildRegionList = () => {
    return regionsFiltred.map(region => {
      return (
        <tr key={region._id}>
          <td>
            <div className="td-container">
              {region.name}
              <ButtonGroup className="pull-right show-hover">
                <Button className="list-action btn-link text-muted" onClick={editRegion(region)}>
                  <i className="fa fa-edit" />
                </Button>
                <ConfirmButton
                  className="list-action btn-link text-danger text-danger-hover"
                  onClick={() => dispatch(deleteRegion(region._id))}
                  message={t('region.removeThisRegion')}
                  confirmButtonStyle="danger"
                >
                  <i className="fa fa-trash-o" />
                </ConfirmButton>
              </ButtonGroup>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <table
        className="table table-striped table-bordered table-hover dataTable no-footer"
        role="grid"
      >
        <thead>
          <tr role="row">
            <th>{t('region.name')}</th>
          </tr>
        </thead>
        <tbody>{buildRegionList()}</tbody>
      </table>
      <RegionModal
        region={region}
        businessUnits={businessUnits}
        open={isOpenRegionModal}
        close={closeRegionModal}
        onSubmit={handleRegionEdit}
        users={[]}
      />
    </div>
  );
};

export default loadData(async dispatch => {
  await dispatch(indexRegion());
})(RegionTable);
