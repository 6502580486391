import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash';
import OEEInputHelpPopover from '../OEEInputHelpPopover';
import { PlantAndLine } from '../../../../redux/modules/plants';

interface OEEInputAvailabilityTheadProps {
  plantAndLine: PlantAndLine;
}

const OEEInputAvailabilityThead: React.FC<OEEInputAvailabilityTheadProps> = ({ plantAndLine }) => {
  const { t } = useTranslation();
  const shiftName = plantAndLine.shift ? t('plant.shiftN', { n: _.last(plantAndLine.shift) }) : '';
  return (
    <thead>
      <tr role="row">
        <th style={{ width: '30px' }} className="cell-invisible" />
        <th style={{ width: '98px' }}>{t('OEEInput.date')}</th>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover(plantAndLine.shift ? 'shiftOpeningTime' : 'openingTime')}
        >
          <th style={{ width: '100px' }} className={plantAndLine.shift ? 'help' : 'help hidden'}>
            {t(plantAndLine.shift ? 'OEEInput.shiftOpeningTime' : 'OEEInput.openingTime', {
              shift: shiftName,
            })}
            <div>[{t('OEEInput.openingTimeUnit')}]</div>
          </th>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover(plantAndLine.shift ? 'shiftStopTotal' : 'stopTotal')}
        >
          <th style={{ width: '100px' }} className="help">
            {t(plantAndLine.shift ? 'OEEInput.shiftStopTotal' : 'OEEInput.stopTotal', {
              shift: shiftName,
            })}
            <div>[{t('OEEInput.stopTotalUnit')}]</div>
          </th>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover(plantAndLine.shift ? 'shiftStopDetail' : 'stopDetail')}
        >
          <th style={{ width: '100px' }} className="help">
            {t(plantAndLine.shift ? 'OEEInput.shiftStopDetail' : 'OEEInput.stopDetail', {
              shift: shiftName,
            })}
            <div>[{t('OEEInput.stopDetailUnit')}]</div>
          </th>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover('planned')}
        >
          <th style={{ width: '100px' }} className="help">
            {t('OEEInput.planned')}
            <div>[{t('OEEInput.plannedOptions')}]</div>
          </th>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover(plantAndLine.shift ? 'shiftStopProlonged' : 'stopProlonged')}
        >
          <th style={{ width: '70px' }} className="help">
            {t('OEEInput.stopProlonged')}
          </th>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={OEEInputHelpPopover('reason')}
        >
          <th style={{ width: '40%' }} className="help">
            {t('OEEInput.reason')}
          </th>
        </OverlayTrigger>
        <th style={{ width: '105px' }}>{t('OEEInput.comments')}</th>
        <th style={{ width: '50px' }} />
      </tr>
    </thead>
  );
};

export default React.memo(OEEInputAvailabilityThead);
