import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { exportUser } from '../../../redux/modules/users';

const UserExportButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleExportXlsx = () => {
    dispatch(exportUser());
  };

  const xlsxExportTooltip = <Tooltip id="exportXlsTooltip">{t('common.exportToXlsx')}</Tooltip>;
  return (
    <OverlayTrigger placement="bottom" overlay={xlsxExportTooltip}>
      <Button
        className="btn btn-secondary btn-md-hide-label m-r btn-round m-l"
        onClick={handleExportXlsx}
      >
        <i className="fa fa-file-excel-o" aria-hidden="true" />
      </Button>
    </OverlayTrigger>
  );
};

export default UserExportButton;
