import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

export const downloadFile = (response: AxiosResponse, filename: string) => {
  const file = new Blob([response.data]);
  FileSaver.saveAs(file, filename);
};

export const queryToParams = (query: any): string => {
  return Object.keys(query)
    .map(k => {
      return `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`;
    })
    .join('&');
};
