import { Role, User, UserPolicies } from '../common/models/user';
import { hasIdInUserPoliciesByType } from '../common/policies';

export function avatarUrl(filename: string, version: string): string {
  const file = filename.split('.');
  switch (version) {
    case 'mini':
      return `${process.env.REACT_APP_API_ROOT}/uploads/avatar/${file[0]}_mini.${
        file[1]
      }?${Date.now()}`;
    default:
      return `${process.env.REACT_APP_API_ROOT}/uploads/avatar/${filename}?${Date.now()}`;
  }
}

export function isAdmin(user: User): boolean {
  return user.role === Role.admin;
}

export function isAuthorizeResource(user: User, policyType: UserPolicies, id: string): boolean {
  return isAdmin(user) || hasIdInUserPoliciesByType(id, user, policyType);
}
