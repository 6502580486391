import _ from 'lodash';
import { createSelector } from 'reselect';
import { OEEFilterState, oeeFilterSelector } from './oeeFilter';
import { Line, Plant, PlantData } from '../../common/models/plant';
import { NONE } from '../../common/filter';
import { error } from '../../utils/error';
import { addToCollection, removeFromCollection, updateCollection } from '../../utils/collection';
import {
  API_CREATE_PLANT_LINE_PATH,
  API_CREATE_PLANT_PATH,
  API_DELETE_PLANT_PATH,
  API_DELETE_PLANT_PHOTO_PATH,
  API_INDEX_PLANT_PATH,
  API_SHOW_PLANT_PATH,
  API_UPDATE_PLANT_LINE_PATH,
  API_UPDATE_PLANT_PATH,
  API_UPDATE_PLANT_PHOTO_PATH,
  API_UPDATE_PLANT_LINE_POSITION_PATH,
} from '../../utils/routeHelper';
import { UserPolicies } from '../../common/models/user';
import { SIGNOUT } from './auth';

export const INDEX_PLANT = 'plant/INDEX_PLANT';
export const INDEX_PLANT_SUCCESS = 'plant/INDEX_PLANT_SUCCESS';
export const INDEX_PLANT_FAIL = 'plant/INDEX_PLANT_FAIL';
export const CREATE_PLANT = 'plant/CREATE_PLANT';
export const CREATE_PLANT_SUCCESS = 'plant/CREATE_PLANT_SUCCESS';
export const CREATE_PLANT_FAIL = 'plant/CREATE_PLANT_FAIL';
export const SHOW_PLANT = 'plant/SHOW_PLANT';
export const SHOW_PLANT_SUCCESS = 'plant/SHOW_PLANT_SUCCESS';
export const SHOW_PLANT_FAIL = 'plant/SHOW_PLANT_FAIL';
export const UPDATE_PLANT = 'plant/UPDATE_PLANT';
export const UPDATE_PLANT_SUCCESS = 'plant/UPDATE_PLANT_SUCCESS';
export const UPDATE_PLANT_FAIL = 'plant/UPDATE_PLANT_FAIL';
export const DELETE_PLANT = 'plant/DELETE_PLANT';
export const DELETE_PLANT_SUCCESS = 'plant/DELETE_PLANT_SUCCESS';
export const DELETE_PLANT_FAIL = 'plant/DELETE_PLANT_FAIL';
export const UPDATE_PLANT_PHOTO = 'plant/UPDATE_PLANT_PHOTO';
export const UPDATE_PLANT_PHOTO_SUCCESS = 'plant/UPDATE_PLANT_PHOTO_SUCCESS';
export const UPDATE_PLANT_PHOTO_FAIL = 'plant/UPDATE_PLANT_PHOTO_FAIL';
export const DELETE_PLANT_PHOTO = 'plant/DELETE_PLANT_PHOTO';
export const DELETE_PLANT_PHOTO_SUCCESS = 'plant/DELETE_PLANT_PHOTO_SUCCESS';
export const DELETE_PLANT_PHOTO_FAIL = 'plant/DELETE_PLANT_PHOTO_FAIL';
export const CREATE_PLANT_LINE = 'plant/CREATE_PLANT_LINE';
export const CREATE_PLANT_LINE_SUCCESS = 'plant/CREATE_PLANT_LINE_SUCCESS';
export const CREATE_PLANT_LINE_FAIL = 'plant/CREATE_PLANT_LINE_FAIL';
export const UPDATE_PLANT_LINE = 'plant/UPDATE_PLANT_LINE';
export const UPDATE_PLANT_LINE_SUCCESS = 'plant/UPDATE_PLANT_LINE_SUCCESS';
export const UPDATE_PLANT_LINE_FAIL = 'plant/UPDATE_PLANT_LINE_FAIL';
export const UPDATE_PLANT_LINE_POSITION = 'plant/UPDATE_PLANT_LINE_POSITION';
export const UPDATE_PLANT_LINE_POSITION_SUCCESS = 'plant/UPDATE_PLANT_LINE_POSITION_SUCCESS';
export const UPDATE_PLANT_LINE_POSITION_FAIL = 'plant/UPDATE_PLANT_LINE_POSITION_FAIL';

interface PlantState {
  data?: Array<Plant>;
  loading: boolean;
  creating?: boolean;
  updating?: boolean;
  deleting?: boolean;
  error?: string;
  createError?: string;
  updateError?: string;
  deleteError?: string;
}

const initialState: PlantState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export default function reducer(
  state: PlantState = initialState,
  action: ActionResult,
): PlantState {
  switch (action.type) {
    case INDEX_PLANT:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_PLANT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_PLANT_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case CREATE_PLANT:
      return {
        ...state,
        creating: true,
        createError: undefined,
      };
    case CREATE_PLANT_SUCCESS:
      return {
        ...state,
        creating: false,
        data: addToCollection(state.data, action.result.data),
      };
    case CREATE_PLANT_FAIL:
      return {
        ...state,
        creating: false,
        createError: error(action),
      };
    case SHOW_PLANT:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SHOW_PLANT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: addToCollection(state.data, action.result.data),
      };
    case SHOW_PLANT_FAIL:
      return {
        ...state,
        loading: false,
        error: error(action),
      };
    case UPDATE_PLANT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_PLANT_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_PLANT_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case DELETE_PLANT:
      return {
        ...state,
        deleting: true,
        deleteError: undefined,
      };
    case DELETE_PLANT_SUCCESS:
      return {
        ...state,
        deleting: false,
        data: removeFromCollection(state.data, action.preload),
      };
    case DELETE_PLANT_FAIL:
      return {
        ...state,
        deleting: false,
        deleteError: error(action),
      };
    case UPDATE_PLANT_PHOTO:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_PLANT_PHOTO_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_PLANT_PHOTO_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case DELETE_PLANT_PHOTO:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case DELETE_PLANT_PHOTO_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case DELETE_PLANT_PHOTO_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case CREATE_PLANT_LINE:
      return {
        ...state,
        updating: false,
        updateError: undefined,
      };
    case CREATE_PLANT_LINE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateError: undefined,
        data: updateCollection(state.data, action.result.data),
      };
    case CREATE_PLANT_LINE_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case UPDATE_PLANT_LINE:
      return {
        ...state,
        updating: false,
        updateError: undefined,
      };
    case UPDATE_PLANT_LINE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateError: undefined,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_PLANT_LINE_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case UPDATE_PLANT_LINE_POSITION:
      return {
        ...state,
        updating: false,
        updateError: undefined,
      };
    case UPDATE_PLANT_LINE_POSITION_SUCCESS:
      return {
        ...state,
        updating: false,
        updateError: undefined,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_PLANT_LINE_POSITION_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexPlants(scope: UserPolicies = UserPolicies.plantRead): ActionPromise {
  return {
    types: [INDEX_PLANT, INDEX_PLANT_SUCCESS, INDEX_PLANT_FAIL],
    promise: client => client.get(API_INDEX_PLANT_PATH(scope)),
  };
}

export function createPlant(news: PlantData): ActionPromise {
  return {
    types: [CREATE_PLANT, CREATE_PLANT_SUCCESS, CREATE_PLANT_FAIL],
    promise: client => client.post(API_CREATE_PLANT_PATH, news),
  };
}

export function showPlant(id: string): ActionPromise {
  return {
    types: [SHOW_PLANT, SHOW_PLANT_SUCCESS, SHOW_PLANT_FAIL],
    promise: client => client.get(API_SHOW_PLANT_PATH(id)),
  };
}

export function updatePlant(id: string, data: PlantData): ActionPromise {
  return {
    types: [UPDATE_PLANT, UPDATE_PLANT_SUCCESS, UPDATE_PLANT_FAIL],
    promise: client => client.patch(API_UPDATE_PLANT_PATH(id), data),
  };
}

export function deletePlant(news: Plant): ActionPromise {
  return {
    types: [DELETE_PLANT, DELETE_PLANT_SUCCESS, DELETE_PLANT_FAIL],
    promise: client => client.delete(API_DELETE_PLANT_PATH(news._id)),
    preload: { _id: news._id },
  };
}

export function updatePlantPhoto(id: string, photos): ActionPromise {
  return {
    types: [UPDATE_PLANT_PHOTO, UPDATE_PLANT_PHOTO_SUCCESS, UPDATE_PLANT_PHOTO_FAIL],
    promise: client => client.patch(API_UPDATE_PLANT_PHOTO_PATH(id), photos),
  };
}

export function deletePlantPhoto(id: string, photoId: string): ActionPromise {
  return {
    types: [DELETE_PLANT_PHOTO, DELETE_PLANT_PHOTO_SUCCESS, DELETE_PLANT_PHOTO_FAIL],
    promise: client => client.delete(API_DELETE_PLANT_PHOTO_PATH(id, photoId)),
  };
}

export function createPlantLine(id: string, line: Line): ActionPromise {
  return {
    types: [CREATE_PLANT_LINE, CREATE_PLANT_LINE_SUCCESS, CREATE_PLANT_LINE_FAIL],
    promise: client => client.post(API_CREATE_PLANT_LINE_PATH(id), line),
  };
}

export function updatePlantLine(id: string, lineId: string, line: Line): ActionPromise {
  return {
    types: [UPDATE_PLANT_LINE, UPDATE_PLANT_LINE_SUCCESS, UPDATE_PLANT_LINE_FAIL],
    promise: client => client.patch(API_UPDATE_PLANT_LINE_PATH(id, lineId), line),
  };
}

export function updatePlantLinePosition(id: string, data: any): ActionPromise {
  return {
    types: [
      UPDATE_PLANT_LINE_POSITION,
      UPDATE_PLANT_LINE_POSITION_SUCCESS,
      UPDATE_PLANT_LINE_POSITION_FAIL,
    ],
    promise: client => client.put(API_UPDATE_PLANT_LINE_POSITION_PATH(id), data),
  };
}

export const plantsSelector = state => state.plants.data;

export interface PlantAndLine {
  plant?: Plant;
  line?: Line;
  shift?: string;
}

export const getPlantAndLineByOEEFilterSelector = createSelector(
  plantsSelector,
  oeeFilterSelector,
  (plants: Plant[], filter: OEEFilterState): PlantAndLine => {
    const plant = _.find(plants, { _id: filter.plantId });
    if (plant && filter.line !== NONE) {
      const line = _.find(plant.lines, { slug: filter.line });
      const data: any = { plant, line };
      if (line.isShift && filter.shift !== NONE) {
        data.shift = filter.shift;
      }
      return data;
    }
    return { plant: undefined, line: undefined, shift: undefined };
  },
);
