import _ from 'lodash';
import { Model, identifier } from '../common/models/model';

/**
 * Add the given item to the provided collection. Does not modify the original collection but return a deep copy.
 * @param collection A collection of objects
 * @param item The item to add
 */
export function addToCollection<T extends Model>(collection: Array<T> = [], item: T): Array<T> {
  const copy = _.cloneDeep(collection);
  copy.push(item);
  return copy;
}

/**
 * Update the given item in the provided collection, based on its ID.
 * Does not modify the original collection but return a copy with pointers to the same objects content
 * @param collection A collection of objects containing, among others, the original item
 * @param item The item to update
 */
export function updateCollection<T extends Model>(collection: Array<T> = [], item: T): Array<T> {
  const copy: Array<T> = [];
  for (const element of collection) {
    if (element[identifier] === item[identifier]) {
      copy.push(item);
    } else {
      copy.push(element);
    }
  }
  return copy;
}

/**
 * Update the given items in the provided collection, based on their IDs.
 * Does not modify the original collection but return a copy with pointers to the same objects content
 * @param collection A collection of objects containing, among others, the original items
 * @param items The items to update
 */
export function bulkUpdateCollection<T extends Model>(
  collection: Array<T> = [],
  items: Array<T>,
): Array<T> {
  const copy: Array<T> = [];
  for (const element of collection) {
    const item = _.find(items, { [identifier]: element[identifier] });
    if (item) {
      copy.push(item);
    } else {
      copy.push(element);
    }
  }
  return copy;
}

/**
 * Remove the given item from the provided collection, based on its ID.
 * Does not modify the original collection but return a copy with pointers to the same objects content.
 * @param collection A collection of objects containing, among others, the item to remove
 * @param item The item to remove
 */
export function removeFromCollection<T extends Model>(
  collection: Array<T> = [],
  item: T,
): Array<T> {
  const copy: Array<T> = [];
  for (const element of collection) {
    if (element[identifier] !== item[identifier]) {
      copy.push(element);
    }
  }
  return copy;
}

/**
 * Remove the given items from the provided collection, based on their IDs.
 * Does not modify the original collection but return a copy with pointers to the same objects content.
 * @param collection A collection of objects containing, among others, the items to remove
 * @param items The items to remove
 */
export function bulkRemoveFromCollection<T extends Model>(
  collection: Array<T> = [],
  items: Array<T>,
): Array<T> {
  const copy: Array<T> = [];
  for (const element of collection) {
    if (!_.find(items, { [identifier]: element[identifier] })) {
      copy.push(element);
    }
  }
  return copy;
}

/**
 * Return the element identified by the given ID, in the provided collection
 * @param collection A collection of objects
 * @param id Identifier of the object to return
 */
export function getFromCollection<T extends Model>(
  collection: Array<T> = [],
  id: string,
): T | undefined {
  for (const element of collection) {
    if (element[identifier] === id) {
      return element;
    }
  }
  return undefined;
}
