import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PlotlyChart from '../PlotlyChart/PlotlyChart';
import { COLORS, getTimeDuration } from '../../../utils/oeeHelper';
import { getRangeByAggregation } from '../../../common/utils/oeeDashboardHelper';
import { OEEDurationType } from '../../../common/models/oee';

function getOEEGaugeData(data, filter, t): any {
  const { endDate } = getRangeByAggregation('oee', filter);
  const date = moment(endDate).startOf(getTimeDuration(filter.aggregationType));
  const oee = _.find(data.oee, d => {
    return date.isSame(d.key, getTimeDuration(filter.aggregationType));
  });
  const oeeBudgetChallenge = _.find(data.oeeBudgetChallenges, d => {
    return date.isSame(d.date, getTimeDuration(filter.aggregationType));
  });
  let oeeWasteTreated: any = null;
  if (oee) {
    if (oee.openingTime.value === 0) {
      oeeWasteTreated = t('OEEDashboard.dayOff');
    } else if (_.isFinite(oee.oeeWasteTreated.value)) {
      oeeWasteTreated = _.round(oee.oeeWasteTreated.value, 1);
    }
  }

  let oeeWasteTreatedBudget: any = null;
  if (oeeBudgetChallenge) {
    if (oeeBudgetChallenge.openingTime === 0 && filter.aggregationType === OEEDurationType.daily) {
      oeeWasteTreatedBudget = t('OEEDashboard.dayOff');
    } else if (_.isFinite(oeeBudgetChallenge.oeeWasteTreated)) {
      oeeWasteTreatedBudget = _.round(oeeBudgetChallenge.oeeWasteTreated * 100, 1);
    }
  } else if (_.isFinite(oeeWasteTreated)) {
    oeeWasteTreatedBudget = 100;
  }

  let oeeWasteTreatedChallenge: any = null;
  if (oeeBudgetChallenge) {
    if (oeeBudgetChallenge.openingTime === 0 && filter.aggregationType === OEEDurationType.daily) {
      oeeWasteTreatedChallenge = t('OEEDashboard.dayOff');
    } else if (_.isFinite(oeeBudgetChallenge.oeeWasteTreatedChallenge)) {
      oeeWasteTreatedChallenge = _.round(oeeBudgetChallenge.oeeWasteTreatedChallenge * 100, 1);
    }
  } else if (_.isFinite(oeeWasteTreated)) {
    oeeWasteTreatedChallenge = 100;
  }
  return {
    oeeWasteTreated,
    oeeWasteTreatedBudget,
    oeeWasteTreatedChallenge,
  };
}

function buildOEEGaugeChartData(oeeData, filter, t): any[] {
  const traces: any[] = [];
  const { oeeWasteTreated, oeeWasteTreatedBudget, oeeWasteTreatedChallenge } = oeeData;
  if (
    _.isFinite(oeeWasteTreated) &&
    _.isFinite(oeeWasteTreatedBudget) &&
    _.isFinite(oeeWasteTreatedChallenge)
  ) {
    traces.push({
      type: 'category',
      x: [0],
      y: [0],
      marker: { size: 15, color: COLORS.oeeWasteTreatedReal },
      showlegend: false,
      textinfo: 'none',
      hoverinfo: 'none',
      name: 'oeeWasteTreated',
      //text: oeeWasteTreated,
    });
  }
  if (_.isFinite(oeeWasteTreatedBudget) && _.isFinite(oeeWasteTreatedChallenge)) {
    const challenge = _.round(oeeWasteTreatedChallenge - oeeWasteTreatedBudget, 1);
    const delta = _.round(100 - oeeWasteTreatedChallenge, 1);
    traces.push({
      values: [oeeWasteTreatedBudget, challenge, delta, 100],
      rotation: -90,
      direction: 'clockwise',
      sort: false,
      textinfo: 'none',
      marker: {
        colors: [
          COLORS.oeeWasteTreatedBudgetBudget,
          COLORS.oeeWasteTreatedBudgetChallenge,
          COLORS.oeeBudgetChallenge,
          'rgba(255, 255, 255, 0)',
        ],
      },
      hoverinfo: 'none',
      hole: 0.6,
      type: 'pie',
      showlegend: false,
    });
  }
  return traces;
}

function getOEEGaugeChartLayout(oeeData, filter) {
  const { oeeWasteTreated, oeeWasteTreatedBudget, oeeWasteTreatedChallenge } = oeeData;
  const shapes: any[] = [];
  if (
    _.isFinite(oeeWasteTreated) &&
    _.isFinite(oeeWasteTreatedBudget) &&
    _.isFinite(oeeWasteTreatedChallenge)
  ) {
    // Trig to calc meter point
    const degrees = 180 - (oeeWasteTreated / 100) * 180;
    const radius = 0.8;
    const radians = (degrees * Math.PI) / 180;
    const x = radius * Math.cos(radians);
    const y = radius * Math.sin(radians);
    let x0 = 0;
    let y0 = -0.03;
    if (y > 0.56) {
      x0 = -0.03;
      y0 = 0;
    }
    const path = `M ${x0} ${y0} L ${0 - x0} ${0 - y0} L ${x} ${y} Z`;
    shapes.push({
      type: 'path',
      path: path,
      fillcolor: COLORS.oeeWasteTreatedReal,
      line: {
        color: COLORS.oeeWasteTreatedReal,
      },
    });
  }
  return {
    shapes,
    margin: { t: 5, b: 5 },
    xaxis: {
      type: 'category',
      zeroline: false,
      showticklabels: false,
      showgrid: false,
      range: [-1, 1],
    },
    yaxis: {
      type: 'category',
      zeroline: false,
      showticklabels: false,
      showgrid: false,
      range: [-1, 1],
    },
  };
}

function buildOEEGaugeChartTable(oeeData, filter, t, print = false) {
  const { oeeWasteTreated, oeeWasteTreatedBudget, oeeWasteTreatedChallenge } = oeeData;
  const formatOEE = value => {
    return _.isFinite(value) ? `${value} %` : value;
  };
  if (
    oeeWasteTreated === null &&
    oeeWasteTreatedBudget === null &&
    oeeWasteTreatedChallenge === null
  ) {
    return null;
  }
  const oeeRealfontSize = print ? '1.3rem' : '1.5rem';
  const fontSize = print ? '1.1rem' : '1.3rem';
  return (
    <table
      className="oeeTable"
      style={{
        position: 'relative',
        top: print ? '-100px' : '-170px',
        width: print ? '90%' : '80%',
        margin: 'auto',
      }}
    >
      <tbody>
        <tr
          style={{
            backgroundColor: COLORS.oeeWasteTreatedReal,
            color: 'white',
          }}
        >
          <td style={{ fontSize: oeeRealfontSize }}>{t('OEEDashboard.oeeWasteTreatedReal')}</td>
          <td style={{ fontSize: oeeRealfontSize }}>{formatOEE(oeeWasteTreated)}</td>
        </tr>
        <tr style={{ backgroundColor: COLORS.oeeWasteTreatedBudgetChallenge, color: 'black' }}>
          <td style={{ fontSize }}>{t('OEEDashboard.oeeWasteTreatedBudget')}</td>
          <td style={{ fontSize }}>{formatOEE(oeeWasteTreatedBudget)}</td>
        </tr>
        <tr style={{ backgroundColor: COLORS.oeeBudgetChallenge, color: 'black' }}>
          <td style={{ fontSize }}>{t('OEEDashboard.oeeWasteTreatedChallenge')}</td>
          <td style={{ fontSize }}>{formatOEE(oeeWasteTreatedChallenge)}</td>
        </tr>
      </tbody>
    </table>
  );
}

const OEEGaugeChart: React.FC<any> = ({
  data,
  layout,
  filter,
  aggregationType,
  loading,
  print,
}) => {
  const { t } = useTranslation();
  const q = Object.assign({ aggregationType }, filter);
  const oeeData = getOEEGaugeData(data, q, t);
  return (
    <div style={{ height: `${layout.height}px` }} className="oee-gauge">
      <PlotlyChart
        data={buildOEEGaugeChartData(oeeData, q, t)}
        layout={_.merge(getOEEGaugeChartLayout(oeeData, q), layout)}
        loading={loading}
      />
      {buildOEEGaugeChartTable(oeeData, q, t, print)}
    </div>
  );
};

export default OEEGaugeChart;
