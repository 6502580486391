import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../Modal/ModalWrapper';
import ObjectiveTable from './ObjectiveTable';
import { createObjective, updateObjective } from '../../../redux/modules/objectives';
import { indexOEEInput } from '../../../redux/modules/oeeInputs';
import { ObjectiveData } from '../../../common/models/objective';

const ObjectiveModal = ({ open, close, plant, line }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const objectives = useSelector(state => state.objectives.data);
  const oeeFilter = useSelector(state => state.oeeFilter);

  const handleObjectiveUpdate = async (id: string, data: ObjectiveData) => {
    await dispatch(updateObjective(id, data));
    const { plantId, line, date } = oeeFilter;
    await dispatch(
      indexOEEInput({
        plantId,
        line,
        endYear: date.getFullYear(),
        endMonth: date.getMonth(),
      }),
    );
  };

  return (
    <ModalWrapper
      title={t('objective.objectivesFor', { plant: plant.name, line: line.name })}
      open={open}
      close={close}
      bsSize="large"
    >
      <ObjectiveTable
        objectives={objectives}
        onNewObjective={data => dispatch(createObjective(data))}
        onObjectiveUpdate={handleObjectiveUpdate}
        plant={plant}
        line={line}
      />
    </ModalWrapper>
  );
};

export default ObjectiveModal;
