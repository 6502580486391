import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import notificationStyle from '../../../utils/notificationStyle';

interface NotificationProps {
  notification?: NotificationSystem.Notification;
}

class Notification extends Component<NotificationProps> {
  private notificationSystem = React.createRef<NotificationSystem.System>();

  componentDidUpdate() {
    const notification = this.notificationSystem.current;
    if (notification && this.props.notification) {
      notification.addNotification(this.props.notification);
    }
  }

  render() {
    return <NotificationSystem ref={this.notificationSystem} style={notificationStyle} />;
  }
}

const mapStateToProps = state => {
  return {
    notification: state.notification,
  };
};

export default connect(mapStateToProps)(Notification);
