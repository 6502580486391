import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-bootstrap';

const OEEInputHelpPopover = (key: string) => {
  const { t } = useTranslation();
  return (
    <Popover id={`${key}-help`} className="popover-md" title={t('OEEInput.help.definition')}>
      <span dangerouslySetInnerHTML={{ __html: t(`OEEInput.help.${key}`) }} />
    </Popover>
  );
};

export default OEEInputHelpPopover;
