import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios from 'axios';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../common/i18n';

//import Backend from 'i18next-xhr-backend';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  //.use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      debug: process.env.NODE_ENV !== 'production',

      resources: LANGUAGES.reduce((o, lng) => {
        o[lng] = require(`../locales/${lng}.json`);
        return o;
      }, {}),
      fallbackLng: DEFAULT_LANGUAGE,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      detection: {
        // order and from where user language should be detected
        order: ['querystring', 'localStorage'],
      },
    },
    () => (axios.defaults.headers.common['Accept-Language'] = i18n.language),
  );

i18n.on('languageChanged', lng => {
  axios.defaults.headers.common['Accept-Language'] = lng;
});

export default i18n;
