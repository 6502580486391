import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import LineModal from './LineModal';
import { identifier } from '../../../../common/models/model';

class PlantLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewLineModal: false,
      openEditLineModal: false,
      lineEdited: {},
    };
  }

  toggleNewLineModal = () => {
    this.setState({ openNewLineModal: !this.state.openNewLineModal });
  };

  newLine() {
    const { t } = this.props;
    const lines = this.props.plant.lines;
    const nextLine = lines.length + 1;
    return {
      name: `${t('plant.line')} ${nextLine}`,
    };
  }

  handleAddLine = data => {
    this.props.onAddLine(this.props.plant[identifier], data).then(action => {
      if (action.type.match(/SUCCESS/)) {
        this.toggleNewLineModal();
      }
    });
  };

  editLine(line) {
    return () => {
      this.setState({
        openEditLineModal: true,
        lineEdited: line,
      });
    };
  }

  closeEditLineModal = () => {
    this.setState({
      openEditLineModal: false,
      lineEdited: {},
    });
  };

  handleUpdateLine = data => {
    this.props
      .onUpdateLine(this.props.plant[identifier], this.state.lineEdited[identifier], data)
      .then(action => {
        if (action.type.match(/SUCCESS/)) {
          this.closeEditLineModal();
        }
      });
  };

  updateLinePosition = (line, direction) => {
    return () => {
      const {
        plant: { _id, lines },
      } = this.props;
      const newPosition = line.position + direction;
      const lineSwitched = _.find(lines, { position: newPosition });
      const update = {
        [line.slug]: newPosition,
        [lineSwitched.slug]: line.position,
      };
      this.props.onUpdateLinePosition(_id, update);
    };
  };

  buildTableHeader() {
    const { t } = this.props;
    return (
      <thead>
        <tr role="row">
          <th style={{ width: '4%' }}>#</th>
          <th style={{ width: '11%' }}>{t('plant.lineName')}</th>
          <th style={{ width: '11%' }}>{t('plant.lineIdentifier')}</th>
          <th style={{ width: '13%' }}>{t('plant.lineType')}</th>
          <th style={{ width: '7%' }}>{t('plant.shifts')}</th>
          <th style={{ width: '12%' }}>{t('plant.lineStartDate')}</th>
          <th style={{ width: '12%' }}>{t('plant.lineEndDate')}</th>
          <th style={{ width: '11%' }}>{t('plant.lineDesignCapacity')}</th>
          <th style={{ width: '11%' }}>{t('plant.lineUnit')}</th>
          <th style={{ width: '8%' }} />
        </tr>
      </thead>
    );
  }

  buildTable() {
    const {
      t,
      plant: { lines },
    } = this.props;
    const trs = [];
    _.sortBy(lines, 'position').forEach((line, index) => {
      trs.push(
        <tr key={line[identifier]}>
          <td className="text-center">
            {index !== 0 && (
              <i className="fa fa-angle-up" onClick={this.updateLinePosition(line, -1)} />
            )}
            {index !== lines.length - 1 && (
              <i className="fa fa-angle-down" onClick={this.updateLinePosition(line, 1)} />
            )}
          </td>
          <td className="text-center">{line.name}</td>
          <td className="text-center">{line.slug}</td>
          <td className="text-center">{line.type ? t(`plant.lineTypes.${line.type}`) : ''}</td>
          <td className="text-center">
            {line.isShift && (
              <div>
                {`${line.shifts} ${t(`plant.shift${line.shifts > 1 ? 's' : ''}`)}`.toLowerCase()}
              </div>
            )}
            {!line.isShift && <div>{t('common.no')}</div>}
          </td>
          <td className="text-center">{moment(line.startDate).format(t('date.short'))}</td>
          <td className="text-center">
            {line.endDate ? moment(line.endDate).format(t('date.short')) : ''}
          </td>
          <td className="text-center">{line.designCapacity}</td>
          <td className="text-center">{line.unit ? t(`plant.lineUnits.${line.unit}`) : ''}</td>
          <td className="text-center">
            <Button className="list-action btn-link text-muted" onClick={this.editLine(line)}>
              <i className="fa fa-edit" />
            </Button>
          </td>
        </tr>,
      );
    });
    return <tbody>{trs}</tbody>;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="table-responsive min-h-500">
        <div>
          <h4 className="m-l">
            {t('plant.lines')}
            <Button
              className="btn btn-secondary btn-md-hide-label pull-right"
              onClick={this.toggleNewLineModal}
            >
              <i className="fa fa-plus m-r" />
              <span>{t('plant.addLine')}</span>
            </Button>
          </h4>
          <LineModal
            data={this.newLine()}
            open={this.state.openNewLineModal}
            close={this.toggleNewLineModal}
            onSubmit={this.handleAddLine}
          />
          <table className="table table-striped table-vcenter">
            {this.buildTableHeader()}
            {this.buildTable()}
          </table>
        </div>
        <LineModal
          data={this.state.lineEdited}
          open={this.state.openEditLineModal}
          close={this.closeEditLineModal}
          onSubmit={this.handleUpdateLine}
        />
      </div>
    );
  }
}

PlantLines.propTypes = {
  plant: PropTypes.object.isRequired,
  onAddLine: PropTypes.func.isRequired,
  onUpdateLine: PropTypes.func.isRequired,
  onUpdateLinePosition: PropTypes.func.isRequired,
};

export default withTranslation()(PlantLines);
