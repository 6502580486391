import { User, UserPolicies } from './models/user';

export const getUserPoliciesByType = (
  user: User,
  policyType: UserPolicies = UserPolicies.plantRead,
): string[] => {
  return user.policies ? user.policies[policyType] : [];
};

export const hasIdInUserPoliciesByType = (
  id: string,
  user: User,
  policyType: UserPolicies = UserPolicies.plantRead,
): boolean => {
  const policies = getUserPoliciesByType(user, policyType);
  return policies.indexOf(id) > -1;
};
