import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import ConfirmButton from '../../../components/App/ConfirmButton/ConfirmButton';
import CategoryForm from './CategoryForm';
import { Category as ICategory, CategoryData, LossType } from '../../../common/models/category';
import { getCategoryItemName } from '../../../utils/categoryHelper';

interface CategoryProps {
  category: ICategory;
  parent: ICategory;
  categoryItems: any[];
  isExpanded: boolean;
  onExpand: Function;
  onCollapse: Function;
  dragHandle: any;
  onAddCategory: Function;
  onSaveCategory: Function;
  onDeleteCategory: Function;
}

//const MAX_CATEGORY_LEVEL: number = 4 as const;

const Category: React.FC<CategoryProps> = props => {
  const {
    category,
    parent,
    dragHandle,
    onExpand,
    onCollapse,
    onAddCategory,
    onSaveCategory,
    onDeleteCategory,
  } = props;
  const { t, i18n } = useTranslation();
  const categoryItems = useSelector(state => state.categoryItems.data);
  const categoryItem = useMemo(() => {
    return _.find(categoryItems, { slug: category.categoryItemSlug });
  }, [categoryItems, category]);
  const [isEdit, setIsEdit] = useState(!category._id);

  const startDragCategory = category => {
    return e => {
      if (isEdit) {
        return;
      }
      if (dragHandle) {
        onCollapse(category._id);
        dragHandle.onMouseDown(e);
      }
    };
  };

  const clickCategory = category => {
    return e => {
      if (dragHandle) {
        onExpand(category._id);
      }
    };
  };

  const toggleEditCategory = () => {
    if (!category._id) {
      return;
    }
    setIsEdit(!isEdit);
  };

  const handleEditCancel = () => {
    if (category._id) {
      setIsEdit(false);
    } else {
      onDeleteCategory(category);
    }
  };

  const handleSaveCategory = (data: CategoryData) => {
    return onSaveCategory(category, data).then(() => {
      if (category._id) {
        setIsEdit(false);
      }
    });
  };

  const handleDeleteCategory = () => {
    onDeleteCategory(category);
  };

  const lossTypeTag = () => {
    if (category.lossType && category.lossType.length > 0) {
      return (
        <div className="m-l-md">
          {category.lossType.map(l => {
            return (
              <span className="category__losstype" key={l.name}>
                {t(`category.lossType.${l.name}`)}
                {l.name === LossType.treatedWaste && ` - ${t(`category.lossType.${l.loss}`)}`}
              </span>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const hiddenActions = category._id ? '' : 'hidden';
  return (
    <div className="category__tree">
      <div className="category__node">
        <div className="category__node-head">
          <div className={`category__actions ${hiddenActions}`}>
            <div
              className="btn-link text-muted btn category__draggable"
              {..._.omit(dragHandle, 'onMouseDown')}
              onMouseDown={startDragCategory(category)}
              onClick={clickCategory(category)}
            >
              <i className="fa fa-arrows" />
            </div>
            <button className="text-muted btn category__expand" onClick={toggleEditCategory}>
              {isEdit && <i className="fa fa-chevron-up" />}
              {!isEdit && <i className="fa fa-edit" />}
            </button>
          </div>
          <div className={`category__actions category__actions--right ${hiddenActions}`}>
            <button
              className="btn-link text-muted btn"
              onClick={() => {
                onAddCategory(category._id);
              }}
            >
              <i className="fa fa-plus" />
            </button>
            <ConfirmButton
              className="btn-link text-muted btn text-danger-hover"
              onClick={handleDeleteCategory}
              message={t('category.removeThisCategory')}
              confirmButtonStyle="danger"
            >
              <i className="fa fa-trash-o" />
            </ConfirmButton>
          </div>
          <span className="category__name" onClick={toggleEditCategory}>
            {category._id && categoryItem
              ? getCategoryItemName(categoryItem, i18n.language)
              : t('category.newCategoryTitle')}
            {lossTypeTag()}
          </span>
        </div>
        {isEdit && (
          <CategoryForm
            data={category}
            onSubmit={handleSaveCategory}
            onCancel={handleEditCancel}
            categoryItem={categoryItem}
            categoryItems={categoryItems}
            lineType={parent.lineType || []}
            lossList={parent.lossType ? parent.lossType.map(l => l.name) : []}
          />
        )}
      </div>
    </div>
  );
};

export default Category;
