import React from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, RadioGroup } from 'formsy-react-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import fp from 'lodash/fp';
import Switch from '../Switch/Switch';
import BaseForm from '../Form/BaseForm';
import Select from '../Form/Select';
import loadData from '../../../utils/loadData';
import { indexBusinessUnit } from '../../../redux/modules/businessUnits';
import { indexRegion } from '../../../redux/modules/regions';
import { indexPlants, getPlantAndLineByOEEFilterSelector } from '../../../redux/modules/plants';
import { OEEExportType } from '../../../common/models/oee';

function buildOptions(elements) {
  return elements.map(e => {
    return { value: e._id, label: e.name };
  });
}

class OEEExportForm extends BaseForm {
  constructor(props) {
    super(props);
    let plantIds = [];
    let businessUnit = null;
    let region = null;
    if (props.plantAndLine && props.plantAndLine.plant) {
      const bu = _.find(props.businessUnits, { _id: props.plantAndLine.plant.businessUnitId });
      businessUnit = { value: bu._id, label: bu.name };
      const r = _.find(props.regions, { _id: props.plantAndLine.plant.regionId });
      region = { value: r._id, label: r.name };
      plantIds = [{ value: props.plantAndLine.plant._id, label: props.plantAndLine.plant.name }];
    }
    const businessUnits = this.buildBusinessUnits(props);
    const regions = this.buildRegions(props, businessUnit);
    const plants = this.buildPlants(props, region, businessUnit, props.filter.showArchived);
    const lines = this.buildLinesOptions(plantIds, plants);
    this.state = {
      businessUnit,
      region,
      plantIds,
      businessUnits,
      regions,
      plants,
      lines,
      linesValue: [],
      exportType: this.getOEEExportTypeOptions()[0],
      showArchived: props.filter.showArchived,
      aggregation: 'line',
    };
    this.updateBU = this.updateBU.bind(this);
    this.updateRegion = this.updateRegion.bind(this);
    this.updatePlants = this.updatePlants.bind(this);
    this.updateLines = this.updateLines.bind(this);
    this.aggregationOptions = [
      { value: 'line', label: <span>{this.props.t('plant.line')}</span> },
      { value: 'shift', label: <span>{this.props.t('plant.shift')}</span> },
    ];
  }

  handleSubmit(data) {
    const lines = _.map(data.linesValue, 'value');
    console.log(data, lines);
    if (lines.length === 0) {
      let plants = [];
      if (data.plantIds && data.plantIds.length > 0) {
        plants = _.filter(
          this.state.plants,
          p => _.map(data.plantIds, 'value').indexOf(p._id) !== -1,
        );
      } else if (data.region) {
        plants = _.filter(this.state.plants, { regionId: data.region.value });
      } else if (data.businessUnit) {
        plants = _.filter(this.state.plants, { businessUnitId: data.businessUnit.value });
      }
      plants.forEach(p => {
        if (p.lines && p.lines.length > 0) {
          p.lines.forEach(l => {
            lines.push({ plantId: p._id, line: l.slug, plantSlug: p.slug });
          });
        }
      });
    }
    this.props.onSubmit({
      exportType: data.exportType.value,
      startDate: data.startDate,
      endDate: data.endDate,
      lines,
      aggregation: data.aggregation,
    });
  }

  handleDownloadComplete() {
    const { onDownloadComplete } = this.props;
    if (typeof onDownloadComplete === 'function') onDownloadComplete();
  }

  getOEEExportTypeOptions() {
    return Object.values(OEEExportType).map(k => {
      return { value: k, label: this.props.t([`OEE.${k}`]) };
    });
  }

  getStartDate() {
    return moment(this.props.filter.date)
      .startOf('month')
      .format('YYYY-MM-DD');
  }

  getEndDate() {
    return moment(this.props.filter.date)
      .endOf('month')
      .format('YYYY-MM-DD');
  }

  updateBU(businessUnit) {
    this.setState((prevState, props) => {
      const plants = this.buildPlants(props, null, businessUnit, prevState.showArchived);
      return {
        businessUnit,
        region: null,
        plantIds: [],
        linesValue: [],
        regions: this.buildRegions(props, businessUnit),
        plants,
        lines: this.buildLinesOptions([], plants),
      };
    });
  }

  updateRegion(region) {
    this.setState((prevState, props) => {
      const plants = this.buildPlants(
        props,
        region,
        prevState.businessUnit,
        prevState.showArchived,
      );
      return {
        region,
        plantIds: [],
        linesValue: [],
        plants,
        lines: this.buildLinesOptions([], plants),
      };
    });
  }

  updatePlants(plantIds) {
    this.setState(prevState => ({
      plantIds,
      linesValue: [],
      lines: this.buildLinesOptions(plantIds, prevState.plants),
    }));
  }

  updateLines(values) {
    this.setState({
      linesValue: values,
    });
  }

  buildBusinessUnits(props) {
    const allowed = props.plants.map(p => {
      return p.businessUnitId;
    });
    return props.businessUnits.filter(bu => {
      return allowed.indexOf(bu._id) > -1;
    });
  }

  buildRegions(props, businessUnit) {
    const allowed = props.plants.map(p => {
      return p.regionId;
    });
    let regions = props.regions.filter(r => {
      return allowed.indexOf(r._id) > -1;
    });

    if (businessUnit) {
      regions = regions.filter(r => {
        return r.businessUnitId === businessUnit.value;
      });
    }
    return regions;
  }

  buildPlants(props, region, businessUnit, showArchived = false) {
    let plants = _.filter(props.plants, p => {
      return !p.archived || (showArchived && p.archived);
    });
    if (!region && businessUnit) {
      plants = plants.filter(p => {
        return p.businessUnitId === businessUnit.value;
      });
    } else if (region) {
      plants = plants.filter(p => {
        return p.regionId === region.value;
      });
    }
    const data = [];
    for (const p of plants) {
      const plant = _.clone(p);
      if (p.archived) {
        plant.name = `${p.name} (${this.props.t('plant.archived')})`;
      }
      data.push(plant);
    }
    return data;
  }

  buildLinesOptions(plantIds, plants) {
    const plantsLines = [];
    let plantsFiltered = plants;
    // get the concerned plants
    if (plantIds && plantIds.length > 0) {
      plantsFiltered = plants.filter(p => {
        return _.map(plantIds, 'value').indexOf(p._id) >= 0;
      });
    }
    // get the lines those plants
    plantsFiltered.forEach(p => {
      const lines = [];
      _.sortBy(p.lines, 'position').forEach(line => {
        lines.push({
          value: { plantId: p._id, line: line.slug, plantSlug: p.slug },
          label: line.name,
        });
      });
      if (lines.length > 0) {
        let label = p.name;
        if (p.archived) {
          label = `${p.name} (${this.props.t('plant.archived')})`;
        }
        plantsLines.push({
          label,
          options: lines,
        });
      }
    });
    return plantsLines;
  }

  updateExportType = value => {
    this.setState({
      exportType: value,
    });
  };

  handleArchivedChange = value => {
    const plants = this.buildPlants(this.props, this.state.region, this.state.businessUnit, value);
    this.setState({
      showArchived: value,
      plantIds: [],
      plants,
      lines: this.buildLinesOptions([], plants),
    });
  };

  render() {
    const { businessUnits, regions, plants, lines, showArchived } = this.state;
    const { t } = this.props;
    return (
      <Row>
        <Col lg={12}>
          <Form
            onSubmit={this.handleSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            className="horizontal"
          >
            <fieldset>
              <Select
                label={`${t('OEE.exportType')} *`}
                name="exportType"
                options={this.getOEEExportTypeOptions()}
                placeholder={t('common.select')}
                layout="horizontal"
                value={this.state.exportType}
                onChange={this.updateExportType}
                required
              />

              <Input
                name="startDate"
                label={t('OEE.startDate')}
                type="date"
                value={this.getStartDate()}
                required
              />
              <Input
                name="endDate"
                label={t('OEE.endDate')}
                type="date"
                value={this.getEndDate()}
                required
              />
              <Select
                label={`${t('businessUnit.businessUnit')} *`}
                name="businessUnit"
                options={buildOptions(businessUnits)}
                placeholder={t('common.select')}
                layout="horizontal"
                onChange={this.updateBU}
                value={this.state.businessUnit}
                required
              />
              {this.state.businessUnit && (
                <div>
                  <div className="col-md-offset-3 m-b text-small">
                    <span className="fa-stack fa-lg m-l m-r-md">
                      <i className="fa fa-circle fa-stack-2x text-warning" />
                      <i className="fa fa-lightbulb-o fa-stack-1x fa-inverse" />
                    </span>
                    <span>{t('OEE.hintLeaveEmpty')}</span>
                  </div>
                  <Select
                    label={t('region.region')}
                    name="region"
                    options={buildOptions(regions)}
                    placeholder={t('common.select')}
                    layout="horizontal"
                    onChange={this.updateRegion}
                    value={this.state.region}
                    clearable
                  />
                  <Switch
                    className="archived--switch"
                    title={t('plant.showArchived')}
                    value={showArchived}
                    onChange={this.handleArchivedChange}
                    horizontalForm
                  />
                  <Select
                    multi
                    label={t('plant.plants')}
                    name="plantIds"
                    options={buildOptions(plants)}
                    placeholder={t('common.select')}
                    layout="horizontal"
                    onChange={this.updatePlants}
                    value={this.state.plantIds}
                  />
                  <Select
                    multi
                    label={t('plant.lines')}
                    name="linesValue"
                    options={lines}
                    placeholder={t('common.select')}
                    layout="horizontal"
                    value={this.state.linesValue}
                    onChange={this.updateLines}
                  />
                  <RadioGroup
                    labelClassName="radiogroup"
                    name="aggregation"
                    type="inline"
                    label={t('OEE.aggregation')}
                    value={this.state.aggregation}
                    options={this.aggregationOptions}
                  />
                </div>
              )}
            </fieldset>
            <div className="text-center m-t">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                formNoValidate
                disabled={!this.state.canSubmit}
              >
                {t('OEE.export')}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

OEEExportForm.propTypes = {
  filter: PropTypes.object.isRequired,
  businessUnits: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  plants: PropTypes.array.isRequired,
  plantAndLine: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    filter: state.oeeFilter,
    businessUnits: state.businessUnits.data,
    regions: state.regions.data,
    plants: state.plants.data,
    plantAndLine: getPlantAndLineByOEEFilterSelector(state),
  };
};

const mapDispatchToProps = {};

export default fp.compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  loadData(async dispatch => {
    await dispatch(indexBusinessUnit());
    await dispatch(indexRegion());
    await dispatch(indexPlants());
  }),
)(OEEExportForm);
