import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import ModalWrapper from '../Modal/ModalWrapper';
import PlantForm from './PlantForm';

const PlantModal = ({ open, close, plant, businessUnits, regions, onSubmit }) => {
  return (
    <ModalWrapper title={i18n.t('plant.plantBasicInformations')} open={open} close={close}>
      <PlantForm data={plant} businessUnits={businessUnits} regions={regions} onSubmit={onSubmit} />
    </ModalWrapper>
  );
};

PlantModal.propTypes = {
  plant: PropTypes.object.isRequired,
  businessUnits: PropTypes.array,
  regions: PropTypes.array,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

PlantForm.defaultProps = {
  businessUnits: [],
  regions: [],
};

export default PlantModal;
