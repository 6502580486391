import _ from 'lodash';
import moment, { unitOfTime } from 'moment';
import { OEEDurationType } from '../models/oee';

export function getRangeByAggregation(aggregation, q): any {
  const query = Object.assign({}, q, _.get(q, `aggs.${aggregation}`));

  let endDate = moment.utc(query.date);
  let startDate = endDate.clone().startOf('day');
  if (q.aggregationType === OEEDurationType.daily && aggregation === 'oee') {
    startDate = endDate
      .clone()
      .subtract(7, 'day')
      .startOf('day');
  }
  if (q.aggregationType === OEEDurationType.weekly) {
    endDate = endDate.endOf('isoWeek');
    startDate = endDate.clone().startOf('isoWeek');
    if (aggregation === 'oee') {
      startDate = endDate
        .clone()
        .subtract(11, 'week')
        .startOf('isoWeek');
    }
  }
  if (q.aggregationType === OEEDurationType.monthly) {
    endDate = endDate.endOf('month');
    startDate = endDate.clone().startOf('month');
    if (aggregation === 'oee') {
      startDate = endDate
        .clone()
        .subtract(11, 'month')
        .startOf('month');
    }
  }

  return {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  };
}

export function getCalendarInterval(aggregationType: OEEDurationType): unitOfTime.Base {
  switch (aggregationType) {
    case OEEDurationType.daily:
      return 'day';
    case OEEDurationType.weekly:
      return 'week';
    case OEEDurationType.monthly:
      return 'month';
    default:
      return 'day';
  }
}
