import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  API_INDEX_OBJECTIVE_PATH,
  API_CREATE_OBJECTIVE_PATH,
  API_UPDATE_OBJECTIVE_PATH,
} from '../../utils/routeHelper';
import { Objective, ObjectiveData } from '../../common/models/objective';
import { OEEFilterState, oeeFilterSelector } from './oeeFilter';
import { updateCollection, addToCollection } from '../../utils/collection';
import { error } from '../../utils/error';
import { queryToParams } from '../../utils/appHelper';
import { SIGNOUT } from './auth';

export const INDEX_OBJECTIVE = 'objectives/INDEX_OBJECTIVE';
export const INDEX_OBJECTIVE_SUCCESS = 'objectives/INDEX_OBJECTIVE_SUCCESS';
export const INDEX_OBJECTIVE_FAIL = 'objectives/INDEX_OBJECTIVE_FAIL';
export const CREATE_OBJECTIVE = 'objectives/CREATE_OBJECTIVE';
export const CREATE_OBJECTIVE_SUCCESS = 'objectives/CREATE_OBJECTIVE_SUCCESS';
export const CREATE_OBJECTIVE_FAIL = 'objectives/CREATE_OBJECTIVE_FAIL';
export const UPDATE_OBJECTIVE = 'objectives/UPDATE_OBJECTIVE';
export const UPDATE_OBJECTIVE_SUCCESS = 'objectives/UPDATE_OBJECTIVE_SUCCESS';
export const UPDATE_OBJECTIVE_FAIL = 'objectives/UPDATE_OBJECTIVE_FAIL';
export const RESET_OBJECTIVE_DATA = 'objectives/RESET_OBJECTIVE_DATA';

interface ObjectiveState {
  data?: Array<Objective>;
  loading: boolean;
  creating?: boolean;
  updating?: boolean;
  error?: string;
  createError?: string;
  updateError?: string;
}

export interface IndexObjectiveQuery {
  plantId: string;
  line: string;
}

const initialState: ObjectiveState = {
  data: [],
  loading: false,
  error: undefined,
};

export default function reducer(
  state: ObjectiveState = initialState,
  action: ActionResult,
): ObjectiveState {
  switch (action.type) {
    case INDEX_OBJECTIVE:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_OBJECTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_OBJECTIVE_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case CREATE_OBJECTIVE:
      return {
        ...state,
        creating: true,
        createError: undefined,
      };
    case CREATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        creating: false,
        data: addToCollection(state.data, action.result.data),
      };
    case CREATE_OBJECTIVE_FAIL:
      return {
        ...state,
        creating: false,
        createError: error(action),
      };
    case UPDATE_OBJECTIVE:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_OBJECTIVE_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case RESET_OBJECTIVE_DATA:
      return initialState;
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexObjective(query: IndexObjectiveQuery): ActionPromise {
  return {
    types: [INDEX_OBJECTIVE, INDEX_OBJECTIVE_SUCCESS, INDEX_OBJECTIVE_FAIL],
    promise: client => client.get(API_INDEX_OBJECTIVE_PATH(queryToParams(query))),
  };
}

export function createObjective(data: ObjectiveData): ActionPromise {
  return {
    types: [CREATE_OBJECTIVE, CREATE_OBJECTIVE_SUCCESS, CREATE_OBJECTIVE_FAIL],
    promise: client => client.post(API_CREATE_OBJECTIVE_PATH, data),
  };
}

export function updateObjective(id: string, data: ObjectiveData): ActionPromise {
  return {
    types: [UPDATE_OBJECTIVE, UPDATE_OBJECTIVE_SUCCESS, UPDATE_OBJECTIVE_FAIL],
    promise: client => client.put(API_UPDATE_OBJECTIVE_PATH(id), data),
  };
}

export function resetObjective(): ActionPreload {
  return { type: RESET_OBJECTIVE_DATA };
}

export const objectivesSelector = state => state.objectives.data;

export const getObjectiveSelector = createSelector(
  objectivesSelector,
  oeeFilterSelector,
  (objectives: Objective[], filter: OEEFilterState): Objective | void => {
    return _.find(objectives, {
      plantId: filter.plantId,
      line: filter.line,
      year: filter.date.getFullYear(),
    });
  },
);
