import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const SidebarItemLink = ({ item, onClick, currentPath, children }) => {
  const { t } = useTranslation();

  const itemClassName = classNames('sidebar-item__link', {
    active: currentPath === item.path,
  });

  if (item.children) {
    return (
      <button className={itemClassName} onClick={onClick}>
        {children}
      </button>
    );
  }

  if (item.externalUrl) {
    return (
      <a
        href={item.externalUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={itemClassName}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  if (item.i18nUrl) {
    return (
      <a
        href={t(item.i18nUrl)}
        target="_blank"
        rel="noopener noreferrer"
        className={itemClassName}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  if (item.path) {
    return (
      <Link to={item.path} className={itemClassName} onClick={onClick}>
        {children}
      </Link>
    );
  }
  return null;
};

SidebarItemLink.propTypes = {
  item: PropTypes.shape({
    children: PropTypes.array,
    externalUrl: PropTypes.string,
    i18nUrl: PropTypes.string,
  }).isRequired,
  currentPath: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default React.memo(SidebarItemLink);
