import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form, Textarea } from 'formsy-react-components';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import BaseForm from '../Form/BaseForm';
import { OEEInputType } from '../../../common/models/oeeInput';
import { OEEDurationType } from '../../../common/models/oee';

export class OEEDashboardCommentForm extends BaseForm {
  handleSubmit(data) {
    const { oeeInputType, aggregationType } = this.props;
    this.props.onSubmit(
      Object.assign({ commentType: oeeInputType, duration: aggregationType }, data),
    );
  }

  defaultComment() {
    const { t, oeeInputs, oeeInputType, aggregationType, otherComments } = this.props;
    const loss = oeeInputType === OEEInputType.availability ? 'stopDetail' : 'lossDetail';
    let comment = '';
    if (aggregationType === OEEDurationType.daily || aggregationType === OEEDurationType.weekly) {
      oeeInputs.forEach(oeeInput => {
        oeeInput[oeeInputType][loss].forEach(o => {
          if (o.comment && o.comment !== '') {
            comment += `${moment.utc(oeeInput.date).format(t('date.short'))} - ${o.comment}\n`;
          }
        });
      });
    }
    if (aggregationType === OEEDurationType.monthly) {
      otherComments.forEach(c => {
        if (c.body && c.body !== '') {
          comment += `${moment.utc(c.date).format(t('date.short'))} - ${moment
            .utc(c.date)
            .endOf('isoWeek')
            .format(t('date.short'))} : ${c.body}\n`;
        }
      });
    }
    return comment;
  }

  hasBody = () => {
    return this.props.data && (this.props.data.body || this.props.data.body === '');
  };

  getBody = () => {
    if (this.hasBody()) {
      return this.props.data.body;
    }
    return this.defaultComment();
  };

  getCommentLabel = () => {
    const { t } = this.props;
    return (
      <div className="m-b">
        {t('OEEDashboard.oeeDashboardComment')}
        {_.isNil(this.props.data.body) && this.defaultComment() !== '' && !this.props.disabled && (
          <div className="text-sm font-italic text-warning">
            <i className="fa fa-lightbulb-o" /> {t('OEEDashboard.commentPrefilled')}
          </div>
        )}
      </div>
    );
  };

  showComment = () => {
    const body = this.getBody();
    if (body && body !== '') {
      return body.split('\n').map(t => {
        return <div>{t}</div>;
      });
    }
    return <p>{this.props.t('OEEDashboard.noComment')}</p>;
  };

  render() {
    const { t, print } = this.props;
    return (
      <Row>
        <Col lg={12}>
          <Form
            onSubmit={this.handleSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            {!print && this.getCommentLabel()}
            {!print && (
              <Textarea
                layout="vertical"
                rows={4}
                cols={50}
                name="body"
                value={this.getBody()}
                disabled={this.props.disabled}
                labelClassName="hidden"
              />
            )}
            {print && this.showComment()}
            {!this.props.disabled && (
              <div className="text-center m-t-md">
                <button
                  type="submit"
                  className="btn btn-primary btn-md"
                  formNoValidate
                  disabled={!this.state.canSubmit}
                >
                  {t('common.save')}
                </button>
              </div>
            )}
          </Form>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(OEEDashboardCommentForm);
