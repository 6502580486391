import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col, Alert } from 'react-bootstrap';
import { Form, Input } from 'formsy-react-components';

export class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.enableSubmitButton = this.enableSubmitButton.bind(this);
    this.disableSubmitButton = this.disableSubmitButton.bind(this);
  }

  enableSubmitButton() {
    this.setState({
      canSubmit: true,
    });
  }

  disableSubmitButton() {
    this.setState({
      canSubmit: false,
    });
  }

  handleSubmit(data) {
    this.props.onDefine(data.password).then(this.props.onDefineSuccess, err => {
      this.setState({
        errorMessage: this.props.t(err.error.response.data.message),
      });
    });
  }

  errorMessage() {
    if (this.state.errorMessage) {
      return (
        <Alert bsStyle="danger">
          <p>{this.state.errorMessage}</p>
        </Alert>
      );
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Row>
        <Col md={6} mdOffset={3} lg={4} lgOffset={4}>
          <div className="card card--login card--shadow text-center">
            <div className="card-header bg-white">
              <h2 className="card-title upper">{t('password.defineYourNewPassword')}</h2>
            </div>
            <div className="card-block bg-white">
              {this.errorMessage()}
              <Form
                onSubmit={this.handleSubmit}
                onValid={this.enableSubmitButton}
                onInvalid={this.disableSubmitButton}
              >
                <fieldset>
                  <div className="form-group">
                    <Input
                      type="password"
                      id="inputPassword"
                      name="password"
                      value=""
                      label={t('password.password')}
                      className="form-control form-control-lg"
                      placeholder={t('password.password')}
                      validations="minLength:8"
                      validationError={t('password.passwordTooShort')}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      type="password"
                      id="inputPassword2"
                      name="passwordConfirmation"
                      value=""
                      className="form-control form-control-lg"
                      placeholder={t('password.passwordConfirmation')}
                      label={t('password.confirmation')}
                      validations="equalsField:password"
                      validationError={t('password.passwordsMismatch')}
                      required
                    />
                  </div>
                  <p className="indication-text">{t('password.passwordRequirements')}</p>
                  <button
                    className="btn btn-lg btn-secondary button--primary"
                    type="submit"
                    disabled={!this.state.canSubmit}
                  >
                    {t('password.savePassword')}
                  </button>
                </fieldset>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

ResetPasswordForm.propTypes = {
  onDefine: PropTypes.func.isRequired,
  onDefineSuccess: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ResetPasswordForm);
