import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import BUTable from '../../../components/App/BusinessUnit/BUTable';
import BUModal from '../../../components/App/BusinessUnit/BUModal';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import { createBusinessUnit } from '../../../redux/modules/businessUnits';

export const BUList: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpenBUModal, setIsOpenBUModal] = useState(false);

  const openBUModal = e => {
    e.preventDefault();
    setIsOpenBUModal(true);
  };

  const closeBUModal = () => {
    setIsOpenBUModal(false);
  };

  const handleBUNew = data => {
    dispatch(createBusinessUnit(data)).then(() => {
      closeBUModal();
    });
  };

  return (
    <div className="business_unit-list">
      <PageNav>
        <div className="nav-secondary__right">
          <Button className="btn-lg btn-secondary btn-md-hide-label" onClick={openBUModal}>
            <i className="fa fa-plus m-r" />
            <span>{t('businessUnit.newBusinessUnit')}</span>
          </Button>
          <BUModal
            businessUnit={{}}
            open={isOpenBUModal}
            close={closeBUModal}
            onSubmit={handleBUNew}
          />
        </div>
        <h2 className="nav-secondary__title">{t('businessUnit.businessUnits')}</h2>
      </PageNav>
      <PageContent>
        <div className="card bg-white m-t-lg">
          <div className="card-block">
            <BUTable />
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default BUList;
