import { Style } from 'react-notification-system';

const notificationStyle: Style = {
  NotificationItem: {
    DefaultStyle: {
      padding: '15px',
    },

    success: {
      borderTop: '2px solid #AADC14',
      backgroundColor: '#fff',
      color: '#333',
    },

    error: {
      backgroundColor: '#fff',
      color: '#333',
    },

    warning: {
      backgroundColor: '#fff',
      color: '#333',
    },

    info: {
      backgroundColor: '#fff',
      color: '#333',
    },
  },

  Dismiss: {
    DefaultStyle: {
      backgroundColor: 'transparent',
      color: '#333',
      fontSize: '19px',
    },

    success: {
      backgroundColor: 'transparent',
      color: '#AADC14',
    },
  },
};

export default notificationStyle;
