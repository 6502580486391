import { User } from '../models/user';

type AuthorizationFunction = (
  user: User,
  action: string,
  record: any,
  callback: Function,
) => boolean;

interface Policy {
  index?: AuthorizationFunction;
  create?: AuthorizationFunction;
  show?: AuthorizationFunction;
  update?: AuthorizationFunction;
  destroy?: AuthorizationFunction;
}

export default (policies: Policy) => {
  return {
    isAuthorize: (user: User, action: string, record?: any, callback?: Function) => {
      return policies[action](user, record, callback);
    },
  };
};
