import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Alert } from 'react-bootstrap';
import { Form, Input } from 'formsy-react-components';
import { withTranslation } from 'react-i18next';

class ObjectiveEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edition: false,
      canSubmit: false,
    };
    this.handleShowInput = this.handleShowInput.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  enableButton() {
    this.setState({ canSubmit: true });
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  handleShowInput() {
    this.setState({ edition: true });
  }

  handleSubmit(data) {
    this.props.onSubmit(this.props.objective._id, data);
  }

  handleCancel() {
    this.props.close();
  }

  render() {
    const { open, close, line, objective, t } = this.props;
    return (
      <Modal show={open === objective._id} onHide={close}>
        <Modal.Header>
          <Modal.Title className="text-center">
            {t('objective.changeObjectiveValue', { year: objective.year, line: line.name })}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Alert bsStyle="warning">
            <h4>{t('objective.warning')}</h4>
            <p>{t('objective.warningChangeValue')}</p>
            {!this.state.edition && (
              <p className="m-t">
                <Button bsStyle="danger" onClick={this.handleShowInput}>
                  {t('objective.understood')}
                </Button>
                <Button className="pull-right" onClick={this.handleCancel}>
                  {t('common.cancel')}
                </Button>
              </p>
            )}
          </Alert>
          {this.state.edition && (
            <Form
              onSubmit={this.handleSubmit}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
            >
              <Input
                className="form-control form-control-lg"
                layout="vertical"
                name="value"
                value={objective.value}
                label={t('objective.objective')}
                type="number"
                required
              />

              <div className="text-center m-t-md">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  formNoValidate
                  disabled={!this.state.canSubmit}
                >
                  {t('common.valid')}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-lg"
                  onClick={this.handleCancel}
                >
                  {t('common.cancel')}
                </button>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

ObjectiveEditModal.propTypes = {
  open: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  line: PropTypes.object.isRequired,
  objective: PropTypes.object.isRequired,
};

export default withTranslation()(ObjectiveEditModal);
