import { combineReducers } from 'redux';
import auth from './auth';
import businessUnits from './businessUnits';
import news from './news';
import notification from './notification';
import plants from './plants';
import profile from './profile';
import regions from './regions';
import categories from './categories';
import categoryItems from './categoryItems';
import users from './users';
import suez from './suez';
import oeeInputs from './oeeInputs';
import oeeFilter from './oeeFilter';
import objectives from './objectives';
import oee from './oee';
import oeeDashboard from './oeeDashboard';
import oeeBudgetChallenges from './oeeBudgetChallenges';
import oeeDashboardComments from './oeeDashboardComments';

const rootReducer = combineReducers({
  auth,
  businessUnits,
  news,
  notification,
  plants,
  profile,
  regions,
  categories,
  categoryItems,
  users,
  suez,
  oeeInputs,
  oeeFilter,
  objectives,
  oee,
  oeeDashboard,
  oeeBudgetChallenges,
  oeeDashboardComments,
});

export default rootReducer;
