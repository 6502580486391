import _ from 'lodash';
import i18n from 'i18next';

export function error(action: { error?: {} }) {
  let message = _.get(action, 'error.response.data.message');
  if (!message) message = _.get(action, 'error.response.data');
  if (!message) message = _.get(action, 'error.message');
  if (!message) message = action.error;
  if (!message) message = JSON.stringify(action);
  if (_.startsWith(message, 'errors.')) message = i18n.t(message);
  return message;
}
