import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PlotlyChart from '../PlotlyChart/PlotlyChart';
import OEELegends from './OEELegends';
import { OEEDurationType } from '../../../common/models/oee';
import {
  getRangeByAggregation,
  getCalendarInterval,
} from '../../../common/utils/oeeDashboardHelper';
import {
  isHaveData,
  getInvisibleLists,
  getTimeDuration,
  OEE_INDICATORS,
  COLORS,
} from '../../../utils/oeeHelper';

export function getOEERange(aggregation, filter) {
  const range: any = [];
  const { startDate, endDate } = getRangeByAggregation(aggregation, filter);
  const duration = getCalendarInterval(filter.aggregationType);
  const start = moment(startDate);
  const end = moment(endDate);
  let diff = 0;
  if (filter.aggregationType !== OEEDurationType.daily) {
    diff = -1;
  }
  do {
    range.push(start.clone());
  } while (start.add(1, duration).diff(end, duration) <= diff);
  if (filter.aggregationType !== OEEDurationType.daily) {
    range.push(start.clone());
  }
  return range;
}

export function rangeFormat(date, aggregationType): string {
  if (aggregationType === OEEDurationType.monthly) {
    return date.format('MMM YY');
  }
  if (aggregationType === OEEDurationType.weekly) {
    return `${date.format('DD')} - ${date
      .clone()
      .endOf('isoWeek')
      .format('DD')} ${date.format('MMM')}`;
  }
  return date.format('DD MMM');
}

function buildOEEChartData(oeeData, filter, invisibleLists, t, showText = false) {
  if (!isHaveData(oeeData.oee)) {
    return [];
  }

  const range = getOEERange('oee', filter);
  const x = range.map(d => rangeFormat(d, filter.aggregationType));

  const traces = OEE_INDICATORS.map(indicator => {
    const y: any = [];
    range.forEach(i => {
      const data = _.find(oeeData.oee, d => {
        return i.isSame(d.key, getTimeDuration(filter.aggregationType));
      });
      if (data && data[indicator]) {
        y.push(_.round(data[indicator].value, 1));
      } else {
        y.push(null);
      }
    });
    let text = {};
    if (showText) {
      text = {
        text: y.map(v => `${v}%`),
        textposition: 'auto',
      };
    }
    const trace: any = {
      x,
      y,
      type: 'bar',
      name: t(`OEE.indicators.${indicator}`),
      marker: { color: COLORS[indicator] },
      visible: invisibleLists.indexOf(indicator) === -1,
      ...text,
    };
    if (indicator === 'oeeWasteTreated') {
      trace.type = 'scatter';
      trace.marker.size = 8;
      trace.line = {
        width: 3,
      };
      trace.mode = 'markers+lines';
    }
    return trace;
  });
  const yBudget: any = [];
  const yChallenge: any = [];
  range.forEach(i => {
    const oeeBudgetChallenge = _.find(oeeData.oeeBudgetChallenges, d => {
      return i.isSame(d.date, getTimeDuration(filter.aggregationType));
    });
    if (oeeBudgetChallenge) {
      yBudget.push(_.round(oeeBudgetChallenge.oeeWasteTreated * 100, 1));
      yChallenge.push(_.round(oeeBudgetChallenge.oeeWasteTreatedChallenge * 100, 1));
    } else {
      yBudget.push(null);
      yChallenge.push(null);
    }
  });
  traces.push({
    x,
    y: yBudget,
    type: 'scatter',
    marker: { color: COLORS.oeeWasteTreatedBudget, size: 8 },
    line: {
      width: 3,
    },
    mode: 'markers+lines',
    name: t(`OEE.indicators.oeeWasteTreatedBudget`),
    visible: invisibleLists.indexOf('oeeWasteTreatedBudget') === -1,
  });
  traces.push({
    x,
    y: yChallenge,
    type: 'scatter',
    marker: { color: COLORS.oeeWasteTreatedChallenge, size: 8 },
    line: {
      width: 3,
    },
    mode: 'markers+lines',
    name: t(`OEE.indicators.oeeWasteTreatedChallenge`),
    visible: invisibleLists.indexOf('oeeWasteTreatedChallenge') === -1,
  });

  return traces;
}

const barLayout = {
  height: 300,
  autosize: true,
  showlegend: false,
  barmode: 'group',
  font: {
    family: 'Montserrat, sans-serif',
  },
  hoverlabel: { namelength: -1 },
};

const OEEChart: React.FC<any> = ({
  data,
  layout,
  filter,
  aggregationType,
  loading,
  aggregations,
  aggs,
  print,
}) => {
  const { t } = useTranslation();
  const q = Object.assign({ aggregationType }, filter);
  const legends = OEE_INDICATORS.concat(['oeeWasteTreatedBudget', 'oeeWasteTreatedChallenge']);
  return (
    <div>
      <PlotlyChart
        data={buildOEEChartData(data, q, getInvisibleLists(aggs, aggregations), t, print)}
        layout={_.merge(
          {
            xaxis: {
              range: [-0.5, isHaveData(data.oee) ? getOEERange('oee', q).length - 0.5 : null],
              fixedrange: false,
            },
            yaxis: { range: [0, 119], fixedrange: false },
          },
          barLayout,
          layout,
        )}
        loading={loading}
      />
      {isHaveData(data.oee) && (
        <OEELegends
          legends={legends}
          categoryItems={[]}
          aggregations={aggregations}
          aggs={aggs}
          customClasses={{
            oeeWasteTreated: 'round',
            oeeWasteTreatedBudget: 'round',
            oeeWasteTreatedChallenge: 'round',
          }}
        />
      )}
    </div>
  );
};

export default OEEChart;
