import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import homeMap from '../../assets/images/home-map.png';
import homeProjectTracker from '../../assets/images/home-project-tracker.png';
import homeOee from '../../assets/images/home-oee.png';
import logoSuezHW from '../../assets/images/logo-suez-hw.png';
import UnreadNews from '../../components/App/UnreadNews/UnreadNews';
import * as routeHelper from '../../utils/routeHelper';

const AppHome: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="connected-home">
      <div className="connected-home__header">
        <img src={logoSuezHW} alt="SUEZ HW" className="logo-suez-hw m-b" />
        <h1 className="header__title">{t('appHome.welcome')}</h1>
        <h1 className="header__title">{t('common.hwName')}</h1>
        <h4 className="header__subtitle">{t('appHome.subTitle')}</h4>
      </div>
      <div className="connected-home__main">
        <div className="main__content container">
          <Row>
            <Col lg={12}>
              <div className="alert alert-danger" role="alert">
                <div dangerouslySetInnerHTML={{ __html: t('securityAlert.message') }} />
              </div>
            </Col>
          </Row>
          <Row className="text-center">
            <Col lg={4}>
              <Link className="shadow-box" to={routeHelper.APP_OEE_INPUT_PATH}>
                <img src={homeMap} alt={t('appHome.plantsMap')} />
                <div className="shadow-box__overlay">
                  <i className="shadow-box__icon fa fa-map-marker" />
                  <span className="shadow-box__label">{t('appHome.plantsMap')}</span>
                </div>
              </Link>
            </Col>
            <Col lg={4}>
              <a
                className="shadow-box"
                href="https://one4u.sharepoint.com/sites/DIPIRRWASTE/HW/SitePages/Performance%20-%20R%26R%20Hazardous%20Waste.aspx"
                rel="noopener noreferrer"
                target="_black"
              >
                <img src={homeOee} alt={t('appHome.dataInput')} />
                <div className="shadow-box__overlay">
                  <i className="shadow-box__icon fa fa-line-chart" />
                  <span className="shadow-box__label">{t('appHome.dataInput')}</span>
                </div>
              </a>
            </Col>
            <Col lg={4}>
              <a
                className="shadow-box"
                href="https://one4u.sharepoint.com/sites/DIPIRRWASTE/HW/SitePages/Performance%20-%20R%26R%20Hazardous%20Waste.aspx"
                rel="noopener noreferrer"
                target="_black"
              >
                <img src={homeProjectTracker} alt={t('appHome.KPIs')} />
                <div className="shadow-box__overlay">
                  <i className="shadow-box__icon fa fa-suitcase" />
                  <span className="shadow-box__label">{t('appHome.KPIs')}</span>
                </div>
              </a>
            </Col>
          </Row>
          <div className="main__presentation">
            <h2>{t('appHome.app')}</h2>
            <p>{t('appHome.description')}</p>
            <p
              className="custom-html-description"
              dangerouslySetInnerHTML={{ __html: t('appHome.description2_html') }}
            />
            <p
              className="custom-html-description"
              dangerouslySetInnerHTML={{ __html: t('appHome.description3_html') }}
            />
            <p
              className="custom-html-description"
              dangerouslySetInnerHTML={{ __html: t('appHome.description4_html') }}
            />
          </div>
        </div>
      </div>
      <UnreadNews />
    </div>
  );
};

export default AppHome;
