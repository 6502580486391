import _ from 'lodash';
import moment, { unitOfTime } from 'moment';
import { OEEDurationType } from '../common/models/oee';

export function roundOEEInput(num: number | null | void, fixed = 2): number | null {
  if (num === undefined || num === null) {
    return null;
  }
  return _.round(num, fixed);
}

export function numberFormat(num: number | null | void, locale): string | null {
  if (num === undefined || num === null) {
    return null;
  }
  return new Intl.NumberFormat(locale).format(num);
}

export function isHaveData(data) {
  return data && Object.keys(data).length > 0;
}

export function lossTotalTitle(title, data): string {
  if (data && data.length > 0) {
    const sum = _.round(_.sumBy(data, 'value'));
    return `${title} : ${sum}h`;
  }
  return title;
}

export function getInvisibleLists(aggs, aggregations) {
  return _.get(aggregations, `${aggs}.invisibleLists`, []);
}

export function filterLossOrStop(data, aggs, aggregations) {
  const invisibleLists = getInvisibleLists(aggs, aggregations);
  return _.filter(data, d => {
    return !_.includes(invisibleLists, d.key);
  });
}

export function getMaxStopOrLoss(data, aggs, aggregations) {
  return _.maxBy(filterLossOrStop(data, aggs, aggregations), 'value');
}

export function getMinStopOrLoss(data, aggs, aggregations) {
  return _.minBy(filterLossOrStop(data, aggs, aggregations), 'value');
}

export function getTimeDuration(aggregationType: OEEDurationType): unitOfTime.StartOf {
  switch (aggregationType) {
    case OEEDurationType.daily:
      return 'day';
    case OEEDurationType.weekly:
      return 'isoWeek';
    case OEEDurationType.monthly:
      return 'month';
    default:
      return 'day';
  }
}

export function oeeExportFilename(query): string {
  const plants = _.uniqBy(query.lines, 'plantId');
  let plantSlug = `${plants.length}_plants`;
  if (plants.length === 1) {
    plantSlug = plants[0].plantSlug;
  }
  return `OEE_${plantSlug}_${moment(query.startDate).format('YYYY-MM-DD')}-${moment(
    query.endDate,
  ).format('YYYY-MM-DD')}.xlsx`;
}

export const OEE_INDICATORS = ['oeeWasteTreated', 'availability', 'performance', 'quality'];
export const COLORS = {
  availability: '#5EA5DB',
  performance: '#27628C',
  quality: '#626262',
  oeeWasteTreated: 'RGB(111, 40, 157)',
  realOeeSteam: '#B7DF17',
  useFactor: '#1E74B4',
  none: '#E0E0E0',
  plannedStop: '#00BA63',
  unplannedStop: '#FFC900',
  realPlannedStop: '#00BA63',
  realUnplannedStop: '#FFC900',
  speedLosses: '#8548B0',
  qualityLosses: '#6DACDE',
  oeeWasteTreatedReal: 'RGB(111, 40, 157)',
  oeeWasteTreatedBudgetBudget: 'RGB(193, 194, 203)',
  oeeWasteTreatedBudgetChallenge: 'RGB(225, 239, 170)',
  oeeBudgetChallenge: 'RGB(171, 222, 52)',
  oeeWasteTreatedBudget: 'RGB(225, 239, 170)',
  oeeWasteTreatedChallenge: 'RGB(171, 222, 52)',
};
export const CATEGORY_COLORS = [
  '#1E74B4',
  '#FF8000',
  '#2BA330',
  '#D6211E',
  '#9461BD',
  '#FFC900',
  '#8C564A',
  '#E371C1',
  '#BCC122',
  '#14BED0',
  '#00BA63',
  '#001F3F',
  '#85144B',
  '#7F7F7F',
  '#01FF70',
  '#34495E',
  '#FCEEBF',
  '#DDB974',
  '#065060',
  '#E56E9C',
  '#1A64B4',
  '#F28000',
  '#2BBBB0',
  '#D6525E',
  '#9560BD',
  '#F0C1E0',
  '#8C564A',
  '#E1F3C1',
  '#BCF192',
  '#14BED0',
  '#01BB63',
  '#051F9F',
  '#811F4B',
  '#7F6F1F',
  '#01F180',
  '#34885E',
  '#FC11BF',
  '#DD2274',
  '#061F60',
  '#E5119C',
  '#1E61B4',
  '#F11000',
  '#2B1F30',
  '#D61F1E',
  '#9411BD',
  '#FF6600',
  '#8C1F4A',
  '#E311C1',
  '#BC1F22',
  '#1411D0',
  '#001163',
  '#00663F',
  '#851F4B',
  '#7F117F',
  '#011170',
  '#341F5E',
];
