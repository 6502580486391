import React, { useState } from 'react';
import _ from 'lodash';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

interface ConfirmButtonProps {
  title?: string;
  message: string;
  onClick: Function;
  children?: JSX.Element;
  confirmButtonStyle?: string;
  element?: string;
  value?: any;
  className?: string;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = props => {
  const { title, message, confirmButtonStyle, onClick, value, element, children } = props;
  const _element: string = element || 'button';
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const openConfirmModal = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!isOpenConfirmModal) {
      setIsOpenConfirmModal(true);
    }
  };

  const closeConfirmModal = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpenConfirmModal(false);
  };

  const handleConfirm = () => {
    setIsOpenConfirmModal(false);
    return onClick(value);
  };

  const buildConfirmModal = () => {
    return (
      <ConfirmModal
        open={isOpenConfirmModal}
        close={closeConfirmModal}
        title={title}
        onConfirm={handleConfirm}
        confirmButtonStyle={confirmButtonStyle}
      >
        <span>{message}</span>
      </ConfirmModal>
    );
  };

  const elementProps = _.omit(Object.assign({}, props, { onClick: openConfirmModal }), [
    'title',
    'message',
    'children',
    'confirmButtonStyle',
    'element',
  ]);
  return React.createElement(_element, elementProps, buildConfirmModal(), children);
};

export default ConfirmButton;
