import { Model } from './model';

export interface SignInStatsType {
  signInCount: number;
  currentSignInAt: Date;
  lastSignInAt: Date;
  currentSignInIp: string;
  lastSignInIp: string;
}

export enum Role {
  admin = 'admin',
  user = 'user',
}

export enum UserPolicies {
  plantRead = 'plantRead',
  plantWrite = 'plantWrite',
  oeeRead = 'oeeRead',
  oeeWrite = 'oeeWrite',
}

const policyKeys = Object.keys(UserPolicies);
type policyTypes = typeof policyKeys[number];
export type UserPoliciesType = { [t in policyTypes]: string[] };

export interface UserData {
  email?: string;
  firstName?: string;
  lastName?: string;
  role?: Role;
  phone?: string;
  avatar?: string;
  disabled?: boolean;
  policies?: UserPoliciesType;
}

export interface User extends Model, UserData {
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  last?: Date;
  attempts?: number;
  lastPasswordChangedAt?: Date;
  resetPasswordToken?: string;
  signInStats?: SignInStatsType;
  // virtual field
  name: string;
  passwordIsExpired: boolean;
}
