import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SelectFilter from '../Filter/SelectFilter';
import { ALL } from '../../../common/filter';
import { Role } from '../../../common/models/user';
import { isAdmin } from '../../../utils/userHelper';

class UserFilter extends Component {
  constructor(props) {
    super(props);
    this.state = props.filter;
    this.filterByName = this.filterByName.bind(this);
    this.filterByRole = this.filterByRole.bind(this);
  }

  filterByRole(role) {
    const newFilter = Object.assign({}, this.state, { role });
    this.handleFilter(newFilter);
  }

  filterByName(e) {
    const name = e.target.value;
    const newFilter = Object.assign({}, this.state, { name });
    this.handleFilter(newFilter);
  }

  handleFilter(filter) {
    this.setState(filter);
    this.props.onChange(filter);
  }

  buildRolesOptions() {
    const { t } = this.props;
    return [
      { value: ALL, label: t('user.allUsers') },
      { value: Role.admin, label: t('user.admin') },
      { value: Role.user, label: t('user.user') },
    ];
  }

  render() {
    const { name, role } = this.state;
    const { t, currentUser } = this.props;
    return (
      <form className="">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="form-group">
              <input
                name="query"
                type="text"
                className="form-control"
                placeholder={t('common.search')}
                onChange={this.filterByName}
                value={name}
              />
            </div>
          </div>
          {isAdmin(currentUser) && (
            <div className="col-lg-3 col-sm-6">
              <div className="form-group">
                <SelectFilter
                  options={this.buildRolesOptions()}
                  onChange={this.filterByRole}
                  value={role}
                />
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

UserFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UserFilter);
