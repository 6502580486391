import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Node } from '../TreeNodes/TreeNodes';
import { UserPolicies } from '../../../common/models/user';

const PolicyActions = props => {
  const updateNode = (node, key, value) => {
    node[key] = value;
    if (node.nodes.length > 0) {
      node.nodes.forEach(n => {
        updateNode(n, key, value);
      });
    }
  };

  const toggle = key => {
    return e => {
      const node = Object.assign({}, props.node);
      const isChecked = e.target.checked;
      updateNode(node, key, isChecked);

      if (key === UserPolicies.plantWrite && isChecked) {
        updateNode(node, UserPolicies.plantRead, isChecked);
      }
      if (key === UserPolicies.plantRead && !isChecked) {
        updateNode(node, UserPolicies.plantWrite, isChecked);
      }
      if (key === UserPolicies.oeeWrite && isChecked) {
        updateNode(node, UserPolicies.oeeRead, isChecked);
      }
      if (key === UserPolicies.oeeRead && !isChecked) {
        updateNode(node, UserPolicies.oeeWrite, isChecked);
      }
      props.onChange(node, key, isChecked);
    };
  };

  return (
    <div className="node__actions">
      <div className="node__action">
        <input
          type="checkbox"
          checked={props.node[UserPolicies.plantRead] === true}
          onChange={toggle(UserPolicies.plantRead)}
        />
      </div>
      <div className="node__action">
        <input
          type="checkbox"
          checked={props.node[UserPolicies.plantWrite] === true}
          onChange={toggle(UserPolicies.plantWrite)}
        />
      </div>
      <div className="node__action">
        <input
          type="checkbox"
          checked={props.node[UserPolicies.oeeRead] === true}
          onChange={toggle(UserPolicies.oeeRead)}
        />
      </div>
      <div className="node__action">
        <input
          type="checkbox"
          checked={props.node[UserPolicies.oeeWrite] === true}
          onChange={toggle(UserPolicies.oeeWrite)}
        />
      </div>
    </div>
  );
};

PolicyActions.propTypes = {
  node: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default class PoliciesTree extends Component {
  constructor(props) {
    super(props);
    this.handleNodeChange = this.handleNodeChange.bind(this);
  }

  handleNodeChange(node) {
    const nodes = [...this.props.policies];
    nodes.forEach(n => {
      if (n.id === node.id) {
        Object.assign(n, node);
      }
    });
    this.props.onChange(nodes);
  }

  render() {
    return (
      <div className="treenode">
        {this.props.policies.map(node => (
          <Node
            key={node.id}
            node={node}
            onChange={this.handleNodeChange}
            actions={PolicyActions}
          />
        ))}
      </div>
    );
  }
}

PoliciesTree.propTypes = {
  policies: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
