import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import ModalWrapper from '../Modal/ModalWrapper';
import RegionForm from './RegionForm';

const RegionModal = ({ region, businessUnits, open, close, onSubmit, users, t }) => {
  return (
    <ModalWrapper title={t('region.region')} open={open} close={close}>
      <RegionForm data={region} businessUnits={businessUnits} users={users} onSubmit={onSubmit} />
    </ModalWrapper>
  );
};

RegionModal.propTypes = {
  region: PropTypes.object.isRequired,
  businessUnits: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(RegionModal);
