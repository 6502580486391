import _ from 'lodash';
import {
  API_CURRENT_USER_PATH,
  API_CURRENT_USER_CHANGE_PASSWORD_PATH,
} from '../../utils/routeHelper';
import { SIGNOUT } from './auth';
import { error } from '../../utils/error';

export const GET_PROFILE = 'profile/GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'profile/GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'profile/GET_PROFILE_FAIL';
export const UPDATE_PROFILE = 'profile/UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'profile/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'profile/UPDATE_PROFILE_FAIL';
export const CHANGE_PASSWORD = 'profile/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'profile/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'profile/CHANGE_PASSWORD_FAIL';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export default function reducer(state = initialState, action: ActionResult) {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: error(action),
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        updating: true,
        updateError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updating: false,
        data: action.result.data,
      };
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        updating: false,
        data: null,
        error: error(action),
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.result.data,
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function getProfile(): ActionPromise {
  return {
    types: [GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL],
    promise: client => client.get(API_CURRENT_USER_PATH),
  };
}

export function updateProfile(data): ActionPromise {
  const formData = new FormData();
  _.forEach(_.keys(data), key => {
    if (key !== 'avatar' && key !== 'avatarCrop' && !_.isNil(data[key])) {
      formData.append(`user[${key}]`, data[key]);
    }
  });
  if (data.avatar && data.avatar[0] && data.avatarCrop) {
    const avatar = data.avatarCrop;
    avatar.name = data.avatar[0].name;

    formData.append('avatar', avatar);
  }
  return {
    types: [UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL],
    promise: client => client.put(API_CURRENT_USER_PATH, formData),
  };
}

export function changePassword(data): ActionPromise {
  return {
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    promise: client => client.put(API_CURRENT_USER_CHANGE_PASSWORD_PATH, data),
  };
}
