import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../Modal/ModalWrapper';

const OEEInputHelpButton = () => {
  const { t } = useTranslation();
  const [openValidationHelpModal, setOpenValidationHelpModal] = useState(false);
  const triggerOpenValidationHelpModal = () => {
    setOpenValidationHelpModal(!openValidationHelpModal);
  };
  return (
    <span
      className="oee-input__label oee-input__label--help"
      onClick={triggerOpenValidationHelpModal}
    >
      <i className="fa fa-question" /> {t('OEEInput.help.help')}
      <ModalWrapper
        title={t('OEEInput.help.validation')}
        open={openValidationHelpModal}
        close={triggerOpenValidationHelpModal}
      >
        <div dangerouslySetInnerHTML={{ __html: t('OEEInput.help.validationConditions') }} />
      </ModalWrapper>
    </span>
  );
};

export default OEEInputHelpButton;
