import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import fp from 'lodash/fp';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import UserForm from '../../../components/App/User/UserForm';
import Policies from '../../../components/App/User/Policies';
import { UserData } from '../../../common/models/user';
import { identifier } from '../../../common/models/model';
import { showUser, updateUser, userSelector } from '../../../redux/modules/users';
import { indexSuez } from '../../../redux/modules/suez';
import loadData from '../../../utils/loadData';

interface RouteParams {
  id: string;
}
interface UserEditProps extends RouteComponentProps<RouteParams> {}

const UserEdit: React.FC<UserEditProps> = ({ match, history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState<string>('basicInfo');
  const user = useSelector(userSelector(match.params.id));
  const suez = useSelector(state => state.suez.data);

  const navClassName = (view: string) => {
    return classNames({ active: currentView === view });
  };

  const editUser = (view: string) => {
    return e => {
      e.preventDefault();
      setCurrentView(view);
    };
  };

  const handleUpdateUser = (data: UserData) => {
    dispatch(updateUser(user[identifier], data));
  };

  const buildCurrentView = () => {
    if (currentView === 'policies') {
      return (
        <Policies
          user={user || {}}
          policies={suez}
          onSubmit={handleUpdateUser}
          onCancel={history.goBack}
        />
      );
    }
    return <UserForm data={user || {}} onSubmit={handleUpdateUser} onCancel={history.goBack} />;
  };

  return (
    <div className="users-edit">
      <PageNav>
        <h2 className="nav-secondary__title">{t('user.editUser')}</h2>
      </PageNav>
      <PageContent>
        <Row>
          <Col>
            <div className="tabs tabs--secondary m-t-lg">
              <div className="tabs-nav">
                <ul>
                  <li className={navClassName('basicInfo')}>
                    <a href="#\" onClick={editUser('basicInfo')}>
                      {t('user.basicInformations')}
                    </a>
                  </li>
                  <li className={navClassName('policies')}>
                    <a href="#\" onClick={editUser('policies')}>
                      {t('user.policies')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tabs-content">{buildCurrentView()}</div>
            </div>
          </Col>
        </Row>
      </PageContent>
    </div>
  );
};

export default fp.compose(
  withRouter,
  loadData(async (dispatch, state, { match }) => {
    const id = match.params.id;
    const user = userSelector(id)(state);
    if (!user) {
      await dispatch(showUser(id));
    }
    dispatch(indexSuez());
  }),
)(UserEdit);
