import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectFilter from '../Filter/SelectFilter';
import { LineType } from '../../../common/models/plant';
import { LossType, TreatedWasteLossType } from '../../../common/models/category';
import { ALL } from '../../../common/filter';

interface CategoryFilterProps {
  onChange: Function;
}

const CategoryFilter: React.FC<CategoryFilterProps> = props => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<any>({
    lineType: ALL,
    lossType: ALL,
  });

  const buildLineTypeOptions = () => {
    const options: any[] = Object.values(LineType).map(value => {
      return { value, label: t(`plant.lineTypes.${value}`) };
    });
    options.unshift({ value: ALL, label: t('common.all') });
    return options;
  };

  const buildLossTypeOptions = () => {
    const options: any[] = Object.values(LossType).map(value => {
      return { value, label: t(`category.lossType.${value}`) };
    });
    Object.values(TreatedWasteLossType).forEach(value => {
      options.push({
        value,
        label:
          t(`category.lossType.${LossType.treatedWaste}`) + ' - ' + t(`category.lossType.${value}`),
      });
    });
    options.unshift({ value: ALL, label: t('common.all') });
    return options;
  };

  const handleSelectChange = (key: string) => {
    return value => {
      const newFilter = { ...filter, [key]: value };
      setFilter(newFilter);
      props.onChange(newFilter);
    };
  };

  return (
    <div>
      <form className="form-inline">
        <div className="form-group">
          <label className="control-label m-r">{t('category.filterByLineType')}</label>
          <SelectFilter
            options={buildLineTypeOptions()}
            onChange={handleSelectChange('lineType')}
            value={filter.lineType}
          />
        </div>
        <div className="form-group m-l">
          <label className="control-label m-r">{t('category.filterByLossType')}</label>
          <SelectFilter
            options={buildLossTypeOptions()}
            onChange={handleSelectChange('lossType')}
            value={filter.lossType}
          />
        </div>
      </form>
    </div>
  );
};

export default CategoryFilter;
