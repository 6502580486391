import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import OEEChart from '../../components/App/OEEDashboard/OEEChart';
import OEESelectedChart from '../../components/App/OEEDashboard/OEESelectedChart';
import OEEGaugeChart from '../../components/App/OEEDashboard/OEEGaugeChart';
import AvailabilityLossesChart from '../../components/App/OEEDashboard/AvailabilityLossesChart';
import TreatedWasteLossesChart from '../../components/App/OEEDashboard/TreatedWasteLossesChart';
import OEEDashboardComment from '../../components/App/OEEDashboard/OEEDashboardComment';
import loadData from '../../utils/loadData';
import { OEEFilterState, updateOEEFilter } from '../../redux/modules/oeeFilter';
import { indexPlants, getPlantAndLineByOEEFilterSelector } from '../../redux/modules/plants';
import {
  fetchOEEDashboard,
  setAggregationType,
  updateAggregations,
} from '../../redux/modules/oeeDashboard';
import { indexCategory } from '../../redux/modules/categories';
import { indexCategoryItem } from '../../redux/modules/categoryItems';
import { fetchOEEDashboardComment } from '../../redux/modules/oeeDashboardComments';
import { OEEDurationType } from '../../common/models/oee';
import { UserPolicies } from '../../common/models/user';
import logo from '../../assets/images/logo.png';
import { NONE, ALL } from '../../common/filter';

const Header = () => {
  const { t } = useTranslation();
  const oeeFilter: OEEFilterState = useSelector(state => state.oeeFilter);
  const plantAndLine = useSelector(getPlantAndLineByOEEFilterSelector);
  const aggregationType: OEEDurationType = useSelector(state => state.oeeDashboard.aggregationType);
  const { plant, line, shift } = plantAndLine;
  return (
    <table className="table table-bordered">
      <tbody>
        <tr>
          <td style={{ width: '166px' }} className="align-middle">
            <img src={logo} alt="SUEZ HW" className="logo" />
          </td>
          <td className="text-center">
            {plant && line && (
              <div className="header--title">{`${plant.name} | ${line.name} | ${
                shift ? `${shift} | ` : ''
              } ${
                aggregationType === OEEDurationType.monthly
                  ? moment(oeeFilter.date).format('MMM YYYY')
                  : moment(oeeFilter.date).format('DD MMM YYYY')
              } | ${t(`OEEDashboard.aggregationType.${aggregationType}`)}`}</div>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const PrintOEEDashboard: React.FC = () => {
  const { t } = useTranslation();
  const oeeFilter: OEEFilterState = useSelector(state => state.oeeFilter);
  const oeeData: any = useSelector(state => state.oeeDashboard.data);
  const plantAndLine = useSelector(getPlantAndLineByOEEFilterSelector);
  const aggregationType: OEEDurationType = useSelector(state => state.oeeDashboard.aggregationType);
  const aggregations: any = useSelector(state => state.oeeDashboard.aggregations);
  const categories = useSelector(state => state.categories.data);
  const categoryItems = useSelector(state => state.categoryItems.data);

  return (
    <Fragment>
      <div className="page A4">
        <Header />
        <div className="row">
          <div className="col-md-12">
            <h4 className="m-b-sm text-center">
              {t('OEEDashboard.titles.oeeByAggregationType', {
                aggregationType: t(`OEEDashboard.aggregationType.${aggregationType}`),
              })}
            </h4>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6">
            <OEEGaugeChart
              data={oeeData}
              layout={{ height: 230, width: 230, margin: { l: 0, r: 0, b: 0 } }}
              filter={oeeFilter}
              aggregationType={aggregationType}
              loading={false}
              print={true}
            />
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 m-t-lg">
            <OEESelectedChart
              data={oeeData}
              layout={{ height: 240, margin: { l: 90 } }}
              filter={oeeFilter}
              aggregationType={aggregationType}
              loading={false}
              print={true}
            />
          </div>
          <div className="col-md-12 m-t-md">
            <AvailabilityLossesChart
              data={oeeData}
              plantAndLine={plantAndLine}
              categories={categories}
              categoryItems={categoryItems}
              loading={false}
              aggregations={aggregations}
              print={true}
              layout={{ height: 290, margin: { t: 30, b: 120, l: 25 } }}
            />
          </div>
          <div className="col-md-12 m-t-md">
            <TreatedWasteLossesChart
              data={oeeData}
              plantAndLine={plantAndLine}
              categories={categories}
              categoryItems={categoryItems}
              loading={false}
              aggregations={aggregations}
              print={true}
              layout={{ height: 290, margin: { t: 30, b: 120, l: 25 } }}
            />
          </div>
        </div>
      </div>
      <div className="page A4">
        <Header />
        <div className="row">
          <div className="col-md-12">
            <h4 className="m-b-md text-center">
              {t('OEEDashboard.titles.oeeEvolution', {
                aggregationType: t(`OEEDashboard.aggregationType.${aggregationType}`),
              })}
            </h4>
          </div>
          <OEEDashboardComment
            plantAndLine={plantAndLine}
            filter={oeeFilter}
            aggregationType={aggregationType}
            print={true}
          />
        </div>
      </div>
      <div className="page A4">
        <Header />
        <div className="row">
          <div className="col-md-12">
            <h4 className="m-b-md text-center">
              {t('OEEDashboard.titles.oeeEvolution', {
                aggregationType: t(`OEEDashboard.aggregationType.${aggregationType}`),
              })}
            </h4>
          </div>
          <div className="col-md-12">
            <OEEChart
              data={oeeData}
              layout={{
                height: 270,
                margin: { t: 5, b: 65, l: 25 },
              }}
              filter={oeeFilter}
              aggregationType={aggregationType}
              loading={false}
              aggregations={aggregations}
              aggs="oee"
              print={true}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default loadData(async (dispatch, state) => {
  //@ts-ignore
  const query = window.query;
  if (query) {
    const filter = {
      businessUnitId: ALL,
      plantId: query.plantId,
      line: query.line,
      shift: query.shift !== NONE ? query.shift : undefined,
      date: new Date(query.date),
      showArchived: false,
      showShift: false,
    };
    dispatch(updateOEEFilter(filter));
    dispatch(setAggregationType(query.aggregationType));
    dispatch(updateAggregations(query.aggregations));
    await dispatch(indexPlants(UserPolicies.oeeRead));
    await dispatch(indexCategory());
    await dispatch(indexCategoryItem());
    await dispatch(fetchOEEDashboard(query));
    await dispatch(fetchOEEDashboardComment(query));
  }
})(PrintOEEDashboard);
