import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Switch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  toggle = e => {
    const value = e.target.checked;
    this.props.onChange(value);
    this.setState({ value });
  };

  render() {
    if (!this.props.horizontalForm) {
      return (
        <div className={`switch ${this.props.className}`}>
          <label className="switch__title" htmlFor="switch">
            {this.props.title}
          </label>
          <span className="switch__action">
            <input
              className="switch--input"
              name="switch"
              type="checkbox"
              checked={this.state.value === true}
              onChange={this.toggle}
            />
          </span>
        </div>
      );
    }
    return (
      <div className="form-group row">
        <label className="control-label col-md-3" htmlFor="switch">
          {this.props.title}
        </label>
        <div className="col-md-9">
          <input
            className="switch--input"
            name="switch"
            type="checkbox"
            checked={this.state.value === true}
            onChange={this.toggle}
          />
        </div>
      </div>
    );
  }
}

Switch.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  horizontalForm: PropTypes.bool,
};
