import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class BaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
    this.enableButton = this.enableButton.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
  }

  handleSubmit() {}

  enableButton() {
    this.setState({
      canSubmit: true,
    });
  }

  disableButton() {
    this.setState({
      canSubmit: false,
    });
  }
}

BaseForm.propTypes = {
  data: PropTypes.object.isRequired,
};
