import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import ImageCropModal from '../Modal/ImageCropModal';
import { PhotoVersion } from '../../../common/upload';
import { plantPhotoUrl } from '../../../utils/plantHelper';
import ValueButton from '../ValueButton/ValueButton';

class PlantPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openImageCropModal: {},
    };
    this.openImageCropModal = this.openImageCropModal.bind(this);
    this.closeImageCropModal = this.closeImageCropModal.bind(this);
    this.handleCropImage = this.handleCropImage.bind(this);
  }

  setDefault(photo) {
    return () => {
      this.props.onSetDefault(photo);
    };
  }

  photoClassName(photo) {
    return classNames({
      default: this.props.plant.defaultPhoto === photo,
      card: true,
    });
  }

  deletePhoto(photo) {
    return () => {
      this.props.onDelete(photo, this.props.category);
    };
  }

  openImageCropModal(i) {
    const { openImageCropModal } = this.state;
    openImageCropModal[i] = true;
    this.setState({ openImageCropModal });
  }

  closeImageCropModal(i) {
    const { openImageCropModal } = this.state;
    openImageCropModal[i] = false;
    this.setState({ openImageCropModal });
  }

  handleCropImage(crop, { photo, category }) {
    this.closeImageCropModal();
    this.props.onUpdate({ cropped: crop.blobResult, photo, category });
  }

  render() {
    const { className, plant, category, t } = this.props;
    return (
      <Row className={`plant-pictures card-deck-wrapper ${className}`}>
        {plant[category].map((photo, i) => (
          <Col sm={6} md={4} key={i}>
            <div
              className={this.photoClassName(photo)}
              style={{
                backgroundImage: `url(${plantPhotoUrl(photo, PhotoVersion.normal, category)})`,
              }}
            >
              <span className="default-check">
                <i className="fa fa-check" title={t('plant.defaultImage')} />
              </span>
              <div className="button-group">
                <Button
                  className="btn-danger m-r"
                  onClick={this.deletePhoto(photo)}
                  title={t('plant.deleteImage')}
                >
                  <i className="fa fa-trash fa-lg" />
                </Button>
                <ValueButton
                  className="btn-info m-r"
                  value={i}
                  onClick={this.openImageCropModal}
                  title={t('plant.editImage')}
                >
                  <i className="fa fa-edit fa-lg" />
                </ValueButton>
                {category === 'photos' && (
                  <Button
                    className="btn-primary"
                    onClick={this.setDefault(photo)}
                    title={t('plant.setAsDefaultImage')}
                  >
                    <i className="fa fa-check fa-lg" />
                  </Button>
                )}
              </div>
              <ImageCropModal
                open={this.state.openImageCropModal[i]}
                close={this.closeImageCropModal}
                onSubmit={this.handleCropImage}
                image={plantPhotoUrl(photo, PhotoVersion.large, category)}
                title={t('plant.cropAndRotate')}
                index={i}
                onSubmitValues={{ photo, category }}
                autoCrop={false}
              />
            </div>
          </Col>
        ))}
      </Row>
    );
  }
}

PlantPhotos.propTypes = {
  plant: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSetDefault: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default withTranslation()(PlantPhotos);
