import { error } from '../../utils/error';
import { API_INDEX_SUEZ_PATH } from '../../utils/routeHelper';
import { UserPolicies } from '../../common/models/user';
import { SIGNOUT } from './auth';

export const INDEX_SUEZ = 'suez/INDEX_SUEZ';
export const INDEX_SUEZ_SUCCESS = 'suez/INDEX_SUEZ_SUCCESS';
export const INDEX_SUEZ_FAIL = 'suez/INDEX_SUEZ_FAIL';

interface SuezState {
  data?: Array<any>;
  loading: boolean;
  error?: string;
}

const initialState: SuezState = {
  data: [],
  loading: false,
  error: undefined,
};

export default function reducer(state: SuezState = initialState, action: ActionResult): SuezState {
  switch (action.type) {
    case INDEX_SUEZ:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_SUEZ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_SUEZ_FAIL:
      return {
        ...state,
        loading: false,
        data: [],
        error: error(action),
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexSuez(scope: UserPolicies = UserPolicies.plantRead): ActionPromise {
  return {
    types: [INDEX_SUEZ, INDEX_SUEZ_SUCCESS, INDEX_SUEZ_FAIL],
    promise: client => client.get(API_INDEX_SUEZ_PATH(scope)),
  };
}
