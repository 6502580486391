import React, { PureComponent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import classNames from 'classnames';
import produce from 'immer';
import Cascader from 'rc-cascader';
import { WithTranslation, withTranslation } from 'react-i18next';
import SelectFilter from '../../Filter/SelectFilter';
import InputNumber from '../../Form/InputNumber';
import OEEInputComment from '../OEEInputComment';
import OEEInputValidation from '../OEEInputValidation';
import { roundOEEInput, numberFormat } from '../../../../utils/oeeHelper';
import { PlantAndLine } from '../../../../redux/modules/plants';
import { OEEInput, OEEInputType, OEE_INPUT_OPENING_TIME } from '../../../../common/models/oeeInput';
import { Category, LossType } from '../../../../common/models/category';
import { CategoryItem } from '../../../../common/models/categoryItem';
import { Objective } from '../../../../common/models/objective';
import {
  buildOEEInputCategoryTreeOptions,
  getCategoryPathIds,
  getCategoryPathNames,
} from '../../../../utils/categoryHelper';
import {
  canValidateOEEInput,
  stopDetailTotal,
  checkOEEInputErrors,
} from '../../../../common/utils/oeeInputHelper';

interface OEEInputAvailabilityProps extends WithTranslation {
  plantAndLine: PlantAndLine;
  data: OEEInput;
  saveOEEInput: Function;
  siblings: {
    prevOEEInput?: OEEInput;
    nextOEEInput?: OEEInput;
    prevShiftOEEInput?: OEEInput;
    nextShiftOEEInput?: OEEInput;
    otherShiftOEEInputs?: OEEInput[];
  };
  canEdit: boolean;
  categories: Category[];
  categoryItems: CategoryItem[];
  objective?: Objective;
  toggleOpenEditOpeningTimeAlert: Function;
}

interface OEEInputAvailabilityState {
  oeeInput: OEEInput;
  isEdit: boolean;
}

function showStopPlannedText(stop, t) {
  if (_.isBoolean(stop.planned)) {
    return stop.planned ? t('common.yes') : t('common.no');
  }
  return null;
}

function buildPlannedOptions(t) {
  return [
    //{ label: t('OEEInput.isPlanned') },
    { label: '' },
    { value: true, label: t('common.yes') },
    { value: false, label: t('common.no') },
  ];
}

class OEEInputAvailability extends PureComponent<
  OEEInputAvailabilityProps,
  OEEInputAvailabilityState
> {
  constructor(props: OEEInputAvailabilityProps) {
    super(props);
    this.state = {
      oeeInput: props.data,
      isEdit: false,
    };
    this.activeEditOEEInput = this.activeEditOEEInput.bind(this);
    this.onExitEditListener = this.onExitEditListener.bind(this);
    this.addStopDetail = this.addStopDetail.bind(this);
    this.handleOpeningTimeChange = this.handleOpeningTimeChange.bind(this);
    this.getPrevStop = this.getPrevStop.bind(this);
  }

  tbodyEl = React.createRef<HTMLTableSectionElement>();

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(nextProps.data.updatedAt, prevState.oeeInput.updatedAt) || !prevState.isEdit) {
      return { oeeInput: nextProps.data };
    } else return null;
  }

  onExitEditListener(e) {
    // if dont click it-self
    if (
      this.tbodyEl &&
      this.tbodyEl.current &&
      !_.includes(this.tbodyEl.current.getElementsByTagName('*'), e.target)
    ) {
      const rootEl = document.getElementById('root');
      if (rootEl) {
        rootEl.removeEventListener('click', this.onExitEditListener, false);
      }
      if (!_.isEqual(this.state.oeeInput, this.props.data)) {
        // Changes on OEEInput data
        this.props.saveOEEInput(this.state.oeeInput).then(action => {
          if (action.type.match(/SUCCESS/)) {
            this.setState({ isEdit: false });
          } else {
            this.setState({ isEdit: false, oeeInput: this.props.data });
          }
        });
      } else {
        // No change on OEE data, exit edit mode
        this.setState({ isEdit: false });
      }
    }
  }

  activeEditOEEInput() {
    const { oeeInput, isEdit } = this.state;
    if (this.props.canEdit && !oeeInput.isValid && !isEdit) {
      //if (this.props.canEdit && !data.isValid && !this.state.validing) {
      this.setState({ isEdit: true });
      const rootEl = document.getElementById('root');
      if (rootEl) {
        rootEl.addEventListener('click', this.onExitEditListener, false);
      }
    }
    return false;
  }

  updateOpeningTime(value: number) {
    return () => {
      this.setState(
        produce(draft => {
          const { oeeInput } = draft;
          oeeInput.availability.openingTime = value;
          if (value === 0) {
            for (const i in oeeInput.availability.stopDetail) {
              oeeInput.availability.stopDetail[i].stop = 0;
              oeeInput.availability.stopDetail[i].planned = undefined;
              oeeInput.availability.stopDetail[i].prolongParent = undefined;
              oeeInput.availability.stopDetail[i].category = undefined;
              oeeInput.availability.stopDetail[i].categories = undefined;
            }
            draft.oeeInput.wasteTreated.wasteTreated = 0;
            for (const i in oeeInput.wasteTreated.lossDetail) {
              oeeInput.wasteTreated.lossDetail[i].loss = 0;
              oeeInput.wasteTreated.lossDetail[i].category = undefined;
              oeeInput.wasteTreated.lossDetail[i].categories = undefined;
            }
          }
        }),
      );
    };
  }

  handleOpeningTimeChange(value: number) {
    if (
      this.state.isEdit &&
      !_.isNil(this.props.data.availability.openingTime) &&
      this.props.data.availability.openingTime === this.state.oeeInput.availability.openingTime
    ) {
      return this.props.toggleOpenEditOpeningTimeAlert(this.updateOpeningTime(value));
    }
    this.updateOpeningTime(value)();
  }

  handleStopDetailChange(i: number) {
    return value => {
      this.setState(
        produce(draft => {
          const { oeeInput } = draft;
          oeeInput.availability.stopDetail[i].stop = _.isFinite(value) ? value : undefined;
          if (_.isFinite(value)) {
            if (value > 0 && _.isNil(oeeInput.availability.stopDetail[i].planned)) {
              oeeInput.availability.stopDetail[i].planned = false;
            }
            if (
              value !== 0 &&
              value === (oeeInput.availability.openingTime || OEE_INPUT_OPENING_TIME)
            ) {
              draft.oeeInput.wasteTreated.wasteTreated = 0;
              for (const i in oeeInput.wasteTreated.lossDetail) {
                oeeInput.wasteTreated.lossDetail[i].loss = 0;
                oeeInput.wasteTreated.lossDetail[i].category = undefined;
                oeeInput.wasteTreated.lossDetail[i].categories = undefined;
              }
            }
          }
          if (value === 0 || !_.isFinite(value)) {
            oeeInput.availability.stopDetail[i].planned = undefined;
            oeeInput.availability.stopDetail[i].category = undefined;
            oeeInput.availability.stopDetail[i].categories = undefined;
          }
        }),
      );
    };
  }

  handlePlannedChange(index: number) {
    return value => {
      let v;
      if (value === 'true') {
        v = true;
      } else if (value === 'false') {
        v = false;
      }
      this.setState(
        produce(draft => {
          const { oeeInput } = draft;
          oeeInput.availability.stopDetail[index].planned = v;
          if (value) {
            oeeInput.availability.stopDetail[index].category = undefined;
            oeeInput.availability.stopDetail[index].categories = undefined;
          }
        }),
      );
    };
  }

  handleStopCategoryChange(index: number) {
    return (value: string[]) => {
      this.setState(
        produce(draft => {
          const { oeeInput } = draft;
          oeeInput.availability.stopDetail[index].category = _.last(value);
          oeeInput.availability.stopDetail[index].categories = value;
        }),
      );
    };
  }

  handleRemoveCategory(index: number) {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      this.handleStopCategoryChange(index)([]);
    };
  }

  handleCopyCategoryFromPrevStop(index: number) {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      const prevStop = this.getPrevStop(index);
      this.handleStopCategoryChange(index)(prevStop.categories);
    };
  }

  handleSaveComment(index: number) {
    return (value: string) => {
      this.setState(
        produce(draft => {
          const { oeeInput } = draft;
          oeeInput.availability.stopDetail[index].comment = value;
        }),
      );
    };
  }

  canCopyCategoryFromPrevStop(index: number) {
    const stop = this.state.oeeInput.availability.stopDetail[index];
    const prevStop = this.getPrevStop(index);
    return prevStop && prevStop.category && prevStop.planned === stop.planned;
  }

  getPrevStop(index: number) {
    if (index === 0) {
      if (this.state.oeeInput.shift) {
        if (this.props.siblings.prevShiftOEEInput) {
          return _.last(this.props.siblings.prevShiftOEEInput.availability.stopDetail);
        }
      } else {
        if (this.props.siblings.prevOEEInput) {
          return _.last(this.props.siblings.prevOEEInput.availability.stopDetail);
        }
      }
    }
    return this.state.oeeInput.availability.stopDetail[index - 1];
  }

  getNextStop(index: number) {
    const nextStop = this.state.oeeInput.availability.stopDetail[index + 1];
    if (nextStop) {
      return nextStop;
    }
    if (this.state.oeeInput.shift) {
      if (this.props.siblings.nextShiftOEEInput) {
        return _.head(this.props.siblings.nextShiftOEEInput.availability.stopDetail);
      }
    } else {
      if (this.props.siblings.nextOEEInput) {
        return _.head(this.props.siblings.nextOEEInput.availability.stopDetail);
      }
    }
    return null;
  }

  hasProlongChildren(index: number) {
    const nextStop = this.getNextStop(index);
    return nextStop && nextStop.prolongParent;
  }

  prolongCanBeChanged(index: number) {
    if (index === 0) {
      let prevStop, stopDetail, openingTime;
      if (this.state.oeeInput.shift) {
        if (this.props.siblings.prevShiftOEEInput) {
          prevStop = _.last(this.props.siblings.prevShiftOEEInput.availability.stopDetail);
          stopDetail = this.props.siblings.prevShiftOEEInput.availability.stopDetail;
          openingTime = this.props.siblings.prevShiftOEEInput.availability.openingTime;
        } else {
          return false;
        }
      } else {
        if (this.props.siblings.prevOEEInput) {
          prevStop = _.last(this.props.siblings.prevOEEInput.availability.stopDetail);
          stopDetail = this.props.siblings.prevOEEInput.availability.stopDetail;
          openingTime = OEE_INPUT_OPENING_TIME;
        } else {
          return false;
        }
      }
      if (
        !this.hasProlongChildren(index) &&
        prevStop &&
        _.isFinite(openingTime) &&
        openingTime > 0
      ) {
        if (prevStop.prolongParent) {
          if (stopDetail.length === 1) {
            return prevStop.stop === openingTime;
          } else {
            const prolongParent = _.find(stopDetail, { _id: prevStop.prolongParent });
            if (prolongParent) {
              return true;
            } else {
              const sameProlongParentStops = _.filter(stopDetail, {
                prolongParent: prevStop.prolongParent,
              });
              return _.sumBy(sameProlongParentStops, 'stop') === openingTime;
            }
          }
        }
        return _.isFinite(prevStop.stop) && prevStop.stop > 0;
      }
      return false;
    }
    return !this.hasProlongChildren(index);
  }

  handleProlongedChange(index: number) {
    return event => {
      const prevStop = this.getPrevStop(index);
      if (event.target.checked) {
        this.setState(
          produce(draft => {
            draft.oeeInput.availability.stopDetail[index].prolongParent =
              prevStop.prolongParent || prevStop._id;
            if (!draft.oeeInput.availability.stopDetail[index].category) {
              draft.oeeInput.availability.stopDetail[index].planned = prevStop.planned;
              draft.oeeInput.availability.stopDetail[index].category = _.last(prevStop.categories);
              draft.oeeInput.availability.stopDetail[index].categories = prevStop.categories;
            }
          }),
        );
      } else {
        this.setState(
          produce(draft => {
            draft.oeeInput.availability.stopDetail[index].prolongParent = undefined;
          }),
        );
      }
    };
  }

  addStopDetail(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.oeeInput.isValid || !this.state.isEdit) {
      return false;
    }
    if (this.hasProlongChildren(this.state.oeeInput.availability.stopDetail.length - 1)) {
      return false;
    }
    const oeeInput = produce(this.state.oeeInput, (draft: any) => {
      draft.availability.stopDetail.push({});
    });
    this.props.saveOEEInput(oeeInput);
  }

  removeStopDetail(i: number) {
    return (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.state.oeeInput.isValid || !this.state.isEdit) {
        return false;
      }
      if (this.hasProlongChildren(i)) {
        return false;
      }
      const oeeInput = produce(this.state.oeeInput, (draft: any) => {
        draft.availability.stopDetail.splice(i, 1);
      });
      this.props.saveOEEInput(oeeInput);
    };
  }

  buildAddRemoveStopDetailCell(index: number) {
    const hideBtn =
      this.state.oeeInput.isValid ||
      !this.props.canEdit ||
      !this.state.isEdit ||
      this.state.oeeInput.availability.openingTime === 0;
    const hideAddBtn =
      hideBtn || this.hasProlongChildren(this.state.oeeInput.availability.stopDetail.length - 1);
    const hideRemoveBtn = hideBtn || this.hasProlongChildren(index);
    const addClassName = classNames('oee-input__cell cell-invisible', {
      hideBtn: hideAddBtn,
    });
    const removeClassName = classNames('oee-input__cell cell-invisible', {
      hideBtn: hideRemoveBtn,
    });
    if (index === 0) {
      return (
        <td className={addClassName} onClick={this.addStopDetail}>
          {!hideAddBtn && (
            <a href="#/" className="btn">
              <i className="fa fa-plus-circle" />
            </a>
          )}
        </td>
      );
    }
    return (
      <td className={removeClassName} onClick={this.removeStopDetail(index)}>
        {!hideRemoveBtn && (
          <a href="#/" className="btn">
            <i className="fa fa-minus" />
          </a>
        )}
      </td>
    );
  }

  isProlonged(stop) {
    return !!stop.prolongParent;
  }

  getProlongedClassName(stop, index) {
    let className = '';
    const {
      siblings: { nextOEEInput, nextShiftOEEInput },
    } = this.props;
    if (this.state.oeeInput.shift) {
      const { line } = this.props.plantAndLine;
      const shifts = line && _.isFinite(line.shifts) ? line.shifts : 1;
      //@ts-ignore
      if (shifts > 1) {
        return className;
      }
      if (nextShiftOEEInput) {
        if (
          this.state.oeeInput.availability.openingTime === 0 &&
          this.isProlonged(nextShiftOEEInput.availability.stopDetail[0])
        ) {
          if (moment(this.state.oeeInput.date).date() === 1) className = 'prolonged-stop-before';
          if (
            moment(this.state.oeeInput.date).date() ===
            moment(this.state.oeeInput.date)
              .endOf('month')
              .date()
          )
            className = 'prolonged-stop-after';
          className += ' prolonged-stop';
        }
      }
    } else {
      if (nextOEEInput && moment(nextOEEInput.date).date() === 1) {
        const nextStop = this.getNextStop(index);
        if (nextStop.prolongParent) {
          className = 'prolonged-stop-after';
        }
      }
    }
    if (this.isProlonged(stop)) {
      if (moment(this.state.oeeInput.date).date() === 1 && index === 0)
        className = 'prolonged-stop-before';
      className += ' prolonged-stop';
    }
    return className;
  }

  oeeInputStatus() {
    const { oeeInput } = this.state;
    const {
      categories,
      objective,
      siblings: { otherShiftOEEInputs },
    } = this.props;
    const errors = checkOEEInputErrors(
      oeeInput,
      categories,
      objective,
      OEEInputType.availability,
      otherShiftOEEInputs,
    );
    if (oeeInput.isValid) return 'oee-input__group oee-input__group--validated';
    if (errors.length === 0) {
      return 'oee-input__group oee-input__group--completed';
    }
    return 'oee-input__group oee-input__group--incompleted';
  }

  render() {
    const { oeeInput, isEdit } = this.state;
    const {
      t,
      i18n,
      data,
      plantAndLine: { line },
      siblings,
      categories,
      categoryItems,
      canEdit,
      objective,
    } = this.props;
    const copyCategoryFromPrevStopTooltip = (
      <Tooltip id="copyPrevOEECategoryTooltip">{t('OEEInput.copyReasonFromPrevStop')}</Tooltip>
    );
    return (
      <tbody ref={this.tbodyEl} className={this.oeeInputStatus()} onClick={this.activeEditOEEInput}>
        {oeeInput.availability.stopDetail.map((s, k) => {
          const isFirstLine = k === 0;
          const categoryPathNames = getCategoryPathNames(
            categories,
            categoryItems,
            s.category,
            i18n.language,
          );
          return (
            <tr className="oee-input__row" key={k}>
              {this.buildAddRemoveStopDetailCell(k)}

              <td className="oee-input__cell text-center">
                {isFirstLine && moment.utc(oeeInput.date).format(t('date.short'))}
              </td>

              {oeeInput.shift && (
                <td className="oee-input__cell text-center">
                  {isFirstLine && !isEdit && (
                    <span>
                      {numberFormat(
                        roundOEEInput(oeeInput.availability.openingTime, 2),
                        i18n.language,
                      )}
                    </span>
                  )}
                  {isFirstLine && isEdit && (
                    <InputNumber
                      onChange={this.handleOpeningTimeChange}
                      name="openingTime"
                      value={oeeInput.availability.openingTime}
                    />
                  )}
                </td>
              )}
              <td className="oee-input__cell text-center">
                {isFirstLine && (
                  <div>
                    {numberFormat(
                      roundOEEInput(stopDetailTotal(this.state.oeeInput), 2),
                      i18n.language,
                    )}
                  </div>
                )}
              </td>

              <td className="oee-input__cell text-center">
                {!isEdit && <span>{numberFormat(roundOEEInput(s.stop, 2), i18n.language)}</span>}
                {isEdit && (
                  <InputNumber
                    onChange={this.handleStopDetailChange(k)}
                    name={`stop${k}`}
                    value={s.stop}
                    disabled={oeeInput.availability.openingTime === 0 || this.hasProlongChildren(k)}
                  />
                )}
              </td>

              <td className="oee-input__cell text-center">
                {!isEdit && <span>{showStopPlannedText(s, t)}</span>}
                {isEdit && (
                  <SelectFilter
                    options={buildPlannedOptions(t)}
                    onChange={this.handlePlannedChange(k)}
                    name={`planned${k}`}
                    value={s.planned}
                    disabled={oeeInput.availability.openingTime === 0}
                  />
                )}
              </td>

              <td className={`oee-input__cell text-center ${this.getProlongedClassName(s, k)}`}>
                {isEdit && (
                  <input
                    type="checkbox"
                    checked={this.isProlonged(s)}
                    onChange={this.handleProlongedChange(k)}
                    value="prolonged"
                    disabled={
                      oeeInput.availability.openingTime === 0 || !this.prolongCanBeChanged(k)
                    }
                  />
                )}
                {!isEdit && this.isProlonged(s) && <span>{t('common.yes')}</span>}
              </td>

              <td className="oee-input__cell">
                {!isEdit && (
                  <span style={{ display: 'inline-block' }}>
                    {categoryPathNames.map((val, index) => {
                      return (
                        <span key={index}>
                          {val}
                          {index < categoryPathNames.length - 1 && (
                            <i className="fa fa-angle-right text-gray-light-mid m-l m-r" />
                          )}
                        </span>
                      );
                    })}
                  </span>
                )}
                {isEdit && !_.isNil(s.planned) && line && (
                  <Cascader
                    options={buildOEEInputCategoryTreeOptions(
                      categories,
                      categoryItems,
                      line.type,
                      s.planned ? LossType.planned : LossType.unplanned,
                      oeeInput.date,
                      i18n.language,
                    )}
                    defaultValue={getCategoryPathIds(categories, s.category)}
                    onChange={this.handleStopCategoryChange(k)}
                    changeOnSelect
                    disabled={oeeInput.availability.openingTime === 0}
                  >
                    <div className="rc-cascader-input-group">
                      <input type="text" value={categoryPathNames.join(', ')} readOnly />
                      {s.category && (
                        <a href="#/" onClick={this.handleRemoveCategory(k)} className="remove">
                          <i className="fa fa-times" />
                        </a>
                      )}
                      {this.canCopyCategoryFromPrevStop(k) && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={copyCategoryFromPrevStopTooltip}
                        >
                          <a
                            href="#/"
                            onClick={this.handleCopyCategoryFromPrevStop(k)}
                            className="copy"
                          >
                            <i className="fa fa-arrow-down" />
                          </a>
                        </OverlayTrigger>
                      )}
                    </div>
                  </Cascader>
                )}
              </td>
              <td className="oee-input__cell text-center">
                <OEEInputComment
                  comment={s.comment}
                  isEdit={isEdit}
                  onSaveComment={this.handleSaveComment(k)}
                />
              </td>
              <td className="oee-input__cell">
                {isFirstLine && data._id && canEdit && !isEdit && (
                  <OEEInputValidation
                    oeeInput={data}
                    categories={categories}
                    objective={objective}
                    oeeInputType={OEEInputType.availability}
                    canValid={canValidateOEEInput(data, siblings.prevOEEInput, line)}
                    otherOEEInputs={siblings.otherShiftOEEInputs}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default withTranslation()(OEEInputAvailability);
