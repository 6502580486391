import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import SelectFilter from '../Filter/SelectFilter';
import { Category } from '../../../common/models/category';
import { CategoryItem } from '../../../common/models/categoryItem';

interface OEECategorySelectProps {
  categories: Category[];
  categoryItems: CategoryItem[];
  categoryId: string;
  subCategories: Category[];
  onChange: Function;
  printMode?: boolean;
}

const OEECategorySelect: React.FC<OEECategorySelectProps> = ({
  categories,
  categoryItems,
  categoryId,
  subCategories,
  onChange,
  printMode,
}) => {
  const { t, i18n } = useTranslation();

  const onClickParentCategory = cat => {
    return () => {
      onChange(cat ? cat._id : cat);
    };
  };

  const buildSubCategorySelect = () => {
    const options: any[] = subCategories.map(c => {
      const categoryItem = _.find(categoryItems, { slug: c.categoryItemSlug });
      return {
        label: categoryItem.name[i18n.language],
        value: c._id,
      };
    });
    if (options.length === 0) return null;
    options.unshift({ label: t('category.selectCategory') });
    return <SelectFilter options={options} onChange={onChange} value={categoryId} />;
  };

  const buildParentCategory = () => {
    if (categoryId) {
      const _category = _.find(categories, { _id: categoryId });
      const _categories = _.filter(categories, c => {
        return _category.path.split('#').indexOf(c._id) !== -1;
      });
      const lastSubCategoryIndex = _categories.length - 1;
      return _categories.map((c, i) => {
        const categoryItem = _.find(categoryItems, { slug: c.categoryItemSlug });
        return (
          <span
            className="oee-category-selector__name"
            key={c._id}
            onClick={onClickParentCategory(_categories[i - 1])}
          >
            {categoryItem.name[i18n.language]}
            {(!printMode || i < lastSubCategoryIndex) && (
              <i className="fa fa-angle-right text-gray-light-mid m-l m-r" />
            )}
          </span>
        );
      });
    }
    return null;
  };
  return (
    <div className="oee-category-selector">
      <span className="m-r">{t('category.categories')}:</span>
      {buildParentCategory()}
      {!printMode && buildSubCategorySelect()}
    </div>
  );
};

export default OEECategorySelect;
