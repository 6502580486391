import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProps {
  title?: string;
  open: boolean;
  close: Function;
  onConfirm: Function;
  children?: JSX.Element;
  confirmButtonStyle?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  open,
  close,
  onConfirm,
  children,
  confirmButtonStyle = 'primary',
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleKeyboard = event => {
    if (event.key === 'Enter') {
      handleConfirm(event);
    }
  };

  const handleConfirm = event => {
    const promise = onConfirm(event);
    if (promise) {
      setIsDisabled(true);
      promise
        .then(action => {
          if (action && !action.type.match(/DELETE/)) {
            close(event);
            setIsDisabled(false);
          }
        })
        .catch(() => {
          setIsDisabled(false);
        });
    } else {
      close(event);
    }
  };

  const { t } = useTranslation();

  return (
    <Modal show={open} onHide={close} onKeyPress={handleKeyboard}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center">{title || t('confirmModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children || t('confirmModal.message')}</Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>{t('common.close')}</Button>
        <Button bsStyle={confirmButtonStyle} onClick={handleConfirm} disabled={isDisabled}>
          {t('common.valid')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ConfirmModal);
