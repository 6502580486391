import ReactDOM from 'react-dom';
import { PropTypes } from 'prop-types';
import { Input } from 'formsy-react-components';
import _ from 'lodash';
import places from 'places.js';

export default class InputAddress extends Input {
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.autocomplete.setVal(this.props.value);
    }
  }

  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    const input = el.getElementsByTagName('input')[0];
    this.autocomplete = places({
      container: input,
    });
    this.autocomplete.on('change', e => {
      this.setValue(e.suggestion.value);
      if (_.isFunction(this.props.onCoordinatesChange)) {
        this.props.onCoordinatesChange([e.suggestion.latlng.lng, e.suggestion.latlng.lat]);
      }
    });
    this.autocomplete.on('suggestions', e => {
      if (_.isFunction(this.props.onCoordinatesChange)) {
        if (e.suggestions.length >= 0) {
          this.props.onCoordinatesChange([
            e.suggestions[0].latlng.lng,
            e.suggestions[0].latlng.lat,
          ]);
        } else {
          this.props.onCoordinatesChange([]);
        }
      }
    });
    this.autocomplete.on('clear', () => {
      if (_.isFunction(this.props.onCoordinatesChange)) {
        this.props.onCoordinatesChange([]);
      }
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
}

InputAddress.propTypes = {
  onCoordinatesChange: PropTypes.func,
};
