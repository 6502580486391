import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../modules/reducers';
import clientMiddleware from '../middlewares/clientMiddleware';

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose)) ||
  compose;
const finalCreateStore = composeEnhancers(applyMiddleware(clientMiddleware(), thunk))(createStore);

export default function configureStore(initialState: any) {
  const store = finalCreateStore(rootReducer, initialState);
  if (module['hot']) {
    // Enable Webpack hot module replacement for reducers
    module['hot'].accept('../modules/reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }
  return store;
}
