import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import _ from 'lodash';
import { EditorState, convertFromRaw } from 'draft-js';

interface TextEditorProps {
  rawState: Object;
  placeholder?: string;
  onChange?: Function;
  readOnly?: Boolean;
}

interface TextEditorState {
  editorState: EditorState;
}

export default class TextEditor extends Component<TextEditorProps, TextEditorState> {
  static defaultProps = {
    readOnly: false,
  };

  static initialState(rawState) {
    if (rawState) {
      if (!rawState.entityMap) {
        rawState.entityMap = {};
      } // eslint-disable-line no-param-reassign
      const contentState = convertFromRaw(rawState);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  }

  state = {
    editorState: TextEditor.initialState(this.props.rawState),
  };

  toolbar = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'remove', 'history'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    },
    list: {
      inDropdown: false,
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      inDropdown: false,
      options: ['left', 'center', 'right', 'justify'],
    },
    history: {
      inDropdown: false,
      options: ['undo', 'redo'],
    },
  };

  componentDidMount() {
    if (this.props.onChange) this.props.onChange(this.state.editorState);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.rawState, nextProps.rawState)) {
      const editorState = TextEditor.initialState(nextProps.rawState);
      this.setState({ editorState });
      if (this.props.onChange) this.props.onChange(editorState);
    }
  }

  onEditorStateChange = editorState => {
    this.setState({ editorState });
    if (this.props.onChange) this.props.onChange(editorState);
  };

  render() {
    const { editorState } = this.state;
    const { placeholder, readOnly, rawState } = this.props;

    if (readOnly) {
      return (
        <div
          className="readonly-textarea"
          dangerouslySetInnerHTML={{ __html: draftToHtml(rawState) }}
        />
      );
    }

    return (
      <Editor
        editorState={editorState}
        toolbarClassName="text-editor__toolbar"
        wrapperClassName="text-editor__wrapper"
        editorClassName="text-editor__editor"
        onEditorStateChange={this.onEditorStateChange}
        placeholder={placeholder}
        toolbar={this.toolbar}
      />
    );
  }
}
