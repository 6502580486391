import _ from 'lodash';
import moment from 'moment';
import Big from 'big.js';
import {
  OEEInputType,
  OEEInput,
  OEE_INPUT_OPENING_TIME,
  THEORETICAL_HOURS_PER_DAY,
  //OEE_INPUT_BEGINNING_DATE,
} from '../models/oeeInput';
import { Objective } from '../models/objective';
import { Category, LossType } from '../models/category';
import { Line } from '../models/plant';

export const stopDetailTotal = (oeeInput: OEEInput): number | void => {
  if (oeeInput.availability.stopDetail.every(s => _.isFinite(s.stop))) {
    return Number(
      _.reduce(oeeInput.availability.stopDetail, (sum: Big, s) => sum.plus(s.stop), Big(0)),
    );
  }
};

export const lossDetailTotal = (oeeInput: OEEInput): number | void => {
  if (oeeInput.wasteTreated.lossDetail.every(l => _.isFinite(l.loss))) {
    return Number(
      _.reduce(oeeInput.wasteTreated.lossDetail, (sum: Big, l) => sum.plus(l.loss), Big(0)),
    );
  }
};

export const lossTotal = (oeeInput: OEEInput, objective: Objective): number | void => {
  // Total losses = (opening time (h)- total stops(h)) x target (t/h) – treated tonnage (t)
  const totalStop = stopDetailTotal(oeeInput);
  const openingTime = oeeInput.shift ? oeeInput.availability.openingTime : OEE_INPUT_OPENING_TIME;
  if (
    typeof openingTime === 'number' &&
    typeof totalStop === 'number' &&
    _.isFinite(openingTime) &&
    _.isFinite(oeeInput.wasteTreated.wasteTreated) &&
    _.isFinite(totalStop)
  ) {
    const value = _.round(
      Number(
        Big(openingTime)
          .minus(totalStop)
          .times(objective.value)
          .minus(oeeInput.wasteTreated.wasteTreated),
      ),
      2,
    );
    return Object.is(value, -0) ? 0 : value;
  }
};

export const categoryiesLossTypeIsRequired = (
  categories: Category[],
  lossType: LossType,
): boolean => {
  const lossCategories = categories.filter(c => _.find(c.lossType, { name: lossType }));
  return (
    lossCategories.length > 0 &&
    lossCategories.every(c => {
      const loss: any = _.find(c.lossType, { name: lossType });
      return loss.required;
    })
  );
};

export const openingTimeMax = (oeeInput: OEEInput, otherOEEInputs?: OEEInput[]): number => {
  if (oeeInput.shift) {
    const total = _.reduce(
      otherOEEInputs,
      (sum: Big, o) => sum.plus(o.availability.openingTime || 0),
      Big(0),
    );
    return Number(Big(OEE_INPUT_OPENING_TIME).minus(total));
  }
  return THEORETICAL_HOURS_PER_DAY;
};

export const checkOEEInputErrors = (
  oeeInput: OEEInput,
  categories: Category[],
  objective?: Objective,
  oeeInputType?: OEEInputType,
  otherOEEInputs?: OEEInput[],
): any[] => {
  const { availability, wasteTreated } = oeeInput;
  const availabilityErrors: any[] = [];
  const wasteTreatedErrors: any[] = [];
  const openingTime = oeeInput.shift ? availability.openingTime : OEE_INPUT_OPENING_TIME;
  if (availability) {
    if (availability.stopDetail.length === 0)
      availabilityErrors.push({ message: 'OEEInput.error.stopRequired', params: { index: 0 } });
    availability.stopDetail.forEach((stop, i) => {
      if (!_.isFinite(stop.stop))
        availabilityErrors.push({ message: 'OEEInput.error.stopRequired', params: { index: i } });
      else if (stop.stop < 0)
        availabilityErrors.push({
          message: 'OEEInput.error.stopLessThanZero',
          params: { index: i },
        });
      else if (stop.stop > THEORETICAL_HOURS_PER_DAY)
        availabilityErrors.push({
          message: 'OEEInput.error.stopMax',
          params: { index: i, max: THEORETICAL_HOURS_PER_DAY },
        });
      else if (stop.stop === 0 && _.isBoolean(stop.planned)) {
        availabilityErrors.push({
          message: 'OEEInput.error.stopEqualToZero',
          params: { index: i, min: 0 },
        });
      } else if (stop.stop > 0 && !_.isBoolean(stop.planned)) {
        availabilityErrors.push({
          message: 'OEEInput.error.stopIsPlannedRequired',
          params: { index: i },
        });
      } else if (stop.stop > 0 && _.isBoolean(stop.planned)) {
        if (_.isNil(stop.category)) {
          availabilityErrors.push({
            message: 'OEEInput.error.stopCategoryRequired',
            params: { index: i },
          });
        } else {
          const subCategories = _.filter(categories, { parent: stop.category });
          if (stop.planned) {
            if (categoryiesLossTypeIsRequired(subCategories, LossType.planned)) {
              availabilityErrors.push({
                message: 'OEEInput.error.stopCategoryChildRequired',
                params: { index: i },
              });
            }
          } else {
            if (categoryiesLossTypeIsRequired(subCategories, LossType.unplanned)) {
              availabilityErrors.push({
                message: 'OEEInput.error.stopCategoryChildRequired',
                params: { index: i },
              });
            }
          }
        }
      }
    });
    if (_.isFinite(openingTime)) {
      const totalStop = stopDetailTotal(oeeInput);
      if (totalStop > openingTime) {
        availabilityErrors.push({
          message: `OEEInput.error.${
            oeeInput.shift ? 'totalStopMoreThanOpeningTime' : 'totalStopMoreThan24'
          }`,
          params: { totalStop },
        });
      }
      if (openingTime < 0) {
        availabilityErrors.push({
          message: 'OEEInput.error.openingTimeLessThanZero',
        });
      }
      const _openingTimeMax = openingTimeMax(oeeInput, otherOEEInputs);
      if (openingTime > _openingTimeMax) {
        availabilityErrors.push({
          message: 'OEEInput.error.openingTimeMax',
          params: { max: _openingTimeMax },
        });
      }
    } else {
      availabilityErrors.push({
        message: 'OEEInput.error.openingTimeRequired',
      });
    }
  }

  if (wasteTreated) {
    if (!_.isFinite(wasteTreated.wasteTreated))
      wasteTreatedErrors.push({ message: 'OEEInput.error.wasteTreatedRequired' });
    else if (wasteTreated.wasteTreated < 0)
      wasteTreatedErrors.push({ message: 'OEEInput.error.wasteTreatedMin', params: { min: 0 } });
    else {
      if (wasteTreated.lossDetail.length === 0)
        wasteTreatedErrors.push({ message: 'OEEInput.error.lossRequired', params: { index: 0 } });
      else if (objective) {
        wasteTreated.lossDetail.forEach((loss, i) => {
          if (!_.isFinite(loss.loss))
            wasteTreatedErrors.push({
              message: 'OEEInput.error.lossRequired',
              params: { index: i },
            });
          //else if (loss.loss < 0)
          //wasteTreatedErrors.push({
          //message: 'OEEInput.error.lossMin',
          //params: { index: i, min: 0 },
          //});
          //else if (loss.loss > lossMax)
          //wasteTreatedErrors.push({
          //message: 'OEEInput.error.lossMax',
          //params: { index: i, max: lossMax },
          //});
          else if (loss.loss !== 0) {
            if (_.isNil(loss.category))
              wasteTreatedErrors.push({
                message: 'OEEInput.error.lossCategoryRequired',
                params: { index: i },
              });
            else {
              const subCategories = _.filter(categories, { parent: loss.category });
              if (categoryiesLossTypeIsRequired(subCategories, LossType.treatedWaste)) {
                wasteTreatedErrors.push({
                  message: 'OEEInput.error.lossCategoryChildRequired',
                  params: { index: i },
                });
              }
            }
          }
        });
        const totalLoss = lossTotal(oeeInput, objective);
        const totalLossDetail = lossDetailTotal(oeeInput);
        const totalStop = stopDetailTotal(oeeInput);
        if (_.isFinite(totalLoss) && _.isFinite(totalLossDetail)) {
          //@ts-ignore
          if (_.round(totalLossDetail, 2) !== _.round(totalLoss, 2)) {
            wasteTreatedErrors.push({
              message: 'OEEInput.error.lossTotalNotEqual',
              params: { totalLoss, totalLossDetail },
            });
          }
        }
        if (totalStop === openingTime && _.isFinite(totalLoss) && totalLoss !== 0) {
          wasteTreatedErrors.push({
            message: 'OEEInput.error.wasteTreatedEqualToZero',
            params: { totalLoss, totalStop },
          });
        }
      } else {
        wasteTreatedErrors.push({ message: 'OEEInput.error.objectiveRequired' });
      }
    }
  }
  if (oeeInputType === OEEInputType.availability) {
    return availabilityErrors.concat(wasteTreatedErrors);
  }
  return wasteTreatedErrors.concat(availabilityErrors);
};

export const oeeInputIsInRange = (oeeInputDate: Date | string, line?: Line): boolean => {
  if (!line) {
    return false;
  }
  //let oeeInputStartDate = moment(OEE_INPUT_BEGINNING_DATE);
  //if (oeeInputStartDate.isBefore(line.startDate)) {
  //oeeInputStartDate = moment(line.startDate);
  //}
  const oeeInputStartDate = moment(line.startDate);
  const lineEndDate = line.endDate ? line.endDate : undefined;
  return moment(oeeInputDate).isBetween(oeeInputStartDate, lineEndDate, undefined, '[]');
};

export const canValidateOEEInput = (
  oeeInput: OEEInput,
  prevOeeInput?: OEEInput,
  line?: Line,
): boolean => {
  if (!oeeInput.isValid && prevOeeInput && prevOeeInput.isValid) {
    return true;
  }
  if (!line) {
    return false;
  }
  //let oeeInputStartDate = moment(OEE_INPUT_BEGINNING_DATE);
  //if (oeeInputStartDate.isBefore(line.startDate)) {
  //oeeInputStartDate = moment(line.startDate);
  //}
  const oeeInputStartDate = moment(line.startDate);
  return oeeInputStartDate.isSame(oeeInput.date);
};
