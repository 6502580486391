import React from 'react';
import { useSelector } from 'react-redux';
import Avatar from './Avatar';

const ProfileAvatar: React.FC = () => {
  const profile = useSelector(state => state.profile.data);

  return (
    <span className="user-avatar">
      <Avatar filename={profile.avatar} version="mini" />
      <span>{profile.name}</span>
    </span>
  );
};

export default React.memo(ProfileAvatar);
