import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button, Row, Col, Label } from 'react-bootstrap';
import _ from 'lodash';
import plantPolicy from '../../../common/policies/plant_policy';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { plantBusinessUnit, plantRegion, plantPhotoUrl } from '../../../utils/plantHelper';
import { PhotoVersion } from '../../../common/upload';
import { Plant } from '../../../common/models/plant';
import { BusinessUnit } from '../../../common/models/businessUnit';
import { Region } from '../../../common/models/region';
import { User } from '../../../common/models/user';
import { APP_EDIT_PLANT_PATH } from '../../../utils/routeHelper';

import plantImage from '../../../assets/images/plant.png';

interface IdCardsProps extends RouteComponentProps {
  plants: Plant[];
  businessUnits: BusinessUnit[];
  regions: Region[];
  user: User;
  onDeletePlant: Function;
}

const IdCards: React.FC<IdCardsProps> = ({
  plants,
  businessUnits,
  regions,
  user,
  onDeletePlant,
  history,
}) => {
  const { t } = useTranslation();

  const buildPlantImage = plant => {
    if (plant.defaultPhoto) {
      return <img src={plantPhotoUrl(plant.defaultPhoto, PhotoVersion.normal)} alt={plant.name} />;
    }
    return (
      <img className="card-plant__placeholder" src={plantImage} width="145px" alt={plant.name} />
    );
  };

  const editPlant = (plant: Plant) => {
    history.push(APP_EDIT_PLANT_PATH(plant._id));
  };

  const buildPlantList = () => {
    return _.map(plants, (plant: Plant) => {
      return (
        <Col sm={6} lg={4} key={plant._id}>
          <div className="card card-plant bg-white no-border">
            <div className="card-block card-plant-body">
              <h2 className="card-title m-b-0 plant--title">{plant.name}</h2>
              {plant.archived && <Label>{t('plant.archived')}</Label>}
              <p className="card-text">
                {plantBusinessUnit(plant, businessUnits).name} - {plantRegion(plant, regions).name}
              </p>
            </div>

            <div className="card-actions text-center">
              <ButtonGroup>
                {plantPolicy.isAuthorize(user, 'edit') && (
                  <Button
                    className="btn btn-secondary btn-sm btn-border-thin btn-circle m-r"
                    onClick={() => editPlant(plant)}
                  >
                    <i className="fa fa-pencil" />
                  </Button>
                )}
                {plantPolicy.isAuthorize(user, 'destroy') && (
                  <ConfirmButton
                    className="btn btn-secondary btn-sm btn-border-thin btn-circle text-danger"
                    onClick={() => onDeletePlant(plant)}
                    message={t('plant.removeThisPlant')}
                    confirmButtonStyle="danger"
                  >
                    <i className="fa fa-trash-o" />
                  </ConfirmButton>
                )}
              </ButtonGroup>
            </div>

            <div className="card-img-bottom text-center">{buildPlantImage(plant)}</div>
          </div>
        </Col>
      );
    });
  };

  return (
    <div className="plants__list m-t-lg">
      <Row>{buildPlantList()}</Row>
    </div>
  );
};

export default withRouter(IdCards);
