import {
  API_INDEX_BUSINESS_UNIT_PATH,
  API_CREATE_BUSINESS_UNIT_PATH,
  API_UPDATE_BUSINESS_UNIT_PATH,
  API_DELETE_BUSINESS_UNIT_PATH,
} from '../../utils/routeHelper';
import { removeFromCollection, updateCollection, addToCollection } from '../../utils/collection';
import { BusinessUnit, BusinessUnitData } from '../../common/models/businessUnit';
import { error } from '../../utils/error';
import { SIGNOUT } from './auth';

export const INDEX_BUSINESS_UNIT = 'businessUnits/INDEX_BUSINESS_UNIT';
export const INDEX_BUSINESS_UNIT_SUCCESS = 'businessUnits/INDEX_BUSINESS_UNIT_SUCCESS';
export const INDEX_BUSINESS_UNIT_FAIL = 'businessUnits/INDEX_BUSINESS_UNIT_FAIL';
export const CREATE_BUSINESS_UNIT = 'businessUnits/CREATE_BUSINESS_UNIT';
export const CREATE_BUSINESS_UNIT_SUCCESS = 'businessUnits/CREATE_BUSINESS_UNIT_SUCCESS';
export const CREATE_BUSINESS_UNIT_FAIL = 'businessUnits/CREATE_BUSINESS_UNIT_FAIL';
export const UPDATE_BUSINESS_UNIT = 'businessUnits/UPDATE_BUSINESS_UNIT';
export const UPDATE_BUSINESS_UNIT_SUCCESS = 'businessUnits/UPDATE_BUSINESS_UNIT_SUCCESS';
export const UPDATE_BUSINESS_UNIT_FAIL = 'businessUnits/UPDATE_BUSINESS_UNIT_FAIL';
export const DELETE_BUSINESS_UNIT = 'businessUnits/DELETE_BUSINESS_UNIT';
export const DELETE_BUSINESS_UNIT_SUCCESS = 'businessUnits/DELETE_BUSINESS_UNIT_SUCCESS';
export const DELETE_BUSINESS_UNIT_FAIL = 'businessUnits/DELETE_BUSINESS_UNIT_FAIL';

interface BusinessUnitState {
  data?: Array<BusinessUnit>;
  loading: boolean;
  creating?: boolean;
  updating?: boolean;
  deleting?: boolean;
  error?: string;
  createError?: string;
  updateError?: string;
  deleteError?: string;
}

const initialState: BusinessUnitState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export default function reducer(
  state: BusinessUnitState = initialState,
  action: ActionResult,
): BusinessUnitState {
  switch (action.type) {
    case INDEX_BUSINESS_UNIT:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case CREATE_BUSINESS_UNIT:
      return {
        ...state,
        creating: true,
        createError: undefined,
      };
    case CREATE_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        creating: false,
        data: addToCollection(state.data, action.result.data),
      };
    case CREATE_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        creating: false,
        createError: error(action),
      };
    case UPDATE_BUSINESS_UNIT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case DELETE_BUSINESS_UNIT:
      return {
        ...state,
        deleting: true,
        deleteError: undefined,
      };
    case DELETE_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        deleting: false,
        data: removeFromCollection(state.data, action.preload),
      };
    case DELETE_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        deleting: false,
        deleteError: error(action),
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexBusinessUnit(): ActionPromise {
  return {
    types: [INDEX_BUSINESS_UNIT, INDEX_BUSINESS_UNIT_SUCCESS, INDEX_BUSINESS_UNIT_FAIL],
    promise: client => client.get(API_INDEX_BUSINESS_UNIT_PATH),
  };
}

export function createBusinessUnit(data: BusinessUnitData): ActionPromise {
  return {
    types: [CREATE_BUSINESS_UNIT, CREATE_BUSINESS_UNIT_SUCCESS, CREATE_BUSINESS_UNIT_FAIL],
    promise: client => client.post(API_CREATE_BUSINESS_UNIT_PATH, data),
  };
}

export function updateBusinessUnit(id: string, data: BusinessUnitData): ActionPromise {
  return {
    types: [UPDATE_BUSINESS_UNIT, UPDATE_BUSINESS_UNIT_SUCCESS, UPDATE_BUSINESS_UNIT_FAIL],
    promise: client => client.put(API_UPDATE_BUSINESS_UNIT_PATH(id), data),
  };
}

export function deleteBusinessUnit(id: string): ActionPromise {
  return {
    types: [DELETE_BUSINESS_UNIT, DELETE_BUSINESS_UNIT_SUCCESS, DELETE_BUSINESS_UNIT_FAIL],
    promise: client => client.delete(API_DELETE_BUSINESS_UNIT_PATH(id)),
    preload: { _id: id },
  };
}
