import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ObjectiveModal from '../Objective/ObjectiveModal';
import OEEImportModal from '../OEE/OEEImportModal';
import { PlantAndLine } from '../../../redux/modules/plants';
import { importOEE } from '../../../redux/modules/oee';
import { indexOEEInput } from '../../../redux/modules/oeeInputs';
import { importOEEBudgetChallenge } from '../../../redux/modules/oeeBudgetChallenges';
import { OEEInputType } from '../../../common/models/oeeInput';
import { UserPolicies } from '../../../common/models/user';
import { isAdmin, isAuthorizeResource } from '../../../utils/userHelper';

interface OEEInputActionsProps {
  oeeInputType: OEEInputType;
  plantAndLine: PlantAndLine;
}

const OEEInputActions: React.FC<OEEInputActionsProps> = ({ plantAndLine, oeeInputType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.profile.data);
  const oeeFilter = useSelector(state => state.oeeFilter);
  const [isOpenObjectivesModal, setIsOpenObjectivesModal] = useState(false);
  const [isOpenOEEImportModal, setIsOpenOEEImportModal] = useState(false);
  const [isOpenOEEBudgetChallengeImportModal, setIsOpenOEEBudgetChallengeImportModal] = useState(
    false,
  );
  const toggleObjectivesModal = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpenObjectivesModal(!isOpenObjectivesModal);
    return false;
  };
  const toggleOEEImportModal = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpenOEEImportModal(!isOpenOEEImportModal);
    return false;
  };
  const toggleOEEBudgetChallengeImportModal = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpenOEEBudgetChallengeImportModal(!isOpenOEEBudgetChallengeImportModal);
    return false;
  };

  const handleImportOEE = data => {
    if (plantAndLine.plant) {
      dispatch(
        importOEE({
          ...data,
          plantId: plantAndLine.plant._id,
        }),
      ).then(action => {
        if (action.type.match(/SUCCESS/)) {
          const { plantId, line, date } = oeeFilter;
          dispatch(
            indexOEEInput({
              plantId,
              line,
              endYear: date.getFullYear(),
              endMonth: date.getMonth(),
            }),
          );
          setIsOpenOEEImportModal(false);
        }
      });
    }
  };

  const handleImportOEEOEEBudgetChallenge = data => {
    dispatch(importOEEBudgetChallenge(data)).then(action => {
      if (action.type.match(/SUCCESS/)) {
        setIsOpenOEEBudgetChallengeImportModal(false);
      }
    });
  };

  const oeeImportTooltip = <Tooltip id="oeeImportTooltip">{t('OEE.importOEE')}</Tooltip>;
  const canImportOEE =
    plantAndLine.plant &&
    isAuthorizeResource(currentUser, UserPolicies.oeeWrite, plantAndLine.plant._id);

  return (
    <div className="oee-input__main-actions">
      {plantAndLine.plant && canImportOEE && (
        <Fragment>
          <OverlayTrigger placement="bottom" overlay={oeeImportTooltip}>
            <a
              href="/#"
              onClick={toggleOEEImportModal}
              className="oee-input__main-action btn btn-update-oee-input"
            >
              <div className="btn btn-secondary btn-round">
                <i className="fa fa-cloud-upload" />
              </div>
            </a>
          </OverlayTrigger>
          <OEEImportModal
            open={isOpenOEEImportModal}
            close={toggleOEEImportModal}
            onSubmit={handleImportOEE}
          />
        </Fragment>
      )}
      {plantAndLine.plant &&
        plantAndLine.line &&
        oeeInputType === OEEInputType.wasteTreated &&
        isAdmin(currentUser) && (
          <span>
            <a href="#/" onClick={toggleObjectivesModal} className="oee-input__main-action btn">
              <div className="btn btn-secondary btn-round">
                <i className="fa fa-compass" />
              </div>
              <span className="hidden-md-down">{t('objective.objectives')}</span>
            </a>
            <ObjectiveModal
              open={isOpenObjectivesModal}
              close={toggleObjectivesModal}
              plant={plantAndLine.plant}
              line={plantAndLine.line}
            />
          </span>
        )}
      {plantAndLine.plant && plantAndLine.line && isAdmin(currentUser) && (
        <span>
          <a
            href="/#"
            onClick={toggleOEEBudgetChallengeImportModal}
            className="oee-input__main-action btn"
          >
            <div className="btn btn-secondary btn-round">
              <i className="fa fa-upload" />
            </div>
            <span className="hidden-md-down">{t('OEE.oeeBudget')}</span>
          </a>
          <OEEImportModal
            open={isOpenOEEBudgetChallengeImportModal}
            close={toggleOEEBudgetChallengeImportModal}
            onSubmit={handleImportOEEOEEBudgetChallenge}
            isBudget={true}
          />
        </span>
      )}
    </div>
  );
};

export default OEEInputActions;
