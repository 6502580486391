import {
  API_EXPORT_OEE_PATH,
  API_EXPORT_OEE_TO_S3_PATH,
  API_IMPORT_OEE_PATH,
} from '../../utils/routeHelper';
import { error } from '../../utils/error';
import { downloadFile } from '../../utils/appHelper';
import { oeeExportFilename } from '../../utils/oeeHelper';

export const EXPORT_OEE = 'oee/EXPORT_OEE';
export const EXPORT_OEE_SUCCESS = 'oee/EXPORT_OEE_SUCCESS';
export const EXPORT_OEE_FAIL = 'oee/EXPORT_OEE_FAIL';
export const EXPORT_OEE_TO_S3 = 'oee/EXPORT_OEE_TO_S3';
export const EXPORT_OEE_TO_S3_SUCCESS = 'oee/EXPORT_OEE_TO_S3_SUCCESS';
export const EXPORT_OEE_TO_S3_FAIL = 'oee/EXPORT_OEE_TO_S3_FAIL';
export const IMPORT_OEE = 'oee/IMPORT_OEE';
export const IMPORT_OEE_SUCCESS = 'oee/IMPORT_OEE_SUCCESS';
export const IMPORT_OEE_FAIL = 'oee/IMPORT_OEE_FAIL';

interface OEEState {
  exporting: boolean;
  exportError?: string;
  importing: boolean;
  importError?: string;
}

const initialState: OEEState = {
  exporting: false,
  exportError: undefined,
  importing: false,
  importError: undefined,
};

export default function reducer(state: OEEState = initialState, action: ActionResult): OEEState {
  switch (action.type) {
    case EXPORT_OEE:
      return {
        ...state,
        exporting: true,
      };
    case EXPORT_OEE_SUCCESS:
      downloadFile(action.result, oeeExportFilename(action.preload));
      return {
        ...state,
        exporting: false,
        exportError: undefined,
      };
    case EXPORT_OEE_FAIL:
      return {
        ...state,
        exporting: false,
        exportError: error(action),
      };
    case IMPORT_OEE:
      return {
        ...state,
        importing: true,
      };
    case IMPORT_OEE_SUCCESS:
      return {
        ...state,
        importing: false,
        importError: undefined,
      };
    case IMPORT_OEE_FAIL:
      return {
        ...state,
        importing: false,
        importError: error(action),
      };
    default:
      return state;
  }
}

export function exportOEE(query): ActionPromise {
  return {
    types: [EXPORT_OEE, EXPORT_OEE_SUCCESS, EXPORT_OEE_FAIL],
    promise: client => client.post(API_EXPORT_OEE_PATH, query, { responseType: 'blob' }),
    preload: query,
  };
}

export function exportOEEToS3(): ActionPromise {
  return {
    types: [EXPORT_OEE_TO_S3, EXPORT_OEE_TO_S3_SUCCESS, EXPORT_OEE_TO_S3_FAIL],
    promise: client => client.get(API_EXPORT_OEE_TO_S3_PATH),
  };
}

export function importOEE(data): ActionPromise {
  const formData = new FormData();
  formData.set('plantId', data.plantId);
  if (data.file && data.file[0]) {
    formData.append('file', data.file[0]);
  }
  return {
    types: [IMPORT_OEE, IMPORT_OEE_SUCCESS, IMPORT_OEE_FAIL],
    promise: client => client.post(API_IMPORT_OEE_PATH, formData),
  };
}
