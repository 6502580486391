import React, { useState, useEffect } from 'react';
import { useStore, useDispatch } from 'react-redux';

/*
 * A Higher-order Components to load data for a component and pass data to props of this component.
 * Object is let component stateless.
 *
 * params:
 *   {ComposedComponent}: a component need load data
 *   {load}: load data function
 *
 * Usage:
 *   loadData(async (dispatch, state) => {
 *     await dispatch(getUser(match.params.id));
 *   })(ExampleComponent);
 *
 *   withRouter(loadData(await (dispatch, state, { match, location }) => {
 *     await dispatch(getUser(match.params.id));
 *   })(ExampleComponent));
 */
const loadData = (load: Function, options = { loader: null, onError: e => {} }) => {
  return function(ComposedComponent: React.ComponentType<any>) {
    const Enhance: React.FC<any> = props => {
      const [loading, setLoading] = useState(true);
      const store = useStore();
      const dispatch = useDispatch();
      /* eslint-disable */
      useEffect(() => {
        const result = load(dispatch, store.getState(), props);
        if (result instanceof Promise) {
          result
            .then(() => {
              setLoading(false);
            })
            .catch(e => {
              options.onError(e);
            });
        } else {
          setLoading(false);
        }
      }, []);
      /* eslint-enable */

      if (loading) {
        if (options.loader) {
          return options.loader;
        }
        return (
          <div id="loader">
            <div className="loader" />
          </div>
        );
      }
      return React.createElement(ComposedComponent, props);
    };

    return Enhance;
  };
};

export default loadData;
