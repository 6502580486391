import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import fp from 'lodash/fp';
import PostForm from '../../../components/App/News/PostForm';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import { createNews } from '../../../redux/modules/news';
import { APP_EDIT_NEWS_PATH, APP_NEWS_PATH } from '../../../utils/routeHelper';
import { identifier } from '../../../common/models/model';

interface NewsCreateProps extends RouteComponentProps, WithTranslation {
  createNews: Function;
}

class NewsCreate extends Component<NewsCreateProps> {
  handleSubmit = data => {
    this.props.createNews(data).then(action => {
      if (action && action.type.match(/SUCCESS/)) {
        this.props.history.push(APP_EDIT_NEWS_PATH(action.result.data[identifier]));
      }
    });
  };

  goToPostsList = () => {
    this.props.history.push(APP_NEWS_PATH);
  };

  render() {
    const { i18n } = this.props;
    return (
      <div className="posts-show">
        <PageNav>
          <div className="plant-band">
            <div className="nav-secondary__left">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="returnTooltip">{i18n.t('news.returnToPostsList')}</Tooltip>}
              >
                <Button className="btn-secondary btn-xl" onClick={this.goToPostsList}>
                  <i className="fa fa-long-arrow-left m-r" />
                </Button>
              </OverlayTrigger>
            </div>
            <h2 className="nav-secondary__title">{i18n.t('news.newPost')}</h2>
          </div>
        </PageNav>
        <PageContent>
          <Row>
            <Col md={8} mdOffset={0}>
              <div className="card bg-white m-t-lg">
                <div className="card-block">
                  <PostForm
                    data={{}}
                    onSubmit={this.handleSubmit}
                    onCancel={this.props.history.goBack}
                    locale={i18n.language}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </PageContent>
      </div>
    );
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
  createNews,
};

export default fp.compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NewsCreate);
