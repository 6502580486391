import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import PageNav from '../Page/PageNav';
import PageContent from '../Page/PageContent';
import { APP_PROFILE_PATH, APP_PROFILE_PASSWORD_PATH } from '../../../utils/routeHelper';

export enum ProfileNav {
  'editProfile',
  'changePassword',
}

interface ProfilePageProps {
  active: ProfileNav;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ active, children }) => {
  const { t } = useTranslation();

  return (
    <div className="profile-page">
      <PageNav>
        <h2 className="nav-secondary__title">{t('user.editProfile')}</h2>
      </PageNav>
      <PageContent>
        <Row>
          <Col md={12} lg={8} lgOffset={2}>
            <div className="tabs tabs--secondary m-t-lg">
              <div className="tabs-nav">
                <ul>
                  <li className={active === ProfileNav.editProfile ? 'active' : ''}>
                    <Link to={APP_PROFILE_PATH}>{t('user.basicInformations')}</Link>
                  </li>
                  <li className={active === ProfileNav.changePassword ? 'active' : ''}>
                    <Link to={APP_PROFILE_PASSWORD_PATH}>{t('password.changePassword')}</Link>
                  </li>
                </ul>
              </div>
              <div className="tabs-content">{children}</div>
            </div>
          </Col>
        </Row>
      </PageContent>
    </div>
  );
};

export default ProfilePage;
