import { Middleware } from 'redux';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { signout } from '../modules/auth';
import _ from 'lodash';

export default function clientMiddleware(): Middleware {
  return ({ dispatch, getState }) => {
    return (next: Function) => (action: any) => {
      if (typeof action === 'function') {
        return action(axios, dispatch, getState);
      }
      const { promise, types, ...rest } = action;
      if (!promise) {
        return next(action);
      }
      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });
      const actionPromise = promise(axios);
      return actionPromise
        .then((result: AxiosResponse) => {
          const res = { ...rest, result, type: SUCCESS };
          next(res);
          return res;
        })
        .catch((error: AxiosError) => {
          const err = { ...rest, error, type: FAILURE };
          console.error('Action Promise ERROR:', err);
          if (_.get(error, 'response.data') === 'Unauthorized') {
            return dispatch(signout());
          }
          next(err);
          throw err;
        });
    };
  };
}
