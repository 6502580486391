import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import ModalWrapper from '../Modal/ModalWrapper';
import BUForm from './BUForm';

const BUModal = ({ businessUnit, open, close, onSubmit, t }) => {
  return (
    <ModalWrapper title={t('businessUnit.businessUnit')} open={open} close={close}>
      <BUForm data={businessUnit} onSubmit={onSubmit} />
    </ModalWrapper>
  );
};

BUModal.propTypes = {
  businessUnit: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(BUModal);
