import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tree, { mutateTree, moveItemOnTree } from '@atlaskit/tree';
import _ from 'lodash';
import PageNav from '../../../components/App/Page/PageNav';
import PageContent from '../../../components/App/Page/PageContent';
import Category from '../../../components/App/Category/Category';
import CategoryExportButton from '../../../components/App/Category/CategoryExportButton';
import CategoryFilter from '../../../components/App/Category/CategoryFilter';
import {
  indexCategory,
  createCategory,
  updateCategory,
  deleteCategory,
  updateCategoryPosition,
} from '../../../redux/modules/categories';
import { indexCategoryItem } from '../../../redux/modules/categoryItems';
import loadData from '../../../utils/loadData';
import {
  Category as ICategory,
  CategoryData,
  LossType,
  TreatedWasteLossType,
} from '../../../common/models/category';
import { LineType } from '../../../common/models/plant';
import { filterCategories } from '../../../common/utils/categoryHelper';

const PADDING_PER_LEVEL = 45;

const buildTree = (list: any[]): any => {
  const tree: { rootId: string; items: any } = {
    rootId: 'root',
    items: {
      root: {
        id: 'root',
        children: _.sortBy(
          _.filter(list, i => !i.parent),
          ['position'],
        ).map(i => i._id),
        isExpanded: true,
        isChildrenLoading: false,
        data: {
          lineType: Object.values(LineType),
          lossType: Object.values(LossType).map(l => ({ name: l })),
        },
      },
    },
  };
  for (const item of list) {
    const children = _.sortBy(_.filter(list, { parent: item._id }), ['position']).map(i => i._id);
    tree.items[item._id] = {
      id: item._id,
      children,
      hasChildren: children.length !== 0,
      isExpanded: children.length !== 0,
      isChildrenLoading: false,
      data: item,
    };
  }
  return tree;
};

const Categories: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const categories = useSelector(state => state.categories.data);
  const [tree, setTree] = useState(buildTree(categories));
  useEffect(() => {
    setTree(buildTree(categories));
  }, [categories]);

  const addCategory = (parent: string = 'root') => {
    if (tree.items['newCategory']) {
      return;
    }
    const newCategory = {
      parent,
    };
    const newTree = _.cloneDeep(tree);
    newTree.items['newCategory'] = {
      id: 'newCategory',
      children: [],
      isExpanded: true,
      isChildrenLoading: false,
      data: newCategory,
    };
    newTree.items[parent].children.push('newCategory');
    newTree.items[parent].hasChildren = true;
    newTree.items[parent].isExpanded = true;
    setTree(newTree);
  };

  const saveCategory = (category: ICategory, data: CategoryData): Promise<void> => {
    let categoryPromise: Promise<any>;
    if (category._id) {
      categoryPromise = dispatch(updateCategory(category._id, data));
    } else {
      if (category.parent !== 'root') {
        data['parent'] = category.parent;
      }
      categoryPromise = dispatch(createCategory(data));
    }
    return categoryPromise.then(res => {
      if (res.type.match(/SUCCESS/)) {
        mutateTree(tree, category._id, {
          id: res.result.data,
          data: res.result.data,
        });
        dispatch(indexCategoryItem());
      }
    });
  };

  const handleDeleteCategory = (category: ICategory) => {
    if (category._id) {
      dispatch(deleteCategory(category._id)).then(() => {
        dispatch(indexCategory());
      });
    } else {
      const newTree = _.cloneDeep(tree);
      _.unset(newTree, 'items.newCategory');
      const parent = category.parent || 'root';
      _.pull(newTree.items[parent].children, 'newCategory');
      if (newTree.items[parent].children === 0) {
        newTree.items[parent].hasChildren = false;
      }
      setTree(newTree);
    }
  };

  const handleFilter = (filter: any) => {
    const lineType = _.includes(Object.keys(LineType), filter.lineType)
      ? filter.lineType
      : undefined;
    const lossType = _.includes(Object.keys(LossType), filter.lossType)
      ? filter.lossType
      : undefined;
    const loss = _.includes(Object.keys(TreatedWasteLossType), filter.lossType)
      ? filter.lossType
      : undefined;
    const newTree = buildTree(filterCategories(categories, lineType, lossType, loss));
    setTree(newTree);
  };

  const onExpand = (itemId: any) => {
    setTree(mutateTree(tree, itemId, { isExpanded: true }));
  };

  const onCollapse = (itemId: any) => {
    setTree(mutateTree(tree, itemId, { isExpanded: false }));
  };

  const onDragEnd = (source: any, destination: any) => {
    if (!destination || source.parentId !== destination.parentId) {
      setTree(
        mutateTree(tree, tree.items[source.parentId].children[source.index], {
          isExpanded: true,
        }),
      );
      return;
    }
    const newTree = moveItemOnTree(tree, source, destination);
    _.forIn(newTree.items, i => (i.isExpanded = true));
    setTree(newTree);

    const positions = {};
    positions[source.parentId] = newTree.items[source.parentId].children;
    positions[destination.parentId] = newTree.items[destination.parentId].children;
    dispatch(updateCategoryPosition(positions)).then(() => {
      dispatch(indexCategory());
    });
  };

  const renderItem = ({ item, onExpand, onCollapse, provided }: any) => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps}>
        <Category
          category={item.data}
          parent={tree.items[item.data.parent || 'root'].data}
          categoryItems={[]}
          dragHandle={provided.dragHandleProps}
          isExpanded={item.isExpanded}
          onExpand={onExpand}
          onCollapse={onCollapse}
          onAddCategory={addCategory}
          onSaveCategory={saveCategory}
          onDeleteCategory={handleDeleteCategory}
        />
      </div>
    );
  };

  return (
    <div className="categories-list">
      <PageNav containerLg>
        <h2 className="nav-secondary__title">{t('category.categories')}</h2>
        <div className="nav-secondary__right">
          <CategoryExportButton />
        </div>
      </PageNav>
      <PageContent>
        <div className="card bg-white m-t-lg p-a-lg">
          <div className="card-header m-b-md">
            <CategoryFilter onChange={handleFilter} />
          </div>
          <Tree
            tree={tree}
            renderItem={renderItem}
            onExpand={onExpand}
            onCollapse={onCollapse}
            onDragEnd={onDragEnd}
            offsetPerLevel={PADDING_PER_LEVEL}
            isDragEnabled
            isNestingEnabled
          />
          <div className="text-center">
            <button className="btn btn-secondary btn-md m-t-md" onClick={() => addCategory()}>
              <i className="fa fa-plus m-r" />
              {t('category.newCategory')}
            </button>
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default loadData(async dispatch => {
  await dispatch(indexCategory());
  await dispatch(indexCategoryItem());
})(Categories);
