import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ModalWrapper from '../Modal/ModalWrapper';

class OEEInputValidationModal extends Component {
  state = {
    isDisabled: false,
  };

  handleUnvalidOEEInput = () => {
    this.setState({ isDisabled: true });
    this.props.onUnvalidOEEInput().then(action => {
      if (action && action.type.match(/FAILURE/)) {
        this.setState({ isDisabled: false });
      }
    });
  };

  render() {
    const { open, close, validationHistory, t } = this.props;
    return (
      <ModalWrapper title={t('OEEInput.validation')} open={open} close={close}>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: '30%' }}>{t('OEEInput.date')}</th>
              <th style={{ width: '30%' }}>{t('OEEInput.validationAction')}</th>
              <th style={{ width: '40%' }}>{t('OEEInput.validationUser')}</th>
            </tr>
          </thead>
          <tbody>
            {validationHistory &&
              validationHistory
                .slice()
                .reverse()
                .map(h => {
                  return (
                    <tr key={h._id}>
                      <td>{moment(h.date).format(t('date.short'))}</td>
                      <td>{t(`OEEInput.${h.action}`)}</td>
                      <td>{h.userId ? h.userId.name : t('user.noExist')}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        <div className="text-center m-t-md">
          <button
            className="btn btn-danger"
            onClick={this.handleUnvalidOEEInput}
            disabled={this.state.isDisabled}
          >
            {t('OEEInput.unvalidate')}
          </button>
        </div>
      </ModalWrapper>
    );
  }
}

OEEInputValidationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onUnvalidOEEInput: PropTypes.func.isRequired,
  validationHistory: PropTypes.array,
};

export default withTranslation()(OEEInputValidationModal);
