import React, { useState } from 'react';
import { useTranslation, Translation } from 'react-i18next';
import { OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import { Form, Textarea } from 'formsy-react-components';
import BaseForm from '../Form/BaseForm';
import ModalWrapper from '../Modal/ModalWrapper';

class OEEInputCommentForm extends BaseForm {
  render() {
    const {
      onSubmit,
      t,
      data: { comment },
    } = this.props;
    return (
      <Row>
        <Col lg={12}>
          <Form
            className="horizontal"
            onSubmit={onSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            <Textarea
              rows={4}
              cols={50}
              name="comment"
              value={comment}
              label={t('OEEInput.yourComment')}
            />
            <div className="text-center m-t-md">
              <button type="submit" className="btn btn-primary btn-lg" formNoValidate>
                {t('common.save')}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

const OEEInputCommentModal = ({ open, close, data, onSaveComment }) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper title={t('OEEInput.comments')} open={open} close={close}>
      <Translation>
        {t => <OEEInputCommentForm data={data} onSubmit={onSaveComment} t={t} />}
      </Translation>
    </ModalWrapper>
  );
};

const OEEInputCommentButton = ({ comment, onSaveComment }) => {
  const [isOpenCommentModal, setIsOpenPlantModal] = useState(false);
  const toggleCommentModal = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpenPlantModal(!isOpenCommentModal);
  };
  const saveComment = data => {
    setIsOpenPlantModal(false);
    onSaveComment(data.comment);
  };
  const commentIcon = comment ? 'fa fa-commenting fa-lg' : 'fa fa-commenting-o fa-lg';
  return (
    <span className="oee-input__comment">
      <a href="#/" className="btn" onClick={toggleCommentModal}>
        <i className={commentIcon} />
      </a>
      <OEEInputCommentModal
        open={isOpenCommentModal}
        close={toggleCommentModal}
        data={{ comment }}
        onSaveComment={saveComment}
      />
    </span>
  );
};

const OEEInputComment: React.FC<{ comment: string; isEdit: boolean; onSaveComment: Function }> = ({
  comment,
  isEdit,
  onSaveComment,
}) => {
  const { t } = useTranslation();
  if (!isEdit) {
    if (comment) {
      const commentOverlay = (
        <Popover
          id="oee-input-comment-popover"
          className="popover-md"
          title={t('OEEInput.comments')}
        >
          <p>{comment}</p>
        </Popover>
      );
      return (
        <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={commentOverlay}>
          <span className="oee-input__comment">
            <i className="fa fa-commenting fa-lg" />
          </span>
        </OverlayTrigger>
      );
    }
    return null;
  }
  return <OEEInputCommentButton comment={comment} onSaveComment={onSaveComment} />;
};

export default React.memo(OEEInputComment);
