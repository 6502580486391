import React from 'react';
import { withFormsy } from 'formsy-react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { OverlayTrigger } from 'react-bootstrap';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(option) {
    this.props.setValue(option);

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(option);
    }
  }

  render() {
    const {
      id,
      label,
      name,
      multi,
      options,
      placeholder,
      clearable,
      searchable,
      disabled,
      toolTipInfoContent,
      ...rest
    } = this.props;

    const className = classNames('form-group', this.props.className, {
      required: this.props.isRequired(),
      error: this.props.showError(),
      row: this.props.layout === 'horizontal',
    });

    return (
      <div className={className}>
        {label && (
          <label
            className={`control-label ${this.props.layout === 'horizontal' ? ' col-md-3' : ''}`}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        {toolTipInfoContent && (
          <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={toolTipInfoContent}>
            <i className="fa fa-info-circle m-l" />
          </OverlayTrigger>
        )}
        <ReactSelect
          id={id}
          name={name}
          isMulti={multi}
          onChange={this.changeValue}
          defaultValue={this.props.getValue()}
          options={options}
          placeholder={placeholder}
          className={this.props.layout === 'horizontal' && label ? ' col-md-9' : ''}
          isClearable={clearable}
          isSearchable={searchable}
          isDisabled={disabled}
          {...rest}
        />
        <span className="error-message">{this.props.getErrorMessage()}</span>
      </div>
    );
  }
}

export default withFormsy(Select);
