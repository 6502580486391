import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  API_INDEX_USER_PATH,
  API_SHOW_USER_PATH,
  API_CREATE_USER_PATH,
  API_UPDATE_USER_PATH,
  API_DELETE_USER_PATH,
  API_EXPIRE_USER_PASSWORD_PATH,
  API_EXPORT_USER_PATH,
} from '../../utils/routeHelper';
import { User, UserData } from '../../common/models/user';
import { error } from '../../utils/error';
import { addToCollection, removeFromCollection, updateCollection } from '../../utils/collection';
import { downloadFile } from '../../utils/appHelper';
import { SIGNOUT } from './auth';

export const INDEX_USER = 'users/INDEX_USER';
export const INDEX_USER_SUCCESS = 'users/INDEX_USER_SUCCESS';
export const INDEX_USER_FAIL = 'users/INDEX_USER_FAIL';
export const SHOW_USER = 'users/SHOW_USER';
export const SHOW_USER_SUCCESS = 'users/SHOW_USER_SUCCESS';
export const SHOW_USER_FAIL = 'users/SHOW_USER_FAIL';
export const CREATE_USER = 'users/CREATE_USER';
export const CREATE_USER_SUCCESS = 'users/CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'user/CREATE_USER_FAIL';
export const UPDATE_USER = 'users/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'users/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'users/UPDATE_USER_FAIL';
export const DELETE_USER = 'users/DELETE_USER';
export const DELETE_USER_SUCCESS = 'users/DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'users/DELETE_USER_FAIL';
export const EXPIRE_USER_PASSWORD = 'users/EXPIRE_USER_PASSWORD';
export const EXPIRE_USER_PASSWORD_SUCCESS = 'users/EXPIRE_USER_PASSWORD_SUCCESS';
export const EXPIRE_USER_PASSWORD_FAIL = 'users/EXPIRE_USER_PASSWORD_FAIL';
export const EXPORT_USER = 'categories/EXPORT_USER';
export const EXPORT_USER_SUCCESS = 'categories/EXPORT_USER_SUCCESS';
export const EXPORT_USER_FAIL = 'categories/EXPORT_USER_FAIL';

interface UserState {
  data?: Array<User>;
  loading: boolean;
  creating?: boolean;
  updating?: boolean;
  deleting?: boolean;
  error?: string;
  createError?: string;
  updateError?: string;
  deleteError?: string;
}

const initialState: UserState = {
  data: [],
  loading: false,
  error: undefined,
};

export default function reducer(state: UserState = initialState, action: ActionResult): UserState {
  switch (action.type) {
    case INDEX_USER:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_USER_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case SHOW_USER:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SHOW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: addToCollection(state.data, action.result.data),
      };
    case SHOW_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: error(action),
      };
    case UPDATE_USER:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case DELETE_USER:
      return {
        ...state,
        deleting: true,
        deleteError: undefined,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleting: false,
        data: removeFromCollection(state.data, action.preload),
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        deleting: false,
        deleteError: error(action),
      };
    case EXPIRE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        data: updateCollection(state.data, action.result.data),
      };
    case EXPORT_USER_SUCCESS:
      downloadFile(action.result, 'users.xlsx');
      return state;
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexUser(): ActionPromise {
  return {
    types: [INDEX_USER, INDEX_USER_SUCCESS, INDEX_USER_FAIL],
    promise: client => client.get(API_INDEX_USER_PATH),
  };
}

export function showUser(id: string): ActionPromise {
  return {
    types: [SHOW_USER, SHOW_USER_SUCCESS, SHOW_USER_FAIL],
    promise: client => client.get(API_SHOW_USER_PATH(id)),
  };
}

export function createUser(data: UserData): ActionPromise {
  return {
    types: [CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_FAIL],
    promise: client => client.post(API_CREATE_USER_PATH, data),
  };
}

export function updateUser(id: string, data: UserData): ActionPromise {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL],
    promise: client => client.put(API_UPDATE_USER_PATH(id), data),
  };
}

export function deleteUser(id: string): ActionPromise {
  return {
    types: [DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAIL],
    promise: client => client.delete(API_DELETE_USER_PATH(id)),
    preload: { _id: id },
  };
}

export function expireUserPassword(id: string): ActionPromise {
  return {
    types: [EXPIRE_USER_PASSWORD, EXPIRE_USER_PASSWORD_SUCCESS, EXPIRE_USER_PASSWORD_FAIL],
    promise: client => client.put(API_EXPIRE_USER_PASSWORD_PATH(id)),
  };
}

export const usersSelector = state => state.users.data;

const _userSelector = createSelector(
  usersSelector,
  (_users: User[], id: string) => id,
  (users: User[], id: string) => {
    return _.find(users, { _id: id });
  },
);
export const userSelector = (id: string) => {
  return state => {
    return _userSelector(state, id);
  };
};

export function exportUser(): ActionPromise {
  return {
    types: [EXPORT_USER, EXPORT_USER_SUCCESS, EXPORT_USER_FAIL],
    promise: client => client.get(API_EXPORT_USER_PATH, { responseType: 'blob' }),
  };
}
