import React from 'react';
import classNames from 'classnames';

interface PageNavProps {
  children: React.ReactNode;
  fluid?: boolean;
  containerLg?: boolean;
}

const PageNav: React.FC<PageNavProps> = ({ fluid, containerLg, children }) => {
  const containerClassName = classNames({
    container: !fluid && !containerLg,
    'container-fluid': fluid,
    'container-lg': containerLg,
  });

  return (
    <div className="nav-secondary">
      <section className={containerClassName}>{children}</section>
    </div>
  );
};

export default React.memo(PageNav);
