/* global Blob atob */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-cropper';

export class ImageCropModal extends Component {
  static dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = decodeURIComponent(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  constructor(props) {
    super(props);
    this.handleRotateLeft = this.handleRotateLeft.bind(this);
    this.handleRotateRight = this.handleRotateRight.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCropImage = this.handleCropImage.bind(this);
  }

  handleRotateRight() {
    this.cropper.rotate(90);
  }

  handleRotateLeft() {
    this.cropper.rotate(-90);
  }

  handleCropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    this.props.onSubmit(
      {
        b64Result: this.cropper.getCroppedCanvas().toDataURL(),
        blobResult: ImageCropModal.dataURItoBlob(this.cropper.getCroppedCanvas().toDataURL()),
      },
      this.props.onSubmitValues,
    );
  }

  handleCancel() {
    this.props.close(this.props.index);
  }

  render() {
    const { open, title, image, aspectRatio, autoCrop, t } = this.props;
    return (
      <Modal show={open} onHide={this.handleCancel}>
        <Modal.Header>
          <Modal.Title className="text-center">{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Cropper
            style={{ height: 400, width: '100%' }}
            aspectRatio={aspectRatio}
            autoCrop={autoCrop}
            preview=".img-preview"
            guides={false}
            src={image}
            ref={item => {
              this.cropper = item;
            }}
            crop={this._crop}
          />
          <Button onClick={this.handleRotateLeft} className="btn btn-info m-t">
            <i className="fa fa-undo" aria-hidden="true" />
          </Button>
          <Button onClick={this.handleRotateRight} className="btn btn-info m-t">
            <i className="fa fa-repeat" aria-hidden="true" />
          </Button>
          <Button onClick={this.handleCancel} className="m-t float-right">
            {t('common.close')}
          </Button>
          <Button onClick={this.handleCropImage} className="btn btn-warning m-t float-right">
            {t('common.valid')}
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}

ImageCropModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number,
  autoCrop: PropTypes.bool.isRequired,
  index: PropTypes.any,
  onSubmitValues: PropTypes.any,
};

export default withTranslation()(ImageCropModal);
