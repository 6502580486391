import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import i18n from 'i18next';
import SelectFilter from '../Filter/SelectFilter';
import { ALL } from '../../../common/filter';

export default class PlantFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.filterByName = this.filterByName.bind(this);
    this.filterByBU = this.filterByBU.bind(this);
    this.filterByRegion = this.filterByRegion.bind(this);
    this.filterByArchived = this.filterByArchived.bind(this);
  }

  buildBusinessUnitOptions() {
    let buOptions = this.props.businessUnits.map(bu => {
      return { value: bu._id, label: bu.name };
    });
    buOptions = _.orderBy(buOptions, 'label');
    buOptions.unshift({ value: ALL, label: i18n.t('businessUnit.businessUnit') });
    return buOptions;
  }

  buildRegionOptionsByBU(bu) {
    let regionOptions = [];
    if (bu === ALL) {
      this.props.regions.forEach(r => {
        regionOptions.push({ value: r._id, label: r.name });
      });
    } else {
      _.filter(this.props.regions, { businessUnitId: bu }).forEach(r => {
        regionOptions.push({ value: r._id, label: r.name });
      });
    }
    regionOptions = _.orderBy(regionOptions, 'label');
    regionOptions.unshift({ value: ALL, label: i18n.t('region.region') });
    return regionOptions;
  }

  buildPlantArchivedOptions() {
    return [
      { value: false, label: i18n.t('plant.hideArchived') },
      { value: true, label: i18n.t('plant.showArchived') },
    ];
  }

  filterByBU(bu) {
    const newFilter = Object.assign({}, this.props.filter, { businessUnitId: bu, regionId: ALL });
    this.handleFilter(newFilter);
  }

  filterByRegion(region) {
    const newFilter = Object.assign({}, this.props.filter, { regionId: region });
    this.handleFilter(newFilter);
  }

  filterByName(e) {
    const name = e.target.value;
    const newFilter = Object.assign({}, this.props.filter, { name });
    this.handleFilter(newFilter);
  }

  filterByArchived(archived) {
    const newFilter = Object.assign({}, this.props.filter, { archived: archived === 'true' });
    this.handleFilter(newFilter);
  }

  handleFilter(filter) {
    this.setState(filter);
    this.props.onChange(filter);
  }

  render() {
    const { name, businessUnitId, regionId, archived } = this.props.filter;
    return (
      <div className="text-right">
        <form className="form-inline">
          <div className="form-group">
            <input
              name="query"
              type="text"
              className="form-control"
              placeholder={i18n.t('plant.search')}
              onChange={this.filterByName}
              value={name}
            />
          </div>
          <div className="form-group">
            <SelectFilter
              options={this.buildBusinessUnitOptions()}
              onChange={this.filterByBU}
              value={businessUnitId}
            />
          </div>
          <div className="form-group">
            <SelectFilter
              options={this.buildRegionOptionsByBU(businessUnitId)}
              onChange={this.filterByRegion}
              value={regionId}
            />
          </div>
          <div className="form-group">
            <SelectFilter
              options={this.buildPlantArchivedOptions()}
              onChange={this.filterByArchived}
              value={archived}
            />
          </div>
        </form>
      </div>
    );
  }
}

PlantFilter.propTypes = {
  businessUnits: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
};
