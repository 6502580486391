import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, RadioGroup } from 'formsy-react-components';
import BaseForm from '../Form/BaseForm';

export class UserForm extends BaseForm {
  handleSubmit(data) {
    this.props.onSubmit(data);
  }

  render() {
    const {
      data: { firstName, lastName, email, phone, role },
      t,
    } = this.props;
    const roleOptions = [
      { value: 'admin', label: <span>{t('user.admin')}</span> },
      { value: 'user', label: <span>{t('user.user')}</span> },
    ];
    const sharedProps = {
      validations: 'maxLength:255',
      validationError: t('errors.inputTextMaxLength', { max: 255 }),
    };
    return (
      <Row>
        <Col lg={12}>
          <Form
            onSubmit={this.handleSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="firstName"
              value={firstName}
              label={t('user.firstName')}
              type="text"
              placeholder={t('user.yourFirstName')}
              {...sharedProps}
              required
            />
            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="lastName"
              value={lastName}
              label={t('user.lastName')}
              type="text"
              placeholder={t('user.yourLastName')}
              {...sharedProps}
              required
            />
            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="email"
              value={email}
              label={t('user.email')}
              type="text"
              placeholder={t('user.yourEmail')}
              validations="isEmail"
              required
            />
            <Input
              className="form-control form-control-lg"
              layout="vertical"
              name="phone"
              value={phone}
              label={t('user.phone')}
              type="text"
              {...sharedProps}
              placeholder={t('user.yourPhoneNumber')}
            />

            <RadioGroup
              layout="vertical"
              name="role"
              type="inline"
              label={t('user.role')}
              value={role}
              options={roleOptions}
              required
            />

            <div className="text-center m-t-md">
              <button
                type="submit"
                className="btn btn-primary btn-lg m-r-md"
                formNoValidate
                disabled={!this.state.canSubmit}
              >
                {t('common.save')}
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-lg"
                onClick={this.props.onCancel}
              >
                {t('common.cancel')}
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(UserForm);
