import _ from 'lodash';
import { Category, LossType, TreatedWasteLossType } from '../models/category';
import { LineType } from '../models/plant';

export const filterCategories = (
  categories: Category[],
  lineType?: LineType,
  lossType?: LossType,
  loss?: TreatedWasteLossType,
): Category[] => {
  return _.filter(categories, (c: Category) => {
    if (lineType && (lossType || loss)) {
      return !!(
        _.includes(c.lineType, lineType) && _.find(c.lossType, loss ? { loss } : { name: lossType })
      );
    } else if (lineType) {
      return !!_.includes(c.lineType, lineType);
    } else if (lossType) {
      return !!_.find(c.lossType, { name: lossType });
    } else if (loss) {
      return !!_.find(c.lossType, { loss });
    } else {
      return true;
    }
  });
};
