import { Model } from './model';

export enum OEEExportType {
  kpi = 'kpi',
  oeeLosses = 'oeeLosses',
  oeeBudgetChallenge = 'oeeBudgetChallenge',
  stopsAndEvents = 'stopsAndEvents',
}

export enum OEEDurationType {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export interface OEEData {
  plantId: string;
  line: string;
  shift?: string;
  date: Date;
  indicator: string;
  value?: number;
  category?: string;
  prolongParent?: string;
  comment?: string;
}
export interface OEE extends Model, OEEData {
  value: number;
}
