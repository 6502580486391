/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { toggleClassByTagName, addClassByTagName } from '../../../utils/vanilla';
import SidebarItem from './SidebarItem';
import logoWhite from '../../../assets/images/logo-suez-white.png';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    const tabletBreakpoint = 992;
    this.state = {
      reduce: window.innerWidth < tabletBreakpoint,
      tabletBreakpoint,
      desktopBreakpoint: 1150,
      openedSidebarItem: this.getCurrentSidebarItem(),
    };

    if (this.state.reduce) toggleClassByTagName('body', 'reduce');

    this.itemClicked = this.itemClicked.bind(this);
    this.itemOpenToggled = this.itemOpenToggled.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'object' && !!window) {
      window.addEventListener('resize', this.handleResize);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    if (typeof window === 'object' && !!window) {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  getCurrentSidebarItem() {
    return this.props.sidebarItems.filter(sidebarItem => {
      if (!sidebarItem.children) return false;
      return sidebarItem.children.filter(item => {
        if (item.children) {
          return item.children.filter(i => {
            return this.props.currentPath.includes(i.path);
          })[0];
        }
        return this.props.currentPath.includes(item.path);
      })[0];
    })[0];
  }

  handleResize() {
    if ('matchMedia' in window) {
      if (window.matchMedia(`screen and (max-width: ${this.state.desktopBreakpoint}px)`).matches) {
        const { reduce } = this.state;
        if (!reduce) {
          this.closeSidebar();
        }
      }
    }
  }

  itemClicked() {
    if (window.innerWidth < 992) this.toggleSidebar();
  }

  itemOpenToggled(item, isOpen) {
    this.setState({ openedSidebarItem: isOpen ? item : null });
  }

  closeSidebar(e) {
    if (e) e.preventDefault();
    this.setState({
      reduce: true,
    });
    addClassByTagName('body', 'reduce');
  }
  toggleSidebar(e) {
    if (e) e.preventDefault();
    this.setState({
      reduce: !this.state.reduce,
    });
    toggleClassByTagName('body', 'reduce');
  }

  buildItemsView() {
    return this.props.sidebarItems.map((item, i) => {
      return (
        <SidebarItem
          key={i}
          item={item}
          currentPath={this.props.currentPath}
          onItemClicked={this.itemClicked}
          onOpenToggled={this.itemOpenToggled}
          openedSibling={this.state.openedSidebarItem}
          reduce={this.state.reduce}
        />
      );
    });
  }

  render() {
    const sidebarClass = classNames('sidebar', {
      reduce: this.state.reduce,
    });
    const sidebarButtonClass = classNames('sidebar__button', {
      active: this.state.reduce,
    });
    return (
      <div>
        <a href="#/" className={sidebarButtonClass} onClick={this.toggleSidebar}>
          <i className="fa fa-bars" />
        </a>
        <nav className={sidebarClass}>
          <div className="sidebar__header">
            <Link className="sidebar__logo" to="/app">
              <img src={logoWhite} alt="SUEZ" className="logo" />
            </Link>
          </div>
          <ul className="nav">{this.buildItemsView()}</ul>
        </nav>
      </div>
    );
  }
}

Sidebar.propTypes = {
  sidebarItems: PropTypes.array.isRequired,
  currentPath: PropTypes.string.isRequired,
};
