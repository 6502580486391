import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const ModalWrapper = ({
  title,
  open,
  close,
  children,
  bsSize,
  dialogClassName,
  hideCloseButton,
  backdrop,
  keyboard,
}) => {
  const closeButton = !hideCloseButton;
  return (
    <Modal
      show={open}
      onHide={close}
      bsSize={bsSize}
      dialogClassName={dialogClassName}
      backdrop={backdrop}
      keyboard={keyboard}
    >
      <Modal.Header closeButton={closeButton}>
        <Modal.Title className="text-center">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

ModalWrapper.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.node,
  bsSize: PropTypes.string,
  dialogClassName: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  backdrop: PropTypes.string,
  keyboard: PropTypes.bool,
};

export default React.memo(ModalWrapper);
