import { Model, identifier } from './model';

export const OEE_INPUT_BEGINNING_DATE = '2019-01-01' as const;
export const OEE_INPUT_OPENING_TIME = 24 as const;
export const THEORETICAL_HOURS_PER_DAY = 24 as const;

export enum OEEInputType {
  availability = 'availability',
  wasteTreated = 'wasteTreated',
}

export interface OEEInputAvailability {
  openingTime: number;
  stopDetail: {
    [identifier]: string;
    stop: number;
    planned: boolean;
    prolongParent: string;
    category: string;
    categories: string[];
    comment: string;
  }[];
}

export interface OEEInputWasteTreated {
  wasteTreated: number;
  lossDetail: {
    [identifier]: string;
    loss: number;
    category: string;
    categories: string[];
    comment: string;
  }[];
}

export enum OEEInputValidationAction {
  validate = 'validate',
  unvalidate = 'unvalidate',
}

export interface OEEInputData {
  plantId?: string;
  line?: string;
  shift?: string;
  date?: Date;
  availability?: OEEInputAvailability;
  wasteTreated?: OEEInputWasteTreated;
}

export interface OEEInput extends Model, OEEInputData {
  plantId: string;
  line: string;
  date: Date;
  availability: OEEInputAvailability;
  wasteTreated: OEEInputWasteTreated;
  availabilityCompleted: boolean;
  wasteTreatedCompleted: boolean;
  isValid: boolean;
  validationHistory: {
    date: Date;
    action: OEEInputValidationAction;
    userId: string;
  }[];
}
