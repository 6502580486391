import _ from 'lodash';
import {
  API_INDEX_NEWS_PATH,
  API_UNREAD_NEWS_PATH,
  API_CREATE_NEWS_PATH,
  API_UPDATE_NEWS_PATH,
  API_DELETE_NEWS_PATH,
  API_UPDATE_NEWS_AS_READ_PATH,
} from '../../utils/routeHelper';
import {
  removeFromCollection,
  updateCollection,
  addToCollection,
  bulkUpdateCollection,
  bulkRemoveFromCollection,
} from '../../utils/collection';
import { News } from '../../common/models/news';
import { error } from '../../utils/error';
import { identifier } from '../../common/models/model';
import { SIGNOUT } from './auth';

export const INDEX_NEWS = 'news/INDEX_NEWS';
export const INDEX_NEWS_SUCCESS = 'news/INDEX_NEWS_SUCCESS';
export const INDEX_NEWS_FAIL = 'news/INDEX_NEWS_FAIL';
export const INDEX_UNREAD_NEWS = 'news/INDEX_UNREAD_NEWS';
export const INDEX_UNREAD_NEWS_SUCCESS = 'news/INDEX_UNREAD_NEWS_SUCCESS';
export const INDEX_UNREAD_NEWS_FAIL = 'news/INDEX_UNREAD_NEWS_FAIL';
export const CREATE_NEWS = 'news/CREATE_NEWS';
export const CREATE_NEWS_SUCCESS = 'news/CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAIL = 'news/CREATE_NEWS_FAIL';
export const UPDATE_NEWS = 'news/UPDATE_NEWS';
export const UPDATE_NEWS_SUCCESS = 'news/UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAIL = 'news/UPDATE_NEWS_FAIL';
export const UPDATE_NEWS_AS_READ = 'news/UPDATE_NEWS_AS_READ';
export const UPDATE_NEWS_AS_READ_SUCCESS = 'news/UPDATE_NEWS_AS_READ_SUCCESS';
export const UPDATE_NEWS_AS_READ_FAIL = 'news/UPDATE_NEWS_AS_READ_FAIL';
export const DELETE_NEWS = 'news/DELETE_NEWS';
export const DELETE_NEWS_SUCCESS = 'news/DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAIL = 'news/DELETE_NEWS_FAIL';

interface NewsState {
  data?: Array<News>;
  unread?: Array<News>;
  loading: boolean;
  loadingUnread?: boolean;
  creating?: boolean;
  updating?: boolean;
  deleting?: boolean;
  error?: string;
  unreadError?: string;
  createError?: string;
  updateError?: string;
  deleteError?: string;
}

const initialState: NewsState = {
  data: undefined,
  unread: [],
  loading: false,
  error: undefined,
};

export default function reducer(state: NewsState = initialState, action: ActionResult): NewsState {
  switch (action.type) {
    case INDEX_NEWS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INDEX_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result.data,
      };
    case INDEX_NEWS_FAIL:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: error(action),
      };
    case INDEX_UNREAD_NEWS:
      return {
        ...state,
        loadingUnread: true,
        unreadError: undefined,
      };
    case INDEX_UNREAD_NEWS_SUCCESS:
      return {
        ...state,
        loadingUnread: false,
        unread: action.result.data,
      };
    case INDEX_UNREAD_NEWS_FAIL:
      return {
        ...state,
        loadingUnread: false,
        unread: undefined,
        unreadError: error(action),
      };
    case CREATE_NEWS:
      return {
        ...state,
        creating: true,
        createError: undefined,
      };
    case CREATE_NEWS_SUCCESS:
      return {
        ...state,
        creating: false,
        data: addToCollection(state.data, action.result.data),
      };
    case CREATE_NEWS_FAIL:
      return {
        ...state,
        creating: false,
        createError: error(action),
      };
    case UPDATE_NEWS:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        updating: false,
        data: updateCollection(state.data, action.result.data),
      };
    case UPDATE_NEWS_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case UPDATE_NEWS_AS_READ:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_NEWS_AS_READ_SUCCESS:
      return {
        ...state,
        updating: false,
        data: bulkUpdateCollection(state.data, action.result.data),
        unread: bulkRemoveFromCollection(state.unread, action.result.data),
      };
    case UPDATE_NEWS_AS_READ_FAIL:
      return {
        ...state,
        updating: false,
        updateError: error(action),
      };
    case DELETE_NEWS:
      return {
        ...state,
        deleting: true,
        deleteError: undefined,
      };
    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        deleting: false,
        data: removeFromCollection(state.data, action.preload),
      };
    case DELETE_NEWS_FAIL:
      return {
        ...state,
        deleting: false,
        deleteError: error(action),
      };
    case SIGNOUT:
      return initialState;
    default:
      return state;
  }
}

export function indexNews(): ActionPromise {
  return {
    types: [INDEX_NEWS, INDEX_NEWS_SUCCESS, INDEX_NEWS_FAIL],
    promise: client => client.get(API_INDEX_NEWS_PATH),
  };
}

export function unreadNews(): ActionPromise {
  return {
    types: [INDEX_UNREAD_NEWS, INDEX_UNREAD_NEWS_SUCCESS, INDEX_UNREAD_NEWS_FAIL],
    promise: client => client.get(API_UNREAD_NEWS_PATH),
  };
}

export function createNews(news: News): ActionPromise {
  return {
    types: [CREATE_NEWS, CREATE_NEWS_SUCCESS, CREATE_NEWS_FAIL],
    promise: client => client.post(API_CREATE_NEWS_PATH, news),
  };
}

export function updateNews(news: News): ActionPromise {
  return {
    types: [UPDATE_NEWS, UPDATE_NEWS_SUCCESS, UPDATE_NEWS_FAIL],
    promise: client => client.patch(API_UPDATE_NEWS_PATH(news._id), news),
  };
}

export function updateNewsAsRead(news: News[]): ActionPromise {
  return {
    types: [UPDATE_NEWS_AS_READ, UPDATE_NEWS_AS_READ_SUCCESS, UPDATE_NEWS_AS_READ_FAIL],
    promise: client => client.patch(API_UPDATE_NEWS_AS_READ_PATH, _.map(news, identifier)),
  };
}

export function deleteNews(news: News): ActionPromise {
  return {
    types: [DELETE_NEWS, DELETE_NEWS_SUCCESS, DELETE_NEWS_FAIL],
    promise: client => client.delete(API_DELETE_NEWS_PATH(news._id)),
    preload: { _id: news._id },
  };
}
